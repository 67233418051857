import React from 'react'
import { Button, Label, Popup } from 'semantic-ui-react'
import _ from 'lodash'

import DataTable from './DataTable'

const OutsourcedArticlesDataTable = ({
  outsourcedArticles,
  tableData,
  handleDataTablePageChange,
  hasUpdatePermission,
  changeShared,
  patchOutsourcedArticle,
}) => {
  const createButtonGroup = item => (
    <Button.Group secondary>
      {_.get(item, 'title') && _.get(item, 'summary') && _.get(item, 'description') && (
        <Button
          disabled={!hasUpdatePermission}
          as="a"
          icon="arrow down"
          href={`/article/${item.articleId}?fromOutsourcedArticle=true`}
        />
      )}
      {(!_.get(item, 'title') || !_.get(item, 'summary') || !_.get(item, 'description')) && (
        <Popup
          content="記事が書かれていない為取り込めません。記事編集委託ツールでタイトル・概要・本文を入力し、記事を編集してください。"
          trigger={<Button icon="arrow down" />}
        />
      )}
      <Button icon={item.shared === false ? 'share alternate' : 'cancel'} onClick={() => changeShared(item)} />}
    </Button.Group>
  )

  const columns = [
    {
      label: 'ID',
      align: 'center',
      render: item => item.articleId,
    },
    {
      label: '共有',
      align: 'center',
      render: item => (item.shared ? <Label color="green">ON</Label> : <Label color="red">OFF</Label>),
    },
    {
      label: '編集用URL',
      render: item => {
        const url = `${process.env.REACT_APP_TRILL_OUTSOURCE_URL}/article/${item.articleId}?token=${item.id}`
        return (
          <Button
            disabled={!item.shared}
            as="a"
            icon="external"
            content="外部サイト"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          />
        )
      },
    },
    {
      label: 'タイトル',
      collapsing: false,
      render: item => _.get(item, 'title'),
    },
    {
      label: '備考',
      collapsing: false,
      render: item => {
        const comment = _.get(item, 'comment')
        let returnedObject

        const handleBlur = async eventTarget => {
          returnedObject = await patchOutsourcedArticle(item.articleId, { comment: eventTarget.innerText })
          const style = eventTarget.style
          if (_.get(returnedObject, 'patchError')) {
            style.margin = '-11px'
            style.padding = '19px 11px 20px 11px'
            style.background = '#fff6f6'
            style.color = '#9f3a38'
          }
        }

        return (
          <div
            contentEditable="true"
            dangerouslySetInnerHTML={{ __html: _.isUndefined(comment) ? '' : comment.replace(/\n/g, '<br />') }}
            onBlur={event => handleBlur(event.target)}
          />
        )
      },
    },
    {
      label: '操作',
      align: 'center',
      render: item => createButtonGroup(item),
    },
  ]

  return (
    <DataTable
      rowKey="id"
      columns={columns}
      items={outsourcedArticles}
      itemsPerPage={tableData.itemsPerPage}
      currentPage={tableData.currentPage}
      totalPages={tableData.totalPages}
      onPageChange={handleDataTablePageChange}
    />
  )
}

export default OutsourcedArticlesDataTable
