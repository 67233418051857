import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Modal, Dimmer, Loader, Button } from 'semantic-ui-react'
import { TopApi } from 'trill-api-admin-client'

import CancelablePromise from '../CancelablePromise'
import ApiErrorMessage from './ApiErrorMessage'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('RecommendedArticleRemoveModal')
const topApi = new TopApi()
let deleteRecommendedArticle

const propTypes = {
  /**
   * 削除対象のおすすめ記事
   */
  recommendedArticle: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  recommendedArticle: {},
  open: false,
}

class RecommendedArticleRemoveModal extends Component {
  state = {
    isBusy: false,
    apiError: null,
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(deleteRecommendedArticle)) {
      deleteRecommendedArticle.cancel()
    }
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 削除ボタンを押したときのハンドラ
   */
  handleRemoveButtonClick = () => {
    const recommendedArticleId = _.get(this.props, 'recommendedArticle.id')

    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteRecommendedArticle = CancelablePromise(topApi.deleteRecommendedArticle(recommendedArticleId))
    deleteRecommendedArticle.promise
      .then(() => {
        // 削除に成功したら一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.setState({ isBusy: false }, () => {
          if (this.props.onClose) {
            this.props.onClose()
          }
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`recommended article delete {recommended article id: ${recommendedArticleId}. error `, error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <Modal
        className="RecommendedArticleRemoveModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>編集部おすすめの削除</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <p>編集部おすすめ記事をゴミ箱へ移動しますか？</p>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button negative content="ゴミ箱へ移動" onClick={this.handleRemoveButtonClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

RecommendedArticleRemoveModal.propTypes = propTypes
RecommendedArticleRemoveModal.defaultProps = defaultProps

export default RecommendedArticleRemoveModal
