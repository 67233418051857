import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Message, Dimmer, Loader } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { SponsorApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('SponsorRemoveModal')
const sponsorApi = new SponsorApi()
let deleteSponsor

const propTypes = {
  /**
   * 削除対象のスポンサー
   */
  sponsor: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新に成功したときに呼び出す外部関数
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  open: false,
}

class SponsorRemoveModal extends Component {
  state = {
    isBusy: false,
    isFormValid: false,
    apiError: null,
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(deleteSponsor)) {
      deleteSponsor.cancel()
    }
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効なときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = () => {
    const sponsorId = this.props.sponsor.id

    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteSponsor = CancelablePromise(sponsorApi.deleteSponsor(sponsorId))
    deleteSponsor.promise
      .then(() => {
        // 削除に成功したら一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.setState({ isBusy: false }, () => {
          if (this.props.onClose) {
            this.props.onClose()
          }
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`remove sponsor id #${sponsorId} error`, error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 削除ボタンを押したときのハンドラ
   */
  handleRemoveButtonClick = () => {
    this.refs.form.submit()
  }

  render() {
    return (
      <Modal
        className="SponsorRemoveModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{`${_.get(this.props.sponsor, 'name', 'スポンサー')} の削除`}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          {/* 警告メッセージ */}
          <Message negative content="確認のため、ゴミ箱へ移動するスポンンサーのスポンサー名を入力してください。" />

          {/* 削除フォーム */}
          {this.props.sponsor && (
            <Form
              ref="form"
              noValidate
              onChange={this.handleFormChange}
              onValid={this.handleFormValid}
              onInvalid={this.handleFormInvalid}
              onValidSubmit={this.handleFormValidSubmit}
            >
              {/* スポンサー名入力フィールド */}
              <Form.Input
                name="name"
                label="スポンサー名"
                placeholder="削除するスポンサー名を入力してください"
                validations={{
                  isSameSponsorName: (values, value) => value === this.props.sponsor.name,
                }}
                required
              />
            </Form>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            negative
            content="ゴミ箱へ移動"
            onClick={this.handleRemoveButtonClick}
            disabled={!this.state.isFormValid}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

SponsorRemoveModal.propTypes = propTypes
SponsorRemoveModal.defaultProps = defaultProps

export default SponsorRemoveModal
