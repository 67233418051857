import React, { Component } from 'react'
import { Header, Icon, Button, Divider, Label, Dropdown, Checkbox, Container } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import _ from 'lodash'
import moment from 'moment'

import FormErrorLabel from './FormErrorLabel'
import MediaDropdown from './MediaDropdown'
import CategoriesDropdown from './CategoriesDropdown'
import ManagementCategoriesDropdown from './ManagementCategoriesDropdown'
import SponsorsDropdown from './SponsorsDropdown'
import PublishDatetimeRangePicker from './PublishDatetimeRangePicker'

import ArticleStatus from '../enums/ArticleStatus'

import './ArticleFilterContainer.css'

const DEFAULT_FILTERING = {
  text: '',
  textType: 'keyword',
  mediumIds: [],
  categoryIds: [],
  managementCategoryIds: [],
  sponsorIds: [],
  type: null,
  mediumItem: {
    autoPublish: null,
    shannonSubmissionAllowed: null,
  },
  createDateRangeStartAt: moment().startOf('day'),
  createDateRangeEndAt: moment().endOf('day'),
  clearCreateDateRange: true,
  publishDateRangeStartAt: moment().startOf('day'),
  publishDateRangeEndAt: moment().endOf('day'),
  clearPublishDateRange: true,
  isIncludingTrashArticle: true,
}
const LAPTOP_SCREEN_WIDTH = 1024

class ArticleFilterContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filtering: _.cloneDeep(DEFAULT_FILTERING),
      basicFilteringKeys: [],
      advancedFilteringKeys: [],
      isAdvancedFilterOpen: false,
      isSmallScreen: null,
    }

    this.advancedButtonRef = React.createRef()
    this.advancedSearchRef = React.createRef()
    this.handleAdvancedFilterOutsideClick = this.handleAdvancedFilterOutsideClick.bind(this)
    this.handleWindowResize = this.handleWindowResize.bind(this)
  }

  componentDidMount() {
    this.handleResetFilterKeys()

    document.addEventListener('click', this.handleAdvancedFilterOutsideClick)
    window.addEventListener('resize', this.handleWindowResize)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isNil(nextProps.filtering) && !_.isEqual(this.state.filtering, nextProps.filtering)) {
      this.setState({
        filtering: _.cloneDeep(nextProps.filtering),
        isAdvancedFilterOpen: false,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleAdvancedFilterOutsideClick)
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleAdvancedFilterOutsideClick(event) {
    const isOutClickAdvancedFilterElement =
      this.advancedSearchRef &&
      !this.advancedSearchRef.contains(event.target) &&
      this.advancedButtonRef &&
      !this.advancedButtonRef.contains(event.target)
    if (isOutClickAdvancedFilterElement && this.state.isAdvancedFilterOpen) {
      this.handleAdvancedFilterClose()
    }
  }

  handleWindowResize() {
    this.handleResetFilterKeys()
  }

  handleResetFilterKeys = () => {
    const documentWidth = document.documentElement.clientWidth
    const newIsSmallScreen = documentWidth <= LAPTOP_SCREEN_WIDTH

    if (_.isEqual(newIsSmallScreen, this.state.isSmallScreen)) {
      return
    }

    const basicFilteringKeys = [
      'text',
      'textType',
      'createDateRangeStartAt',
      'createDateRangeEndAt',
      'clearCreateDateRange',
      'publishDateRangeStartAt',
      'publishDateRangeEndAt',
      'clearPublishDateRange',
      'isIncludingTrashArticle',
      'mediumIds',
      'managementCategoryIds',
      'mediumItem.autoPublish',
      'mediumItem.shannonSubmissionAllowed',
    ]
    const advancedFilteringKeys = ['type', 'categoryIds', 'sponsorIds']

    this.setState({
      isSmallScreen: newIsSmallScreen,
      basicFilteringKeys,
      advancedFilteringKeys,
    })
  }

  handleAdvancedFilterClose = () => {
    const newState = {
      isAdvancedFilterOpen: false,
    }

    if (!_.isEqual(this.state.filtering, this.props.filtering)) {
      newState.filtering = _.cloneDeep(this.props.filtering)
    }

    this.setState(newState)
  }

  handleAdvancedFilterOpen = () => {
    this.setState({
      isAdvancedFilterOpen: true,
    })
  }

  /**
   * 公開日時で絞り込むピッカーのイベントハンドラ
   */
  handleDateRangePickerValueEvent = (event, { name, picker }) => {
    if (event.type === 'apply' || event.type === 'cancel') {
      const filtering = this.state.filtering
      const clearDateRangeKey = `clear${_.upperFirst(name)}`
      let startDate
      let endDate
      let clearDateRange

      if (event.type === 'apply') {
        startDate = picker.startDate
        endDate = picker.endDate
        clearDateRange = false
      } else if (event.type === 'cancel') {
        startDate = moment().startOf('day')
        endDate = moment().endOf('day')
        clearDateRange = true
      }

      filtering[`${name}StartAt`] = startDate
      filtering[`${name}EndAt`] = endDate
      filtering[clearDateRangeKey] = clearDateRange

      this.setState({ filtering }, () => {
        this.handleFilterChangeEmit(clearDateRangeKey)
      })
    }
  }

  /**
   * フィルタリングの変更をして記事一覧の更新を行う
   * @param {string} name - key となる値
   * @param {any} value - value となる値
   */
  handleFilteringValueChange = (event, { name, value }) => {
    const filtering = this.state.filtering
    const previousValue = _.get(filtering, name)

    if (_.isEqual(previousValue, value)) {
      return
    }

    _.set(filtering, name, value)

    this.setState({ filtering }, () => {
      this.handleFilterChangeEmit(name)
    })
  }

  handleFilterChangeEmit = key => {
    if (_.includes(this.state.basicFilteringKeys, key) && this.props.handleFilterChange) {
      const newBasicFiltering = _.pick(this.state.filtering, this.state.basicFilteringKeys)
      const oldOtherFiltering = _.omit(_.cloneDeep(this.props.filtering), this.state.basicFilteringKeys)
      const newFiltering = _.merge(oldOtherFiltering, newBasicFiltering)

      this.props.handleFilterChange(newFiltering)
    }
  }

  handleClearButtonAdvancedFilterClick = () => {
    const defaultAdvancedFiltering = _.pick(_.cloneDeep(DEFAULT_FILTERING), this.state.advancedFilteringKeys)
    const oldOtherFiltering = _.omit(this.state.filtering, this.state.advancedFilteringKeys)
    const newFiltering = _.merge(oldOtherFiltering, defaultAdvancedFiltering)

    this.setState({ isAdvancedFilterOpen: false }, () => {
      this.props.handleFilterChange(newFiltering)
    })
  }

  handleSubmitButtonAdvancedFilterClick = () => {
    if (this.props.handleFilterChange) {
      const newFiltering = _.cloneDeep(this.state.filtering)

      this.setState({ isAdvancedFilterOpen: false }, () => {
        this.props.handleFilterChange(newFiltering)
      })
    }
  }

  render() {
    const { filtering, basicFilteringKeys, advancedFilteringKeys, isAdvancedFilterOpen, isSmallScreen } = this.state

    const mediumItemShannonSubmissionAllowed = _.get(filtering, 'mediumItem.shannonSubmissionAllowed')
    const mediumItemAutoPublish = _.get(filtering, 'mediumItem.autoPublish')
    const renderAdvancedFilteringKeys = _.filter(
      advancedFilteringKeys,
      key =>
        !_.includes(
          ['createDateRangeStartAt', 'createDateRangeEndAt', 'publishDateRangeStartAt', 'publishDateRangeEndAt'],
          key,
        ),
    )

    const isAdvancedFilterSearched = !_.isEqual(
      _.pick(this.props.filtering, renderAdvancedFilteringKeys),
      _.pick(DEFAULT_FILTERING, renderAdvancedFilteringKeys),
    )
    const isAdvancedFilterChange = !_.isEqual(
      _.pick(filtering, renderAdvancedFilteringKeys),
      _.pick(this.props.filtering, renderAdvancedFilteringKeys),
    )
    const isDefaultAdvancedFilter = _.isEqual(
      _.pick(filtering, renderAdvancedFilteringKeys),
      _.pick(DEFAULT_FILTERING, renderAdvancedFilteringKeys),
    )

    const isAdvancedFilterSubmitButtonEnable =
      isAdvancedFilterChange || (isAdvancedFilterSearched && isDefaultAdvancedFilter)

    const articleSearchTextPlaceholderByType = {
      keyword: 'ID、タイトル、本文で検索',
      id: 'IDで検索',
      title: 'タイトルで検索',
      description: '本文で検索',
    }
    const articleSearchTextValidations = {
      matchRegexp: /^((?!,).)*$/i,
      isNumeric: true,
      maxLength: 10,
    }
    const articleSearchTextValidationErrors = {
      matchRegexp: 'キーワードに不正な記号があるため検索できません',
      isNumeric: '半角数字で入力してください',
      maxLength: '10桁までの半角数字で入力してください',
    }
    const articleSearchTextValidationKeysByType = {
      keyword: ['matchRegexp'],
      id: ['matchRegexp', 'isNumeric', 'maxLength'],
      title: ['matchRegexp'],
      description: ['matchRegexp'],
    }

    const isRenderBasicFilterComponent = type => {
      return _.includes(basicFilteringKeys, type)
    }

    const renderFilterComponent = type => {
      switch (type) {
        case 'text':
          return (
            <Input
              name="text"
              type="text"
              placeholder={articleSearchTextPlaceholderByType[filtering.textType] || ''}
              action
              value={filtering.text}
              onChange={this.handleFilteringValueChange}
              validations={_.reduce(
                articleSearchTextValidationKeysByType[filtering.textType],
                (result, key) => {
                  result[key] = articleSearchTextValidations[key]
                  return result
                },
                {},
              )}
              validationErrors={_.reduce(
                articleSearchTextValidationKeysByType[filtering.textType],
                (result, key) => {
                  result[key] = articleSearchTextValidationErrors[key]
                  return result
                },
                {},
              )}
              errorLabel={<FormErrorLabel />}
            >
              <input />

              {renderFilterComponent('textType')}

              <Button
                icon="search"
                onClick={this.props.handleSearchButtonClick}
                color="blue"
                disabled={!this.props.isFormSearchValid}
              />
            </Input>
          )

        case 'textType':
          return (
            <Dropdown
              name="textType"
              onChange={this.handleFilteringValueChange}
              value={filtering.textType}
              selection
              options={[
                {
                  key: 'keyword',
                  value: 'keyword',
                  text: 'キーワード',
                },
                {
                  key: 'id',
                  value: 'id',
                  text: 'ID',
                },
                {
                  key: 'title',
                  value: 'title',
                  text: 'タイトル',
                },
                {
                  key: 'description',
                  value: 'description',
                  text: '本文',
                },
              ]}
              style={{ minWidth: '9rem' }}
            />
          )

        case 'clearCreateDateRange':
          return (
            <PublishDatetimeRangePicker
              timePicker
              timePicker24Hour
              startDate={filtering.createDateRangeStartAt}
              endDate={filtering.createDateRangeEndAt}
              onEvent={(event, picker) => {
                this.handleDateRangePickerValueEvent(event, { name: 'createDateRange', picker })
              }}
              clear={filtering.clearCreateDateRange}
              placeholderText="登録日時で絞り込み"
              disabled={!this.props.isFormSearchValid}
            />
          )

        case 'clearPublishDateRange':
          return (
            <PublishDatetimeRangePicker
              timePicker
              timePicker24Hour
              startDate={filtering.publishDateRangeStartAt}
              endDate={filtering.publishDateRangeEndAt}
              onEvent={(event, picker) => {
                this.handleDateRangePickerValueEvent(event, { name: 'publishDateRange', picker })
              }}
              clear={filtering.clearPublishDateRange}
              disabled={!this.props.isFormSearchValid}
            />
          )

        case 'isIncludingTrashArticle':
          return (
            <Checkbox
              name="isIncludingTrashArticle"
              label="ゴミ箱も検索"
              checked={filtering.isIncludingTrashArticle}
              disabled={!this.props.isFormSearchValid}
              onChange={(event, { name, checked }) => {
                this.handleFilteringValueChange(event, { name, value: checked })
              }}
            />
          )

        case 'mediumIds':
          return (
            <MediaDropdown
              multiple
              clearable
              ignoreTrashContents={false}
              fluid={false}
              mediumIds={filtering.mediumIds}
              onChange={this.handleFilteringValueChange}
              disabled={!this.props.isFormSearchValid}
            />
          )

        case 'managementCategoryIds':
          return (
            <ManagementCategoriesDropdown
              multiple
              clearable
              ignoreTrashContents={false}
              fluid={false}
              disabled={!this.props.isFormSearchValid}
              managementCategoryIds={filtering.managementCategoryIds}
              onChange={this.handleFilteringValueChange}
            />
          )

        case 'mediumItem.autoPublish':
          return (
            <Dropdown
              name="mediumItem.autoPublish"
              placeholder="公開設定で絞り込み"
              onChange={this.handleFilteringValueChange}
              selection
              value={mediumItemAutoPublish}
              options={[
                {
                  text: 'どちらも',
                  value: null,
                  icon: `circle${_.isNull(mediumItemAutoPublish) ? '' : ' outline'}`,
                },
                {
                  text: 'オート公開のみ',
                  value: true,
                  icon: `circle${_.isEqual(mediumItemAutoPublish, true) ? '' : ' outline'}`,
                },
                {
                  text: 'マニュアル公開のみ',
                  value: false,
                  icon: `circle${_.isEqual(mediumItemAutoPublish, false) ? '' : ' outline'}`,
                },
              ]}
              disabled={!this.props.isFormSearchValid}
            />
          )

        case 'mediumItem.shannonSubmissionAllowed':
          return (
            <Dropdown
              name="mediumItem.shannonSubmissionAllowed"
              placeholder="Shannon連携で絞り込み"
              onChange={this.handleFilteringValueChange}
              selection
              value={mediumItemShannonSubmissionAllowed}
              options={[
                {
                  text: 'どちらも',
                  value: null,
                  icon: `circle${_.isNull(mediumItemShannonSubmissionAllowed) ? '' : ' outline'}`,
                },
                {
                  text: 'Shannon連携のみ',
                  value: true,
                  icon: `circle${_.isEqual(mediumItemShannonSubmissionAllowed, true) ? '' : ' outline'}`,
                },
                {
                  text: '非Shannon連携のみ',
                  value: false,
                  icon: `circle${_.isEqual(mediumItemShannonSubmissionAllowed, false) ? '' : ' outline'}`,
                },
              ]}
              disabled={!this.props.isFormSearchValid}
            />
          )

        case 'type':
          return (
            <Dropdown
              name="type"
              placeholder="記事タイプを選択"
              onChange={this.handleFilteringValueChange}
              selection
              fluid
              value={filtering.type}
              options={[
                {
                  text: 'すべて',
                  value: null,
                  icon: `circle${_.isNull(filtering.type) ? '' : ' outline'}`,
                },
                {
                  text: 'スポコン記事',
                  value: 'sponsored',
                  icon: `circle${_.isEqual(filtering.type, 'sponsored') ? '' : ' outline'}`,
                },
                {
                  text: '動画記事',
                  value: 'video_only',
                  icon: `circle${_.isEqual(filtering.type, 'video_only') ? '' : ' outline'}`,
                },
                {
                  text: '動画記事候補',
                  value: 'video_cantidate_only',
                  icon: `circle${_.isEqual(filtering.type, 'video_cantidate_only') ? '' : ' outline'}`,
                },
              ]}
              disabled={!this.props.isFormSearchValid}
            />
          )

        case 'categoryIds':
          return (
            <CategoriesDropdown
              multiple
              clearable
              fluid
              ignoreTrashContents={false}
              categoryIds={filtering.categoryIds}
              onChange={this.handleFilteringValueChange}
              disabled={!this.props.isFormSearchValid}
            />
          )

        case 'sponsorIds':
          return (
            <SponsorsDropdown
              multiple
              clearable
              fluid
              ignoreTrashContents={false}
              sponsorIds={filtering.sponsorIds}
              onChange={this.handleFilteringValueChange}
              disabled={!this.props.isFormSearchValid}
            />
          )

        default:
          return null
      }
    }

    return (
      <div className="articleFilterContainer">
        <Form onValid={this.props.handleFormSearchValid} onInvalid={this.props.handleFormSearchInvalid}>
          <Form.Group className="articleFilterContainer__basicSearch">
            <Form.Field width={8} className="articleFilterContainer__basicSearch__text">
              {renderFilterComponent('text')}
            </Form.Field>

            {isRenderBasicFilterComponent('clearCreateDateRange') && (
              <Form.Field width={4} className="articleFilterContainer__basicSearch__createDateRange">
                {renderFilterComponent('clearCreateDateRange')}
              </Form.Field>
            )}

            {isRenderBasicFilterComponent('clearPublishDateRange') && (
              <Form.Field width={4} className="articleFilterContainer__basicSearch__publishDateRange">
                {renderFilterComponent('clearPublishDateRange')}
              </Form.Field>
            )}

            {this.props.status !== ArticleStatus.TRASH && isRenderBasicFilterComponent('isIncludingTrashArticle') && (
              <Form.Field className="articleFilterContainer__basicSearch__includingTrash">
                {renderFilterComponent('isIncludingTrashArticle')}
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group className="articleFilterContainer__basicSearch">
            {isRenderBasicFilterComponent('mediumIds') && (
              <Form.Field width={4}>{renderFilterComponent('mediumIds')}</Form.Field>
            )}

            {isRenderBasicFilterComponent('managementCategoryIds') && (
              <Form.Field width={4}>{renderFilterComponent('managementCategoryIds')}</Form.Field>
            )}

            {isRenderBasicFilterComponent('mediumItem.autoPublish') && (
              <Form.Field width={4}>{renderFilterComponent('mediumItem.autoPublish')}</Form.Field>
            )}

            {isRenderBasicFilterComponent('mediumItem.shannonSubmissionAllowed') && (
              <Form.Field width={4}>{renderFilterComponent('mediumItem.shannonSubmissionAllowed')}</Form.Field>
            )}

            <Form.Field className="articleFilterContainer__basicSearch__advancedButton">
              <div
                ref={ref => {
                  this.advancedButtonRef = ref
                }}
              >
                <Button icon inverted onClick={this.handleAdvancedFilterOpen}>
                  <Icon name="sliders" color="grey" size="big" />
                  <Label
                    circular
                    empty
                    color="teal"
                    size="mini"
                    style={{ display: isAdvancedFilterSearched ? 'inline-block' : 'none' }}
                  />
                </Button>
              </div>
            </Form.Field>
          </Form.Group>

          <div
            className="articleFilterContainer__advancedSearch"
            style={{ display: isAdvancedFilterOpen ? 'block' : 'none' }}
            ref={ref => {
              this.advancedSearchRef = ref
            }}
          >
            <Header as="h4" className="articleFilterContainer__advancedSearch__header">
              <Button
                inverted
                className="articleFilterContainer__advancedSearch__header__clearButton"
                disabled={!isAdvancedFilterSearched}
                onClick={this.handleClearButtonAdvancedFilterClick}
              >
                クリア
              </Button>
              <Button
                icon
                inverted
                className="articleFilterContainer__advancedSearch__header__closeButton"
                onClick={this.handleAdvancedFilterClose}
              >
                <Icon name="close" size="small" color="black" />
              </Button>
              フィルタ検索
              <Divider clearing />
            </Header>

            <Container className="articleFilterContainer__advancedSearch__content">
              {_.map(renderAdvancedFilteringKeys, key => {
                const renderComponent = renderFilterComponent(key)

                return _.isNull(renderComponent) ? null : (
                  <Form.Field className={`articleFilterContainer__advancedSearch__content__${key}Field`} key={key}>
                    <Divider hidden clearing />
                    {renderComponent}
                  </Form.Field>
                )
              })}

              <Divider hidden clearing />
              <Button
                disabled={!isAdvancedFilterSubmitButtonEnable}
                onClick={this.handleSubmitButtonAdvancedFilterClick}
              >
                検索
              </Button>
            </Container>
          </div>
        </Form>
      </div>
    )
  }
}

export default ArticleFilterContainer
