import React, { Component } from 'react'
import {
  Button,
  Header,
  Icon,
  Menu,
  Divider,
  Dimmer,
  Loader,
  Label,
  Modal,
  Popup,
  Segment,
  List,
  Statistic,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'

import { CategoryApi } from 'trill-api-admin-client'
import _ from 'lodash'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import CategoryEditModal from '../../components/CategoryEditModal'
import CategoryRemoveModal from '../../components/CategoryRemoveModal'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('Categories')
const categoryApi = new CategoryApi()
let getCategories
let putCategory
const getCategoryTags = []

/**
 * カテゴリ一覧の状態
 * @enum {string}
 */
const CategoryStatus = {
  /** すべて */
  ALL: 'all',
  /** ゴミ箱 */
  TRASH: 'trash',
}

class Categories extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      categories: [],
      changeFeaturedCategoies: [],
      isFeaturedChangeModalOpen: false,
      isCategoryEditModalOpen: false,
      isCategoryRemoveModalOpen: false,
      editCategory: null,
      removeCategory: null,
      // 表示中のカテゴリーステータス
      status: CategoryStatus.ALL,
      isUndoModalOpen: false,
      undoCategory: null,
      apiError: null,
      undoApiError: null,
      changeApiError: null,
      permission: GetPermission('category'),
      isFormSearchValid: false,
    }

    _.each(CategoryStatus, status => {
      _.extend(this.state, {
        [status]: {
          currentPage: 1,
          itemsPerPage: 50,
          totalPages: 0,
          totalItems: 0,
          sorting: { featured: 'desc' },
          filtering: { name: '' },
        },
      })
    })
  }

  componentDidMount() {
    this.retrieveCategories()
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getCategories)) {
      getCategories.cancel()
    }
    if (!_.isNil(putCategory)) {
      putCategory.cancel()
    }
    if (!_.isNil(getCategoryTags)) {
      _.each(getCategoryTags, getCategoryTag => getCategoryTag.cancel())
      getCategoryTags.length = 0
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    this.setState({
      isCategoryEditModalOpen: true,
      editCategory: {},
    })
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handleCategoryEditModalClose = () => {
    this.setState({
      isCategoryEditModalOpen: false,
      editCategory: null,
    })
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleCategoryRemoveModalClose = () => {
    this.setState({
      isCategoryRemoveModalOpen: false,
      removeCategory: null,
    })
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveCategories()
    })
  }

  /**
   * データテーブルのヘッダーをタップして並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveCategories()
    })
  }

  /**
   * カテゴリのデータに変更があったときのハンドラ
   */
  handleCategoryDataChanged = () => {
    // カテゴリに変更があった場合は、変更対象のカテゴリ一覧をリセット (変更したいときは再度取得)
    this.setState({ changeFeaturedCategoies: [] })

    this.retrieveCategories()
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveCategories()
    })
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const categoryId = this.state.undoCategory.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putCategory = CancelablePromise(categoryApi.putCategory(categoryId))
    putCategory.promise
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoCategory: null,
        })

        this.retrieveCategories()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`put category categoryId #${categoryId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoCategory: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoCategory: null,
    })
  }

  /**
   * 人気記事一覧の表示順変更モーダルの変更ボタンを押したときのハンドラ
   */
  handleFeaturedChangeModalChangeButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 人気記事一覧の表示順変更モーダル画面を閉じたときのハンドラ
   */
  handleFeaturedChangeModalClose = () => {
    this.setState({ isFeaturedChangeModalOpen: false })
  }

  /**
   * 人気記事一覧の表示順変更モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleFeaturedChangeModalCancelButtonClick = () => {
    this.setState({ isFeaturedChangeModalOpen: false })
  }

  /**
   * カテゴリ名で絞り込むインプットの値を変更したときのハンドラ
   */
  handleCategoryNameInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.name = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveCategories()
  }

  /**
   * 人気記事一覧の順番を更新するボタンを押してフォームを送信するハンドラ
   */
  handleFormValidSubmit = submitFormData => {
    const updateCategoryFeaturedPromisses = _.map(submitFormData, data => {
      const featured = !_.isNumber(data.featured) && _.isEmpty(data.featured) ? 0 : parseInt(data.featured, 10)
      const categoryUpdateValues = { featured }
      return categoryApi.patchCategory(data.id, { categoryUpdateValues })
    })

    this.setState({
      isBusy: true,
      changeApiError: null,
    })
    Promise.all(updateCategoryFeaturedPromisses)
      .then(() => {
        this.setState({
          isBusy: false,
          isFeaturedChangeModalOpen: false,
          changeFeaturedCategoies: [],
        })

        // カテゴリの更新
        this.retrieveCategories()
      })
      .catch(error => {
        this.setState({
          isBusy: false,
          changeApiError: error,
        })
      })
  }

  /**
   * 作成・更新モーダルを表示する関数
   * @param {Object} category - カテゴリデータ
   */
  openCategoryEditModal(category) {
    this.setState({
      isCategoryEditModalOpen: true,
      editCategory: category,
    })
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} category - カテゴリデータ
   */
  openCategoryRemoveModal(category) {
    this.setState({
      isCategoryRemoveModalOpen: true,
      removeCategory: category,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   * @param {Object} category - カテゴリデータ
   */
  openUndoModal(category) {
    this.setState({
      isUndoModalOpen: true,
      undoCategory: category,
    })
  }

  /**
   * カテゴリの人気記事一覧の表示順を変更するモーダル画面を表示する関数
   */
  openCategoryFeaturedChangeModalOpen() {
    this.setState({
      isBusy: true,
      isFeaturedChangeModalOpen: true,
    })

    this.getAllCategory()
      .then(categories => {
        this.setState({
          isBusy: false,
          changeFeaturedCategoies: categories,
        })
      })
      .catch(error => {
        logger.error('get all category error', error)

        this.setState({
          apiError: error,
        })
      })
  }

  /**
   * カテゴリ一覧取得
   */
  retrieveCategories = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getCategories = CancelablePromise(categoryApi.getCategories(this.getRequestQuery()))
    getCategories.promise
      .then(response => {
        const categories = response.data
        const responseHeader = response.header
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.currentPage = currentPage === 0 ? 1 : currentPage
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        logger.debug(`get categories (total categories count: ${categories.length}) categories: `, categories)

        // Promise を入れる配列の初期化を行う
        getCategoryTags.length = 0
        _.each(categories, (category, reject) => {
          getCategoryTags.push(
            CancelablePromise(
              new Promise(resolve => {
                this.setRelatedTags(category)
                  .then(() => {
                    resolve(category)
                  })
                  .catch(error => {
                    if (error.isCanceled) {
                      reject()
                      return
                    }

                    logger.error(`set related tags category id #${category.id} error`, error)

                    // 一覧表示は行いたいので処理は続行
                    resolve(category)
                  })
              }),
            ),
          )
        })

        return Promise.all(_.map(getCategoryTags, getCategoryTag => getCategoryTag.promise))
      })
      .then(categories => {
        this.setState({
          categories,
          isBusy: false,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve categories get categories error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 10
        tableData.totaPage = 0
        tableData.totalItems = 0

        this.setState({
          isBusy: false,
          [status]: tableData,
          categories: [],
          apiError: error,
        })
      })
  }

  /**
   * カテゴリに関連するタグをカテゴリに設定
   */
  setRelatedTags = category =>
    new Promise((resolve, reject) => {
      categoryApi
        .getCategoryTags(category.id)
        .then(response => {
          const relatedTags = response.data
          const responseHeader = response.header
          // 合計ページが 2 ページ以上あったら最後に ... を表示する
          const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
          category.relatedTags = relatedTags
          category.relatedTagsTotalPages = totalPages
          logger.debug('retrieve related tags', {
            category,
            relatedTags,
          })
          resolve(category)
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * カテゴリを全件取得 (featured を一度に更新するために使用)
   */
  getAllCategory = () =>
    new Promise((resolve, reject) => {
      if (_.size(this.state.changeFeaturedCategoies) > 0) {
        // すでに取得済みなら通信しない
        resolve(this.state.changeFeaturedCategoies)

        return
      }
      const header = {
        currentPage: 1,
        itemsPerPage: 10,
        filtering: ['deleted_at IS NULL'],
      }

      // 記事本文に関連するタグの配列
      const categories = []

      categoryApi
        .getCategories(header)
        .then(response => {
          // 全ページ分の通信を入れる Promise
          const categoriesPromises = []
          categories.push(response.data)

          // 合計ページ数
          const totalPages = _.get(response, 'header.pagination-totalpages', 0)

          // 現在ページが合計ページ未満の場合はループ
          while (header.currentPage < totalPages) {
            // 次のページ取得のため +1 する
            header.currentPage += 1
            categoriesPromises.push(categoryApi.getCategories(header))
          }

          return Promise.all(categoriesPromises)
        })
        .then(responses => {
          _.each(responses, response => categories.push(response.data))

          // 多重配列を戻してから resolve
          resolve(_.flatten(categories))
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 表示中のカテゴリのステータス
    const status = this.state.status
    // 表示中のテーブルデータを取得
    const tableData = this.state[status]

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage

    // 1 ページあたりに含めるデータの数
    const itemsPerPage = tableData.itemsPerPage

    const filtering = []
    if (_.isEqual(status, CategoryStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }
    if (!_.isEmpty(tableData.filtering.name)) {
      let filteringName = tableData.filtering.name
      if (filteringName.match(/\,/)) { // eslint-disable-line
        filteringName = ''
      }

      filtering.push(`name LIKE "%${filteringName}%"`)
    }

    // ソートを配列に変換
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * 関連タグをレンダリング
   */
  renderRelatedTags = item => {
    const renderTag = tag => <Label key={tag.id} content={tag.name} size="small" style={{ margin: '2px' }} />

    return (
      <div>
        {_.map(item.relatedTags, relatedTag => renderTag(relatedTag))}

        {item.relatedTagsTotalPages > 1 && <span>...</span>}
      </div>
    )
  }

  render() {
    const status = this.state.status
    const tableData = this.state[status]
    const { permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    return (
      <div className="Categories">
        <Header as="h1">
          <Icon name="folder open" />

          <Header.Content>カテゴリ</Header.Content>
        </Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {/* 公開・削除切り替えボタン */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={CategoryStatus.ALL}
            active={_.isEqual(status, CategoryStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={CategoryStatus.TRASH}
            active={_.isEqual(status, CategoryStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Field width={8}>
            <Input
              name="categories-search"
              type="text"
              placeholder="カテゴリ名で検索"
              action
              value={tableData.filtering.name}
              onChange={this.handleCategoryNameInputChange}
              validations={{ matchRegexp: /^((?!,).)*$/i }}
              validationErrors={{
                matchRegexp: 'キーワードに不正な記号があるため検索できません',
              }}
              errorLabel={<FormErrorLabel />}
            >
              <input />

              <Button
                icon="search"
                onClick={this.handleSearchButtonClick}
                color="blue"
                disabled={!this.state.isFormSearchValid}
              />
            </Input>
          </Form.Field>
        </Form>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/* カテゴリ一覧 */}
        {!_.isEmpty(this.state.categories) && (
          <DataTable
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSelectionChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            sort={tableData.sorting}
            items={this.state.categories}
            cellStyle={{ position: 'relative' }} // ラベル表示のためセルにスタイルを指定
            columns={[
              {
                label: 'カテゴリ名',
                field: 'name',
                minWidth: '20em',
                collapsing: false,
                render: item => {
                  const name = item.name
                  const color = item.color
                  return (
                    <div>
                      {!_.isEmpty(color) && (
                        <Popup
                          wide
                          size="small"
                          inverted
                          trigger={<Label corner style={{ borderColor: color }} />}
                          content="カテゴリのラベルに設定されている色です"
                        />
                      )}
                      {name}
                    </div>
                  )
                },
              },
              {
                label: 'スラッグ',
                field: 'slug',
              },
              {
                label: '関連タグ',
                collapsing: false,
                minWidth: '30em',
                render: item => this.renderRelatedTags(item),
              },
              {
                label: '人気記事一覧の表示順番',
                field: 'featured',
                align: 'center',
                minWidth: '5em',
                collapsing: true,
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group key={item.key} secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.openCategoryEditModal(item)
                      }}
                    />

                    {/* TODO: API 側にも相談する必要があるためコメントアウト */}
                    {/* {!_.isEqual(status, CategoryStatus.TRASH) &&
                      <Popup
                        wide
                        size='small'
                        inverted
                        trigger={
                          <Button icon='exchange' onClick={() => {
                            this.openCategoryFeaturedChangeModalOpen();
                          }} />
                        }
                        content='人気記事一覧の表示順番を変更します'
                      />
                    } */}

                    {!_.isEqual(status, CategoryStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openCategoryRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, CategoryStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
            compact
            rowKey="id"
          />
        )}

        {/* カテゴリ作成・編集モーダル */}
        {hasUpdatePermission && (
          <CategoryEditModal
            category={this.state.editCategory}
            open={this.state.isCategoryEditModalOpen}
            onClose={this.handleCategoryEditModalClose}
            onSuccessDataChanged={this.handleCategoryDataChanged}
          />
        )}

        {/* カテゴリ削除モーダル */}
        {hasDeletePermission && (
          <CategoryRemoveModal
            category={this.state.removeCategory}
            open={this.state.isCategoryRemoveModalOpen}
            onClose={this.handleCategoryRemoveModalClose}
            onSuccessDataChanged={this.handleCategoryDataChanged}
          />
        )}

        {/* 削除したタグ・グループを戻すときに表示する確認用モーダル */}
        {hasRestorePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isUndoModalOpen}
            onClose={this.handleUndoModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>カテゴリの公開</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.undoApiError} />
              {_.get(this.state, 'undoCategory.name', 'カテゴリ')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.handleUndoModalPublishButtonClick} />
            </Modal.Actions>
          </Modal>
        )}

        {/* 人気記事一覧の表示順を変更するモーダル */}
        {hasUpdatePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isFeaturedChangeModalOpen}
            onClose={this.handleFeaturedChangeModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>トップに表示するカテゴリの表示順を変更</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.changeApiError} />

              {/* ローディング */}
              <Dimmer active={this.state.isBusy} inverted>
                <Loader />
              </Dimmer>

              <Form ref="form" noValidate onValidSubmit={this.handleFormValidSubmit}>
                <Segment>
                  <List divided relaxed verticalAlign="middle">
                    {_.map(this.state.changeFeaturedCategoies, category => {
                      const categoryId = category.id.toString()

                      return (
                        <List.Item key={categoryId}>
                          <List.Content>
                            <Form.Input
                              label={category.name}
                              name={categoryId.concat('.featured')}
                              value={category.featured}
                            />

                            <Form.Input className="isHidden" name={categoryId.concat('.id')} value={category.id} />
                          </List.Content>
                        </List.Item>
                      )
                    })}
                  </List>
                </Segment>
              </Form>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleFeaturedChangeModalCancelButtonClick} />

              <Button positive content="変更" onClick={this.handleFeaturedChangeModalChangeButtonClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default Categories
