import React, { Component } from 'react'
import { Modal, Form, Button } from 'semantic-ui-react'
import TagDropdown from './TagDropdown'
import _ from 'lodash'

class RecommendedTagsEditModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      recommendedTagsIds: [],
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  initialize = () => {
    const recommendedTagsIds = _.map(this.props.recommendedTags, recommendedTag => recommendedTag.tag.id)

    this.setState({ recommendedTagsIds })

    this.openModal()
  }

  handleOnSubmit = event => {
    event.preventDefault()

    const { recommendedTagsIds } = this.state
    this.props.postRecommendedTags(recommendedTagsIds)

    this.setState({ open: false })
  }

  handleTagDropdownChange = (event, { key, value }) => {
    event.preventDefault()

    const { recommendedTagsIds } = this.state
    recommendedTagsIds[key] = value
    this.setState({ recommendedTagsIds })
  }

  render() {
    const { open } = this.state
    const { recommendedTagsIds } = this.state
    const { permission } = this.props
    const { hasUpdatePermission } = permission
    const numberOfTagDropdownsToBeRendered = 10
    const recommendedTagsForm = [...Array(numberOfTagDropdownsToBeRendered).keys()]

    return (
      <Modal
        closeIcon
        size="tiny"
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={
          <Button
            disabled={!hasUpdatePermission}
            circular
            color="blue"
            content="キーワードを編集"
            icon="edit"
            onClick={this.initialize}
          />
        }
      >
        <Modal.Header>話題・人気のキーワードの編集</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleOnSubmit}>
            {recommendedTagsForm.map(index => (
              <Form.Field key={index}>
                <TagDropdown
                  key={index}
                  handleKey={index}
                  value={recommendedTagsIds[index]}
                  ignoreTagValues={recommendedTagsIds}
                  onChange={this.handleTagDropdownChange}
                />
              </Form.Field>
            ))}
            <Button circular positive>
              更新
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

export default RecommendedTagsEditModal
