import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Modal, Dimmer, Loader, Message, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { CpApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('CpRemoveModal')
const cpApi = new CpApi()
let deleteCp

const propTypes = {
  /**
   * 削除対象の CP
   */
  cp: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * 作成・更新の API 通信が成功したときに呼び出す外部関数
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  cp: {},
  open: false,
  apiError: null,
}

class CpRemoveModal extends Component {
  state = {
    isBusy: false,
    isFormValid: false,
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(deleteCp)) {
      deleteCp.cancel()
    }
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効なときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteCp = CancelablePromise(cpApi.deleteCp(this.props.cp.id))
    deleteCp.promise
      .then(() => {
        // 削除に成功したら一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.setState({ isBusy: false }, () => {
          if (this.props.onClose) {
            this.props.onClose()
          }
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('remove cp error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 削除ボタンを押したときのハンドラ
   */
  handleRemoveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <Modal
        className="CpRemoveModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{`${_.get(this.props.cp, 'name', 'CP')} の削除`}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          {/* 警告メッセージ */}
          <Message negative content="確認のため、ゴミ箱へ移動する CP 名を入力してください。" />

          {/* 削除フォーム */}
          {this.props.cp && (
            <Form
              ref="form"
              noValidate
              onChange={this.handleFormChange}
              onValid={this.handleFormValid}
              onInvalid={this.handleFormInvalid}
              onValidSubmit={this.handleFormValidSubmit}
            >
              {/* CP 名入力フィールド */}
              <Form.Input
                name="name"
                label="CP 名"
                placeholder="削除する CP 名を入力してください"
                validations={{
                  isSameCpName: (values, value) => value === this.props.cp.name,
                }}
                required
              />
            </Form>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            negative
            content="ゴミ箱へ移動"
            onClick={this.handleRemoveButtonClick}
            disabled={!this.state.isFormValid}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

CpRemoveModal.propTypes = propTypes
CpRemoveModal.defaultProps = defaultProps

export default CpRemoveModal
