import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CpApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('CpsDropdown')
const cpApi = new CpApi()
let getCps
let getCp

const propTypes = {
  /**
   * Cp ID
   */
  cpId: PropTypes.number,

  /**
   * 必須項目 (必須項目の場合、解除項目は表示しない)
   */
  required: PropTypes.bool,

  /**
   * 幅を最大にするかどうか
   */
  fluid: PropTypes.bool,

  /**
   * ゴミ箱にあるコンテンツを表示させたくない場合
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * CP 項目を変更したときに呼び出す外部関数
   */
  onChange: PropTypes.func,

  /**
   * Whether disabled or not
   */
  disabled: PropTypes.bool,
}

const defaultProps = {
  cpId: null,
  required: false,
  fluid: true,
  ignoreTrashContents: true,
  disabled: false,
}

/**
 * CP 選択用のコンポーネント
 */
class CpsDropdown extends Component {
  state = {
    isBusy: false,
    cps: [],
    currentPage: 0,
    totalPages: 0,
  }

  /**
   * ドロップダウンの値を変更したかどうか
   */
  isDropdownValueChanged = false

  /**
   * CP 名検索クエリ
   */
  cpSearchQuery = ''

  componentDidMount() {
    const cpId = this.props.cpId

    this.retrieveCp(cpId).then(() => {
      this.retrieveCps()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const cpId = this.props.cpId
    const nextCpId = nextProps.cpId

    if (!_.isEqual(cpId, nextCpId) && !this.isDropdownValueChange) {
      this.setState(
        {
          cps: [],
          currentPage: 0,
          totalPages: 0,
        },
        () => {
          this.retrieveCp(nextCpId).then(() => {
            this.retrieveCps()
          })
        },
      )
    } else if (!_.isEqual(cpId, nextCpId)) {
      this.retrieveCp(nextCpId).then(() => {
        this.retrieveCps()
      })
    }

    this.cpSearchQuery = ''
    this.isDropdownValueChanged = false
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getCps)) {
      getCps.cancel()
    }
    if (!_.isNil(getCp)) {
      getCp.cancel()
    }
  }

  /**
   * ドロップダウンにメディア名を入れて検索を行ったときのハンドラ
   */
  handleInfiniteDropdownSearch = searchQuery => {
    this.cpSearchQuery = searchQuery

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveCps()
      },
    )
  }

  /**
   * ドロップダウンの値を変更したときのハンドラ
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    if (this.props.onChange) {
      this.isDropdownValueChange = true
      this.props.onChange(event, { name: 'cpId', value })
    }
  }

  /**
   * CP 詳細の取得
   */
  retrieveCp = cpId =>
    new Promise(resolve => {
      if (_.isNil(cpId)) {
        resolve()

        return
      }

      this.setState({ isBusy: true })

      getCp = CancelablePromise(cpApi.getCp(cpId))
      getCp.promise
        .then(response => {
          const cp = response.data
          const cps = this.state.cps
          cps.unshift(cp)

          logger.debug(`get cp id #${cpId}`, { cp, cps })

          this.setState(
            {
              cps: _.uniqBy(cps, 'id'),
              isBusy: false,
            },
            () => resolve(),
          )
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          logger.error(`get cp id #${cpId}, error`, error)

          this.setState({ isBusy: false })

          // CP 詳細が取得できなくても処理は続行させたいため resolve
          resolve()
        })
    })

  /**
   * CP 一覧の取得
   */
  retrieveCps = () => {
    this.setState({ isBusy: true })

    getCps = CancelablePromise(cpApi.getCps(this.getRequestQuery()))
    getCps.promise
      .then(response => {
        let cps = _.concat(this.state.cps, response.data)
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

        // 選択中の CP
        const selectedCp = _.head(cps)
        // id でソート
        cps = _.sortBy(cps, o => o.id * -1)
        // 選択中の CP は先頭に表示
        cps.unshift(selectedCp)

        this.setState({
          isBusy: false,
          cps: _.uniqBy(cps, 'id'),
          totalPages,
          currentPage,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get cps error', error)

        this.setState({ isBusy: false })
      })
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { currentPage, cps } = this.state

    // 絞り込み
    const filtering = []

    // メディア名検索文字列があった場合は、メディア名で絞り込むため filtering に追加
    if (!_.isEmpty(this.cpSearchQuery)) {
      filtering.push(`name LIKE '%${this.cpSearchQuery}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    // 取得済みのメディア一覧は除外して一覧を取得: 取得済みのタグを再取得しないため
    _.each(cps, cp => filtering.push(`id <> '${cp.id}'`))

    const query = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
      sorting: ['-id'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const options = _.map(this.state.cps, cp => ({
      text: cp.name,
      value: cp.id,
      icon: 'users',
    }))

    if (!this.props.required) {
      // 必須項目ではない場合、解除項目を先頭に付与
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }
    return (
      <InfiniteDropdown
        fluid={this.props.fluid}
        placeholder="CPを選択"
        noResultsMessage="該当するCPが見つかりません"
        loading={this.state.isBusy}
        value={this.props.cpId}
        options={options}
        onLoadMore={this.retrieveCps}
        onSearch={this.handleInfiniteDropdownSearch}
        onChange={this.handleInfiniteDropdownChange}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
        disabled={this.props.disabled}
      />
    )
  }
}

CpsDropdown.propTypes = propTypes
CpsDropdown.defaultProps = defaultProps

export default CpsDropdown
