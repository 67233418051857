import React, { Component } from 'react'
import { Grid, Header, Button, Tab, Menu, Icon, Modal, Segment, Label, Popup } from 'semantic-ui-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Form } from 'formsy-semantic-ui-react'

import FormErrorLabel from './FormErrorLabel'
import MediumInput from '../components/MediumInput'

const propTypes = {
  /**
   * 診断結果
   */
  results: PropTypes.array,

  /**
   * フォームの値に変更があったときに呼び出す外部関数
   */
  onFormValueChanged: PropTypes.func,

  /**
   * 診断結果の追加を行ったときに呼び出す外部関数
   */
  onResultAdd: PropTypes.func,

  /**
   * 診断結果の削除を行ったときに呼び出す外部関数
   */
  onResultRemove: PropTypes.func,

  /**
   * フォームの必須項目が全て埋まったときに呼び出す外部関数
   */
  onValid: PropTypes.func,

  /**
   * フォームの値に変更があったときに呼び出す外部関数
   */
  onInvalid: PropTypes.func,
}

const defaultProps = {
  results: [],
}

class PersonalityQuizResultList extends Component {
  state = {
    activeIndex: 0, // 現在選択しているタブ
    formValids: {},
    isDeleteModalOpen: false,
    deleteKey: null,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.size(this.props.results) !== _.size(nextProps.results)) {
      // 結果の追加を行ったら再度初期化を行う
      const formValids = {}
      _.each(nextProps.results, (result, index) => {
        // 初期化の段階でサムネイル項目がないデータ (ex. 結果を追加した場合) は false とする
        _.extend(formValids, { [index]: this.isRequired(result) })
      })

      this.updateFormValids(formValids)

      if (_.size(nextProps.results) === 1) {
        this.setState({ activeIndex: 0 })
      }
    }
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleValid = () => {
    const { activeIndex, formValids } = this.state

    _.extend(formValids, { [activeIndex]: true })

    this.updateFormValids(formValids)
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleInvalid = () => {
    const { activeIndex, formValids } = this.state

    _.extend(formValids, { [activeIndex]: false })

    this.updateFormValids(formValids)
  }

  /**
   * フォーム変更時のハンドラ
   */
  handleFormChange = (event, data, index) => {
    const resultData = {
      name: data.name,
      value: data.value,
      index,
    }

    if (this.props.onFormValueChanged && this.props.results) {
      this.props.onFormValueChanged(resultData)
    }
  }

  /**
   * タブを変更したときのハンドラ
   */
  handleTabChange = (event, data) => {
    this.setState({ activeIndex: data.activeIndex })
  }

  /**
   * 結果削除確認モーダルを閉じたときのハンドラ
   */
  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false,
      deleteKey: null,
    })
  }

  /**
   * 結果削除確認モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleDeleteModalCancelButtonClick = () => {
    this.setState({
      isDeleteModalOpen: false,
      deleteKey: null,
    })
  }

  /**
   * 結果削除確認用モーダルの削除ボタンを押したときのハンドラ
   */
  handleDeleteModalDeleteButtonClick = () => {
    const key = this.state.deleteKey

    if (this.props.onResultRemove) {
      this.props.onResultRemove(key)
    }
    const partialState = {
      isDeleteModalOpen: false,
      deleteKey: null,
    }

    if (_.size(this.props.results) - 1 === key) {
      // 最後の結果を削除した場合、activeIndex も更新
      partialState.activeIndex = _.size(this.props.results) - 2
    }

    this.setState(partialState)
  }

  /**
   * 結果削除モーダル画面を表示する関数
   */
  openDeleteModal(key) {
    this.setState({
      isDeleteModalOpen: true,
      deleteKey: key,
    })
  }

  /**
   * 必須項目が埋まっているかどうかのチェック
   */
  isRequired = resultData => {
    if (_.isEmpty(resultData.summary)) {
      return false
    }

    if (_.isEmpty(resultData.description)) {
      return false
    }

    if (_.isEmpty(_.get(resultData, 'cover.image.url'))) {
      return false
    }

    return true
  }

  /**
   * フォームの妥当性を更新
   */
  updateFormValids = formValids => {
    this.setState({ formValids }, () => {
      if (this.props.onInvalid && !_.every(this.state.formValids)) {
        // 一つでも formValid が false のタブがあれば props の onInvalid を呼び出す
        this.props.onInvalid()
      } else if (this.props.onValid && _.every(this.state.formValids)) {
        // 全タブの formValid が true の場合 props の onValid を呼び出す
        this.props.onValid()
      }
    })
  }

  /**
   * 結果をレンダリング
   * @param {Object} result - 結果データ
   * @param {string} index - index
   */
  renderResult(result, index) {
    const description = _.defaultTo(result.description, '')
    const summary = _.defaultTo(result.summary, '')
    const dynamicLink = _.defaultTo(result.dynamicLink, '')
    const resultPoint = _.defaultTo(result.resultPoint, '')
    const coverImage = _.get(result, 'cover.image.url', '')
    const copyright = _.get(result, 'cover.image.copyright.title', '')
    const copyrightUrl = _.get(result, 'cover.image.copyright.url', '')

    return (
      <Tab.Pane key={index}>
        <Grid columns="2" doubling>
          {/* 合計点 */}
          <Grid.Column width={10}>
            <Form.Dropdown
              required
              label="合計点 (最大値)"
              name="resultPoint"
              placeholder="合計点範囲の最大値を選択してください"
              fluid
              selection
              options={_.times(21, i => {
                const num = (i + 5) * 5
                const text = num.toString().concat('点')
                return {
                  key: num,
                  text,
                  value: num,
                }
              })}
              value={resultPoint}
              onChange={(event, data) => {
                this.handleFormChange(event, data, index)
              }}
            />

            {/* 診断内容 */}
            <Form.TextArea
              required
              name="summary"
              label="概要"
              rows={2}
              placeholder="概要を入力してください"
              value={summary}
              onChange={(event, data) => {
                this.handleFormChange(event, data, index)
              }}
            />

            <Form.TextArea
              required
              name="description"
              label=" 本文"
              rows={10}
              placeholder="本文を入力してください"
              value={description}
              onChange={(event, data) => {
                this.handleFormChange(event, data, index)
              }}
            />

            {/* ダイナミックリンク入力フィールド */}
            <Popup
              inverted
              trigger={
                <Form.Input
                  readOnly
                  label="ダイナミックリンク"
                  value={dynamicLink}
                  placeholder="ダイナミックリンクを入力してください"
                  name="dynamicLink"
                  onChange={(event, data) => {
                    this.handleFormChange(event, data, index)
                  }}
                  validations="isUrl"
                  validationErrors={{ isUrl: '無効な URL です' }}
                  errorLabel={<FormErrorLabel />}
                />
              }
              content="自動で生成されます"
            />
          </Grid.Column>

          {/* 結果画像表示 */}
          <Grid.Column width={6}>
            <Segment>
              <Label attached="top" color="blue" content="カバー" />

              <Form.Field required>
                <label>画像</label>

                <MediumInput
                  mediumUrl={coverImage}
                  onChange={(event, { mediumUrl }) => {
                    this.handleFormChange(event, { name: 'cover.image.url', value: mediumUrl }, index)
                  }}
                />

                <Form.Input className="isHidden" readOnly name="cover.image.url" type="text" fluid value={coverImage} />
              </Form.Field>

              <Form.Input
                label="出典元"
                value={copyright}
                placeholder="出典元を入力してください"
                name="cover.image.copyright.title"
                onChange={(event, data) => {
                  this.handleFormChange(event, data, index)
                }}
              />

              <Form.Input
                label="出典元の URL"
                value={copyrightUrl}
                placeholder="出典元の URL を入力してください"
                name="cover.image.copyright.url"
                onChange={(event, data) => {
                  this.handleFormChange(event, data, index)
                }}
                validations="isUrl"
                validationErrors={{ isUrl: '無効な URL です' }}
                errorLabel={<FormErrorLabel />}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </Tab.Pane>
    )
  }

  render() {
    return (
      <Form
        className="PersonalityQuizResultList"
        ref="form"
        noValidate
        onValid={this.handleValid}
        onInvalid={this.handleInvalid}
      >
        <Grid>
          <Grid.Column floated="left" width={5}>
            <Header size="tiny">結果一覧</Header>
          </Grid.Column>

          <Grid.Column floated="right" width={5}>
            <Button
              positive
              floated="right"
              content="結果を追加"
              onClick={event => {
                event.preventDefault()
                if (this.props.onResultAdd) {
                  this.props.onResultAdd()
                }
              }}
            />
          </Grid.Column>
        </Grid>

        {!_.isEmpty(this.props.results) && (
          <Tab
            activeIndex={this.state.activeIndex}
            onTabChange={this.handleTabChange}
            renderActiveOnly={true}
            grid={{ tabWidth: 3, paneWidth: 13 }}
            menu={{ fluid: true, vertical: true, tabular: true }}
            panes={_.map(this.props.results, (result, key) => {
              const menuMessage = `結果 ${key + 1}`
              const menuItem = (
                <Menu.Item key={key}>
                  {menuMessage}

                  <Icon
                    name="trash alternate outline"
                    link
                    onClick={event => {
                      event.preventDefault()
                      this.openDeleteModal(key)
                    }}
                  />
                </Menu.Item>
              )
              return {
                menuItem,
                render: () => this.renderResult(result, key),
              }
            })}
          />
        )}

        {/* 結果削除確認用のモーダル */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isDeleteModalOpen}
          onClose={this.handleDeleteModalClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>結果の削除</Modal.Header>

          <Modal.Content>結果 {parseInt(_.get(this.state, 'deleteKey'), 10) + 1} を削除しますか？</Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleDeleteModalCancelButtonClick} />

            <Button negative content="削除" onClick={this.handleDeleteModalDeleteButtonClick} />
          </Modal.Actions>
        </Modal>
      </Form>
    )
  }
}

PersonalityQuizResultList.propTypes = propTypes
PersonalityQuizResultList.defaultProps = defaultProps

export default PersonalityQuizResultList
