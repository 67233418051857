import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'

const propTypes = {
  /**
   * 開始時間の moment
   */
  startDate: PropTypes.object,

  /**
   * 終了時間の moment
   */
  endDate: PropTypes.object,

  /**
   * Input の値をリセット
   */
  clear: PropTypes.bool,

  /**
   * 選択範囲を変更したときに呼び出す外部関数
   */
  onEvent: PropTypes.func,
}

const defaultProps = {
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month'),
  clear: false,
  placeholder: '日時〜日時を指定',
}

/**
 * 公開日時の範囲を選択するコンポーネント
 */
const DatetimeRangePicker = props => {
  const dateRangeStartLabel = props.startDate.format('LL(ddd) HH:mm:ss')
  const dateRangeEndLabel = props.endDate.format('LL(ddd) HH:mm:ss')
  let dateRangeLabel =
    dateRangeStartLabel !== dateRangeEndLabel ? `${dateRangeStartLabel} 〜 ${dateRangeEndLabel}` : dateRangeStartLabel

  // DateRangePicker の input 項目をリセットする場合
  if (props.clear) {
    dateRangeLabel = ''
  }

  return (
    <DateRangePicker
      containerStyles={{ width: '100%' }}
      locale={{
        format: 'LL(ddd) HH:mm:ss',
        applyLabel: '確定',
        cancelLabel: '解除',
        fromLabel: '開始日',
        toLabel: '終了日',
        customRangeLabel: 'その他',
      }}
      timePicker
      timePicker24Hour
      alwaysShowCalendars
      startDate={props.startDate}
      endDate={props.endDate}
      onEvent={props.onEvent}
    >
      <Input icon="calendar" iconPosition="left" placeholder={props.placeholder} value={dateRangeLabel} readOnly />
    </DateRangePicker>
  )
}

DatetimeRangePicker.propTypes = propTypes
DatetimeRangePicker.defaultProps = defaultProps

export default DatetimeRangePicker
