import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Modal, Button, Dimmer, Loader, Segment, Header, Menu, Divider } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import { MediumApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import CpsDropdown from './CpsDropdown'
import MediumInput from './MediumInput'
import MediumItemDataTable from './MediumItemDataTable'
import MediumItemEditModal from './MediumItemEditModal'
import MediumItemRemoveModal from './MediumItemRemoveModal'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('MediumEditModal')
const mediumApi = new MediumApi()
let sendMedium
let getMediumItems

const propTypes = {
  /**
   * 編集対象のメディア（作成の場合は空）
   */
  medium: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新に成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  medium: {},
  open: false,
  cpId: null,
}

class MediumEditModal extends Component {
  state = {
    isFormValid: false,
    isFormModified: false,
    isLogoImageModified: false,
    isBusy: false,
    isMediumItemEditModalOpen: false,
    isMediumItemRemoveModalOpen: false,
    logoImageUrlInputValue: '',
    logoImageError: null,
    apiError: null,
    editMediumItem: null,
    removeMediumItem: null,
    mediumItems: [],
    currentPage: 1,
    itemsPerPage: 5,
    totalPages: 0,
    totalItems: 0,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されてるかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.medium, nextProps.medium)) {
      this.setState(
        {
          isFormModified: false,
          cpId: _.get(nextProps, 'medium.cp.id', null),
          logoImageUrlInputValue: _.get(nextProps, 'medium.logo', ''),

          mediumItems: [],
          currentPage: 1,
          itemsPerPage: 5,
          totalPages: 0,
          totalItems: 0,
        },
        () => {
          this.retrieveMediumItems()
        },
      )

      this.isFormResetted = false
    }
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState) {
    if (this.refs.form && !this.isFormResetted) {
      const medium = this.props.medium

      if (medium) {
        this.initializeFormValues(medium)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  // eslint-disable-next-line
  componentWillUnmount() {
    if (!_.isNil(sendMedium)) {
      sendMedium.cancel()
    }
    if (!_.isNil(getMediumItems)) {
      getMediumItems.cancel()
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  // eslint-disable-next-line
  handleFormChange = (currentValues, isChanged) => {
    const partialState = { isFormModified: false }

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(currentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendMedium = CancelablePromise(this.sendData(this.getRequestParameters(submitFormData)))
    sendMedium.promise
      .then(response => {
        const medium = response.data

        // 更新 or 作成に成功したらお知らせ一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.initializeFormValues(medium)

        resetForm(this.initialFormValues)

        this.setState(
          {
            isBusy: false,
            isFormModified: false,
          },
          () => {
            if (_.isEmpty(this.props.medium) && this.props.onClose) {
              // 新規作成の場合は作成後に閉じる
              this.props.onClose()
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send data error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * ロゴ画像を変更したときのハンドラ
   */
  handleLogoImageInputChange = (event, { mediumUrl, error }) => {
    this.setState({
      logoImageUrlInputValue: _.defaultTo(mediumUrl, ''),
      logoImageError: error,
      isLogoImageModified: !_.isEqual(mediumUrl, _.get(this.initialFormValues, 'logo')),
    })
  }

  /**
   * CP 選択ドロップダウンを変更したときのハンドラ
   */
  handleCpDropdownChange = (event, { value }) => {
    this.setState({ cpId: value })
  }

  /**
   * 記事一覧のデータテーブルのページ情報を更新したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    this.setState(
      {
        currentPage,
        itemsPerPage,
      },
      () => {
        this.retrieveMediumItems()
      },
    )
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateMediumItemModalOpen = () => {
    this.setState({
      isMediumItemEditModalOpen: true,
      editMediumItem: {},
    })
  }

  /**
   * 更新モーダルを表示する関数
   * @param {Object} item - メディアの設定データ
   */
  handleEditMediumItemModalOpen(item) {
    this.setState({
      isMediumItemEditModalOpen: true,
      editMediumItem: item,
    })
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handleMediumItemModalClose = () => {
    this.setState(
      {
        isMediumItemEditModalOpen: false,
        editMediumItem: null,
      },
      () => {
        this.retrieveMediumItems()
      },
    )
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} item - メディアの設定データ
   */
  handleRemoveMediumItemModalOpen(item) {
    this.setState({
      isMediumItemRemoveModalOpen: true,
      removeMediumItem: item,
    })
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleRemoveMediumItemModalClose = () => {
    this.setState(
      {
        isMediumItemRemoveModalOpen: false,
        removeMediumItem: null,
      },
      () => {
        this.retrieveMediumItems()
      },
    )
  }

  /**
   * メディアの設定一覧取得
   */
  retrieveMediumItems = () => {
    if (_.isEmpty(this.props.medium)) {
      return
    }
    this.setState({
      isBusy: true,
      apiError: null,
    })

    logger.debug('retrieveMediumItems', this.props.medium)

    const mediumId = this.props.medium.id
    getMediumItems = CancelablePromise(mediumApi.getMediumMediumItems(mediumId, this.getMediumItemsRequestQuery()))
    getMediumItems.promise
      .then(response => {
        const responseHeader = response.header
        const mediumItems = response.data
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        const itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 5), 10)
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        const totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
        this.setState({
          isBusy: false,
          mediumItems,
          currentPage,
          itemsPerPage,
          totalPages,
          totalItems,
        })
        logger.debug('get mediumItems', {
          mediumItems,
          totalPages,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get mediumItems error', error)

        this.setState({
          isBusy: false,
          mediumItems: [],
          currentPage: 1,
          itemsPerPage: 5,
          totalPages: 0,
          totalItems: 0,
          apiError: error,
        })
      })
  }

  /**
   * フォームの初期化
   * @param {Object} medium メディアデータ
   */
  initializeFormValues(medium) {
    this.initialFormValues.name = medium.name
    this.initialFormValues.site = medium.site
    this.initialFormValues.cpId = _.get(medium, 'cp.id')
    this.initialFormValues.displayPriority = _.defaultTo(medium.displayPriority, '').toString()
    this.initialFormValues.logo = medium.logo

    logger.debug('initialize form values', this.initialFormValues, { medium })
  }

  /**
   * API にデータを送信
   */
  sendData = submitMediumData => {
    if (_.isEmpty(this.props.medium)) {
      return mediumApi.postMedium(submitMediumData)
    }

    const mediumId = this.props.medium.id
    return mediumApi.patchMedium(mediumId, {
      mediumUpdateValues: submitMediumData,
    })
  }

  /**
   * API 通信時のリクエストパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 送信データに空文字があれば 0 を入れる
    const submitDisplayPriority = submitFormData.displayPriority
    submitFormData.displayPriority = _.isEmpty(submitDisplayPriority) ? 0 : parseInt(submitDisplayPriority, 10)
    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}
    initialData.name = initialFormValues.name
    initialData.site = initialFormValues.site
    initialData.cpId = initialFormValues.cpId
    initialData.displayPriority = _.isEmpty(initialFormValues.displayPriority)
      ? 0
      : parseInt(initialFormValues.displayPriority, 10)
    initialData.logo = initialFormValues.logo

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    if (_.has(requestParameters, 'displayPriority')) {
      _.set(requestParameters, 'displayPriority', parseInt(submitFormData.displayPriority, 10))
    }

    logger.debug('get request parameters', {
      requestParameters,
      initialData,
      submitFormData,
    })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getMediumItemsRequestQuery = () => {
    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(this.state.totalItems / this.state.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && this.state.currentPage > totalPage ? totalPage : this.state.currentPage
    // 1 ページあたりに含めるデータの数
    const itemsPerPage = this.state.itemsPerPage

    // フィルタリング
    const filtering = []
    // 削除済は除外
    filtering.push('deletedAt IS NULL')

    // ソートを配列に変換
    const sorting = _.map(this.state.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    return (
      <Modal
        className="MediumEditModal"
        size="large"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.props.medium) ? 'メディアの作成' : 'メディアの編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          {!_.isEmpty(this.props.medium) && <Header size="tiny">メディアの設定一覧</Header>}

          {!_.isEmpty(this.state.mediumItems) && (
            <MediumItemDataTable
              mediumItems={this.state.mediumItems}
              sort={this.state.sorting}
              currentPage={this.state.currentPage}
              itemsPerPage={this.state.itemsPerPage}
              totalPages={this.state.totalPages}
              onPageChange={this.handleDataTablePageChange}
              extColumns={[
                {
                  label: '操作',
                  align: 'center',
                  render: item => (
                    <Button.Group secondary>
                      <Button
                        icon="edit"
                        onClick={() => {
                          this.handleEditMediumItemModalOpen(item)
                        }}
                      />
                      <Button
                        icon="trash alternate outline"
                        onClick={() => {
                          this.handleRemoveMediumItemModalOpen(item)
                        }}
                      />
                    </Button.Group>
                  ),
                },
              ]}
            />
          )}

          {/* 新規作成ボタン */}
          {!_.isEmpty(this.props.medium) && (
            <Menu secondary floated="right">
              <Menu.Item fitted>
                <Button
                  primary
                  content="メディアの設定を追加"
                  icon="write"
                  labelPosition="right"
                  onClick={this.handleCreateMediumItemModalOpen}
                />
              </Menu.Item>
            </Menu>
          )}

          <Divider hidden clearing />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            <Form.Group widths="equal">
              {/* メディア名入力フィールド */}
              <Form.Input name="name" label="メディア名" placeholder="メディア名を入力してください" required />

              {/* 表示優先度フィールド */}
              <Form.Input
                label="表示優先度"
                placeholder="表示優先度"
                name="displayPriority"
                type="number"
                validations={{
                  isNumeric: true,
                }}
              />
            </Form.Group>

            {/* サイト入力フィールド */}
            <Form.Input
              name="site"
              label="サイト"
              placeholder="サイト URL を入力してください"
              validations="isUrl"
              validationErrors={{ isUrl: '無効な URL です' }}
              errorLabel={<FormErrorLabel />}
              required
            />

            {/* CP 名入力フィールド */}
            <Form.Field required>
              <label>CP</label>

              <CpsDropdown cpId={this.state.cpId} onChange={this.handleCpDropdownChange} required />

              <Form.Input required className="isHidden" value={this.state.cpId} name="cpId" />
            </Form.Field>

            {/* ロゴ画像入力フィールド */}
            <Segment>
              <Form.Field error={!_.isNil(this.state.logoImageError)} required>
                <label>ロゴ画像</label>

                <MediumInput mediumUrl={this.state.logoImageUrlInputValue} onChange={this.handleLogoImageInputChange} />

                <Input
                  className="isHidden"
                  name="logo"
                  placeholder="ロゴ画像を選択してください"
                  readOnly
                  value={this.state.logoImageUrlInputValue}
                  required
                />
              </Form.Field>
            </Segment>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.props.medium) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || (!this.state.isFormModified && !this.state.isLogoImageModified)}
          />
        </Modal.Actions>

        {/* メディアの設定追加・編集モーダル */}
        <MediumItemEditModal
          medium={this.props.medium}
          mediumItem={this.state.editMediumItem}
          open={this.state.isMediumItemEditModalOpen}
          onClose={this.handleMediumItemModalClose}
        />

        {/* メディアの設定削除モーダル */}
        <MediumItemRemoveModal
          mediumItem={this.state.removeMediumItem}
          open={this.state.isMediumItemRemoveModalOpen}
          onClose={this.handleRemoveMediumItemModalClose}
        />
      </Modal>
    )
  }
}

MediumEditModal.propTypes = propTypes
MediumEditModal.defaultProps = defaultProps

export default MediumEditModal
