import React, { Component } from 'react'
import { Dimmer, Loader, Header, Icon, Divider, Message } from 'semantic-ui-react'
import LogLevel from '../LogLevel'
import PvReportForm from './PvReportForm'
import ApiErrorMessage from './ApiErrorMessage'
import { ReportApi } from 'trill-api-admin-client'

const logger = LogLevel.getLogger('PvReportContainer')
const reportApi = new ReportApi()

export default class PvReportContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      success: false,
    }
  }

  postReportForm = async newMediaArticlePvReport => {
    this.setState({ isBusy: true })
    await reportApi.mediaArticlePv(newMediaArticlePvReport).then(
      response => {
        logger.debug('API called successfully. Response: ', response)
        this.setState({ isBusy: false, success: true })
      },
      error => {
        logger.debug(error)
        this.setState({ apiError: error, isBusy: false, success: false })
      },
    )
  }

  render() {
    const { apiError, isBusy, success } = this.state
    return (
      <div>
        <Header as="h1">
          <Icon name="chart bar" />
          <Header.Content>PVレポート</Header.Content>
        </Header>
        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>
        <ApiErrorMessage error={apiError} />
        {success && !apiError && (
          <Message positive>
            <Message.Header>
              <Icon name="check circle" />
              レポートのリクエストを受け付けました。
            </Message.Header>
          </Message>
        )}
        <PvReportForm {...this.state} postReportForm={this.postReportForm} />
        <Divider hidden />
        ※ダウンロードリンクがメールで送信されるまで最大50分程度かかります
      </div>
    )
  }
}
