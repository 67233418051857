import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Dimmer, Loader, Button } from 'semantic-ui-react'
import { PersonalityQuizApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('PersonalityQuizRemoveModal')
const personalityQuizApi = new PersonalityQuizApi()
let deletePersonalityQuiz

const propTypes = {
  /**
   * 削除対象の診断
   */
  personalityQuiz: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新に成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  open: false,
}

class PersonalityQuizRemoveModal extends Component {
  state = {
    isBusy: false,
    apiError: null,
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(deletePersonalityQuiz)) {
      deletePersonalityQuiz.cancel()
    }
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCacncelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 削除ボタンを押したときのハンドラ
   */
  handleRemoveButtonClick = () => {
    const personalityQuizId = this.props.personalityQuiz.id

    this.setState({
      isBusy: true,
      apiError: null,
    })

    deletePersonalityQuiz = CancelablePromise(personalityQuizApi.deletePersonalityQuiz(personalityQuizId))
    deletePersonalityQuiz.promise
      .then(() => {
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }
        this.setState({ isBusy: false }, () => {
          if (this.props.onClose) {
            this.props.onClose()
          }
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('remove personality quiz error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <Modal
        className="PersonalityQuizRemoveModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>診断の削除</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <p>診断をゴミ箱へ移動しますか？</p>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCacncelButtonClick} />

          <Button negative content="ゴミ箱へ移動" onClick={this.handleRemoveButtonClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

PersonalityQuizRemoveModal.propTypes = propTypes
PersonalityQuizRemoveModal.defaultProps = defaultProps

export default PersonalityQuizRemoveModal
