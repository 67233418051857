import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown, Input } from 'semantic-ui-react'
import _ from 'lodash'

import './Pagination.css'

const propTypes = {
  /**
   * 全体のページ数
   */
  pageCount: PropTypes.number,

  /**
   * 現在のページ番号
   */
  currentPage: PropTypes.number,

  /**
   * ページネーションが無効かどうか
   */
  disabled: PropTypes.bool,

  /**
   * レイアウト（`float`）指定オプション
   */
  floated: PropTypes.string,

  /**
   * ページネーション変更イベントのハンドラー
   *
   * @param {SyntheticEvent} event - React の SyntheticEvent
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {number} data.currentPage - （変更後の）現在のページ番号
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  pageCount: 1,
  currentPage: 1,
}

class Pagination extends PureComponent {
  state = {
    currentPage: 1,
    pageInputValue: 1,
  }

  currentPage = 1
  pageInputValue = 1

  constructor(props) {
    super(props)

    // TODO: 指定されたページ番号がページ範囲内かどうかのチェック

    this.currentPage = props.currentPage
    this.pageInputValue = props.currentPage

    _.extend(this.state, {
      currentPage: this.currentPage,
      pageInputValue: this.pageInputValue,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentPage !== this.currentPage) {
      // TODO: 指定されたページ番号がページ範囲内かどうかのチェック

      this.currentPage = nextProps.currentPage
      this.pageInputValue = nextProps.currentPage

      this.setState({
        currentPage: this.currentPage,
        pageInputValue: this.currentPage,
      })
    }
  }

  handleFirstItemClick = (event, { disabled }) => {
    if (!disabled) {
      this.changePage(event, 1)
    }
  }

  handlePrevItemClick = (event, { disabled }) => {
    if (!disabled) {
      this.changePage(event, this.state.currentPage - 1)
    }
  }

  handlePageItemClick = (event, { content, disabled }) => {
    if (!disabled) {
      this.changePage(event, content)
    }
  }

  handleNextItemClick = (event, { disabled }) => {
    if (!disabled) {
      this.changePage(event, this.state.currentPage + 1)
    }
  }

  handleLastItemClick = (event, { disabled }) => {
    if (!disabled) {
      this.changePage(event, this.props.pageCount)
    }
  }

  handlePageDropdownChange = (event, { value }) => {
    this.changePage(event, value)
  }

  handlePageInputChange = (event, { value }) => {
    this.pageInputValue = _.parseInt(value)

    if (_.isFinite(this.pageInputValue)) {
      if (this.pageInputValue < 1) {
        this.pageInputValue = 1
      } else if (this.pageInputValue > this.props.pageCount) {
        this.pageInputValue = this.props.pageCount
      }
    } else {
      this.pageInputValue = ''
    }

    this.setState({
      pageInputValue: this.pageInputValue,
    })
  }

  handlePageInputBlur = () => {
    // 入力を確定しなかったら入力値を現在のページ番号に戻す
    this.setState({
      pageInputValue: this.state.currentPage,
    })
  }

  handlePageInputKeyPress = event => {
    if (event.key === 'Enter' && _.isFinite(this.pageInputValue)) {
      this.changePage(event, this.pageInputValue)
    }
  }

  changePage = (event, targetPage) => {
    this.currentPage = targetPage

    // TODO: 指定されたページ番号がページ範囲内かどうかのチェック

    this.setState({
      currentPage: this.currentPage,
      pageInputValue: this.currentPage,
    })

    if (this.props.onChange) {
      this.props.onChange(event, {
        ...this.props,
        currentPage: this.currentPage,
      })
    }
  }

  render() {
    const isPrevFirstDisabled = this.props.disabled || this.state.currentPage === 1
    const isNextLastDisabled = this.props.disabled || this.state.currentPage === this.props.pageCount

    return (
      <Menu floated={this.props.floated} pagination className="Pagination">
        <Menu.Item icon="double angle left" disabled={isPrevFirstDisabled} onClick={this.handleFirstItemClick} />

        <Menu.Item icon="angle left" disabled={isPrevFirstDisabled} onClick={this.handlePrevItemClick} />

        {/* ページの数に応じて Item, Dropdown, Input を切り替える */}
        {this.props.pageCount <= 10 ? ( // eslint-disable-line no-nested-ternary
          _.map(Array(this.props.pageCount), (value, index) => (
            <Menu.Item
              key={index}
              content={index + 1}
              active={!this.props.disabled && index + 1 === this.state.currentPage}
              disabled={this.props.disabled}
              onClick={this.handlePageItemClick}
            />
          ))
        ) : this.props.pageCount <= 100 ? (
          <Dropdown
            floating
            scrolling
            className="item upward Pagination__PageDropdown"
            options={_.map(Array(this.props.pageCount), (value, index) => {
              const pageNumber = index + 1

              return {
                text: `${pageNumber} / ${this.props.pageCount}`,
                value: pageNumber,
                content: pageNumber,
              }
            })}
            value={this.state.currentPage}
            disabled={this.props.disabled}
            onChange={this.handlePageDropdownChange}
          />
        ) : (
          <div className="item">
            <Input
              transparent
              icon="write"
              iconPosition="left"
              className="Pagination__PageInput"
              type="number"
              min="1"
              max={this.props.pageCount}
              value={this.state.pageInputValue}
              disabled={this.props.disabled}
              onChange={this.handlePageInputChange}
              onBlur={this.handlePageInputBlur}
              onKeyPress={this.handlePageInputKeyPress}
            />

            <span>&nbsp;/&nbsp;{this.props.pageCount}</span>
          </div>
        )}

        <Menu.Item icon="angle right" disabled={isNextLastDisabled} onClick={this.handleNextItemClick} />

        <Menu.Item icon="double angle right" disabled={isNextLastDisabled} onClick={this.handleLastItemClick} />
      </Menu>
    )
  }
}

Pagination.propTypes = propTypes
Pagination.defaultProps = defaultProps

export default Pagination
