import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const propTypes = {
  /**
   * Dropdown の値
   */
  value: PropTypes.any,

  /**
   * Dropdown のオプション
   */
  options: PropTypes.array.isRequired,

  /**
   * 最大ページ数
   */
  totalPages: PropTypes.number,

  /**
   * 現在のページ
   */
  currentPage: PropTypes.number,

  /**
   * ローディング表示
   */
  loading: PropTypes.bool,

  /**
   * 複数選択を許可するかどうか
   */
  multiple: PropTypes.bool,

  /**
   * クリア可能な設定を使用すると、ユーザーはドロップダウンから選択を削除できます。
   */
  clearable: PropTypes.bool,

  /**
   * プレイスホルダー
   */
  placeholder: PropTypes.string,

  /**
   * 検索結果がないときのメッセージ
   */
  noResultsMessage: PropTypes.string,

  /**
   * 幅を最大にするかどうか
   */
  fluid: PropTypes.bool,

  /**
   * API
   */
  api: PropTypes.object,

  /**
   * スクロール位置が半分の箇所で再読み込みを行うために呼び出す外部関数
   */
  onLoadMore: PropTypes.func,

  /**
   * 値を変更したときに呼び出す外部関数
   */
  onChange: PropTypes.func,

  /**
   * 検索の値を変更したときに呼び出す外部関数
   */
  onSearch: PropTypes.func,

  /**
   * 複数選択時のラベルのレンダリング関数
   */
  renderLabel: PropTypes.func,

  /**
   * Whether disabled or not
   */
  disabled: PropTypes.bool,
}

const defaultProps = {
  value: null,
  options: [],
  totalPages: 0,
  currentPage: 0,
  loading: false,
  placeholder: '',
  noResultsMessage: '',
  multiple: false,
  clearable: false,
  fluid: true,
  disabled: false,
}

class InfiniteDropdown extends Component {
  state = {
    searchQuery: '',
  }

  componentDidMount() {
    this.dropdownRef.ref.current.addEventListener('scroll', this.handleDropdownScroll, true)
  }

  componentWillUnmount() {
    this.dropdownRef.ref.current.removeEventListener('scroll', this.handleDropdownScroll, true)
  }

  /**
   * スクロール開始位置 (スクロール方向判定のため使用)
   */
  scrollStartPos = 0

  /**
   * ドロップダウンをスクロールしたときのハンドラ
   */
  handleDropdownScroll = event => {
    // スクロールの距離
    const scrollTop = event.target.scrollTop
    // 表示エリアの高さを差し引いた高さ (DropdownMenu の高さ)
    const scrollHeight = event.target.scrollHeight - event.target.clientHeight
    // 下方向のスクロール
    const isDownScroll = this.scrollStartPos < scrollTop

    this.scrollStartPos = scrollTop

    const isLastPage = this.props.totalPages === this.props.currentPage
    if (this.props.loading || isLastPage || this.props.totalPages === 0) {
      // 通信中 or 最後のページ or ページがなければ onLoadMore は呼び出さずに終了
      return
    }

    if (isDownScroll && scrollHeight / 2 < scrollTop && this.props.onLoadMore) {
      // 下方向に半分スクロールしたら次のページを表示
      this.props.onLoadMore()
    }
  }

  /**
   * ドロップダウンの値を変更したときのハンドラ
   */
  handleDropdownValueChange = (event, { value }) => {
    this.setState({ searchQuery: '' })

    if (this.props.onChange) {
      this.props.onChange(event, { value })
    }
  }

  /**
   * ドロップダウンに文字を入力して値を変更したときのハンドラ
   */
  handleSearchChange = (event, value) => {
    if (value.searchQuery.match(/\,/)) { // eslint-disable-line
      value.searchQuery = ''
    }

    this.setState({ searchQuery: value.searchQuery })

    if (this.props.onSearch) {
      const searchQuery = value.searchQuery
      this.props.onSearch(searchQuery)
    }
  }

  render() {
    return (
      <Dropdown
        className="InfiniteDropdown"
        ref={ref => {
          this.dropdownRef = ref
        }}
        fluid={this.props.fluid}
        loading={this.props.loading}
        onChange={this.handleDropdownValueChange}
        onSearchChange={this.handleSearchChange}
        placeholder={this.props.placeholder}
        noResultsMessage={this.props.noResultsMessage}
        search
        selection
        searchQuery={this.state.searchQuery}
        options={this.props.options}
        value={this.props.value}
        closeOnChange
        multiple={this.props.multiple}
        clearable={this.props.clearable}
        renderLabel={this.props.renderLabel}
        disabled={this.props.disabled}
      />
    )
  }
}

InfiniteDropdown.propTypes = propTypes
InfiniteDropdown.defaultProps = defaultProps

export default InfiniteDropdown
