import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Dimmer, Loader, Message, Popup } from 'semantic-ui-react'
import { FeedApi, CategoryApi } from 'trill-api-admin-client'
import { Form, Input } from 'formsy-semantic-ui-react'
import classNames from 'classnames'

import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import TagsDropdown from './TagsDropdown'
import CategoriesDropdown from './CategoriesDropdown'
import MediumInput from './MediumInput'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'
import { flattenObject } from '../util'

const logger = LogLevel.getLogger('FeedEditModal')
const feedApi = new FeedApi()
const categoryApi = new CategoryApi()
let sendFeed
let getFeedDetail
let getCategories

const propTypes = {
  /**
   * 編集対象のフィード (作成の場合は空)
   */
  feed: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * 選べる表示順の最大値
   */
  maxOrder: PropTypes.number,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が完了したときに呼び出す外部関数
   */
  onSuccessDataChange: PropTypes.func,
}

const defaultProps = {
  feed: {},
  open: false,
  maxOrder: 0,
}

class FeedEditModal extends Component {
  state = {
    categoryId: null,
    isFormValid: false,
    isFormModified: false,
    isBusy: false,
    iconImageInputValue: '',
    iconImageError: null,
    tagsDropdownValues: [], // 選択中のタグ ID 一覧
    isTagsDropdownValuesModified: false,
    feedType: '',
    categories: [],
    apiError: null,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フィードに関連するタグ ID 一覧
   * tagsDropdownValues との差分でタグの保存、削除を行う
   */
  initialFeedTagIds = []

  /**
   * フォームの入力データが初期化されているかどうか
   */
  isFormResetted = false

  /**
   * カテゴリ選択用のために API からカテゴリ一覧の取得を行う
   */
  componentDidMount() {
    getCategories = CancelablePromise(categoryApi.getCategories())
    getCategories.promise
      .then(response => {
        const categories = response.data
        this.setState({
          categories,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get categories error', error)
      })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.feed, nextProps.feed)) {
      this.setState(
        {
          isBusy: true,
          isFormValid: false,
          isFormModified: false,
          isTagsDropdownValuesModified: false,
          iconImageInputValue: _.get(nextProps.feed, 'icon', ''),
          feedType: _.get(nextProps, 'feed.type', ''),
        },
        () => {
          this.getFeedDetail()
            .then(feed => {
              const tags = _.get(feed, 'tags', {})

              this.initialFeedTagIds = _.map(tags, tag => tag.id)

              // tagsUnion などは詳細取得後に入るため、ここでフォームのリセットを再度行う
              if (!_.isEmpty(feed)) {
                this.initializeFormValues(feed)
                this.refs.form.reset(this.initialFormValues)
              }

              this.setState({
                tagsDropdownValues: this.initialFeedTagIds,
                categoryId: _.get(feed, 'category.id', null),
                isBusy: false,
              })
            })
            .catch(error => {
              if (error.isCanceled) {
                return
              }

              this.setState({ isBusy: false })
            })
        },
      )

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.feed) {
        this.initializeFormValues(this.props.feed)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }
    }

    this.isFormResetted = true
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendFeed)) {
      sendFeed.cancel()
    }
    if (!_.isNil(getFeedDetail)) {
      getFeedDetail.cancel()
    }
    if (!_.isNil(getCategories)) {
      getCategories.cancel()
    }
  }

  /**
   * カスタムフィード選択時のタグを変更したときのハンドラ
   */
  handleTagsDropdownChange = (event, { value }) => {
    const sortedFeedTagIds = _.sortBy(this.initialFeedTagIds)
    const isTagsDropdownValuesModified = !_.isEqual(sortedFeedTagIds, _.sortBy(value))

    this.setState({
      tagsDropdownValues: value,
      isTagsDropdownValuesModified,
    })
  }

  /**
   * カテゴリフィード選択時のカテゴリを変更したときのハンドラ
   */
  handleCategoriesDropdownChange = (event, { value }) => {
    this.setState({ categoryId: value })
  }

  /**
   * フィードのタイプを変更したときのハンドラ
   */
  handleTypeDropdownChange = (event, { value }) => {
    this.setState({ feedType: value })
  }

  /**
   * アイコン画像を変更したときのハンドラ
   */
  handleIconImageInputChange = (event, { mediumUrl, error }) => {
    this.setState({
      iconImageInputValue: _.defaultTo(mediumUrl, ''),
      iconImageError: error,
    })
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }
    const flattenCurrentValues = flattenObject(currentValues)

    // 現在のフォームデータと初期のフォームデータを比較
    _.each(flattenCurrentValues, (currentValue, inputName) => {
      const initialValue = this.initialFormValues[inputName]
      if (_.defaultTo(initialValue, '').toString() !== _.defaultTo(currentValue, '').toString()) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    // 通信前にローディングを出す
    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendFeed = CancelablePromise(this.sendData(this.getRequestParameters(submitFormData)))
    sendFeed.promise
      .then(response => {
        const feed = response.data

        if (this.props.onSuccessDataChange) {
          this.props.onSuccessDataChange()
        }

        this.initializeFormValues(feed)

        resetForm(this.initialFormValues)

        return this.sendFeedTagsData(feed)
      })
      .then(() => {
        // カスタムフィード以外を選んでいた場合は空に設定
        const isCustomFeed = _.isEqual(this.state.feedType, 'custom')
        const tagsDropdownValues = isCustomFeed ? this.state.tagsDropdownValues : []
        const categoryId = _.isEqual(this.state.feedType, 'category') ? this.state.categoryId : null

        this.setState(
          {
            isBusy: false,
            categoryId,
            isFormModified: false,
            isTagsDropdownValuesModified: false,
            tagsDropdownValues,
          },
          () => {
            this.initialFeedTagIds = this.state.tagsDropdownValues

            if (_.isEmpty(this.props.feed) && this.props.onClose) {
              this.props.onClose()
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send data error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * フォームの初期化
   * @param {Object} feedData フィード情報
   */
  initializeFormValues(feedData) {
    this.initialFormValues.name = feedData.name
    this.initialFormValues.icon = _.get(feedData, 'icon', '')
    this.initialFormValues.type = feedData.type
    this.initialFormValues.order = feedData.order
    this.initialFormValues.categoryId = _.get(feedData, 'category.id', null)
    this.initialFormValues.tagsUnion = _.defaultTo(feedData.tagsUnion, false)
    this.initialFormValues['adUnitId.ios'] = _.get(feedData, 'adUnitId.ios', '')
    this.initialFormValues['adUnitId.android'] = _.get(feedData, 'adUnitId.android', '')
    this.initialFormValues['adUnitId.webMobile'] = _.get(feedData, 'adUnitId.webMobile', '')
    this.initialFormValues['adUnitId.webDesktop'] = _.get(feedData, 'adUnitId.webDesktop', '')
  }

  /**
   * フィード詳細を取得
   */
  getFeedDetail = () =>
    new Promise((resolve, reject) => {
      const feed = this.props.feed

      if (_.isEmpty(feed)) {
        // 新規作成の場合
        resolve()
        return
      }

      const type = _.get(this.props, 'feed.type')
      const isCategoryFeed = _.isEqual(type, 'category')
      const isCustomFeed = _.isEqual(type, 'custom')

      if (!isCategoryFeed && !isCustomFeed) {
        // カテゴリフィードでもカスタムフィードでもない場合
        resolve()
        return
      }

      if (_.isEqual(type, 'category')) {
        // カテゴリフィードは詳細取得 getFeed を使ってカテゴリを取得
        getFeedDetail = CancelablePromise(feedApi.getFeed(feed.id))
        getFeedDetail.promise
          .then(response => {
            const feedDetail = response.data

            resolve(feedDetail)
          })
          .catch(error => {
            reject(error)
          })
      } else if (_.isEqual(type, 'custom')) {
        // 初回のヘッダー
        const header = {
          currentPage: 1,
          itemsPerPage: 10,
        }
        let tags = []
        // カスタムフィードの場合は getFeedTags で関連タグ一覧を取得
        feedApi
          .getFeedTags(feed.id, header)
          .then(response => {
            // 初回の通信で取得した関連タグ一覧
            tags = _.concat(tags, response.data)
            // 初回の通信で取得したレスポンスヘッダー
            const responseHeader = response.header
            // 合計ページ数 (合計ページ分、API 通信をして関連タグを全て取得する必要がある)
            const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)

            const feedTagPromises = []
            // 現在ページが合計ページ未満の場合
            while (header.currentPage < totalPages) {
              // 関連タグ取得 API を feedTagPromises に push
              _.set(header, 'currentPage', header.currentPage + 1)
              feedTagPromises.push(feedApi.getFeedTags(feed.id, header))
            }

            // tagsUnion 情報を取得する必要があるため getFeed でフィード詳細も取得
            return Promise.all([feedApi.getFeed(feed.id), Promise.all(feedTagPromises)])
          })
          .then(([feedDetailResponse, feedTagsResponses]) => {
            const feedDetail = feedDetailResponse.data
            const feedTags = _.flatMap(feedTagsResponses, feedTagsResponse => feedTagsResponse.data)
            // フィードに関連タグを設定
            feedDetail.tags = _.omitBy(_.concat(tags, feedTags), _.isUndefined)

            resolve(feedDetail)
          })
          .catch(error => {
            reject(error)
          })
      }
    })

  /**
   * タグの追加、削除
   * 選択中のタグ (tagsDropdownValues) と フィードに関連してるタグ (initialFeedTagIds) との差分から追加、削除処理を分ける
   */
  sendFeedTagsData = feed => {
    const { tagsDropdownValues } = this.state
    const feedTagIds = this.initialFeedTagIds

    const putTagIds = _.difference(tagsDropdownValues, feedTagIds)
    // カスタムフィード以外を選択していた場合、全て削除
    const deleteTagIds = _.isEqual(this.state.feedType, 'custom')
      ? _.difference(feedTagIds, tagsDropdownValues)
      : tagsDropdownValues

    const putPromises = _.map(putTagIds, tagId => {
      feedApi.putFeedTag(feed.id, tagId).then(() => Promise.resolve())
    })
    const deletePromises = _.map(deleteTagIds, tagId => {
      feedApi.deleteFeedTag(feed.id, tagId).then(() => Promise.resolve())
    })
    const promises = putPromises.concat(deletePromises)

    return Promise.all([promises])
  }

  /**
   * API にデータを送信
   */
  sendData = submitFeedData => {
    if (_.isEmpty(this.props.feed)) {
      return feedApi.postFeed(submitFeedData)
    }

    const feedId = this.props.feed.id

    return feedApi.patchFeed(feedId, { feedUpdateValues: submitFeedData })
  }

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}
    initialData.name = initialFormValues.name
    initialData.icon = initialFormValues.icon
    initialData.type = initialFormValues.type
    initialData.order = initialFormValues.order
    initialData.tagsUnion = initialFormValues.tagsUnion
    initialData.categoryId = initialFormValues.categoryId

    _.set(initialData, 'adUnitId.ios', _.get(initialFormValues, 'adUnitId.ios'))
    _.set(initialData, 'adUnitId.android', _.get(initialFormValues, 'adUnitId.android'))
    _.set(initialData, 'adUnitId.webMobile', _.get(initialFormValues, 'adUnitId.webMobile'))
    _.set(initialData, 'adUnitId.webDesktop', _.get(initialFormValues, 'adUnitId.webDesktop'))

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    if (_.has(requestParameters, 'order')) {
      requestParameters.order = parseInt(submitFormData.order, 10)
    }

    if (!_.isEqual(this.state.feedType, 'category') && submitFormData.categoryId > 0) {
      // フィードタイプに category 以外を選択している かつ categoryId が設定されていた場合リセット
      requestParameters.categoryId = 0
    } else if (!_.isEqual(this.state.feedType, 'category')) {
      // フィードタイプに category 以外を選択している場合 categoryId は送らない
      _.unset(requestParameters, 'categoryId')
    }

    if (!_.isEqual(this.state.feedType, 'custom')) {
      // カスタムフィード以外を選んだ場合、tagsUnion の値を false に変更
      requestParameters.tagsUnion = false
    } else {
      requestParameters.tagsUnion = submitFormData.tagsUnion
    }

    if (_.isEqual(_.get(requestParameters, 'adUnitId.ios'), '')) {
      _.set(requestParameters, 'adUnitId.ios', ' ')
    }

    if (_.isEqual(_.get(requestParameters, 'adUnitId.android'), '')) {
      _.set(requestParameters, 'adUnitId.android', ' ')
    }

    if (_.isEqual(_.get(requestParameters, 'adUnitId.webMobile'), '')) {
      _.set(requestParameters, 'adUnitId.webMobile', ' ')
    }

    if (_.isEqual(_.get(requestParameters, 'adUnitId.webDesktop'), '')) {
      _.set(requestParameters, 'adUnitId.webDesktop', ' ')
    }

    logger.debug('get request parameters', {
      requestParameters,
      initialData,
      submitFormData,
    })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  /**
   * フィードタイプ選択用 Dropdown の options を返却
   */
  getFeedTypeDropdownOptions = () => {
    if (
      _.isEmpty(this.props.feed) ||
      _.isEqual(this.state.feedType, 'category') ||
      _.isEqual(this.state.feedType, 'custom')
    ) {
      // 新規作成 or カテゴリフィード or カスタムフィードの場合、選べるタイプはカテゴリとカスタムのみにする
      return [
        {
          text: 'カテゴリフィード',
          value: 'category',
        },
        {
          text: 'カスタムフィード',
          value: 'custom',
        },
      ]
    }

    // Dropdown に値を表示するためにトップ、特集、診断の場合は全ての Options を返却
    return [
      {
        text: 'トップ',
        value: 'top',
      },
      {
        text: '特集',
        value: 'matome',
      },
      {
        text: '診断',
        value: 'personalityQuiz',
      },
      {
        text: 'カテゴリフィード',
        value: 'category',
      },
      {
        text: 'カスタムフィード',
        value: 'custom',
      },
    ]
  }

  render() {
    return (
      <Modal
        className="FeedEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.props.feed) ? 'フィードの作成' : 'フィードの編集'}</Modal.Header>

        {/** ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* API エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/** フィード名入力フィールド */}
            <Form.Input
              name="name"
              label="フィード名"
              placeholder="フィード名を入力してください"
              required
              validations="maxLength:9"
              validationErrors={{ maxLength: '9 文字以内で入力してください。' }}
              errorLabel={<FormErrorLabel />}
            />

            {/** アイコン画像フィールド */}
            <Form.Field required>
              <label>アイコン画像</label>

              <MediumInput mediumUrl={this.state.iconImageInputValue} onChange={this.handleIconImageInputChange} />

              <Input
                className="isHidden"
                name="icon"
                placeholder="アイコン画像を選択してください"
                required
                readOnly
                value={this.state.iconImageInputValue}
              />
            </Form.Field>

            {/** 表示順入力フィールド */}
            <Form.Input
              name="order"
              label="表示順"
              placeholder="表示順を入力してください"
              type="number"
              required
              disabled={_.isEqual(this.state.feedType, 'top')}
              validations={{
                isOverTwoNumber: (values, value) => (!_.isEmpty(value) && value > 1) || _.isEmpty(value),
                isValidMaxOrder: (values, value) =>
                  (this.props.maxOrder !== 0 && this.props.maxOrder >= value) || this.props.maxOrder === 0,
              }}
              validationErrors={{
                isOverTwoNumber: '2 以上で入力してください。',
                isValidMaxOrder: `${this.props.maxOrder} 以下で入力してください。`,
              }}
              errorLabel={<FormErrorLabel />}
            />

            <Form.Group widths="equal">
              {/* iOS の 広告 ID 入力フィールド */}
              <Popup
                inverted
                wide="very"
                content="フィードで表示する広告の SpaceId を入れてください。"
                trigger={
                  <Form.Input
                    name="adUnitId.ios"
                    label="広告 ID (iOS)"
                    placeholder="iOS の広告 ID を入力してください"
                  />
                }
              />

              {/* Android の 広告 ID 入力フィールド */}
              <Popup
                inverted
                wide="very"
                content="フィードで表示する広告の SpaceId を入れてください。"
                trigger={
                  <Form.Input
                    name="adUnitId.android"
                    label="広告 ID (Android)"
                    placeholder="Android の広告 ID を入力してください"
                  />
                }
              />
            </Form.Group>

            <Form.Group widths="equal">
              {/* web mobile の 広告 ID 入力フィールド */}
              <Popup
                inverted
                wide="very"
                content="フィードで表示する広告の SpaceId を入れてください。"
                trigger={
                  <Form.Input
                    name="adUnitId.webMobile"
                    label="広告 ID (web mobile)"
                    placeholder="web (mobile) の広告 ID を入力してください"
                  />
                }
              />

              {/* web desktop の 広告 ID 入力フィールド */}
              <Popup
                inverted
                wide="very"
                content="フィードで表示する広告の SpaceId を入れてください。"
                trigger={
                  <Form.Input
                    name="adUnitId.webDesktop"
                    label="広告 ID (web desktop)"
                    placeholder="web (desktop) の広告 ID を入力してください"
                  />
                }
              />
            </Form.Group>

            {/** タイプ選択フィールド */}
            <Form.Dropdown
              required
              name="type"
              label="タイプ"
              placeholder="カテゴリタイプを選択してください"
              noResultsMessage="該当するカテゴリタイプが見つかりません"
              search
              selection
              disabled={
                !_.isEmpty(this.props.feed) &&
                !_.isEqual(this.state.feedType, 'category') &&
                !_.isEqual(this.state.feedType, 'custom')
              }
              options={this.getFeedTypeDropdownOptions()}
              onChange={this.handleTypeDropdownChange}
            />

            {/** カテゴリ選択フィールド */}
            <Form.Field
              className={classNames({
                isHidden: !_.isEqual(this.state.feedType, 'category'),
              })}
              required={_.isEqual(this.state.feedType, 'category')}
            >
              <label>カテゴリ</label>

              <CategoriesDropdown
                required={_.isEqual(this.state.feedType, 'category')}
                categoryId={this.state.categoryId}
                onChange={this.handleCategoriesDropdownChange}
              />

              <Form.Input
                required={_.isEqual(this.state.feedType, 'category')}
                className="isHidden"
                name="categoryId"
                value={this.state.categoryId}
              />
            </Form.Field>

            {/** タグ選択フィールド(複数選択可) */}
            <div
              className={classNames({
                isHidden: !_.isEqual(this.state.feedType, 'custom'),
              })}
            >
              <Form.Field>
                <label>タグ</label>

                <TagsDropdown value={this.state.tagsDropdownValues} onChange={this.handleTagsDropdownChange} />
              </Form.Field>

              {/** タグユニオン選択フィールド( on or off ) */}
              <Form.Field>
                <label>タグユニオン設定</label>

                <Message
                  size="tiny"
                  content="タグを複数選択した状態でタグユニオンをオンにすると、設定したタグ全てに紐づいている記事がフィードに表示されます"
                />

                <Form.Checkbox toggle name="tagsUnion" />
              </Form.Field>
            </div>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.props.feed) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={
              !this.state.isFormValid || (!this.state.isFormModified && !this.state.isTagsDropdownValuesModified)
            }
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

FeedEditModal.propTypes = propTypes
FeedEditModal.defaultProps = defaultProps

export default FeedEditModal
