import React, { Component } from 'react'
import { Segment, Grid, Label } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormErrorLabel from './FormErrorLabel'
import MediumInput from './MediumInput'
import CreatorsDropdown from './CreatorsDropdown'

const propTypes = {
  /**
   * Series to edit (empty object if create)
   */
  series: PropTypes.object,
  /**
   * the form auto submitted when value is true
   */
  isSubmitForm: PropTypes.bool,
  /**
   * Handler to be called when the form is changed
   */
  onFormChange: PropTypes.func,
  /**
   * Handler to be called when the form is valid
   */
  onFormValid: PropTypes.func,
  /**
   * Handler to be called when the form is invalid
   */
  onFormInvalid: PropTypes.func,
  /**
   * Handler to be called when the form is submitted
   */
  onFormValidSubmit: PropTypes.func,
}

const defaultPropTypes = {
  series: {},
}

class SeriesForm extends Component {
  state = {
    creator: null,
    thumbnailImageUrlInputValue: '',
    thumbnailImageError: null,
  }

  /**
   * Initial form values
   */
  initialFormValues = {}
  /**
   * Whether the input data of the form is initialized
   */
  isFormResetted = false

  componentWillMount() {
    const creator = _.get(this.props, 'series.creator', undefined)
    const thumbnailImageUrlInputValue = _.get(this.props, 'series.thumbnail.image.url', '')

    this.setState({
      creator,
      thumbnailImageUrlInputValue,
      thumbnailImageError: null,
    })
    this.initializeFormValues(this.props.series)
    this.isFormResetted = false
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.isSubmitForm, this.props.isSubmitForm)) {
      if (this.refs.form && nextProps.isSubmitForm) {
        this.refs.form.submit()
      }
    }
  }

  componentDidUpdate() {
    if (this.refs.form && !this.isFormResetted) {
      this.refs.form.reset(this.initialFormValues)
      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // To Do
  }

  /**
   * Handler to be called when the form is changed
   */
  handleFormChange = currentValues => {
    if (this.props.onFormChange) {
      const isModified = !_.isEqual(this.initialFormValues, currentValues)

      this.props.onFormChange(this.getFormatSeriesFromFormValues(currentValues), isModified)
    }
  }

  /**
   * Handler to be called when the form is valid
   */
  handleFormValid = () => {
    if (this.props.onFormValid) {
      this.props.onFormValid()
    }
  }

  /**
   * Handler to be called when the form is invalid
   */
  handleFormInvalid = () => {
    if (this.props.onFormInvalid) {
      this.props.onFormInvalid()
    }
  }

  /**
   * Handler to be called when the form is submitted
   */
  handleFormValidSubmit = submitFormData => {
    if (this.props.onFormValidSubmit) {
      this.props.onFormValidSubmit(this.getFormatSeriesFromFormValues(submitFormData))
    }
  }

  /**
   * Handler to be called when the dropdown creator selection value is changed
   */
  handleCreatorDropdownChange = (event, { detail }) => {
    this.setState({ creator: detail })
  }

  /**
   * Handler to be called when the thumbnail image input form is changed
   */
  handleThumbnailImageInputChange = (event, { mediumUrl, error }) => {
    this.setState({
      thumbnailImageUrlInputValue: _.defaultTo(mediumUrl, ''),
      thumbnailImageError: error,
    })
  }

  /**
   * Initial form values
   */
  initializeFormValues(series) {
    if (_.isEmpty(series)) {
      this.initialFormValues = {}
      return
    }

    this.initialFormValues.creatorId = _.get(series, 'creator.id', undefined)
    this.initialFormValues.title = series.title
    this.initialFormValues['thumbnail.image.url'] = _.get(series, 'thumbnail.image.url', '')
    this.initialFormValues['thumbnail.image.copyright.title'] = _.get(series, 'thumbnail.image.copyright.title', '')
    this.initialFormValues['thumbnail.image.copyright.url'] = _.get(series, 'thumbnail.image.copyright.url', '')
  }

  /**
   * Get format creator from form values
   */
  getFormatSeriesFromFormValues = formValues => {
    const image = {
      url: _.get(formValues, 'thumbnail.image.url', ''),
      copyright: {
        title: _.get(formValues, 'thumbnail.image.copyright.title', ''),
        url: _.get(formValues, 'thumbnail.image.copyright.url', ''),
      },
    }

    return {
      creatorId: formValues.creatorId,
      title: formValues.title,
      thumbnail: { image },
    }
  }

  render() {
    return (
      <Form
        ref="form"
        noValidate
        onChange={this.handleFormChange}
        onValid={this.handleFormValid}
        onInvalid={this.handleFormInvalid}
        onValidSubmit={this.handleFormValidSubmit}
      >
        <Grid columns="2" doubling>
          <Grid.Column width={10}>
            <Form.Field required width={6}>
              <label>クリエイター名</label>

              <CreatorsDropdown creator={this.state.creator} onChange={this.handleCreatorDropdownChange} />
              <Form.Input className="isHidden" name="creatorId" value={_.get(this.state.creator, 'id', undefined)} />
            </Form.Field>

            <Form.Input name="title" label="連載タイトル" placeholder="連載タイトルを入力してください。" required />
          </Grid.Column>

          <Grid.Column width={6}>
            <Segment.Group>
              <Segment>
                <Label attached="top" color="blue" content="サムネイル" />

                <Form.Field required>
                  <label>画像</label>

                  <MediumInput
                    mediumUrl={this.state.thumbnailImageUrlInputValue}
                    onChange={this.handleThumbnailImageInputChange}
                  />

                  <Form.Input
                    className="isHidden"
                    name="thumbnail.image.url"
                    placeholder="サムネイル画像を選択してください"
                    required
                    readOnly
                    value={this.state.thumbnailImageUrlInputValue}
                  />
                </Form.Field>

                <Form.Input
                  label="出典元"
                  placeholder="出典元を入力してください"
                  name="thumbnail.image.copyright.title"
                />

                <Form.Input
                  label="出典元の URL"
                  placeholder="出典元の URL を入力してください"
                  name="thumbnail.image.copyright.url"
                  validations="isUrl"
                  validationErrors={{ isUrl: '無効な URL です' }}
                  errorLabel={<FormErrorLabel />}
                />
              </Segment>
            </Segment.Group>
          </Grid.Column>
        </Grid>
      </Form>
    )
  }
}

SeriesForm.propTypes = propTypes
SeriesForm.defaultPropTypes = defaultPropTypes

export default SeriesForm
