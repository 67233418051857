import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { SponsorApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('SponsorsDropdown')
const sponsorApi = new SponsorApi()

let getSelectedSponsors
let getSponsors

const propTypes = {
  /**
   * スポンサー ID
   */
  sponsorId: PropTypes.number,

  /**
   * スポンサーID一覧
   */
  sponsorIds: PropTypes.arrayOf(PropTypes.number),

  /**
   * 必須項目
   */
  required: PropTypes.bool,

  /**
   * ゴミ箱にあるコンテンツを表示させたくない場合
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * スポンサー項目を変更したときに呼び出す外部関数
   */
  onChange: PropTypes.func,

  /**
   * 保証PV を枠外に表示
   */
  viewSavingArticleMaximum: PropTypes.bool,

  /**
   * Whether disabled or not
   */
  disabled: PropTypes.bool,

  /**
   * 複数選択を許可するかどうか
   */
  multiple: PropTypes.bool,

  /**
   * クリア可能な設定を使用すると、ユーザーはドロップダウンから選択を削除できます。
   */
  clearable: PropTypes.bool,
}

const defaultProps = {
  sponsorId: null,
  sponsorIds: [],
  required: false,
  ignoreTrashContents: true,
  viewSavingArticleMaximum: false,
  disabled: false,
  multiple: false,
  clearable: false,
}

/**
 * スポンサー選択用のコンポーネント
 */
class SponsorsDropdown extends Component {
  state = {
    isBusy: false,
    sponsors: [],
    selectedSponsors: [],
    currentPage: 0,
    totalPages: 0,
  }

  isDropdownValueChanged = false

  /**
   * スポンサー名検索クエリ
   */
  sponsorSearchQuery = ''

  componentDidMount() {
    const { multiple, sponsorId, sponsorIds = [] } = this.props
    const selectedSponsorIds = multiple ? sponsorIds : [sponsorId]

    this.retrieveSelectedSponsors(selectedSponsorIds).then(() => {
      this.retrieveSponsors()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { multiple, sponsorId, sponsorIds = [] } = this.props
    const { multiple: nextMultiple, sponsorId: nextSponsorId, sponsorIds: nextSponsorIds = [] } = nextProps
    const selectedSponsorIds = multiple ? sponsorIds : [sponsorId]
    const nextSelectedSponsorIds = nextMultiple ? nextSponsorIds : [nextSponsorId]
    const newState = this.isDropdownValueChange
      ? {}
      : {
          sponsors: [],
          currentPage: 0,
          totalPages: 0,
        }

    // ドロップダウンで値を更新したとき以外はリロード
    if (!_.isEqual(selectedSponsorIds, nextSelectedSponsorIds)) {
      this.setState(newState, () => {
        this.retrieveSelectedSponsors(nextSelectedSponsorIds).then(() => {
          this.retrieveSponsors()
        })
      })
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getSponsors)) {
      getSponsors.cancel()
    }
    if (!_.isNil(getSelectedSponsors)) {
      getSelectedSponsors.cancel()
    }
  }

  /**
   * ドロップダウンにスポンサー名を入れて検索を行ったときのハンドラ
   */
  handleInfiniteDropdownSearch = searchQuery => {
    this.sponsorSearchQuery = searchQuery

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveSponsors()
      },
    )
  }

  /**
   * ドロップダウンの値を変更したときのハンドラ
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    if (this.props.onChange) {
      const name = this.props.multiple ? 'sponsorIds' : 'sponsorId'

      this.isDropdownValueChange = true
      this.props.onChange(event, { name, value })
    }
  }

  /**
   * スポンサー詳細の取得
   * @param {number} sponsorIds - スポンサーID一覧
   */
  retrieveSelectedSponsors = sponsorIds =>
    new Promise(resolve => {
      const selectedSponsorIds = _.filter(sponsorIds, sponsorId => !_.isNil(sponsorId))

      if (_.isEmpty(selectedSponsorIds)) {
        resolve()

        return
      }

      this.setState({ isBusy: true })
      getSelectedSponsors = CancelablePromise(sponsorApi.getSponsors(this.getSelectedRequestQuery(selectedSponsorIds)))
      getSelectedSponsors.promise
        .then(response => {
          const selectedSponsors = response.data
          const sponsors = this.state.sponsors

          sponsors.unshift(...selectedSponsors)
          this.setState({
            sponsors: _.uniqBy(sponsors, 'id'),
            selectedSponsors,
            isBusy: false,
          })
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          // スポンサー詳細が取得できなくても処理は続行させたいため resolve
          this.setState({ isBusy: false }, () => resolve())
        })
    })

  /**
   * スポンサー一覧の取得
   */
  retrieveSponsors = () => {
    this.setState({ isBusy: true })
    getSponsors = CancelablePromise(sponsorApi.getSponsors(this.getRequestQuery()))
    getSponsors.promise
      .then(response => {
        let sponsors = _.concat(this.state.sponsors, response.data)
        const { selectedSponsors } = this.state
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

        // id でソート
        sponsors = _.sortBy(sponsors, o => o.id * -1)
        // 選択中のスポンサーは先頭に表示
        sponsors.unshift(...selectedSponsors)
        this.setState({
          sponsors: _.uniqBy(sponsors, 'id'),
          totalPages,
          currentPage,
          isBusy: false,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get sponsors error', error)

        this.setState({ isBusy: false })
      })
  }

  getSelectedRequestQuery = sponsorIds => {
    // 絞り込み
    const filtering = []

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    const filterOperator = sponsorIds.length > 1 ? 'IN' : '='
    const filterValue = sponsorIds.join('__AND__')
    filtering.push(`id ${filterOperator} '${filterValue}'`)

    const query = {
      itemsPerPage: sponsorIds.length,
      currentPage: 1,
      filtering,
      sorting: ['-id'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { currentPage } = this.state

    // 絞り込み
    const filtering = []

    // スポンサー名検索文字列があった場合は、スポンサー名で絞り込むため filtering に追加
    if (!_.isEmpty(this.sponsorSearchQuery)) {
      filtering.push(`name LIKE '%${this.sponsorSearchQuery}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    const nextPage = currentPage + 1
    const query = {
      itemsPerPage: 10,
      filtering,
      currentPage: nextPage,
      sorting: ['-updated_at'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const { required, disabled, multiple, clearable, sponsorId, sponsorIds = [] } = this.props
    const options = _.map(this.state.sponsors, sponsor => ({
      text: sponsor.name,
      // eslint-disable-next-line no-nested-ternary
      description: this.props.viewSavingArticleMaximum
        ? _.get(sponsor, 'savingArticleMaximumViews.total') > 0
          ? `保証PV
        total:${_.get(sponsor, 'savingArticleMaximumViews.total', '0')}`
          : `保証PV
      app:${_.get(sponsor, 'savingArticleMaximumViews.app', '0')}
      web:${_.get(sponsor, 'savingArticleMaximumViews.web', '0')}`
        : '',
      value: sponsor.id,
    }))

    if (!multiple && !required) {
      // 必須項目ではない場合、解除項目を先頭に付与
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }

    let savingArticleMaximumText
    if (this.props.viewSavingArticleMaximum && this.props.sponsorId) {
      const selectedSponsor = _.find(options, { value: this.props.sponsorId })
      savingArticleMaximumText = _.get(selectedSponsor, 'description')
    }

    return (
      <div>
        <InfiniteDropdown
          placeholder="スポンサーを選択"
          noResultsMessage="該当するスポンサーが見つかりません"
          loading={this.state.isBusy}
          value={multiple ? sponsorIds : sponsorId}
          options={options}
          onLoadMore={this.retrieveSponsors}
          onSearch={this.handleInfiniteDropdownSearch}
          onChange={this.handleInfiniteDropdownChange}
          totalPages={this.state.totalPages}
          currentPage={this.state.currentPage}
          disabled={disabled}
          multiple={multiple}
          clearable={clearable}
        />
        {this.props.viewSavingArticleMaximum && <p>{savingArticleMaximumText}</p>}
      </div>
    )
  }
}

SponsorsDropdown.propTypes = propTypes
SponsorsDropdown.defaultProps = defaultProps

export default SponsorsDropdown
