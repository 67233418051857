import React from 'react'
import ReactDOM from 'react-dom'
import LogLevel from './LogLevel'
import App from './App'
import _ from 'lodash'
import './index.css'

// ロギングモジュールの読み込み（参考：https://github.com/pimterry/loglevel）
window.LogLevel = LogLevel

const buildEnv = _.defaultTo(process.env.REACT_APP_BUILD_ENV, 'local')
switch (buildEnv) {
  case 'development':
    document.title = 'DEV | TRILL Admin'
    break
  case 'staging':
    document.title = 'STG | TRILL Admin'
    break
  case 'production':
    document.title = 'PROD | TRILL Admin'
    break
  default:
    document.title = 'LOCL | TRILL Admin'
}

ReactDOM.render(<App />, document.getElementById('root'))
