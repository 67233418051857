import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { UserApi } from 'trill-api-admin-client'
import { Modal, Dimmer, Loader, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('UserEditModal')
const userApi = new UserApi()
let sendUser

const propTypes = {
  /**
   * 編集対象のユーザー
   */
  user: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  user: {},
  open: false,
}

class UserEditModal extends Component {
  state = {
    isBusy: false,
    isFormModified: false,
    isFormValid: false,
    apiError: null,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されているかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.user, nextProps.user)) {
      this.setState({ isFormModified: false })

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      const user = this.props.user

      if (user) {
        this.initializeFormValues(user)

        this.refs.form.reset(this.initialFormValues)
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendUser)) {
      sendUser.cancel()
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(currentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const userId = this.props.user.id
    const userUpdateValues = this.getRequestParameters(submitFormData)

    sendUser = CancelablePromise(userApi.patchUser(userId, { userUpdateValues }))
    sendUser.promise
      .then(response => {
        const user = response.data

        // 更新に成功したらユーザー一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.initializeFormValues(user)

        resetForm(this.initialFormValues)

        this.setState({
          isBusy: false,
          isFormModified: false,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('handle form valid submit patch error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * フォームの初期化
   */
  initializeFormValues(user) {
    logger.debug('initialize form values', user)

    this.initialFormValues.role = user.role
    this.initialFormValues.username = user.username
    // パスワードの初期値は API から返ってこないため、空文字を設定
    this.initialFormValues.password = ''
    _.set(this.initialFormValues, 'repeated_password', '')
  }

  /**
   * API 送信時のリクエストパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}

    initialData.role = _.get(initialFormValues, 'article.id')
    initialData.username = initialFormValues.username
    initialData.password = initialFormValues.password

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    // 確認用のパスワードの値は unset
    _.unset(requestParameters, 'repeated_password')

    logger.debug('get request parameters', { requestParameters })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  render() {
    return (
      <Modal
        className="UserEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>ユーザーの編集</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/* ユーザー名入力フィールド */}
            <Form.Input fluid name="username" label="ユーザー名" placeholder="ユーザー名を入力してください" />

            {/* パスワード入力フィールド */}
            <Form.Group widths="equal">
              <Form.Input
                type="password"
                fluid
                name="password"
                label="パスワード"
                placeholder="パスワードを入力してください"
              />

              {/* 確認用パスワード入力フィールド */}
              <Form.Input
                type="password"
                fluid
                name="repeated_password"
                label="パスワード確認入力"
                placeholder="パスワードを再度入力してください"
                validations="equalsField:password"
              />
            </Form.Group>

            {/* 権限選択フィールド */}
            <Form.Dropdown
              fluid
              name="role"
              label="権限"
              placeholder="権限を選択してください"
              selection
              options={[
                {
                  text: 'ユーザー',
                  value: 'user',
                },
                {
                  text: '閲覧者',
                  value: 'viewer',
                },
                {
                  text: 'アナリスト',
                  value: 'analyst',
                },
                {
                  text: '編集者',
                  value: 'editor',
                },
                {
                  text: 'オーナー',
                  value: 'owner',
                },
              ]}
              required
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content="更新"
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || !this.state.isFormModified}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

UserEditModal.propTypes = propTypes
UserEditModal.defaultProps = defaultProps

export default UserEditModal
