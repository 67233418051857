import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import aphroditeInterface from 'react-with-styles-interface-aphrodite'
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme'

ThemedStyleSheet.registerInterface(aphroditeInterface)
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    color: {
      ...DefaultTheme.reactDates.color,
      hoveredSpan: {
        ...DefaultTheme.reactDates.color.selectedSpan,
        backgroundColor: '#d2e6f5',
        backgroundColor_active: '#a6ceec',
        backgroundColor_hover: '#d2e6f5',
        borderColor: '#a6ceec',
        borderColor_active: '#a6ceec',
        borderColor_hover: '#a6ceec',
        color: '#1a6aa6',
        color_active: '#1a6aa6',
        color_hover: '#1a6aa6',
      },
      selectedSpan: {
        ...DefaultTheme.reactDates.color.selectedSpan,
        backgroundColor: '#79b5e2',
        backgroundColor_active: '#4d9dd9',
        backgroundColor_hover: '#4d9dd9',
        borderColor: '#4d9dd9',
        borderColor_active: '#2185d0',
        borderColor_hover: '#2185d0',
      },
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        backgroundColor: '#2185d0',
        backgroundColor_active: '#2185d0',
        backgroundColor_hover: '#2185d0',
        borderColor: '#2185d0',
        borderColor_active: '#2185d0',
        borderColor_hover: '#2185d0',
      },
    },
    font: {
      ...DefaultTheme.reactDates.font,
      input: {
        size: '1em',
        lineHeight: '1.2em',
      },
    },
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderBottomFocused: `2px solid #2185d0`,
      },
      pickerInput: {
        ...DefaultTheme.reactDates.border.pickerInput,
        borderWidth: 1,
      },
    },
  },
})

export default ThemedStyleSheet
