import React, { Component } from 'react'
import {
  Button,
  Header,
  Icon,
  Menu,
  Divider,
  Dimmer,
  Loader,
  Label,
  Modal,
  Dropdown,
  Statistic,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import { TagApi } from 'trill-api-admin-client'
import _ from 'lodash'
import { FormattedDate, FormattedTime } from 'react-intl'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import TagEditModal from '../../components/TagEditModal'
import TagRemoveModal from '../../components/TagRemoveModal'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('Tags')
const tagApi = new TagApi()
let getTags
let putTag
const getRelatedTags = []

/**
 * タグ一覧の状態
 * @enum {string}
 */
const TagStatus = {
  /** すべて */
  ALL: 'all',
  /** ゴミ箱  */
  TRASH: 'trash',
}

const excludeFilteringOptions = [
  { text: '解除', icon: 'remove', value: null },
  { text: '表示から除外のみ', value: true },
  { text: '表示のみ', value: false },
]

class Tags extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      tags: [],
      isTagEditModalOpen: false,
      isTagRemoveModalOpen: false,
      editTag: null,
      removeTag: null,
      // 表示中のタグステータス
      status: TagStatus.ALL,
      exclude: null,
      apiError: null,
      undoApiError: null,
      permission: GetPermission('tag'),
      isFormSearchValid: false,
    }

    _.each(TagStatus, status => {
      _.extend(this.state, {
        [status]: {
          currentPage: 1,
          itemsPerPage: 10,
          totalPages: 0,
          totalItems: 0,
          sorting: { id: 'desc' },
          filtering: { name: '' },
        },
      })
    })
  }

  componentDidMount() {
    this.retrieveTags()
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getTags)) {
      getTags.cancel()
    }
    if (!_.isNil(putTag)) {
      putTag.cancel()
    }
    if (!_.isNil(getRelatedTags)) {
      _.each(getRelatedTags, getRelatedTag => getRelatedTag.cancel())
      getRelatedTags.length = 0
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    this.setState({
      isTagEditModalOpen: true,
      editTag: {},
    })
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handleTagEditModalClose = () => {
    this.setState({
      isTagEditModalOpen: false,
      editTag: null,
    })
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleTagRemoveModalClose = () => {
    this.setState({
      isTagRemoveModalOpen: false,
      removeTag: null,
    })
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    // テーブルのデータ
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveTags()
    })
  }

  /**
   * データテーブルのヘッダーをタップして並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveTags()
    })
  }

  /**
   * タグのデータに変更があったときのハンドラ
   */
  handleTagDataChanged = () => {
    this.retrieveTags()
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveTags()
    })
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const tagId = this.state.undoTag.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putTag = CancelablePromise(tagApi.putTag(tagId))
    putTag.promise
      .then(() => {
        this.setState({
          isBusy: false,
          undoTag: null,
          isUndoModalOpen: false,
        })

        this.retrieveTags()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`put tag tagId #${tagId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoTag: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoTag: null,
    })
  }

  /**
   * タグ名で絞り込むインプットの値を変更したときのハンドラ
   */
  handleTagNameInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.name = data.value

    this.setState({ [status]: tableData })
  }

  handleExcludeDropdownChange = (event, data) => {
    this.setState({ exclude: data.value }, () => {
      this.retrieveTags()
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveTags()
  }

  /**
   * 作成・更新モーダルを表示する関数
   * @param {Object} tag - タグデータ
   */
  openTagEditModal(tag) {
    this.setState({
      isTagEditModalOpen: true,
      editTag: tag,
    })
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} tag - タグデータ
   */
  openTagRemoveModal(tag) {
    this.setState({
      isTagRemoveModalOpen: true,
      removeTag: tag,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   * @param {Object} tag - タグデータ
   */
  openUndoModal(tag) {
    this.setState({
      isUndoModalOpen: true,
      undoTag: tag,
    })
  }

  /**
   * タグ一覧取得
   */
  retrieveTags = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getTags = CancelablePromise(tagApi.getTags(this.getRequestQuery()))
    getTags.promise
      .then(response => {
        const responseHeader = response.header
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.currentPage = currentPage === 0 ? 1 : currentPage
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        logger.debug('get tags', { response })

        getRelatedTags.length = 0
        // 取得したタグ一覧から個々のタグに紐づくタグを取得する
        _.each(response.data, tag => {
          getRelatedTags.push(
            CancelablePromise(
              new Promise((resolve, reject) => {
                this.setTagRelatedTags(tag)
                  .then(() => {
                    resolve(tag)
                  })
                  .catch(error => {
                    if (error.isCanceled) {
                      reject()
                      return
                    }

                    logger.error(`set tag related tags tag id #${tag.id} error`, error)

                    // 一覧表示は行いたいので処理は続行
                    resolve(tag)
                  })
              }),
            ),
          )
        })

        return Promise.all(_.map(getRelatedTags, getRelatedTag => getRelatedTag.promise))
      })
      .then(tags => {
        this.setState({
          tags,
          isBusy: false,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.debug('retrieve tags get tags error', error)

        tableData.itemsPerPage = 10
        tableData.currentPage = 1
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          isBusy: false,
          tags: [],
          [status]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * タグに関連するタグをタグに設定
   */
  setTagRelatedTags = tag =>
    new Promise((resolve, reject) => {
      tagApi
        .getTagRelatedTags(tag.id)
        .then(response => {
          const relatedTags = response.data
          const responseHeader = response.header
          // 合計ページが 2 ページ以上あったら関連タグの項目には ... で表示を行う
          const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
          tag.relatedTagsTotalPages = totalPages
          tag.relatedTags = relatedTags

          logger.debug('retrieve related tags', {
            tag,
            relatedTags,
          })

          resolve(tag)
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { status, exclude } = this.state
    // 表示中のテーブルデータの state を取得
    const tableData = this.state[status]

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage

    // 1 ページあたりに含めるデータの数
    const itemsPerPage = tableData.itemsPerPage

    // フィルタリング
    const filtering = []
    if (_.isEqual(status, TagStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    if (!_.isEmpty(tableData.filtering.name)) {
      let filteringName = tableData.filtering.name
      if (filteringName.match(/\,/)) { // eslint-disable-line
        filteringName = ''
      }

      filtering.push(`name LIKE "%${filteringName}%"`)
    }
    if (!_.isNil(exclude)) {
      filtering.push(`exclude = ${exclude}`)
    }

    // ソートを配列に変換
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * 関連タグをレンダリング
   */
  renderRelatedTags = item => {
    const renderTag = tag => <Label key={tag.id} content={tag.name} size="small" style={{ margin: '2px' }} />

    return (
      <div>
        {_.map(item.relatedTags, relatedTag => renderTag(relatedTag))}

        {item.relatedTagsTotalPages > 1 && <span>...</span>}
      </div>
    )
  }

  formatedTime = item => (
    <span>
      <FormattedDate value={item.updatedAt} day="numeric" month="long" year="numeric" weekday="narrow" />
      <br />
      <FormattedTime value={item.updatedAt} hour="numeric" minute="numeric" second="numeric" />
    </span>
  )

  render() {
    const status = this.state.status
    const tableData = this.state[status]
    const { permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    return (
      <div className="Tags">
        <Header as="h1">
          <Icon name="tag" />

          <Header.Content>タグ</Header.Content>
        </Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {/* 公開・削除切り替えボタン */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={TagStatus.ALL}
            active={_.isEqual(status, TagStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={TagStatus.TRASH}
            active={_.isEqual(status, TagStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="tags-search"
                type="text"
                placeholder="タグ名で検索"
                action
                fluid
                value={tableData.filtering.name}
                onChange={this.handleTagNameInputChange}
                validations={{ matchRegexp: /^((?!,).)*$/i }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>

            <Form.Field width={4}>
              <Dropdown
                selection
                placeholder="表示から除外の検索条件を選択"
                onChange={this.handleExcludeDropdownChange}
                options={excludeFilteringOptions}
                disabled={!this.state.isFormSearchValid}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/* タグ一覧 */}
        {!_.isEmpty(this.state.tags) && (
          <DataTable
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSelectionChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            sort={tableData.sorting}
            items={this.state.tags}
            compact
            rowKey="id"
            cellStyle={{ position: 'relative' }} // ラベル表示のためセルにスタイルを指定
            columns={[
              {
                label: 'ID',
                field: 'id',
                render: item => <div>{item.id}</div>,
              },
              {
                label: 'タグ名',
                field: 'name',
                minWidth: '20em',
                collapsing: false,
                render: item => {
                  const isCosmeTag = _.get(item, 'brandInfo.type.cosme', false)
                  const isFashionTag = _.get(item, 'brandInfo.type.fashion', false)
                  const cosmeIcon = 'wizard'
                  const fashionIcon = 'shopping bag'
                  const name = item.name
                  return (
                    <div>
                      {/* コスメタグとファッションタグ両方の場合 */}
                      {isCosmeTag && isFashionTag && (
                        <Label corner>
                          <Icon name={cosmeIcon} />

                          <Icon name={fashionIcon} />
                        </Label>
                      )}

                      {/* コスメタグの場合 */}
                      {isCosmeTag && !isFashionTag && <Label corner icon={cosmeIcon} />}

                      {/* ファッションタグの場合 */}
                      {!isCosmeTag && isFashionTag && <Label corner icon={fashionIcon} />}

                      {/* タグ名 */}
                      {name}
                    </div>
                  )
                },
              },
              {
                label: '登録日時',
                render: item => !_.isEmpty(item.updatedAt) && this.formatedTime(item),
              },
              {
                label: '表示から除外',
                align: 'center',
                render: item => <div>{item.exclude ? <Icon name="check" /> : null}</div>,
              },
              {
                label: '関連タグ',
                collapsing: false,
                minWidth: '30em',
                render: item => this.renderRelatedTags(item),
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group key={item.key} secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.openTagEditModal(item)
                      }}
                    />

                    {!_.isEqual(status, TagStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openTagRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, TagStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* タグ作成・編集モーダル */}
        {hasUpdatePermission && (
          <TagEditModal
            tag={this.state.editTag}
            open={this.state.isTagEditModalOpen}
            onClose={this.handleTagEditModalClose}
            onSuccessDataChanged={this.handleTagDataChanged}
          />
        )}

        {/* タグ削除モーダル */}
        {hasDeletePermission && (
          <TagRemoveModal
            tag={this.state.removeTag}
            open={this.state.isTagRemoveModalOpen}
            onClose={this.handleTagRemoveModalClose}
            onSuccessDataChanged={this.handleTagDataChanged}
          />
        )}

        {/* 削除したタグ・グループを戻すときに表示する確認用モーダル */}
        {hasRestorePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isUndoModalOpen}
            onClose={this.handleUndoModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>タグの公開</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.undoApiError} />
              {_.get(this.state, 'undoTag.name', 'タグ')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.handleUndoModalPublishButtonClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default Tags
