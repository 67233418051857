import React from 'react'
import { Header, Divider, Label, Message } from 'semantic-ui-react'
import RecommendedTagsFormattedDateAndTime from './RecommendedTagsFormattedDateAndTime'

const RecommendedTags = ({ recommendedTags, updatedAt }) => {
  const listItems = recommendedTags.map((recommendedTag, index) => {
    return <Label key={index}>{recommendedTag.tag.name}</Label>
  })

  return (
    <div>
      {recommendedTags.length >= 1 ? (
        <div>
          <Header.Subheader>
            <RecommendedTagsFormattedDateAndTime updatedAt={updatedAt} />
          </Header.Subheader>
          <Divider hidden />
          <Label.Group tag>{listItems}</Label.Group>
        </div>
      ) : (
        <Message>
          <Message.Header>キーワードが未設定です</Message.Header>
          <p>下のキーワードを編集ボタンをクリックすると編集画面が開きます</p>
        </Message>
      )}
    </div>
  )
}

export default RecommendedTags
