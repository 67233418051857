import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Message, Dimmer, Loader } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { MediumApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('MediumItemRemoveModal')
const mediumApi = new MediumApi()
let removeMediumItem

const propTypes = {
  /**
   * 削除対象のメディアの設定
   */
  mediumItem: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,
}

const defaultProps = {
  open: false,
}

class MediumItemRemoveModal extends Component {
  state = {
    isBusy: false,
    apiError: null,
  }

  // eslint-disable-next-line
  componentWillUnmount() {
    if (!_.isNil(removeMediumItem)) {
      removeMediumItem.cancel()
    }
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効なときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = () => {
    const mediumItemId = this.props.mediumItem.id

    this.setState({
      isBusy: true,
      apiError: null,
    })

    if (!_.isNil(removeMediumItem)) {
      removeMediumItem.cancel()
    }
    removeMediumItem = CancelablePromise(mediumApi.deleteMediumItem(mediumItemId))
    removeMediumItem.promise
      .then(() => {
        this.setState({ isBusy: false }, () => {
          if (this.props.onClose) {
            this.props.onClose()
          }
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('delete medium error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 削除ボタンを押したときのハンドラ
   */
  handleRemoveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <Modal
        className="MediumItemRemoveModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{`${_.get(this.props.mediumItem, 'name', 'メディアの設定')} の削除`}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          {/* 警告メッセージ */}
          <Message
            negative
            content="確認のため、削除するメディア設定のメディア設定名を入力してください。削除するメディア設定の記事削除とメディア設定の削除が行われます。長い場合1時間以上削除にかかる場合もあります。連続で削除を実行しないでください。"
          />

          {/* 削除フォーム */}
          {this.props.mediumItem && (
            <Form
              ref="form"
              noValidate
              onChange={this.handleFormChange}
              onValid={this.handleFormValid}
              onInvalid={this.handleFormInvalid}
              onValidSubmit={this.handleFormValidSubmit}
            >
              {/* メディア名入力フィールド */}
              <Form.Input
                name="name"
                label="メディアの設定名"
                placeholder="削除するメディアの設定名を入力してください"
                validations={{
                  isSameMediumName: (values, value) => value === this.props.mediumItem.name,
                }}
                required
              />
            </Form>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button negative content="削除" onClick={this.handleRemoveButtonClick} disabled={!this.state.isFormValid} />
        </Modal.Actions>
      </Modal>
    )
  }
}

MediumItemRemoveModal.propTypes = propTypes
MediumItemRemoveModal.defaultProps = defaultProps

export default MediumItemRemoveModal
