import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { GameCategoryApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('GameCategoriesDropdown')
const gameCategoryApi = new GameCategoryApi()

let getGameCategoriesPromise

const propTypes = {
  /**
   * The name use for change callback
   */
  name: PropTypes.string,

  /**
   * Text for placeholder
   */
  placeholder: PropTypes.string,

  /**
   * Set true to exclude deleted game categories from the dropdown list
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * (single) Selected Game Category
   */
  selectedGameCategory: PropTypes.object,

  /**
   * Set true to allow resize to fit parent container
   */
  fluid: PropTypes.bool,

  /**
   * Selection change callback
   */
  onChange: PropTypes.func,

  /**
   * Set true to not allow select
   */
  disabled: PropTypes.bool,

  /**
   * Set true to NOT allow clear-selection option
   */
  required: PropTypes.bool,
}

const defaultProps = {
  ignoreTrashContents: true,
  selectedGameCategory: null,

  fluid: true,
  disabled: false,
  required: false,
  name: null,
}

class GameCategoriesDropdown extends Component {
  state = {
    isBusy: false,
    // already loaded game categories
    gameCategories: [],
    currentPage: 0,
    totalPages: 0,
  }

  isDropdownValueChanged = false

  searchQuery = ''

  /**
   * After FIRST rendering, retrieve data
   */
  componentDidMount() {
    this.retrieveGameCategories()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.selectedGameCategory, nextProps.selectedGameCategory)) {
      // if the change in props origins from this component, just query more
      if (this.isDropdownValueChanged) {
        this.retrieveGameCategories()
      } else {
        this.setState(
          {
            gameCategories: [],
            currentPage: 0,
            totalPages: 0,
          },
          () => {
            this.retrieveGameCategories()
          },
        )
      }
      this.searchQuery = ''
      this.isDropdownValueChanged = false
    }
  }

  /**
   * Cancel all API requests on removing Component
   */
  componentWillUnmount() {
    if (!_.isNil(getGameCategoriesPromise)) {
      getGameCategoriesPromise.cancel()
    }
  }

  /**
   * Request query for List API: Filter and Sort
   */
  getRequestQuery = () => {
    const { currentPage, gameCategories } = this.state

    const sorting = ['-id']

    const filtering = []

    if (!_.isEmpty(this.searchQuery)) {
      filtering.push(`name LIKE '%${this.searchQuery}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deletedAt IS NULL')
    }

    // Ignore already loaded (in previous calls) game categories
    _.each(gameCategories, gameCategory => filtering.push(`id <> '${gameCategory.id}'`))

    const queryParams = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
      sorting,
    }

    return _.omitBy(queryParams, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * Get more game categories
   */
  retrieveGameCategories = () => {
    this.setState({ isBusy: true })

    getGameCategoriesPromise = CancelablePromise(gameCategoryApi.getGameCategories(this.getRequestQuery()))
    getGameCategoriesPromise.promise
      .then(response => {
        let gameCategories = _.concat(this.state.gameCategories, response.data)
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

        // sort by id
        gameCategories = _.sortBy(gameCategories, o => o.id * -1)
        // move selected items to front
        if (!_.isEmpty(this.props.selectedGameCategory)) {
          gameCategories.unshift(this.props.selectedGameCategory)
        }

        this.setState({
          isBusy: false,
          gameCategories: _.uniqBy(gameCategories, 'id'),
          totalPages,
          currentPage,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get gameCategories error', error)

        this.setState({ isBusy: false })
      })
  }

  /**
   * Called when the dropdown search value is changed
   */
  handleSearchQueryChange = searchQuery => {
    this.searchQuery = searchQuery

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveGameCategories()
      },
    )
  }

  /**
   * Called when the dropdown selection is changed
   */
  handleSelectionChange = (event, { value }) => {
    if (this.props.onChange) {
      const selected = _.find(this.state.gameCategories, ['id', value])

      this.isDropdownValueChanged = true
      this.props.onChange(event, { name: this.props.name, value: selected })
    }
  }

  render() {
    const options = _.map(this.state.gameCategories, gameCategory => ({
      text: gameCategory.name,
      value: gameCategory.id,
      icon: 'folder open',
    }))

    if (!this.props.required) {
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }

    const gameCategoryId = _.get(this.props.selectedGameCategory, 'id', undefined)

    return (
      <InfiniteDropdown
        placeholder={this.props.placeholder}
        noResultsMessage="該当するゲームカテゴリが見つかりません"
        options={options}
        value={gameCategoryId}
        loading={this.state.isBusy}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
        fluid={this.props.fluid}
        disabled={this.props.disabled}
        clearable={!this.props.required}
        onLoadMore={this.retrieveGameCategories}
        onSearch={this.handleSearchQueryChange}
        onChange={this.handleSelectionChange}
      />
    )
  }
}

GameCategoriesDropdown.propTypes = propTypes
GameCategoriesDropdown.defaultProps = defaultProps

export default GameCategoriesDropdown
