import React, { Component } from 'react'
import { Form } from 'formsy-semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormErrorLabel from './FormErrorLabel'

const GAME_CATEGORY_NAME_LIMIT = 255

const propTypes = {
  /**
   * Game Category for updating (or null for creating)
   */
  gameCategory: PropTypes.object,
  /**
   * Set true to submit form from outside
   */
  isSubmitForm: PropTypes.bool,
  /**
   * Called when the form is changed
   */
  onFormChange: PropTypes.func,
  /**
   * Called when the form is valid
   */
  onFormValid: PropTypes.func,
  /**
   * Called when the form is invalid
   */
  onFormInvalid: PropTypes.func,
  /**
   * Called when the form is submitted
   */
  onFormValidSubmit: PropTypes.func,
}

const defaultPropTypes = {
  gameCategory: {},
}

class GameCategoryForm extends Component {
  state = {}

  initialFormValues = {}

  isFormResetted = false

  // Before FIRST rendering, populate GameCategory to this.initialFormValue.
  UNSAFE_componentWillMount() {
    this.setState({
      isFormModified: false,
    })
    this.initializeFormValues(this.props.gameCategory)
    this.isFormResetted = false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.isSubmitForm, this.props.isSubmitForm)) {
      if (this.refs.form && nextProps.isSubmitForm) {
        this.refs.form.submit()
      }
    }
  }

  // Populate this.initialFormValue to Form
  componentDidUpdate() {
    if (this.refs.form && !this.isFormResetted) {
      this.refs.form.reset(this.initialFormValues)
      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // To Do
  }

  //
  initializeFormValues(gameCategory) {
    if (_.isEmpty(gameCategory)) {
      this.initialFormValues = {}
      return
    }

    this.initialFormValues.name = gameCategory.name
  }

  handleFormChange = currentValues => {
    if (this.props.onFormChange) {
      const isModified = !_.isEqual(this.initialFormValues, currentValues)

      this.props.onFormChange(currentValues, isModified)
    }
  }

  handleFormValid = () => {
    if (this.props.onFormValid) {
      this.props.onFormValid()
    }
  }

  handleFormInvalid = () => {
    if (this.props.onFormInvalid) {
      this.props.onFormInvalid()
    }
  }

  handleFormValidSubmit = submitFormData => {
    if (this.props.onFormValidSubmit) {
      this.props.onFormValidSubmit(submitFormData)
    }
  }

  render() {
    return (
      <Form
        ref="form"
        noValidate
        onChange={this.handleFormChange}
        onValid={this.handleFormValid}
        onInvalid={this.handleFormInvalid}
        onValidSubmit={this.handleFormValidSubmit}
      >
        <Form.Input
          name="name"
          type="text"
          label="ゲームカテゴリ名"
          placeholder="ゲームカテゴリ力してください"
          action
          required
          validations={{ maxLength: GAME_CATEGORY_NAME_LIMIT }}
          validationErrors={{ maxLength: `${GAME_CATEGORY_NAME_LIMIT}文字以内で入力してください。` }}
          errorLabel={<FormErrorLabel />}
        />
      </Form>
    )
  }
}

GameCategoryForm.propTypes = propTypes
GameCategoryForm.defaultPropTypes = defaultPropTypes

export default GameCategoryForm
