import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MediumApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('MediumItemsDropdown')
const mediumApi = new MediumApi()
let getMediumItems
let getMediumItem

const propTypes = {
  /**
   * メディアの設定のメディア ID
   */
  mediumId: PropTypes.number,
  /**
   * メディアの設定 ID
   */
  mediumItemId: PropTypes.number,

  /**
   * 必須項目 (解除項目を追加するかどうか)
   */
  required: PropTypes.bool,

  /**
   * 幅を最大にするかどうか
   */
  fluid: PropTypes.bool,

  /**
   * ゴミ箱にあるコンテンツを表示させたくない場合
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * メディアの設定項目を変更したときに呼び出す外部関数
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  mediumId: null,
  mediumItemId: null,
  required: false,
  fluid: true,
  ignoreTrashContents: true,
}

/**
 * メディア選択用のコンポーネント
 */
class MediumItemsDropdown extends Component {
  state = {
    isBusy: false,
    mediumItems: [],
    currentPage: 0,
    totalPages: 0,
  }

  /**
   * ドロップダウンの値を変更したかどうか
   */
  isDropdownValueChanged = false

  /**
   * メディアの設定名検索クエリ
   */
  mediumItemSearchQuery = ''

  componentDidMount() {
    const { mediumId, mediumItemId } = this.props

    this.retrieveMediumItem(mediumItemId).then(() => {
      this.retrieveMediumItems(mediumId)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { mediumId, mediumItemId } = this.props

    const nextmediumId = nextProps.mediumId
    const nextmediumItemId = nextProps.mediumItemId

    if (!_.isEqual(mediumItemId, nextmediumItemId) && !this.isDropdownValueChanged) {
      // メディアの設定のドロップダウンで値を更新したとき
      this.setState(
        {
          mediumItems: [],
          currentPage: 0,
          totalPages: 0,
        },
        () => {
          this.retrieveMediumItem(nextmediumItemId).then(() => {
            this.retrieveMediumItems(nextmediumId)
          })
        },
      )
    } else if (!_.isEqual(mediumItemId, nextmediumItemId)) {
      this.retrieveMediumItem(nextmediumItemId)
    } else if (!_.isNil(nextmediumId) && !_.isEqual(mediumId, nextmediumId)) {
      // メディアのドロップダウンで値を更新したとき
      this.setState(
        {
          mediumItems: [],
          currentPage: 0,
          totalPages: 0,
        },
        () => {
          this.retrieveMediumItems(nextmediumId)
        },
      )
    }

    this.mediumItemSearchQuery = ''
    this.isDropdownValueChanged = false
  }

  // eslint-disable-next-line
  componentWillUnmount() {
    if (!_.isNil(getMediumItems)) {
      getMediumItems.cancel()
    }
    if (!_.isNil(getMediumItem)) {
      getMediumItem.cancel()
    }
  }

  /**
   * ドロップダウンにメディアの設定名を入れて検索を行ったときのハンドラ
   */
  handleInfiniteDropdownSearch = searchQuery => {
    this.mediumItemSearchQuery = searchQuery

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveMediumItems(this.props.mediumId)
      },
    )
  }

  /**
   * ドロップダウンの値を変更したときのハンドラ
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    if (this.props.onChange) {
      this.isDropdownValueChanged = true

      this.props.onChange(event, { name: 'mediumItemId', value })
    }
  }

  /**
   * メディアの設定詳細の取得
   */
  retrieveMediumItem = mediumItemId =>
    new Promise(resolve => {
      if (_.isNil(mediumItemId)) {
        resolve()

        return
      }

      this.setState({ isBusy: true })

      getMediumItem = CancelablePromise(mediumApi.getMediumItem(mediumItemId))
      getMediumItem.promise
        .then(response => {
          const mediumItem = response.data
          const { mediumItems } = this.state
          mediumItems.unshift(mediumItem)

          logger.debug(`get medium item id #${mediumItemId}`, { mediumItem, mediumItems })

          this.setState(
            {
              mediumItems: _.uniqBy(mediumItems, 'id'),
              isBusy: false,
            },
            () => resolve(),
          )
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          logger.error(`get medium item id #${mediumItemId} error`, error)

          this.setState({ isBusy: false })

          // メディアの設定詳細が取得できなくても処理は続行させたいため resolve
          resolve()
        })
    })

  /**
   * メディアの設定一覧の取得
   */
  retrieveMediumItems = mediumId => {
    if (_.isNil(mediumId)) {
      return
    }
    this.setState({ isBusy: true })

    getMediumItems = CancelablePromise(mediumApi.getMediumMediumItems(mediumId, this.getRequestQuery()))
    getMediumItems.promise
      .then(response => {
        if (response.data.length > 0) {
          let mediumItems = _.concat(this.state.mediumItems, response.data)
          const responseHeader = response.header
          const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
          const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

          // 選択中のメディアの設定
          const selectedMedium = _.head(mediumItems)
          // id でソート
          mediumItems = _.sortBy(mediumItems, o => o.id * -1)
          // 選択中のメディアの設定は先頭に表示
          mediumItems.unshift(selectedMedium)

          this.setState({
            isBusy: false,
            mediumItems: _.uniqBy(mediumItems, 'id'),
            totalPages,
            currentPage,
          })
        } else {
          this.setState({ isBusy: false })
        }
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get medium items error', error)

        this.setState({ isBusy: false })
      })
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { currentPage, mediumItems } = this.state

    // 絞り込み
    const filtering = []

    // メディア名検索文字列があった場合は、メディア名で絞り込むため filtering に追加
    if (!_.isEmpty(this.mediumItemSearchQuery)) {
      filtering.push(`name LIKE '%${this.mediumItemSearchQuery}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    // 取得済みのメディア一覧は除外して一覧を取得: 取得済みのタグを再取得しないため
    _.each(mediumItems, mediumItem => filtering.push(`id <> '${mediumItem.id}'`))

    const query = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
      sorting: ['-id'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const options = _.map(this.state.mediumItems, mediumItem => ({
      text: mediumItem.name,
      value: mediumItem.id,
      icon: 'rss',
    }))

    if (!this.props.required) {
      // 必須項目ではない場合、解除項目を先頭に付与
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }

    return (
      <InfiniteDropdown
        placeholder="メディアの設定を選択してください"
        noResultsMessage="該当するメディアの設定が見つかりません"
        loading={this.state.isBusy}
        fluid={this.props.fluid}
        options={options}
        value={this.props.mediumItemId}
        onLoadMore={this.retrieveMediumItems}
        onSearch={this.handleInfiniteDropdownSearch}
        onChange={this.handleInfiniteDropdownChange}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
      />
    )
  }
}

MediumItemsDropdown.propTypes = propTypes
MediumItemsDropdown.defaultProps = defaultProps

export default MediumItemsDropdown
