import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Checkbox, Form, Dimmer, Loader } from 'semantic-ui-react'

import ApiErrorMessage from './ApiErrorMessage'

import _ from 'lodash'

const propTypes = {
  /**
   * トリガーとなる render
   */
  trigger: PropTypes.node,

  /**
   * 入力された記事 IDで実行する外部関数
   */
  mergeTagsByArticleId: PropTypes.func,
}

class TagsCopyModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isBusy: false,
      apiError: null,
      articleId: '',
      isCopyTitle: true,
      isCopyDescription: true,
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  handleInputChange = event => {
    const obj = {}
    const key = event.target.name
    const value = event.target.value
    obj[key] = value
    this.setState(obj)
  }

  handleCheckboxTitleChange = (event, { checked }) => {
    this.setState({ isCopyTitle: checked })
  }

  handleCheckboxDescriptionChange = (event, { checked }) => {
    this.setState({ isCopyDescription: checked })
  }

  handleExecButtonClick = async () => {
    const { articleId, isCopyTitle, isCopyDescription } = this.state
    if (_.isEmpty(articleId) || !this.props.mergeTagsByArticleId) {
      this.closeModal()
    } else {
      this.setState({
        isBusy: true,
        apiError: null,
      })
      try {
        await this.props.mergeTagsByArticleId(articleId, isCopyTitle, isCopyDescription)
        this.setState(
          {
            isBusy: false,
          },
          () => this.closeModal(),
        )
      } catch (errors) {
        this.setState({
          isBusy: false,
          apiError: errors,
        })
      }
    }
  }

  render() {
    const { open, isBusy, apiError, articleId, isCopyTitle, isCopyDescription } = this.state
    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={this.props.trigger}
      >
        <Modal.Header>他の記事からタグをコピー</Modal.Header>
        <Modal.Content>
          <ApiErrorMessage error={apiError} />
          <Dimmer active={isBusy} inverted>
            <Loader>読み込み中</Loader>
          </Dimmer>
          <Form>
            <Form.Input
              name="articleId"
              label="記事ID"
              placeholder="記事IDを入力"
              type="number"
              onChange={this.handleInputChange}
              value={articleId}
            />
            <Checkbox label="タイトル" checked={isCopyTitle} onChange={this.handleCheckboxTitleChange} />
            &#xA0;
            <Checkbox label="本文" checked={isCopyDescription} onChange={this.handleCheckboxDescriptionChange} />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />

          <Button
            positive
            content="コピー"
            onClick={this.handleExecButtonClick}
            disabled={_.isEmpty(articleId) || (!isCopyTitle && !isCopyDescription)}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

TagsCopyModal.propTypes = propTypes

export default TagsCopyModal
