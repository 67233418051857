import React, { Component } from 'react'
import {
  Header,
  Icon,
  Dimmer,
  Loader,
  Menu,
  Button,
  Divider,
  Image,
  Modal,
  Message,
  Statistic,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import { FeedApi } from 'trill-api-admin-client'
import _ from 'lodash'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import FeedEditModal from '../../components/FeedEditModal'
import FeedRemoveModal from '../../components/FeedRemoveModal'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('Feeds')
const feedApi = new FeedApi()
let getFeeds

/**
 * フィード一覧表示の状態
 */
const FeedStatus = {
  ALL: 'all',
  TRASH: 'trash',
}

class Feeds extends Component {
  constructor(props) {
    super(props)

    this.state = {
      feeds: [],
      tags: {},
      isBusy: false,
      isFeedEditModalOpen: false,
      isFeedRemoveModalOpen: false,
      editFeed: null,
      removeFeed: null,
      status: FeedStatus.ALL,
      isUndoModalOpen: false,
      undoFeed: null,
      apiError: null,
      undoApiError: null,
      maxOrder: 0,
      isUndoFormValid: false,
      undoFeedOrder: 0,
      permission: GetPermission('feed'),
      isFormSearchValid: false,
    }

    _.each(FeedStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 50,
          sorting: { order: 'asc' },
          filtering: { name: '' },
        },
      })
    })
  }

  componentDidMount() {
    this.retrieveFeed()
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getFeeds)) {
      getFeeds.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    this.setState({
      isFeedEditModalOpen: true,
      editFeed: {},
    })
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handleFeedEditModalClose = () => {
    this.setState({
      isFeedEditModalOpen: false,
      editFeed: null,
    })
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleFeedRemoveModalClose = () => {
    this.setState({
      isFeedRemoveModalOpen: false,
      removeFeed: null,
    })
  }

  /**
   * フィードのデータに変更があったときのハンドラ
   */
  handleFeedDataChange = () => {
    // フィードに変更があった場合は、変更対象のフィード一覧をリセット (変更したいときは再度取得)
    this.setState({ changeOrderFeeds: [] })

    this.retrieveFeed()
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveFeed()
    })
  }

  /**
   * データテーブルのヘッダーをタップして並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveFeed()
    })
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveFeed()
    })
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const feedId = this.state.undoFeed.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    const updateUndoFeedPromise = () =>
      new Promise((resolve, reject) => {
        const updateFeedOrder = _.parseInt(this.state.undoFeedOrder)

        if (this.state.undoFeed.order === updateFeedOrder) {
          // 元々の feed の値が設定した feed の表示順と同じなら更新する必要はない
          resolve()
          return
        }

        const feedUpdateValues = { order: updateFeedOrder }
        // 復元しようしている feed の表示順が表示中の feed 数よりも大きい場合
        feedApi
          .patchFeed(feedId, { feedUpdateValues })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })

    feedApi
      .putFeed(feedId)
      .then(() => updateUndoFeedPromise())
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoFeed: null,
        })

        this.retrieveFeed()
      })
      .catch(error => {
        logger.error(`put feed feedId #${feedId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoFeed: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoFeed: null,
    })
  }

  /**
   * Undo モーダル画面のフォームの値が妥当なときに呼び出されるハンドラ
   */
  handleUndoFormValid = () => {
    this.setState({ isUndoFormValid: true })
  }

  /**
   * Undo モーダル画面のフォームの値が無効のときに呼び出されるハンドラ
   */
  handleUndoFormInvalid = () => {
    this.setState({ isUndoFormValid: false })
  }

  /**
   * Undo モーダル画面の表示順の値を変更したときに呼び出されるハンドラ
   */
  handleUndoOrderInputChange = (event, { value }) => {
    this.setState({
      undoFeedOrder: value,
    })
  }

  /**
   * フィード名で絞り込むインプットの値を変更したときのハンドラ
   */
  handleFeedNameInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.name = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveFeed()
  }

  /**
   * Undo モーダル画面を表示する関数
   */
  openUndoModal(feed) {
    this.setState({
      isUndoModalOpen: true,
      undoFeed: feed,
      undoFeedOrder: 0,
      isUndoFormValid: false,
    })
  }

  /**
   * 作成・更新モーダルを表示する関数
   * @param {Object} feed - フィードデータ
   */
  openFeedEditModal(feed) {
    this.setState({
      isFeedEditModalOpen: true,
      editFeed: feed,
    })
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} feed - フィードデータ
   */
  openFeedRemoveModal(feed) {
    this.setState({
      isFeedRemoveModalOpen: true,
      removeFeed: feed,
    })
  }

  /**
   * フィード一覧取得
   */
  retrieveFeed = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getFeeds = CancelablePromise(feedApi.getFeeds(this.getRequestQuery()))
    getFeeds.promise
      .then(response => {
        logger.debug('get feeds', { response })

        const feeds = response.data
        const responseHeader = response.header
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)

        const partialState = {
          isBusy: false,
          feeds,
          [status]: tableData,
        }

        if (_.isEqual(status, FeedStatus.ALL)) {
          partialState.maxOrder = tableData.totalItems
        }

        this.setState(partialState)
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get feeds error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totaPage = 0
        tableData.totalItems = 0

        this.setState({
          isBusy: false,
          feeds: [],
          [status]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * フィードを全件取得 (order を一度に更新するために使用)
   */
  getAllFeed = () =>
    new Promise((resolve, reject) => {
      const header = {
        currentPage: 1,
        itemsPerPage: 10,
        filtering: ['deleted_at IS NULL'],
        sorting: ['order'],
      }

      // 記事本文に関連するタグの配列
      const feeds = []

      feedApi
        .getFeeds(header)
        .then(response => {
          // 全ページ分の通信を入れる Promise
          const feedsPromises = []
          feeds.push(response.data)

          // 合計ページ数
          const totalPages = _.get(response, 'header.pagination-totalpages', 0)

          // 現在ページが合計ページ未満の場合はループ
          while (header.currentPage < totalPages) {
            // 次のページ取得のため +1 する
            header.currentPage += 1
            feedsPromises.push(feedApi.getFeeds(header))
          }

          return Promise.all(feedsPromises)
        })
        .then(responses => {
          _.each(responses, response => feeds.push(response.data))

          // 多重配列を戻してから resolve
          resolve(_.flatten(feeds))
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const status = this.state.status
    const tableData = this.state[status]

    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    // ソート順
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    // フィルタリング
    const filtering = []
    if (_.isEqual(status, FeedStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    if (!_.isEmpty(tableData.filtering.name)) {
      let filteringName = tableData.filtering.name
      if (filteringName.match(/\,/)) { // eslint-disable-line
        filteringName = ''
      }

      filtering.push(`name LIKE "%${filteringName}%"`)
    }

    const query = {
      filtering,
      sorting,
      currentPage,
      itemsPerPage,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const status = this.state.status
    const tableData = this.state[status]
    const { permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    return (
      <div className="Feeds">
        <Header as="h1">
          <Icon name="newspaper" />

          <Header.Content>フィード</Header.Content>
        </Header>

        {/** ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={FeedStatus.ALL}
            active={_.isEqual(status, FeedStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={FeedStatus.TRASH}
            active={_.isEqual(status, FeedStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        {/** 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Field width={8}>
            <Input
              name="feeds-search"
              type="text"
              placeholder="フィード名で検索"
              action
              value={tableData.filtering.name}
              onChange={this.handleFeedNameInputChange}
              validations={{ matchRegexp: /^((?!,).)*$/i }}
              validationErrors={{
                matchRegexp: 'キーワードに不正な記号があるため検索できません',
              }}
              errorLabel={<FormErrorLabel />}
            >
              <input />

              <Button
                icon="search"
                onClick={this.handleSearchButtonClick}
                color="blue"
                disabled={!this.state.isFormSearchValid}
              />
            </Input>
          </Form.Field>
        </Form>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/** Feed 一覧 */}
        {!_.isEmpty(this.state.feeds) && (
          <DataTable
            rowKey="id"
            items={this.state.feeds}
            sort={tableData.sorting}
            currentPage={tableData.currentPage}
            itemsPerPage={tableData.itemsPerPage}
            totalPages={tableData.totalPages}
            onSelectionChange={this.handleDataTableSelectionChange}
            onPageChange={this.handleDataTablePageChange}
            columns={[
              {
                label: 'ID',
                align: 'right',
                field: 'id',
              },
              {
                label: 'アイコン画像',
                render: item => <Image size="tiny" centered src={item.icon} />,
              },
              {
                label: 'フィード名',
                field: 'name',
                minWidth: '20em',
                collapsing: false,
              },
              {
                label: '表示順',
                align: 'center',
                field: 'order',
              },
              {
                label: 'タイプ',
                field: 'type',
                render: item => {
                  switch (item.type) {
                    case 'top':
                      return <span>トップ</span>
                    case 'matome':
                      return <span>特集</span>
                    case 'personalityQuiz':
                      return <span>診断</span>
                    case 'custom':
                      return <span>カスタムフィード</span>
                    case 'category':
                      return <span>カテゴリフィード</span>
                    case 'ranking':
                      return <span>ランキング</span>
                    case 'coupon':
                      return <span>クーポン</span>
                    default:
                      return ''
                  }
                },
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group key={item.key} secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.openFeedEditModal(item)
                      }}
                    />

                    {!_.isEqual(status, FeedStatus.TRASH) && !_.isEqual(item.type, 'top') && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openFeedRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, FeedStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/** フィード作成・編集モーダル */}
        <FeedEditModal
          maxOrder={_.isEmpty(this.state.editFeed) ? this.state.maxOrder + 1 : this.state.maxOrder}
          feed={this.state.editFeed}
          open={this.state.isFeedEditModalOpen}
          onClose={this.handleFeedEditModalClose}
          onSuccessDataChange={this.handleFeedDataChange}
        />

        {/** フィード削除モーダル */}
        <FeedRemoveModal
          feed={this.state.removeFeed}
          open={this.state.isFeedRemoveModalOpen}
          onClose={this.handleFeedRemoveModalClose}
          onSuccessDataChange={this.handleFeedDataChange}
        />

        {/* 削除したフィードを戻すときに表示する確認用モーダル */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isUndoModalOpen}
          onClose={this.handleUndoModalClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{_.get(this.state, 'undoFeed.name')}フィードの公開</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.isBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* エラーメッセージ */}
            <ApiErrorMessage error={this.state.undoApiError} />

            {/** 警告メッセージ */}
            <Message info content="公開時のフィードの表示順を入力してください。" />

            {/* 表示順を何番で戻すか選ぶフィールド */}
            <Form noValidate onValid={this.handleUndoFormValid} onInvalid={this.handleUndoFormInvalid}>
              <Form.Input
                label="表示順"
                placeholder="表示順を入力してください"
                name="order"
                fluid
                value={this.state.undoFeedOrder}
                onChange={this.handleUndoOrderInputChange}
                required
                validations={{
                  isOverTwoNumber: (values, value) => (!_.isEmpty(value) && value > 1) || _.isEmpty(value),
                  isValidMaxOrder: (values, value) =>
                    (this.state.maxOrder !== 0 && this.state.maxOrder + 1 >= value) || this.state.maxOrder === 0,
                }}
                validationErrors={{
                  isOverTwoNumber: '2 以上で入力してください。',
                  isValidMaxOrder: `${this.state.maxOrder + 1} 以下で入力してください。`,
                }}
                errorLabel={<FormErrorLabel />}
              />
            </Form>
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

            <Button
              positive
              content="戻す"
              onClick={this.handleUndoModalPublishButtonClick}
              disabled={!this.state.isUndoFormValid}
            />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default Feeds
