import React, { Component } from 'react'
import { FormattedDate } from 'react-intl'
import moment from 'moment'
import { Button, Header, Icon, Menu, Divider, Dimmer, Loader, Modal, Statistic } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import { NotificationApi } from 'trill-api-admin-client'
import _ from 'lodash'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import NotificationEditModal from '../../components/NotificationEditModal'
import NotificationRemoveModal from '../../components/NotificationRemoveModal'
import PublishDateRangePicker from '../../components/PublishDatetimeRangePicker'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('Notifications')
const notificationApi = new NotificationApi()
let getNotifications
let putNotification

/**
 * お知らせの状態
 */
const NotificationStatus = {
  ALL: 'all',
  TRASH: 'trash',
}

class Notifications extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      notifications: [],
      isNotificationEditModalOpen: false,
      isNotificationRemoveModalOpen: false,
      editNotification: null,
      removeNotification: null,
      status: NotificationStatus.ALL,
      isUndoModalOpen: false,
      undoNotification: null,
      apiError: null,
      undoApiError: null,
      permission: GetPermission('notification'),
      isFormSearchValid: false,
    }

    _.each(NotificationStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 50,
          sorting: { publishDatetime: 'desc' },
          filtering: {
            title: '',
            dateRangeStartAt: moment().startOf('month'),
            dateRangeEndAt: moment().endOf('month'),
            clearDateRange: true,
          },
        },
      })
    })
  }

  componentDidMount() {
    this.retriveNotifications()
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getNotifications)) {
      getNotifications.cancel()
    }
    if (!_.isNil(putNotification)) {
      putNotification.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    this.setState({
      isNotificationEditModalOpen: true,
      editNotification: {},
    })
  }

  /**
   * お知らせ更新・作成モーダルを閉じたときのハンドラ
   */
  handleNotificationEditModalClose = () => {
    this.setState({
      isNotificationEditModalOpen: false,
      editNotification: null,
    })
  }

  /**
   * お知らせ削除モーダルを閉じたときのハンドラ
   */
  handleNotificationRemoveModalClose = () => {
    this.setState({
      isNotificationRemoveModalOpen: false,
      removeNotification: null,
    })
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]

    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState(
      {
        [status]: tableData,
      },
      () => {
        this.retriveNotifications()
      },
    )
  }

  /**
   * テーブルの並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState(
      {
        [status]: tableData,
      },
      () => {
        this.retriveNotifications()
      },
    )
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retriveNotifications()
    })
  }

  /**
   * お知らせのデータに変更があったときのハンドラ
   */
  handleNotificationDataChanged = () => {
    this.retriveNotifications()
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const notificationId = this.state.undoNotification.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putNotification = CancelablePromise(notificationApi.putNotification(notificationId))
    putNotification.promise
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoNotification: null,
        })

        this.retriveNotifications()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`put notification notificationId #${notificationId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoNotification: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoNotification: null,
    })
  }

  /**
   * 公開日時のイベントハンドラ
   */
  handleDateRangePickerEvent = (event, picker) => {
    if (event.type === 'apply' || event.type === 'cancel') {
      let startDate
      let endDate
      let clearDateRange

      if (event.type === 'apply') {
        startDate = picker.startDate
        endDate = picker.endDate
        clearDateRange = false
      } else if (event.type === 'cancel') {
        startDate = moment().startOf('day')
        endDate = moment().endOf('day')
        clearDateRange = true
      }

      const status = this.state.status
      const tableData = this.state[status]
      tableData.filtering.dateRangeStartAt = startDate
      tableData.filtering.dateRangeEndAt = endDate
      tableData.filtering.clearDateRange = clearDateRange

      this.setState(
        {
          [status]: tableData,
        },
        () => {
          this.retriveNotifications()
        },
      )
    }
  }

  /**
   * タイトルで絞り込むインプットの値変更したときのハンドラ
   */
  handleTitleInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.title = data.value

    this.setState({
      [status]: tableData,
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retriveNotifications()
  }

  /**
   * お知らせ作成・編集モーダルを表示する関数
   * @param {Object} notification - お知らせデータ
   */
  openNotificationEditModal(notification) {
    this.setState({
      isNotificationEditModalOpen: true,
      editNotification: notification,
    })
  }

  /**
   * お知らせ削除モーダルを表示する関数
   * @param {Object} notification - お知らせデータ
   */
  openNotificationRemoveModal(notification) {
    this.setState({
      isNotificationRemoveModalOpen: true,
      removeNotification: notification,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   */
  openUndoModal(notidication) {
    this.setState({
      isUndoModalOpen: true,
      undoNotification: notidication,
    })
  }

  /**
   * お知らせ一覧を取得
   */
  retriveNotifications() {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getNotifications = CancelablePromise(notificationApi.getNotifications(this.getRequestQuery()))
    getNotifications.promise
      .then(response => {
        const notifications = response.data
        const responseHeader = response.header
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)

        this.setState({
          notifications,
          isBusy: false,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get notifications error', error)

        tableData.itemsPerPage = 50
        tableData.currentPage = 1
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          notifications: [],
          isBusy: false,
          [status]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * API 通信時のクエリを取得
   */
  getRequestQuery = () => {
    const status = this.state.status
    const tableData = this.state[status]
    const filtering = []

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    // 絞り込み
    if (!tableData.filtering.clearDateRange) {
      const dateRangeStartAt = tableData.filtering.dateRangeStartAt
      const dateRangeEndAt = tableData.filtering.dateRangeEndAt
      filtering.push(`publishDatetime >= '${dateRangeStartAt.toISOString()}'`)
      filtering.push(`publishDatetime <= '${dateRangeEndAt.toISOString()}'`)
    }

    if (_.isEqual(status, NotificationStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }
    // タイトルで絞り込み
    if (!_.isEmpty(tableData.filtering.title)) {
      let filteringTitle = tableData.filtering.title
      if (filteringTitle.match(/\,/)) { // eslint-disable-line
        filteringTitle = ''
      }

      filtering.push(`title LIKE "%${filteringTitle}%"`)
    }

    // ソートを配列に変換
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const status = this.state.status
    const tableData = this.state[status]
    const { permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    return (
      <div className="Notifications">
        <Header as="h1">
          <Icon name="announcement" />

          <Header.Content>お知らせ一覧</Header.Content>
        </Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {/* すべて・ゴミ箱切り替えメニュー */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={NotificationStatus.ALL}
            active={_.isEqual(status, NotificationStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={NotificationStatus.TRASH}
            active={_.isEqual(status, NotificationStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            {/* タイトル入力フィールド */}
            <Form.Field width={8}>
              <Input
                name="notifications-search"
                type="text"
                placeholder="タイトルで検索"
                action
                fluid
                value={tableData.filtering.title}
                onChange={this.handleTitleInputChange}
                validations={{ matchRegexp: /^((?!,).)*$/i }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>
            <Form.Field width={4}>
              <PublishDateRangePicker
                startDate={tableData.filtering.dateRangeStartAt}
                endDate={tableData.filtering.dateRangeEndAt}
                onEvent={this.handleDateRangePickerEvent}
                clear={tableData.filtering.clearDateRange}
                disabled={!this.state.isFormSearchValid}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/* お知らせ一覧 */}
        {!_.isEmpty(this.state.notifications) && (
          <DataTable
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSelectionChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            items={this.state.notifications}
            sort={tableData.sorting}
            compact
            rowKey="id"
            columns={[
              {
                label: 'タイトル',
                field: 'title',
                minWidth: '20em',
                collapsing: false,
              },
              {
                label: '公開日時',
                field: 'publishDatetime',
                defaultSortOrder: 'desc',
                render: item => {
                  const timestamp = parseInt(moment(_.get(item, 'publishDatetime')).format('x'), 10)
                  return (
                    <span>
                      <FormattedDate value={timestamp} day="numeric" month="long" year="numeric" weekday="narrow" />
                      <br />
                      <FormattedDate value={timestamp} hour="numeric" minute="numeric" second="numeric" />
                    </span>
                  )
                },
              },
              {
                label: 'OS 種別',
                align: 'center',
                render: item => (
                  <div>
                    {_.get(item, 'target.ios') && <Icon name="apple" />}

                    {_.get(item, 'target.android') && <Icon name="android" />}

                    {_.get(item, 'target.web') && <Icon name="world" />}
                  </div>
                ),
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group key={item.id} secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.openNotificationEditModal(item)
                      }}
                    />

                    {!_.isEqual(status, NotificationStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openNotificationRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, NotificationStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* お知らせ作成・編集モーダル */}
        {hasUpdatePermission && (
          <NotificationEditModal
            notification={this.state.editNotification}
            open={this.state.isNotificationEditModalOpen}
            onClose={this.handleNotificationEditModalClose}
            onSuccessDataChanged={this.handleNotificationDataChanged}
          />
        )}

        {/* お知らせ削除モーダル */}
        {hasDeletePermission && (
          <NotificationRemoveModal
            notification={this.state.removeNotification}
            open={this.state.isNotificationRemoveModalOpen}
            onClose={this.handleNotificationRemoveModalClose}
            onSuccessDataChanged={this.handleNotificationDataChanged}
          />
        )}

        {/* 削除したお知らせを戻すときに表示する確認用モーダル */}
        {hasRestorePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isUndoModalOpen}
            onClose={this.handleUndoModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>お知らせの公開</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.undoApiError} />
              {_.get(this.state, 'undoNotification.title')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.handleUndoModalPublishButtonClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default Notifications
