import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CouponApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('CouponCompaniesDropdown')
const couponApi = new CouponApi()
let getCouponCompanies
let getCouponCompany

const propTypes = {
  /**
   * クーポン提供企業 ID
   */
  couponCompanyId: PropTypes.number,

  /**
   * 必須項目
   */
  required: PropTypes.bool,

  /**
   * 幅を最大にするかどうか
   */
  fluid: PropTypes.bool,

  /**
   * ゴミ箱にあるコンテンツを表示させたくない場合
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * クーポン提供企業項目を変更したときに呼び出す外部関数
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  couponCompanyId: null,
  required: false,
  fluid: true,
  ignoreTrashContents: true,
}

class CouponCompaniesDropdown extends Component {
  state = {
    isBusy: false,
    couponCompanies: [],
    currentPage: 0,
    totalPages: 0,
  }

  /**
   * ドロップダウンの値を変更したかどうか
   */
  isDropdownValueChanged = false

  /**
   * クーポン提供企業名検索クエリ
   */
  couponCompanySearchQuery = ''

  componentDidMount() {
    const couponCompanyId = this.props.couponCompanyId
    this.retrieveCouponCompany(couponCompanyId).then(() => {
      this.retrieveCouponCompanies()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const couponCompanyId = this.props.couponCompanyId
    const nextCouponCompanyId = nextProps.couponCompanyId

    if (!_.isEqual(couponCompanyId, nextCouponCompanyId) && !this.isDropdownValueChange) {
      // ドロップダウンで値を更新したとき以外はリロード
      this.setState(
        {
          couponCompanies: [],
          currentPage: 0,
          totalPages: 0,
        },
        () => {
          this.retrieveCouponCompany(nextCouponCompanyId).then(() => {
            this.retrieveCouponCompanies()
          })
        },
      )
    } else if (!_.isEqual(couponCompanyId, nextCouponCompanyId)) {
      this.retrieveCouponCompany(nextCouponCompanyId).then(() => {
        this.retrieveCouponCompanies()
      })
    }

    this.couponCompanySearchQuery = ''
    this.isDropdownValueChange = false
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getCouponCompanies)) {
      getCouponCompanies.cancel()
    }
    if (!_.isNil(getCouponCompany)) {
      getCouponCompany.cancel()
    }
  }

  /**
   * ドロップダウンにクーポン提供企業名を入れて検索を行ったときのハンドラ
   */
  handleInfiniteDropdownSearch = searchQuery => {
    this.couponCompanySearchQuery = searchQuery

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveCouponCompanies()
      },
    )
  }

  /**
   * ドロップダウンの値を変更したときのハンドラ
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    if (this.props.onChange) {
      this.isDropdownValueChange = true

      this.props.onChange(event, { value })
    }
  }

  /**
   * クーポン提供企業詳細の取得
   */
  retrieveCouponCompany = couponCompanyId =>
    new Promise(resolve => {
      if (_.isNil(couponCompanyId)) {
        resolve()

        return
      }

      this.setState({ isBusy: true })

      getCouponCompany = CancelablePromise(couponApi.getCouponCompany(couponCompanyId))
      getCouponCompany.promise
        .then(response => {
          const couponCompany = response.data
          const couponCompanies = this.state.couponCompanies
          couponCompanies.unshift(couponCompany)

          logger.debug(
            `get couponCompany couponCompanyId #${couponCompanyId}`,
            { couponCompany, couponCompanies },
            _.uniqBy(couponCompanies, 'id'),
          )

          this.setState(
            {
              couponCompanies: _.uniqBy(couponCompanies, 'id'),
              isBusy: false,
            },
            () => resolve(),
          )
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          logger.error(`get couponCompany couponCompanyId #${couponCompanyId} error`, error)

          this.setState({ isBusy: false })

          // クーポン提供企業詳細が取得できなくても処理は続行させたいため resolve
          resolve()
        })
    })

  /**
   * クーポン提供企業一覧の取得
   */
  retrieveCouponCompanies = () => {
    this.setState({ isBusy: true })

    getCouponCompanies = CancelablePromise(couponApi.getCouponCompanies(this.getRequestQuery()))
    getCouponCompanies.promise
      .then(response => {
        let couponCompanies = _.concat(this.state.couponCompanies, response.data)
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = 0

        // 選択中のクーポン提供企業
        const selectedCategory = _.head(couponCompanies)
        // id でソート
        couponCompanies = _.sortBy(couponCompanies, o => o.id * -1)
        // 選択中のクーポン提供企業は先頭に表示
        couponCompanies.unshift(selectedCategory)

        this.setState({
          isBusy: false,
          couponCompanies: _.uniqBy(couponCompanies, 'id'),
          totalPages,
          currentPage,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get couponCompanies error', error)

        this.setState({ isBusy: false })
      })
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { currentPage, couponCompanies } = this.state

    // 絞り込み
    const filtering = []

    // クーポン提供企業名検索文字列があった場合は、クーポン提供企業名で絞り込むため filtering に追加
    if (!_.isEmpty(this.couponCompanySearchQuery)) {
      filtering.push(`display_name LIKE '%${this.couponCompanySearchQuery}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    // 取得済みのクーポン提供企業一覧は除外して一覧を取得: 取得済みのクーポン提供企業を再取得しないため
    _.each(couponCompanies, couponCompany => filtering.push(`id <> '${couponCompany.id}'`))

    const query = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
      sorting: ['-id'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const options = _.map(this.state.couponCompanies, couponCompany => ({
      text: couponCompany.displayName,
      value: couponCompany.id,
      icon: 'folder open',
    }))

    if (!this.props.required) {
      // 必須項目ではない場合、解除項目を先頭に付与
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }

    return (
      <InfiniteDropdown
        fluid={this.props.fluid}
        placeholder="クーポン提供企業を選択してください"
        noResultsMessage="該当するクーポン提供企業が見つかりません"
        loading={this.state.isBusy}
        options={options}
        value={this.props.couponCompanyId}
        onLoadMore={this.retrieveCouponCompanies}
        onSearch={this.handleInfiniteDropdownSearch}
        onChange={this.handleInfiniteDropdownChange}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
      />
    )
  }
}

CouponCompaniesDropdown.propTypes = propTypes
CouponCompaniesDropdown.defaultProps = defaultProps

export default CouponCompaniesDropdown
