import React from 'react'
import { Form, Dropdown } from 'semantic-ui-react'

const viewableInAppFilteringOptions = [
  { text: '解除', icon: 'remove', value: null },
  { text: 'アプリで表示中', value: true },
]

const CouponsFilters = ({ handleViewableInAppFilterDropdownChange }) => {
  return (
    <Form.Field>
      <Dropdown
        selection
        placeholder="すべてのクーポン"
        onChange={handleViewableInAppFilterDropdownChange}
        options={viewableInAppFilteringOptions}
      />
    </Form.Field>
  )
}

export default CouponsFilters
