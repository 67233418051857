import React, { Component } from 'react'
import SponsorStatus from '../../enums/SponsorStatus'
import Sponsors from './Sponsors'
import getSponsors from '../../services/getSponsors'
import putSponsor from '../../services/putSponsor'
import _ from 'lodash'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('SponsorsContainer')
let getSponsorsService
let putSponsorService

export default class SponsorsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      sponsors: {},
      status: SponsorStatus.ALL,
      removeSponsor: null,
      isSponsorRemoveModalOpen: false,
      isUndoModalOpen: false,
      undoSponsor: null,
      apiError: null,
      undoApiError: null,
      permission: GetPermission('sponsor'),
      isFormSearchValid: false,
    }

    _.each(SponsorStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 50,
          sorting: { updated_at: 'desc' },
          filtering: { name: '' },
        },
      })
    })

    this.openSponsorEditModal = this.openSponsorEditModal.bind(this)
    this.handleSponsorRemoveModalClose = this.handleSponsorRemoveModalClose.bind(this)
    this.openSponsorRemoveModal = this.openSponsorRemoveModal.bind(this)
    this.openUndoModal = this.openUndoModal.bind(this)
  }

  componentDidMount() {
    this.retrieveSponsors()
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getSponsorsService)) {
      getSponsorsService.cancel()
    }
    if (!_.isNil(putSponsorService)) {
      putSponsorService.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]

    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveSponsors()
    })
  }

  /**
   * テーブルの並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveSponsors()
    })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    this.setState({
      isSponsorEditModalOpen: true,
      editSponsor: {},
    })
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handleSponsorEditModalClose = () => {
    this.setState({
      isSponsorEditModalOpen: false,
      editSponsor: null,
    })
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleSponsorRemoveModalClose = () => {
    this.setState({
      isSponsorRemoveModalOpen: false,
      removeSponsor: null,
    })
  }

  /**
   * スポンサーのデータに変更があったときのハンドラ
   */
  handleSponsorDataChanged = () => {
    this.retrieveSponsors()
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveSponsors()
    })
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const sponsorId = this.state.undoSponsor.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putSponsorService = CancelablePromise(putSponsor(sponsorId))
    putSponsorService.promise
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoSponsor: null,
        })

        this.retrieveSponsors()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.debug(`put sponsor sponsorId #${sponsorId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoSponsor: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoSponsor: null,
    })
  }

  /**
   * スポンサー名で絞り込むインプットの値変更したときのハンドラ
   */
  handleSponsorNameInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.name = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveSponsors()
  }

  /**
   * 作成・更新モーダルを表示する関数
   * @param {Object} sponsor - スポンサーデータ
   */
  openSponsorEditModal(sponsor) {
    this.setState({
      isSponsorEditModalOpen: true,
      editSponsor: sponsor,
    })
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} sponsor - スポンサーデータ
   */
  openSponsorRemoveModal(sponsor) {
    this.setState({
      isSponsorRemoveModalOpen: true,
      removeSponsor: sponsor,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   */
  openUndoModal(sponsor) {
    this.setState({
      isUndoModalOpen: true,
      undoSponsor: sponsor,
    })
  }

  /**
   * スポンサー一覧を取得
   */
  retrieveSponsors = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getSponsorsService = CancelablePromise(getSponsors(this.getRequestQuery()))
    getSponsorsService.promise
      .then(response => {
        const sponsors = response.data
        const responseHeader = response.header
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        this.setState({
          isBusy: false,
          sponsors,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve sponsors get sponsors error', error)

        tableData.itemsPerPage = 10
        tableData.currentPage = 1
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          sponsors: [],
          isBusy: false,
          [status]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const status = this.state.status
    const tableData = this.state[status]

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    // ソート順
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    // フィルタリング
    const filtering = []
    if (_.isEqual(status, SponsorStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }
    if (!_.isEmpty(tableData.filtering.name)) {
      let filteringName = tableData.filtering.name
      if (filteringName.match(/\,/)) { // eslint-disable-line
        filteringName = ''
      }

      filtering.push(`name LIKE "%${filteringName}%"`)
    }

    const query = {
      sorting,
      filtering,
      currentPage,
      itemsPerPage,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    return (
      <Sponsors
        {...this.state}
        handleFormSearchValid={this.handleFormSearchValid}
        handleFormSearchInvalid={this.handleFormSearchInvalid}
        handleDataTablePageChange={this.handleDataTablePageChange}
        handleDataTableSelectionChange={this.handleDataTableSelectionChange}
        handleCreateButtonClick={this.handleCreateButtonClick}
        handleSponsorEditModalClose={this.handleSponsorEditModalClose}
        handleSponsorRemoveModalClose={this.handleSponsorRemoveModalClose}
        handleSponsorDataChanged={this.handleSponsorDataChanged}
        handleStatusMenuItemClick={this.handleStatusMenuItemClick}
        handleUndoModalPublishButtonClick={this.handleUndoModalPublishButtonClick}
        handleUndoModalClose={this.handleUndoModalClose}
        handleUndoModalCancelButtonClick={this.handleUndoModalCancelButtonClick}
        handleSponsorNameInputChange={this.handleSponsorNameInputChange}
        handleSearchButtonClick={this.handleSearchButtonClick}
        openSponsorEditModal={this.openSponsorEditModal}
        openSponsorRemoveModal={this.openSponsorRemoveModal}
        openUndoModal={this.openUndoModal}
      />
    )
  }
}
