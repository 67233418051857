import React, { Component } from 'react'
import { Modal, Button, Image, Dimmer, Loader, Divider, Form } from 'semantic-ui-react'
import LogLevel from '../LogLevel'
import _ from 'lodash'

import { NewCouponBrand, CouponContact, CouponBrandImage } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CouponCompaniesDropdown from './CouponCompaniesDropdown'

const logger = LogLevel.getLogger('CouponBrandsCreateModal')

class CouponBrandsCreateModal extends Component {
  static defaultProps = { permission: { hasCreatePermission: false } }

  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      open: false,
      apiError: null,
      newCouponBrandValues: this.createNewCouponBrandValues(),
    }
  }

  openModal = () => this.setState({ open: true })

  closeModal = () => this.setState({ open: false, apiError: null })

  createNewCouponBrandValues = () => {
    const newCouponBrandValues = new NewCouponBrand()
    const couponContact = new CouponContact()
    const couponBrandImage = new CouponBrandImage()
    newCouponBrandValues.contact = couponContact
    newCouponBrandValues.image = couponBrandImage

    // input value 初期化
    newCouponBrandValues.name = ''
    newCouponBrandValues.displayName = ''
    newCouponBrandValues.contact.tel = ''
    newCouponBrandValues.contact.url = ''
    newCouponBrandValues.priority = ''

    return newCouponBrandValues
  }

  readFileAsDataURL = (targetName, files) => {
    const { newCouponBrandValues } = this.state
    const key = targetName
    const file = files[0]
    const reader = new FileReader()

    reader.onload = () => {
      if (_.has(newCouponBrandValues, key)) {
        _.set(newCouponBrandValues, key, reader.result)
      }
      this.setState({ newCouponBrandValues })
    }

    reader.readAsDataURL(file)
  }

  handleChange = event => {
    if (event.dataTransfer) {
      this.readFileAsDataURL(event.target.name, event.dataTransfer.files)
    } else if (event.target.files) {
      this.readFileAsDataURL(event.target.name, event.target.files)
    } else {
      const { newCouponBrandValues } = this.state

      const key = event.target.name
      let value = event.target.value
      const type = _.get(event.target, 'type', '')
      if (value.length > 0 && type === 'number') {
        value = _.parseInt(value)
      }

      if (_.has(newCouponBrandValues, key)) {
        _.set(newCouponBrandValues, key, value)
      }

      this.setState({ newCouponBrandValues }, () => {
        logger.debug('state', this.state.newCouponBrandValues)
      })
    }
  }

  handleSaveButtonClick = async () => {
    const { newCouponBrandValues } = this.state

    const requestParameters = _.cloneDeep(newCouponBrandValues)

    // priority が設定されていない場合リセット
    if (_.isEqual(requestParameters.priority, '')) {
      _.unset(requestParameters, ['priority'])
    }

    if (this.props.postCouponBrand) {
      this.setState({ isBusy: true })
      await this.props.postCouponBrand(requestParameters)
      this.setState({ isBusy: false, apiError: this.props.apiError })
    }

    if (!this.props.apiError) {
      this.setState({ newCouponBrandValues: this.createNewCouponBrandValues() }, () => {
        this.closeModal()
      })
    }
  }

  handleCouponCompaniesDropdownChange = (event, { value }) => {
    const { newCouponBrandValues } = this.state
    newCouponBrandValues.couponCompanyId = value
    this.setState({ newCouponBrandValues })
  }

  render() {
    const { isBusy, open, apiError, newCouponBrandValues } = this.state
    const { couponCompanyId, name, displayName, image, contact, priority } = newCouponBrandValues
    const { logo } = image
    const { tel, url } = contact
    const isButtonDisabled = !name || !displayName || _.isNil(couponCompanyId)
    const { permission } = this.props
    const { hasCreatePermission } = permission

    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={
          <Button disabled={!hasCreatePermission} primary content="新規作成" icon="write" labelPosition="left" />
        }
      >
        <Modal.Header>クーポン提供ブランドの新規作成</Modal.Header>
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />

          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>

          <Form onSubmit={this.handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input required label="ブランド名(正式名称)" name="name" value={name} onChange={this.handleChange} />
              <Form.Input
                required
                label="ブランド名(表示用)"
                name="displayName"
                value={displayName}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Field required>
              <label>ブランド保有企業</label>
              <CouponCompaniesDropdown
                ignoreTrashContents={false}
                couponCompanyId={couponCompanyId}
                fluid={true}
                onChange={this.handleCouponCompaniesDropdownChange}
              />
            </Form.Field>

            <Form.Input
              type="file"
              label="ロゴマーク"
              name="image.logo"
              accept={['image/*']}
              onChange={this.handleChange}
            />
            <Image src={logo} size="medium" />

            <Divider hidden />

            <Form.Group widths="equal">
              <Form.Input
                label="電話番号（ハイフンあり）"
                name="contact.tel"
                value={tel}
                onChange={this.handleChange}
              />
              <Form.Input label="問い合わせURL" name="contact.url" value={url} onChange={this.handleChange} />
            </Form.Group>

            <Form.Group>
              <Form.Input
                label="優先度（1,2,3,…の順でクーポンブランド一覧に優先表示）"
                name="priority"
                value={priority}
                type="number"
                placeholder="1以上の整数を入力"
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />

          <Button
            positive
            content="作成"
            type="button"
            onClick={this.handleSaveButtonClick}
            disabled={isButtonDisabled}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CouponBrandsCreateModal
