import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import _ from 'lodash'

import DataTable from './DataTable'

import CouponViewStatus from '../enums/CouponViewStatus'
import CouponCompaniesEditModal from './CouponCompaniesEditModal'
import CouponCompaniesRemoveModal from './CouponCompaniesRemoveModal'
import CouponCompaniesUndoModal from './CouponCompaniesUndoModal'

const CouponCompaniesDataTable = ({
  viewStatus,
  couponCompaniesItems,
  tableData,
  handleDataTableSelectionChange,
  handleDataTablePageChange,
  patchCouponCompany,
  deleteCouponCompany,
  putCouponCompany,
  patchApiError,
  deleteApiError,
  putApiError,
  permission,
}) => {
  const createButtonGroup = item => (
    <Button.Group secondary>
      <CouponCompaniesEditModal
        permission={permission}
        couponCompany={item}
        couponCompanyId={item.id}
        apiError={patchApiError}
        patchCouponCompany={patchCouponCompany}
      />
      {!_.isEqual(viewStatus, CouponViewStatus.TRASH) && (
        <CouponCompaniesRemoveModal
          permission={permission}
          couponCompany={item}
          couponCompanyId={item.id}
          apiError={deleteApiError}
          deleteCouponCompany={deleteCouponCompany}
        />
      )}
      {_.isEqual(viewStatus, CouponViewStatus.TRASH) && (
        <CouponCompaniesUndoModal
          permission={permission}
          couponCompany={item}
          couponCompanyId={item.id}
          apiError={putApiError}
          putCouponCompany={putCouponCompany}
        />
      )}
    </Button.Group>
  )

  const columns = [
    {
      label: 'ID',
      align: 'center',
      field: 'id',
    },
    {
      label: '企業名',
      field: 'name',
    },
    {
      label: '表示名',
      field: 'displayName',
    },
    {
      label: '電話番号',
      render: item => item.contact && item.contact.tel && <span>{_.get(item, 'contact.tel', '')}</span>,
    },
    {
      label: '問い合わせURL',
      render: item => {
        const url = _.get(item, 'contact.url') ? (
          <a href={_.get(item, 'contact.url')} target="_blank" rel="noopener noreferrer">
            外部サイト
            <Icon name="external" />
          </a>
        ) : (
          ''
        )

        return url
      },
      collapsing: false,
    },
    {
      label: '操作',
      align: 'center',
      render: item => createButtonGroup(item),
    },
  ]

  return (
    <DataTable
      rowKey="id"
      columns={columns}
      items={couponCompaniesItems}
      itemsPerPage={tableData.itemsPerPage}
      currentPage={tableData.currentPage}
      totalPages={tableData.totalPages}
      sort={tableData.sorting}
      onSelectionChange={handleDataTableSelectionChange}
      onPageChange={handleDataTablePageChange}
    />
  )
}

export default CouponCompaniesDataTable
