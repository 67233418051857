import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Modal, Button, Dimmer, Loader, Segment, Popup, Icon, Input, Divider } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { ManagementCategoryApi } from 'trill-api-admin-client'

import DataTable from './DataTable'
import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import TagsDropdown from './TagsDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'
import { flattenObject } from '../util'

const logger = LogLevel.getLogger('ManagementCategoryEditModal')
const categoryApi = new ManagementCategoryApi()
let sendCategory
let getCategoryTags
let deleteCategoryTag
const putCategoryTags = []

const propTypes = {
  /**
   * 編集対象の管理用カテゴリ（作成の場合は空）
   */
  category: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  category: {},
  open: false,
}

class ManagementCategoryEditModal extends Component {
  state = {
    category: {},
    isFormValid: false,
    isFormModified: false,
    isBusy: false,
    apiError: null,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されてるかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.category, nextProps.category)) {
      this.setState({
        category: nextProps.category,
        isFormModified: false,
        isBusy: false,
      })
      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.category) {
        this.initializeFormValues(this.props.category)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendCategory)) {
      sendCategory.cancel()
    }
    if (!_.isNil(deleteCategoryTag)) {
      deleteCategoryTag.cancel()
    }
    if (!_.isNil(getCategoryTags)) {
      getCategoryTags.cancel()
    }
    if (!_.isEmpty(putCategoryTags)) {
      _.each(putCategoryTags, putCategoryTag => putCategoryTag.cancel())
      putCategoryTags.length = 0
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }
    const flattenCurrentValues = flattenObject(currentValues)

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(flattenCurrentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const sendData = this.getRequestParameters(submitFormData)
    sendCategory = CancelablePromise(this.sendData(sendData))

    sendCategory.promise
      .then(response => {
        const category = response.data

        // 更新 or 作成に成功したら管理用カテゴリ一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        // フォームデータを更新して初期化
        this.initializeFormValues(category)

        resetForm(this.initialFormValues)

        this.setState({
          isFormModified: false,
          isBusy: false,
          category,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send category data error', { error })

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * フォームの初期化
   * @param {Object} category - 管理用カテゴリデータ
   */
  initializeFormValues(category) {
    this.initialFormValues.name = category.name
  }

  /**
   * API にデータを送信
   */
  sendData = submitCategoryData => {
    if (_.isEmpty(this.state.category)) {
      return categoryApi.postManagementCategory(submitCategoryData)
    }

    const categoryId = this.state.category.id
    return categoryApi.patchManagementCategory(categoryId, {
      categoryUpdateValues: submitCategoryData,
    })
  }

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}
    initialData.name = initialFormValues.name

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    logger.debug('get request parameters', { requestParameters })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  render() {
    return (
      <Modal
        className="CategoryEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.state.category) ? '管理用カテゴリの作成' : '管理用カテゴリの編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* API エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/* 管理用カテゴリ名入力フィールド */}
            <Form.Input
              name="name"
              label="管理用カテゴリ名"
              placeholder="管理用カテゴリ名を入力してください"
              required
              validations="maxLength:9"
              validationErrors={{ maxLength: '9 文字以内で入力してください。' }}
              errorLabel={<FormErrorLabel />}
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.state.category) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || !this.state.isFormModified}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

ManagementCategoryEditModal.propTypes = propTypes
ManagementCategoryEditModal.defaultProps = defaultProps

export default ManagementCategoryEditModal
