import React, { Component } from 'react'
import { Message, Button, Icon, Segment } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css' // eslint-disable-line import/no-extraneous-dependencies

import LogLevel from '../LogLevel'
import './ImageCropper.css'

const logger = LogLevel.getLogger('ImageCropper')

const propTypes = {
  /**
   * 初期に読み込む画像の URL
   */
  src: PropTypes.string.isRequired,
  /**
   * 画像の最小横幅
   */
  minWidth: PropTypes.number,
  /**
   * 画像の最小高さ
   */
  minHeight: PropTypes.number,
  /**
   * クロップ比率
   */
  initialAspectRatio: PropTypes.number,
  /**
   * 画像のクロップ時に呼び出す外部関数
   *
   * @param {string} クロップした画像の URL
   */
  onComplete: PropTypes.func,
}

const defaultProps = {
  src: '',
}

class ImageCropper extends Component {
  state = {
    src: '',
    warningMessage: '',
    isCropDisabled: false,
    cropWidth: null, // クロップボックスの幅 (number型)
    cropHeight: null, // クロップボックスの高 (number型)
  }

  constructor(props) {
    super(props)
    this.cropperRef = React.createRef()
  }

  UNSAFE_componentWillMount() {
    this.setState({
      src: this.props.src,
    })
  }

  handleCropButtonClick = () => {
    if (this.state.src && this.cropperRef.current) {
      const canvas = this.cropperRef.current.cropper.getCroppedCanvas()

      if (canvas.toDataURL) {
        const dataURL = canvas.toDataURL()

        // 切り取り画像の Data URL セット
        if (this.props.onComplete) {
          this.props.onComplete(dataURL)
        }
      } else {
        logger.error('canvas object not found.')
      }
    }
  }

  setCropBoxSizeAndWarning = event => {
    const target = event.target
    const cropBoxData = target.cropper.cropBoxData
    const imageData = target.cropper.imageData
    let warningMessage = ''
    let isInvalidSize = false

    // cropBox 内のイメージの幅と画像の実際の幅からスケールを計算
    const scale = imageData.naturalWidth / imageData.width

    // もし選択中の領域でクロップした場合の画像のデータの幅と高さを計算
    const croppedImageNaturalWidth = Math.round(cropBoxData.width * scale)
    const croppedImageNaturalHeight = Math.round(cropBoxData.height * scale)

    // 制限を超えていた場合 or 0 だった場合は切り取りボタンを非アクティブにしてメッセージを表示
    if (!_.isNil(this.props.minWidth)) {
      if (croppedImageNaturalWidth < this.props.minWidth) {
        isInvalidSize = true
      }
    }
    if (!_.isNil(this.props.minHeight)) {
      if (croppedImageNaturalHeight < this.props.minHeight) {
        isInvalidSize = true
      }
    }
    if (croppedImageNaturalHeight === 0 || croppedImageNaturalWidth === 0) {
      isInvalidSize = true
    }

    // サイズエラーであればメッセージを設定
    if (isInvalidSize) {
      if (!_.isNil(this.props.minWidth) && !_.isNil(this.props.minHeight)) {
        warningMessage = isInvalidSize
          ? `画像のサイズは ${this.props.minWidth} x ${this.props.minHeight} px 以上にしてください。`
          : ''
        warningMessage =
          croppedImageNaturalHeight === 0 || croppedImageNaturalWidth === 0
            ? '画像のサイズは 0 px 以上にしてください。'
            : warningMessage
      } else if (!_.isNil(this.props.minHeight) && _.isNil(this.props.minWidth)) {
        warningMessage = isInvalidSize ? `画像のサイズは 高さ ${this.props.minHeight} px 以上にしてください。` : ''
        warningMessage = croppedImageNaturalHeight === 0 ? '画像のサイズは 0 px 以上にしてください。' : warningMessage
      } else if (!_.isNil(this.props.minWidth) && _.isNil(this.props.minHeight)) {
        warningMessage = isInvalidSize ? `画像のサイズは 幅 ${this.props.minWidth} px 以上にしてください。` : ''
        warningMessage = croppedImageNaturalWidth === 0 ? '画像のサイズは 0 px 以上にしてください。' : warningMessage
      } else if (croppedImageNaturalHeight === 0 || croppedImageNaturalWidth === 0) {
        // 切り抜きサイズの高さ or 幅が 0 の場合
        warningMessage = '画像のサイズは 0 px 以上にしてください。'
      }
    }

    this.setState({
      warningMessage,
      isCropDisabled: isInvalidSize,
      cropWidth: croppedImageNaturalWidth,
      cropHeight: croppedImageNaturalHeight,
    })
  }

  render() {
    return (
      <div className="ImageCropper">
        <Segment className="ImageCropper__ImageSegment" attached>
          <Cropper
            style={{ height: window.innerHeight * 0.8, width: '100%' }}
            preview=".img-preview"
            viewMode={1}
            autoCropArea={1.0}
            guides
            zoomable={false}
            src={this.state.src}
            ref={this.cropperRef}
            initialAspectRatio={this.props.initialAspectRatio}
            crop={this.setCropBoxSizeAndWarning}
          />
        </Segment>
        <Message size="tiny" warning={!!this.state.warningMessage} attached="bottom">
          {!_.isNull(this.state.cropWidth) && !_.isNull(this.state.cropHeight)
            ? `切り取りサイズ ${this.state.cropWidth} x ${this.state.cropHeight}`
            : ''}
          {this.state.warningMessage && (
            <span className="ImageCropper__WarningMessage">
              <Icon name="warning" />
              {this.state.warningMessage}
            </span>
          )}
        </Message>

        <Button color="green" size="large" disabled={this.state.isCropDisabled} onClick={this.handleCropButtonClick}>
          <Icon name="cut" />
          切り取り
        </Button>
      </div>
    )
  }
}

ImageCropper.propTypes = propTypes
ImageCropper.defaultProps = defaultProps

export default ImageCropper
