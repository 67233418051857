import React, { Component } from 'react'
import { Header, Icon, Dimmer, Loader, Menu, Button, Divider, Modal, Statistic } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import { MediumApi, CpApi } from 'trill-api-admin-client'
import _ from 'lodash'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import MediaTable from '../../components/MediaTable'
import CpEditModal from '../../components/CpEditModal'
import CpRemoveModal from '../../components/CpRemoveModal'
import MediumEditModal from '../../components/MediumEditModal'
import MediumRemoveModal from '../../components/MediumRemoveModal'
import CpsDropdown from '../../components/CpsDropdown'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('CpsMedia')
const mediumApi = new MediumApi()
const cpApi = new CpApi()
let getMedia
let putMedium
let getCps
let putCp

/**
 * 表示画面の状態
 */
const ViewStatus = {
  /** CP すべて */
  CPS_ALL: 'cps_all',
  /** CP ゴミ箱 */
  CPS_TRASH: 'cps_trash',
  /** Media すべて */
  MEDIA_ALL: 'media_all',
  /** Media ゴミ箱 */
  MEDIA_TRASH: 'media_trash',
}

class CpsMedia extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      viewStatus: ViewStatus.CPS_ALL,
      media: [],
      cps: [],
      isCpEditModalOpen: false,
      isMediumEditModalOpen: false,
      isCpRemoveModalOpen: false,
      isMediumRemoveModalOpen: false,
      editCp: null,
      editMedium: null,
      removeCp: null,
      removeMedium: null,
      isUndoModalOpen: false,
      undoItem: null,
      apiError: null,
      undoApiError: null,
      permission: GetPermission('cp'),
      isFormSearchValid: false,
    }

    _.each(ViewStatus, status => {
      const isCpsView = _.isEqual(status, ViewStatus.CPS_ALL) || _.isEqual(status, ViewStatus.CPS_TRASH)
      const sortKey = isCpsView ? 'name' : 'displayPriority'
      const filteringValue = isCpsView ? { name: '' } : { name: '', cpId: null }

      _.extend(this.state, {
        [status]: {
          currentPage: 1,
          itemsPerPage: 10,
          totalPages: 0,
          totalItems: 0,
          sorting: { [sortKey]: 'desc' },
          filtering: filteringValue,
        },
      })
    })
  }

  componentDidMount() {
    this.dispatchRetrieveData()
  }

  // eslint-disable-next-line
  componentWillUnmount() {
    if (!_.isNil(getMedia)) {
      getMedia.cancel()
    }
    if (!_.isNil(putMedium)) {
      putMedium.cancel()
    }
    if (!_.isNil(getCps)) {
      getCps.cancel()
    }
    if (!_.isNil(putCp)) {
      putCp.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * CP or メディアの すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ viewStatus: name }, () => {
      this.dispatchRetrieveData()
    })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    if (this.isCpsMenu()) {
      this.setState({
        isCpEditModalOpen: true,
        editCp: {},
      })
    } else {
      this.setState({
        isMediumEditModalOpen: true,
        editMedium: {},
      })
    }
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handleEditModalClose = () => {
    if (this.isCpsMenu()) {
      this.setState({
        isCpEditModalOpen: false,
        editCp: null,
      })
    } else {
      this.setState({
        isMediumEditModalOpen: false,
        editMedium: null,
      })
    }
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleRemoveModalClose = () => {
    if (this.isCpsMenu()) {
      this.setState({
        isCpRemoveModalOpen: false,
        removeCp: null,
      })
    } else {
      this.setState({
        isMediumRemoveModalOpen: false,
        removeMedium: null,
      })
    }
  }

  /**
   * CP or メディアのデータに変更があったときのハンドラ
   */
  handleDataChanged = () => {
    this.dispatchRetrieveData()
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const viewStatus = this.state.viewStatus
    const tableData = this.state[viewStatus]

    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [viewStatus]: tableData }, () => {
      this.dispatchRetrieveData()
    })
  }

  /**
   * テーブルの並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const viewStatus = this.state.viewStatus
    const tableData = this.state[viewStatus]
    tableData.sorting = sort

    this.setState({ [viewStatus]: tableData }, () => {
      this.dispatchRetrieveData()
    })
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const itemId = this.state.undoItem.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    this.restoreItem(itemId)
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoItem: null,
        })

        this.dispatchRetrieveData()
      })
      .catch(error => {
        logger.debug(`put item itemId #${itemId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleModalUndoClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoItem: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoItem: null,
    })
  }

  /**
   * CP 名 or メディア名で絞り込むインプットの値を変更したときのハンドラ
   */
  handleNameInputChange = (event, data) => {
    const status = this.state.viewStatus
    const tableData = this.state[status]
    tableData.filtering.name = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * CP で絞り込むドロップダウンの値を変更したときのハンドラ
   */
  handleCpsDropdownChange = (event, { value }) => {
    const status = this.state.viewStatus
    const tableData = this.state[status]
    tableData.filtering.cpId = value

    this.setState({ [status]: tableData }, () => {
      this.retrieveMedia()
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.dispatchRetrieveData()
  }

  /**
   * 作成・更新モーダルを表示する関数
   * @param {Object} item - CP or メディアデータ
   */
  openEditModal(item) {
    if (this.isCpsMenu()) {
      this.setState({
        isCpEditModalOpen: true,
        editCp: item,
      })
    } else {
      this.setState({
        isMediumEditModalOpen: true,
        editMedium: item,
      })
    }
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} item - CP or メディアデータ
   */
  openRemoveModal(item) {
    if (this.isCpsMenu()) {
      this.setState({
        isCpRemoveModalOpen: true,
        removeCp: item,
      })
    } else {
      this.setState({
        isMediumRemoveModalOpen: true,
        removeMedium: item,
      })
    }
  }

  /**
   * Undo モーダル画面を表示する関数
   * @param {Object} item - CP or メディアデータ
   */
  openUndoModal(item) {
    this.setState({
      isUndoModalOpen: true,
      undoItem: item,
    })
  }

  /**
   * 表示中のメニューが CP かどうか
   */
  isCpsMenu = () => {
    const viewStatus = this.state.viewStatus
    return _.isEqual(viewStatus, ViewStatus.CPS_ALL) || _.isEqual(viewStatus, ViewStatus.CPS_TRASH)
  }

  /**
   * 表示中のメニューがメディアかどうか
   */
  isMediaMenu = () => {
    const viewStatus = this.state.viewStatus
    return _.isEqual(viewStatus, ViewStatus.MEDIA_ALL) || _.isEqual(viewStatus, ViewStatus.MEDIA_TRASH)
  }

  /**
   * 削除コンテンツを公開に戻すための API (put) を叩く関数
   */
  restoreItem = itemId => {
    if (this.isCpsMenu()) {
      putCp = CancelablePromise(cpApi.putCp(itemId))
      return putCp.promise
    }

    putMedium = CancelablePromise(mediumApi.putMedium(itemId))
    return putMedium.promise
  }

  /**
   * 表示中のメニューで一覧取得の API を振り分ける関数
   */
  dispatchRetrieveData = () => {
    if (this.isCpsMenu()) {
      this.retrieveCps()
    } else {
      this.retrieveMedia()
    }
  }

  /**
   * メディア一覧を取得
   */
  retrieveMedia = () => {
    const viewStatus = this.state.viewStatus
    const tableData = this.state[viewStatus]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getMedia = CancelablePromise(mediumApi.getMedia(this.getRequestQuery()))
    getMedia.promise
      .then(response => {
        const responseHeader = response.header
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        return response.data
      })
      .then(media => {
        this.setState({
          isBusy: false,
          media,
          [viewStatus]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve media error', error)

        tableData.itemsPerPage = 10
        tableData.currentPage = 1
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          media: [],
          isBusy: false,
          [viewStatus]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * CP 一覧を取得
   */
  retrieveCps = () => {
    const viewStatus = this.state.viewStatus
    const tableData = this.state[viewStatus]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getCps = CancelablePromise(cpApi.getCps(this.getRequestQuery()))
    getCps.promise
      .then(response => {
        const cps = response.data
        const responseHeader = response.header
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        this.setState({
          isBusy: false,
          cps,
          [viewStatus]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve cp error', error)

        tableData.itemsPerPage = 10
        tableData.currentPage = 1
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          cps: [],
          isBusy: false,
          [viewStatus]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 表示中の View の状態
    const viewStatus = this.state.viewStatus
    // 表示中のテーブルデータの state を取得
    const tableData = this.state[viewStatus]

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage

    // 1 ページあたりに含めるデータの数
    const itemsPerPage = tableData.itemsPerPage

    const filtering = []
    if (_.isEqual(viewStatus, ViewStatus.CPS_TRASH) || _.isEqual(viewStatus, ViewStatus.MEDIA_TRASH)) {
      // 削除一覧取得の場合
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }
    if (!_.isEmpty(tableData.filtering.name)) {
      let filteringName = tableData.filtering.name
      if (filteringName.match(/\,/)) { // eslint-disable-line
        filteringName = ''
      }

      // 名前で検索する場合
      filtering.push(`name LIKE "%${filteringName}%"`)
    }
    if (this.isMediaMenu() && tableData.filtering.cpId) {
      const cpId = parseInt(tableData.filtering.cpId, 10)
      filtering.push(`cpId = ${cpId}`)
    }

    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const viewStatus = this.state.viewStatus
    const tableData = this.state[viewStatus]
    const undoModalTitle = this.isCpsMenu() ? 'CPの公開' : 'メディアの公開'
    const searchPlaceholder = this.isCpsMenu() ? 'CP名で検索' : 'メディア名で検索'
    const { permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    return (
      <div className="CpsMedia">
        <Header as="h1">
          <Icon name="rss" />

          <Header.Content>CP・メディア</Header.Content>
        </Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {/* CP メディア切り替え */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="CP（すべて）"
            name={ViewStatus.CPS_ALL}
            onClick={this.handleStatusMenuItemClick}
            active={_.isEqual(viewStatus, ViewStatus.CPS_ALL)}
          />

          <Menu.Item
            content="CP（ゴミ箱）"
            name={ViewStatus.CPS_TRASH}
            onClick={this.handleStatusMenuItemClick}
            active={_.isEqual(viewStatus, ViewStatus.CPS_TRASH)}
          />

          <Menu.Item
            content="メディア（すべて）"
            name={ViewStatus.MEDIA_ALL}
            onClick={this.handleStatusMenuItemClick}
            active={_.isEqual(viewStatus, ViewStatus.MEDIA_ALL)}
          />

          <Menu.Item
            content="メディア（ゴミ箱）"
            name={ViewStatus.MEDIA_TRASH}
            onClick={this.handleStatusMenuItemClick}
            active={_.isEqual(viewStatus, ViewStatus.MEDIA_TRASH)}
          />
        </Menu>

        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="cps-media-search"
                type="text"
                placeholder={searchPlaceholder}
                action
                value={tableData.filtering.name}
                onChange={this.handleNameInputChange}
                validations={{ matchRegexp: /^((?!,).)*$/i }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>

            {this.isMediaMenu() && (
              <Form.Field width={4}>
                <CpsDropdown
                  ignoreTrashContents={false}
                  cpId={tableData.filtering.cpId}
                  fluid={false}
                  onChange={this.handleCpsDropdownChange}
                  disabled={!this.state.isFormSearchValid}
                />
              </Form.Field>
            )}
          </Form.Group>
        </Form>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/* CP 一覧表示 */}
        {!_.isEmpty(this.state.cps) && this.isCpsMenu() && (
          <DataTable
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSelectionChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            sort={tableData.sorting}
            items={this.state.cps}
            columns={[
              {
                label: 'CP 名',
                field: 'name',
                minWidth: '20em',
                collapsing: false,
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.openEditModal(item)
                      }}
                    />

                    {!_.isEqual(viewStatus, ViewStatus.CPS_TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(viewStatus, ViewStatus.CPS_TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
            compact
            rowKey="id"
          />
        )}

        {/* メディア一覧表示 */}
        {!_.isEmpty(this.state.media) && this.isMediaMenu() && (
          <MediaTable
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSelectionChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            sort={tableData.sorting}
            items={this.state.media}
            viewStatus={this.state.viewStatus}
            hasUpdatePermission={hasUpdatePermission}
            hasDeletePermission={hasDeletePermission}
            hasRestorePermission={hasRestorePermission}
            openEditModal={item => this.openEditModal(item)}
            openRemoveModal={item => this.openRemoveModal(item)}
            openUndoModal={item => this.openUndoModal(item)}
            compact
            rowKey="id"
          />
        )}

        {/* CP 追加・編集モーダル */}
        {hasUpdatePermission && (
          <CpEditModal
            cp={this.state.editCp}
            open={this.state.isCpEditModalOpen}
            onClose={this.handleEditModalClose}
            onSuccessDataChanged={this.handleDataChanged}
          />
        )}

        {/* CP 削除モーダル */}
        {hasDeletePermission && (
          <CpRemoveModal
            cp={this.state.removeCp}
            open={this.state.isCpRemoveModalOpen}
            onClose={this.handleRemoveModalClose}
            onSuccessDataChanged={this.handleDataChanged}
          />
        )}

        {/* メディア追加・編集モーダル */}
        {hasUpdatePermission && (
          <MediumEditModal
            medium={this.state.editMedium}
            open={this.state.isMediumEditModalOpen}
            onClose={this.handleEditModalClose}
            onSuccessDataChanged={this.handleDataChanged}
          />
        )}

        {/* メディア削除モーダル */}
        {hasDeletePermission && (
          <MediumRemoveModal
            medium={this.state.removeMedium}
            open={this.state.isMediumRemoveModalOpen}
            onClose={this.handleRemoveModalClose}
            onSuccessDataChanged={this.handleDataChanged}
          />
        )}

        {/* 削除した CP or メディアを戻すときに表示する確認用モーダル */}
        {hasRestorePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isUndoModalOpen}
            onClose={this.handleModalUndoClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>{undoModalTitle}</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.undoApiError} />
              {_.get(this.state, 'undoItem.name')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.handleUndoModalPublishButtonClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default CpsMedia
