import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { PUBLISH_DATETIME_LABEL_FORMAT, PUBLISH_DATE_LABEL_FORMAT } from '../constants/date_format'

const propTypes = {
  /**
   * The time picker will be shown or hidden
   */
  timePicker: PropTypes.bool,

  /**
   * 開始時間の moment
   */
  startDate: PropTypes.object,

  /**
   * 終了時間の moment
   */
  endDate: PropTypes.object,

  /**
   * placeholder テキスト
   */
  placeholderText: PropTypes.string,

  /**
   * Input の値をリセット
   */
  clear: PropTypes.bool,

  /**
   * 選択範囲を変更したときに呼び出す外部関数
   */
  onEvent: PropTypes.func,

  /**
   * Whether disabled or not
   */
  disabled: PropTypes.bool,
}

const defaultProps = {
  timePicker: false,
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month'),
  placeholderText: '公開日時で絞り込み',
  clear: false,
  disabled: false,
}

/**
 * 公開日時の範囲を選択するコンポーネント
 */
const PublishDatetimeRangePicker = props => {
  const dateFormat = props.timePicker ? PUBLISH_DATETIME_LABEL_FORMAT : PUBLISH_DATE_LABEL_FORMAT
  const dateRangeStartLabel = props.startDate.format(dateFormat)
  const dateRangeEndLabel = props.endDate.format(dateFormat)
  let dateRangeLabel =
    dateRangeStartLabel !== dateRangeEndLabel ? `${dateRangeStartLabel} 〜 ${dateRangeEndLabel}` : dateRangeStartLabel

  // DateRangePicker の input 項目をリセットする場合
  if (props.clear) {
    dateRangeLabel = ''
  }

  return (
    <DateRangePicker
      opens="center"
      containerStyles={{ display: 'block' }}
      locale={{
        format: dateFormat,
        applyLabel: '確定',
        cancelLabel: '解除',
        fromLabel: '開始日',
        toLabel: '終了日',
        customRangeLabel: 'その他',
      }}
      ranges={{
        '直近 7 日': [
          moment()
            .subtract(6, 'days')
            .startOf('day'),
          moment().endOf('day'),
        ],
        '直近 30 日': [
          moment()
            .subtract(29, 'days')
            .startOf('day'),
          moment().endOf('day'),
        ],
        昨日: [
          moment()
            .subtract(1, 'days')
            .startOf('day'),
          moment()
            .subtract(1, 'days')
            .endOf('day'),
        ],
        今日: [moment().startOf('day'), moment().endOf('day')],
        今週: [moment().startOf('week'), moment().endOf('week')],
        今月: [moment().startOf('month'), moment().endOf('month')],
      }}
      alwaysShowCalendars
      timePicker={props.timePicker}
      timePicker24Hour={props.timePicker24Hour}
      startDate={props.startDate}
      endDate={props.endDate}
      onEvent={props.onEvent}
    >
      <Input
        icon="calendar"
        iconPosition="left"
        placeholder={props.placeholderText}
        value={dateRangeLabel}
        disabled={props.disabled}
        readOnly
      />
    </DateRangePicker>
  )
}

PublishDatetimeRangePicker.propTypes = propTypes
PublishDatetimeRangePicker.defaultProps = defaultProps

export default PublishDatetimeRangePicker
