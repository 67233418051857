import React, { Component } from 'react'
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Header,
  Icon,
  Image,
  Loader,
  Menu,
  Modal,
  Statistic,
} from 'semantic-ui-react'
import { Input, Form } from 'formsy-semantic-ui-react'
import { CreatorApi } from 'trill-api-admin-client'
import _ from 'lodash'
import Store from 'store'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import FormErrorLabel from '../../components/FormErrorLabel'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'
import CreatorForm from '../../components/CreatorForm'

const logger = LogLevel.getLogger('Creators')

const creatorApi = new CreatorApi()

let getCreators
let deleteCreator
let sendCreator
let restoreCreator

const CreatorStatus = {
  ALL: 'all',
  TRASH: 'trash',
}

const inputTextValidateRegex = /^((?!,).)*$/i

class Creators extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      status: CreatorStatus.ALL,
      permission: GetPermission('creator'),
      creators: [],
      isFormSearchValid: false,
      selectedCreator: null,
      isCreatorFormModalOpen: false,
      isSubmitCreatorForm: false,
      isCreatorFormValid: false,
      isCreatorFormModified: false,
      isCreatorDeleteModalOpen: false,
      isCreatorRestoreModalOpen: false,
      isIntroductionValid: true,
      isIntroductionModified: false,
    }

    _.each(CreatorStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 10,
          sorting: {},
          filtering: {
            key: 'name',
            value: '',
          },
        },
      })
    })
  }

  componentDidMount() {
    const pageState = Store.get('creatorsPageState')

    if (pageState) {
      const creators = pageState.creators
      const status = _.get(pageState, 'status', CreatorStatus.ALL)
      const tableData = _.get(pageState, status, this.state[status])

      this.setState(
        {
          status,
          creators,
          [status]: tableData,
        },
        () => {
          this.retrieveCreators()
        },
      )
    } else {
      this.retrieveCreators()
    }
  }

  componentWillUnmount() {
    if (!_.isNil(getCreators)) {
      getCreators.cancel()
    }

    if (!_.isNil(sendCreator)) {
      sendCreator.cancel()
    }

    if (!_.isNil(deleteCreator)) {
      deleteCreator.cancel()
    }

    if (!_.isNil(restoreCreator)) {
      restoreCreator.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * Handler to be called when the status menu item is clicked
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveCreators()
      this.savePageState()
    })
  }

  /**
   * Handler to be called when the search input is clicked
   */
  handleSearchInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.value = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * Handler to be called when the search input type is changed
   */
  handleSearchInputTypeDropdownChange = (event, { value }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.key = value
    tableData.filtering.value = ''

    this.setState({ [status]: tableData })
  }

  /**
   * Handler to be called when the search button is clicked
   */
  handleSearchButtonClick = () => {
    if (!this.state.isFormSearchValid) {
      return
    }

    this.retrieveCreators()
    this.savePageState()
  }

  /**
   * Handler to be called when the creator form modal is opened
   */
  handleCreatorFormModalOpen = selectedCreator => {
    this.setState(
      {
        isCreatorFormModalOpen: true,
        isSubmitCreatorForm: false,
        isCreatorFormValid: false,
        isCreatorFormModified: false,
        selectedCreator,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the creator form is changed
   */
  handleCreatorFormChange = (creatorChanged, isModified) => {
    this.setState({
      isCreatorFormModified: isModified,
      isSubmitCreatorForm: false,
    })
  }

  /**
   * Handler to be called when the creator form is valid
   */
  handleCreatorFormValid = () => {
    this.setState({
      isCreatorFormValid: true,
    })
  }

  /**
   * Handler to be called when the creator form is invalid
   */
  handleCreatorFormInvalid = () => {
    this.setState({
      isCreatorFormValid: false,
    })
  }

  /**
   * Handler to be called when the introduction change
   */
  handleIntroductionChange = (isIntroductionValid, isIntroductionModified) => {
    this.setState({ isIntroductionValid, isIntroductionModified })
  }

  /**
   * Handler to be called when the creator form is submitted
   */
  handleCreatorFormValidSubmit = creatorData => {
    const creator = {
      ...creatorData,
      order: creatorData.order || 0,
    }

    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendCreator = CancelablePromise(
      _.isEmpty(this.state.selectedCreator)
        ? creatorApi.postCreator(creator)
        : creatorApi.patchCreator(this.state.selectedCreator.id, { creatorUpdateValues: creator }),
    )
    sendCreator.promise
      .then(() => {
        this.handleCreatorFormModalClose()
        this.retrieveCreators()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        const apiError =
          error.status === 422 ? { header: 'エラーメッセージ', message: '表示順はすでに存在します' } : error

        this.setState({
          isBusy: false,
          apiError,
          isSubmitCreatorForm: false,
        })
      })
  }

  /**
   * Handler to be called when the creator form modal is closed
   */
  handleCreatorFormModalClose = () => {
    this.setState(
      {
        isCreatorFormModalOpen: false,
        isSubmitCreatorForm: false,
        isCreatorFormValid: false,
        isCreatorFormModified: false,
        selectedCreator: null,
        isBusy: false,
        apiError: null,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the submit button in creator form modal is clicked
   */
  handleSubmitButtonCreatorFormModalClick = () => {
    this.setState({ isSubmitCreatorForm: true })
  }

  /**
   * Handler to be called when the creator delete modal is opened
   */
  handleCreatorDeleteModalOpen = selectedCreator => {
    this.setState(
      {
        isCreatorDeleteModalOpen: true,
        selectedCreator,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the creator delete modal is closed
   */
  handleCreatorDeleteModalClose = () => {
    this.setState(
      {
        isCreatorDeleteModalOpen: false,
        selectedCreator: null,
        isBusy: false,
        apiError: null,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the submit button in creator delete modal is clicked
   */
  handleSubmitButtonCreatorDeleteModalClick = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteCreator = CancelablePromise(creatorApi.deleteCreator(this.state.selectedCreator.id))
    deleteCreator.promise
      .then(() => {
        this.handleCreatorDeleteModalClose()
        this.retrieveCreators()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Handler to be called when the creator restore modal is opened
   */
  handleCreatorRestoreModalOpen = selectedCreator => {
    this.setState(
      {
        isCreatorRestoreModalOpen: true,
        selectedCreator,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the creator restore modal is closed
   */
  handleCreatorRestoreModalClose = () => {
    this.setState(
      {
        isCreatorRestoreModalOpen: false,
        selectedCreator: null,
        isBusy: false,
        apiError: null,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the submit button in creator restore modal is clicked
   */
  handleSubmitButtonCreatorRestoreModalClick = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    restoreCreator = CancelablePromise(creatorApi.putCreator(this.state.selectedCreator.id))
    restoreCreator.promise
      .then(() => {
        this.handleCreatorRestoreModalClose()
        this.retrieveCreators()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Handler to be called when the page or item per page of table is changed
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveCreators()
      this.savePageState()
    })
  }

  /**
   * Handler to be called when the order of table is changed
   */
  handleDataTableSortChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveCreators()
      this.savePageState()
    })
  }

  /**
   * Call api get creators list
   */
  retrieveCreators = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getCreators = CancelablePromise(creatorApi.getCreators(this.getRequestQuery()))
    getCreators.promise
      .then(response => {
        const responseHeader = response.header
        const creators = response.data

        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        logger.debug('retrieve creators', { creators })

        this.setState({
          isBusy: false,
          creators,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve creators error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totaPage = 0
        tableData.totalItems = 0

        this.setState({
          creators: [],
          [status]: tableData,
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Get request query during API communication
   */
  getRequestQuery = () => {
    // Get the state of the displayed table data
    const status = this.state.status
    const tableData = this.state[status]

    // Calculate the total page by dividing the setting tableData.totalItems by the setting tableData.itemsPerPage
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    // Search params
    const filtering = []
    filtering.push(`deletedAt IS${_.isEqual(status, CreatorStatus.TRASH) ? ' NOT ' : ' '}NULL`)

    if (!_.isEmpty(tableData.filtering.value) && tableData.filtering.value.match(inputTextValidateRegex)) {
      const { key, value } = tableData.filtering

      filtering.push(`${key} LIKE "%${value}%"`)
    }

    // Order params
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : '+'

      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // Returns an object except empty and not number
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * Save current page state in store
   */
  savePageState() {
    const creators = this.state.creators
    const status = this.state.status
    const tableData = this.state[status]
    const pageState = {
      creators,
      status,
      [status]: tableData,
    }

    logger.debug('save page state', { pageState })

    Store.set('creatorsPageState', pageState)
  }

  /**
   * Get search input placeholder text
   */
  searchInputPlaceholder = key => {
    switch (key) {
      case 'id':
        return 'IDで検索'
      case 'name':
        return 'クリエイター名で検索'
      case 'introduction':
        return 'プロフィール文で検索'
      case 'order':
        return '一覧ページ表示順位で検索'
      default:
        return ''
    }
  }

  render() {
    const {
      status,
      permission,
      isCreatorFormModalOpen,
      isCreatorDeleteModalOpen,
      isCreatorRestoreModalOpen,
    } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission
    const tableData = this.state[status]
    const hasModalBeShown = isCreatorFormModalOpen || isCreatorDeleteModalOpen || isCreatorRestoreModalOpen
    const disabledFormCreator =
      !this.state.isCreatorFormValid ||
      (!this.state.isCreatorFormModified && !this.state.isIntroductionModified) ||
      !this.state.isIntroductionValid

    return (
      <div className="Creators">
        <Header as="h1">
          <Icon name="user" />

          <Header.Content>クリエイター</Header.Content>
        </Header>

        <Dimmer active={hasModalBeShown ? false : this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={CreatorStatus.ALL}
            active={_.isEqual(status, CreatorStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={CreatorStatus.TRASH}
            active={_.isEqual(status, CreatorStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={() => {
                this.handleCreatorFormModalOpen({})
              }}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="creators-search"
                type="text"
                action
                placeholder={this.searchInputPlaceholder(tableData.filtering.key)}
                value={tableData.filtering.value}
                onChange={this.handleSearchInputChange}
                validations={{ matchRegexp: inputTextValidateRegex }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Dropdown
                  onChange={this.handleSearchInputTypeDropdownChange}
                  value={tableData.filtering.key}
                  selection
                  options={[
                    {
                      key: 'id',
                      value: 'id',
                      text: 'ID',
                    },
                    {
                      key: 'name',
                      value: 'name',
                      text: 'クリエイター名',
                    },
                    {
                      key: 'introduction',
                      value: 'introduction',
                      text: 'プロフィール文',
                    },
                    {
                      key: 'order',
                      value: 'order',
                      text: '一覧ページ表示順位',
                    },
                  ]}
                />

                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>
          </Form.Group>
        </Form>

        {(hasModalBeShown || _.isEmpty(this.state.apiError)) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        <ApiErrorMessage error={hasModalBeShown ? null : this.state.apiError} />

        {!_.isEmpty(this.state.creators) && (
          <DataTable
            rowKey="id"
            sort={tableData.sorting}
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSortChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            items={this.state.creators}
            cellStyle={{ position: 'relative' }}
            columns={[
              {
                label: 'ID',
                align: 'center',
                field: 'id',
                minWidth: '6em',
              },
              {
                label: '画像',
                align: 'center',
                render: item => {
                  const imageUrl = _.get(item, 'thumbnail.image.url', '')

                  return <Image src={imageUrl} centered size="tiny" shape="rounded" />
                },
              },
              {
                label: ' クリエイター名',
                field: 'name',
                minWidth: '20em',
                collapsing: false,
              },
              {
                label: 'プロフィール文',
                field: 'introduction',
                collapsing: false,
                minWidth: '20em',
              },
              {
                label: '一覧ページ表示順位',
                align: 'center',
                field: 'order',
                collapsing: false,
                minWidth: '6em',
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.handleCreatorFormModalOpen(item)
                      }}
                    />

                    {!_.isEqual(status, CreatorStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.handleCreatorDeleteModalOpen(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, CreatorStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.handleCreatorRestoreModalOpen(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {(hasCreatePermission || hasUpdatePermission) && (
          <Modal
            className="Creators__FormModal"
            size="fullscreen"
            closeIcon
            open={this.state.isCreatorFormModalOpen}
            onClose={this.handleCreatorFormModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>
              {_.isEmpty(this.state.selectedCreator) ? 'クリエイターの作成' : 'クリエイターの編集'}
            </Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <CreatorForm
                creator={this.state.selectedCreator}
                isSubmitForm={this.state.isSubmitCreatorForm}
                onFormChange={this.handleCreatorFormChange}
                onFormValid={this.handleCreatorFormValid}
                onFormInvalid={this.handleCreatorFormInvalid}
                onFormValidSubmit={this.handleCreatorFormValidSubmit}
                onIntroductionChange={this.handleIntroductionChange}
                isIntroductionValid={this.state.isIntroductionValid}
              />
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleCreatorFormModalClose} />
              <Button
                positive
                content={_.isEmpty(this.state.selectedCreator) ? '保存' : '更新'}
                disabled={disabledFormCreator}
                onClick={this.handleSubmitButtonCreatorFormModalClick}
              />
            </Modal.Actions>
          </Modal>
        )}

        {hasDeletePermission && (
          <Modal
            className="Creators__DeleteModal"
            size="small"
            closeIcon
            open={this.state.isCreatorDeleteModalOpen}
            onClose={this.handleCreatorDeleteModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>クリエイターの削除</Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <p>クリエイター {_.get(this.state, 'selectedCreator.name')} をゴミ箱へ移動しますか？</p>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleCreatorDeleteModalClose} />
              <Button negative content="ゴミ箱へ移動" onClick={this.handleSubmitButtonCreatorDeleteModalClick} />
            </Modal.Actions>
          </Modal>
        )}

        {hasRestorePermission && (
          <Modal
            className="Creators__RestoreModal"
            size="tiny"
            closeIcon
            open={this.state.isCreatorRestoreModalOpen}
            onClose={this.handleCreatorRestoreModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>クリエイターの公開</Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />
              {_.get(this.state, 'selectedCreator.name')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleCreatorRestoreModalClose} />
              <Button positive content="戻す" onClick={this.handleSubmitButtonCreatorRestoreModalClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default Creators
