import { Component } from 'react'
import { GameApi } from 'trill-api-admin-client'
import { Dimmer, Grid, Header, Icon, Label, Loader, Menu, Popup } from 'semantic-ui-react'
import _ from 'lodash'

import GameForm from '../../components/GameForm'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import CancelablePromise from '../../CancelablePromise'

import { GameStatus } from '../../enums/GameEnum'

const GameStatusWithTrash = {
  ...GameStatus,
  trash: 'trash',
}

const StatusButtonAttributes = {
  [GameStatusWithTrash.publish]: {
    text: '公開',
    icon: 'unhide',
    color: 'green',
  },
  [GameStatusWithTrash.draft]: {
    text: '非 公 開',
    icon: 'wait',
    color: 'yellow',
  },
  [GameStatusWithTrash.trash]: {
    text: 'ゴミ箱',
    icon: 'trash',
    color: 'grey',
  },
}

const gameApi = new GameApi()

let getGamePromise
let patchGamePromise
let restoreGamePromise

class GameContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      game: {},

      isBusy: false,
      apiError: null,
    }
  }

  /**
   * After FIRST rendering, retrieve data
   */
  componentDidMount() {
    const gameId = this.props.routeParams.id

    if (!_.isNil(gameId)) {
      this.retrieveGame(gameId)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldGameId = this.props.routeParams.id
    const nextGameId = nextProps.routeParams.id

    if (!_.isNil(nextGameId) && _.isEqual(nextGameId, oldGameId)) {
      this.retrieveGame(nextGameId)
    }
  }

  componentWillUnmount() {
    if (!_.isNil(getGamePromise)) {
      getGamePromise.cancel()
    }

    if (!_.isNil(patchGamePromise)) {
      patchGamePromise.cancel()
    }

    if (!_.isNil(restoreGamePromise)) {
      restoreGamePromise.cancel()
    }
  }

  handleFormValidSubmit = submitFormData => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const requestParameters = this.getRequestParameters(submitFormData)
    patchGamePromise = CancelablePromise(this.sendGameData(requestParameters))
    patchGamePromise.promise
      .then(response => {
        const game = response.data

        this.setState(
          {
            isBusy: false,
            game,
          },
          () => {
            if (!_.isNil(this.state.game.id)) {
              this.props.router.replace(`/game/${this.state.game.id}`)
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  handleStatusMenuItemChange = (event, { name }) => {
    const gameId = this.state.game.id
    if (_.isNil(gameId)) {
      return
    }

    this.setState({
      isBusy: true,
      apiError: null,
    })

    restoreGamePromise = CancelablePromise(this.restoreGame(gameId))
    restoreGamePromise.promise
      .then(() => {
        if (_.isEqual(name, GameStatusWithTrash.trash)) {
          return gameApi.deleteGame(gameId)
        }

        const gameUpdateValues = { status: name }
        return gameApi.patchGame(gameId, {
          gameUpdateValues,
        })
      })
      .then(response => {
        const game = response.data

        this.setState({
          isBusy: false,
          game: _.isEmpty(game)
            ? {
                ...this.state.game,
                deletedAt: new Date().toISOString(),
              }
            : game,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Extract created/updated game values from Form Data
   */
  getRequestParameters = submitFormData => {
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    const initialData = {}
    initialData.title = this.state.game.title
    initialData.description = this.state.game.description
    initialData.guide = this.state.game.guide
    initialData.originalLink = this.state.game.originalLink
    initialData.displayInformation = this.state.game.displayInformation
    initialData.location = this.state.game.location
    initialData.publishDatetime = this.state.game.publishDatetime
    initialData.order = this.state.game.order
    initialData.gameCategoryId = this.state.game.gameCategoryId
    _.set(initialData, 'thumbnail.image.url', _.get(this.state.game, 'thumbnail.image.url', ''))

    // Filter only the difference
    let requestParameters = difference(submitFormData, initialData)

    // Allow gameCategory is null so set the value 0 for nil
    if (_.has(requestParameters, 'gameCategoryId') && _.isNil(requestParameters.gameCategoryId)) {
      requestParameters.gameCategoryId = 0
    }

    requestParameters = _.omitBy(
      requestParameters,
      v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v) && !_.isString(v),
    )

    return requestParameters
  }

  /**
   * Call API to get Game then reset form with the received data
   */
  retrieveGame = gameId => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    getGamePromise = CancelablePromise(gameApi.getGame(gameId))
    getGamePromise.promise
      .then(response => {
        const game = response.data

        this.setState({
          game,
          isBusy: false,
        })
      })
      .catch(errors => {
        if (errors.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: errors,
        })
      })
  }

  /**
   * Call API to restore game if it is deleted
   */
  restoreGame = gameId =>
    new Promise((resolve, reject) => {
      if (_.isNil(this.state.game.deletedAt)) {
        resolve()

        return
      }

      gameApi
        .putGame(gameId)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * Call API to Post/Patch game based on `this.state.gameId` and `requestParameters`
   */
  sendGameData = requestParameters => {
    const gameId = this.state.game.id

    if (_.isNil(gameId)) {
      return gameApi.postGame(requestParameters)
    }

    return gameApi.patchGame(gameId, {
      gameUpdateValues: requestParameters,
    })
  }

  headerPopupRender() {
    const { id, status: gameStatus, deletedAt } = this.state.game
    const buttonStatus = _.isNil(deletedAt) ? gameStatus : GameStatusWithTrash.trash
    const statusButtonAttribute = _.get(StatusButtonAttributes, buttonStatus)
    const statusMenuAttributes = Object.entries(StatusButtonAttributes).filter(
      ([status]) => !_.isEqual(status, buttonStatus),
    )

    return (
      <Popup
        position="bottom left"
        style={{
          padding: '0',
          border: 'none',
        }}
        hoverable
        trigger={
          <Label as="a" size="large" color={statusButtonAttribute.color} style={{ verticalAlign: '6px' }}>
            <Icon name={statusButtonAttribute.icon} />
            {statusButtonAttribute.text}
            <Label.Detail># {id}</Label.Detail>
          </Label>
        }
      >
        <Popup.Content>
          <Menu vertical borderless compact>
            {statusMenuAttributes.map(([status, attrs]) => (
              <Menu.Item
                key={status}
                name={status}
                icon={attrs.icon}
                content={attrs.text}
                onClick={this.handleStatusMenuItemChange}
              />
            ))}
          </Menu>
        </Popup.Content>
      </Popup>
    )
  }

  render() {
    return (
      <div className="Game">
        <Grid className="Article__Main__Header">
          <Grid.Column width={10}>
            <Header as="h1">
              <Icon name="game" />

              <Header.Content>{_.isNil(this.state.game.id) ? '新規ゲームの作成' : 'ゲームの編集'}</Header.Content>

              {!_.isNil(this.state.game.id) && this.headerPopupRender()}
            </Header>
          </Grid.Column>
        </Grid>

        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <ApiErrorMessage error={this.state.apiError} />

        <GameForm
          setRef={element => {
            this.form = element
          }}
          game={this.state.game}
          onFormValidSubmit={this.handleFormValidSubmit}
        />
      </div>
    )
  }
}

export default GameContainer
