import React, { Component } from 'react'
import { Modal, Dimmer, Loader, Grid, Button, Input, Divider, Segment, Label, Checkbox } from 'semantic-ui-react'
import { PersonalityQuizApi } from 'trill-api-admin-client'
import PropTypes from 'prop-types'
import { Form } from 'formsy-semantic-ui-react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import TextareaAutosize from 'react-textarea-autosize'
import _ from 'lodash'
import moment from 'moment'
import encoding from 'encoding-japanese'
import classNames from 'classnames'

import PersonalityQuizResultList from './PersonalityQuizResultList'
import PersonalityQuizQuestionList from './PersonalityQuizQuestionList'
import FormErrorLabel from './FormErrorLabel'
import CategoriesDropdown from './CategoriesDropdown'
import ApiErrorMessage from './ApiErrorMessage'
import MediumInput from '../components/MediumInput'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'
import { flattenObject } from '../util'
import { PUBLISH_DATETIME_LABEL_FORMAT } from '../constants/date_format'

const logger = LogLevel.getLogger('PersonalityQuizEditModal')
const personalityQuizApi = new PersonalityQuizApi()
let sendPersonalityQuiz
let getPersonalityQuiz

const propTypes = {
  /**
   * 編集対象の診断 (作成は空)
   */
  personalityQuiz: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  personalityQuiz: {},
  open: false,
}

/**
 * 診断編集・作成用コンポーネント
 */
class PersonalityQuizEditModal extends Component {
  state = {
    isFormValid: false,
    isFormModified: false,
    isQuestionsFormModified: false,
    isResultsFormModified: false,
    isPublishDatetimeModified: false,
    isQuestionFormValid: false,
    isQuestionFormModified: false,
    isResultFormValid: false,
    isResultFormModified: false,
    isBusy: false,
    thumbnailImageUrlInputValue: '',
    thumbnailImageError: null,
    coverImageUrlInputValue: '',
    coverImageError: null,
    isCoverImageActive: false,
    publishDatetime: moment(),
    questions: [],
    results: [],
    categoryId: undefined,
    apiError: null,
    description: '',
    isDescriptionValid: true,
    isDescriptionModified: false,
  }

  /**
   * 初期のフォームデータ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されているかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.personalityQuiz, nextProps.personalityQuiz)) {
      this.setState({
        isBusy: true,
        apiError: null,
        isFormModified: false,
        isQuestionsFormModified: false,
        isResultFormModified: false,
      })

      // 詳細の取得
      getPersonalityQuiz = CancelablePromise(this.getPersonalityQuizDetail(nextProps.personalityQuiz))
      getPersonalityQuiz.promise
        .then(personalityQuiz => {
          // 詳細取得後に概要の取得をするため、personalityQuiz があれば診断詳細のデータでフォームを再初期化
          if (!_.isEmpty(personalityQuiz)) {
            this.initializeFormValues(personalityQuiz)
            this.refs.form.reset(this.initialFormValues)
          }

          // 質問一覧 初期値として 5 つ表示
          const defaultQuestions = []
          _.times(5, () => {
            defaultQuestions.push({
              answers: [
                { answer: '', point: 25 },
                { answer: '', point: 20 },
                { answer: '', point: 15 },
                { answer: '', point: 10 },
                { answer: '', point: 5 },
              ],
            })
          })

          // 結果一覧 初期値として 5 つ表示
          const defaultResults = []
          _.times(5, () => defaultResults.push({}))

          // サムネイル画像
          const thumbnailImageUrlInputValue = _.get(personalityQuiz, 'thumbnail.image.url', '')

          // カバー画像
          const coverImageUrlInputValue = _.get(personalityQuiz, 'cover.image.url', '')

          // 初期化
          this.setState({
            isBusy: false,
            isCoverImageActive: !_.isEqual(thumbnailImageUrlInputValue, coverImageUrlInputValue),
            thumbnailImageUrlInputValue,
            coverImageUrlInputValue,
            publishDatetime: moment(_.get(personalityQuiz, 'publishDatetime', moment())),
            categoryId: _.get(personalityQuiz, 'category.id', undefined),
            questions: _.get(personalityQuiz, 'questions', defaultQuestions),
            results: _.get(personalityQuiz, 'results', defaultResults),
          })
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          this.setState({
            isBusy: false,
            apiError: error,
          })
        })

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.personalityQuiz) {
        // フォームの値を初期化する
        this.initializeFormValues(this.props.personalityQuiz)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendPersonalityQuiz)) {
      sendPersonalityQuiz.cancel()
    }
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }

    this.resetDescription()
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }

    this.resetDescription()
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }

    // 現在のフォームデータを初期のフォームデータと比較
    const flattenCurrentValues = flattenObject(currentValues)

    _.each(flattenCurrentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.previousFormValues = currentValues

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * 問題一覧のフォームの値が妥当なときに呼び出されるハンドラ
   */
  handleQuestionFormValid = () => {
    this.setState({ isQuestionFormValid: true })
  }

  /**
   * 問題一覧のフォームの値が無効のときに呼び出されるハンドラ
   */
  handleQuestionFormInvalid = () => {
    this.setState({ isQuestionFormValid: false })
  }

  /**
   * 結果一覧のフォームの値が妥当なときに呼び出されるハンドラ
   */
  handleResultFormValid = () => {
    this.setState({ isResultFormValid: true })
  }

  /**
   * 結果一覧のフォームの値が無効のときに呼び出されるハンドラ
   */
  handleResultFormInvalid = () => {
    this.setState({ isResultFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })
    const formData = { ...submitFormData, description: this.state.description }

    sendPersonalityQuiz = CancelablePromise(this.sendData(this.getRequestParameters(formData)))
    sendPersonalityQuiz.promise
      .then(response => {
        const personalityQuiz = response.data

        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.initializeFormValues(personalityQuiz)

        resetForm(this.initialFormValues)

        this.setState(
          {
            isBusy: false,
            isFormModified: false,
          },
          () => {
            if (_.isEmpty(this.props.personalityQuiz) && this.props.onClose) {
              this.props.onClose()
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('handle form valid submit post personality quiz error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * 公開日時の値を操作したときのイベントハンドラ
   */
  handleDatePickerEvent = (event, picker) => {
    if (event.type === 'apply') {
      this.setState({
        isPublishDatetimeModified: true,
        publishDatetime: picker.startDate,
      })
    }
  }

  /**
   * サムネイル画像を変更したときのハンドラ
   */
  handleThumbnailImageInputChange = (event, { mediumUrl, error }) => {
    this.setState({
      thumbnailImageUrlInputValue: mediumUrl,
      thumbnailImageError: error,
    })
  }

  /**
   * カバー画像を変更したときのハンドラ
   */
  handleCoverImageInputChange = (event, { mediumUrl, error }) => {
    this.setState({
      coverImageUrlInputValue: mediumUrl,
      coverImageError: error,
    })
  }

  /**
   * カバー画像を別のものにするトグル操作のハンドラ
   */
  handleCoverImageToggleChange = (event, { checked }) => {
    this.setState({ isCoverImageActive: checked })
  }

  /**
   * 結果一覧の削除ボタンを押したときのハンドラ
   */
  handlePersonalityQuizResultListResultRemove = key => {
    // 対象の key を除外して結果を取得
    const results = this.state.results.filter((result, resultKey) => resultKey !== key)

    this.setState({ results })
  }

  /**
   * 結果一覧の項目を追加するボタンを押したときのハンドラ
   */
  handlePersonalityQuizResultListResultAdd = () => {
    // 結果に新しい項目を追加
    const results = this.state.results.concat({})

    this.setState({ results })
  }

  /**
   * ファイルから入稿ボタンを押したときのハンドラ
   */
  handleSelectFileButtonClick = event => {
    event.preventDefault()

    this.refs.tsvInput.click()
  }

  /**
   * ファイルを選択したときのハンドラ
   */
  handleSelectFileChange = event => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onload = () => {
        const binaryString = []
        for (let i = 0; i < reader.result.length; i += 1) {
          binaryString.push(reader.result.charCodeAt(i))
        }

        const utf8Array = encoding.convert(binaryString, 'UNICODE')
        const result = encoding.codeToString(utf8Array)

        this.convertToPersonalityQuizData(result, '\t')
      }

      reader.readAsBinaryString(file)
    }
  }

  /**
   * カテゴリ選択項目の値を変更したときのハンドラ
   */
  handleCategoriesDropdownChange = (event, { value }) => {
    this.setState({ categoryId: value })
  }

  /**
   * 結果一覧のフォーム内容に変更があったときのハンドラ
   */
  handlePersonalityQuizResultListFormValueChanged = resultsData => {
    const { index, name, value } = resultsData
    const results = this.state.results.slice()

    // 更新のあった結果項目の値を更新
    _.set(results[index], name, value)

    this.setState({
      isResultFormModified: !_.isEqual(_.get(this.initialFormValues, `results[${index}].${name}`), value),
    })
  }

  /**
   * 質問・回答のフォーム内容に変更があったときのハンドラ
   */
  handlePersonalityQuizQuestionListFormValueChanged = questionData => {
    const { index, answerIndex, name, value } = questionData
    const questions = this.state.questions.slice()
    const partialState = {}

    if (answerIndex < 0) {
      // 質問の値を更新
      _.set(questions[index], name, value)
      partialState.isQuestionFormModified = !_.isEqual(
        _.get(this.initialFormValues, `questions[${index}].${name}`),
        value,
      )
    } else {
      // 更新のあった回答項目の値を更新
      _.set(questions[index].answers[answerIndex], name, value)
      partialState.isQuestionFormModified = !_.isEqual(
        _.get(this.initialFormValues, `questions[${index}].answers[${answerIndex}].${name}`),
        value,
      )
    }

    this.setState(partialState)
  }

  /**
   * Handler to be called when the description is changed
   */
  handleDescriptionChange = e => {
    const description = e.target.value
    let isDescriptionModified = false

    if (!_.isEqual(this.initialFormValues.description, description)) {
      isDescriptionModified = true
    }

    this.setState({ description, isDescriptionValid: !_.isEmpty(description), isDescriptionModified })
  }

  /**
   * reset state description
   */
  resetDescription = () => {
    this.setState({ description: '', isDescriptionValid: true, isDescriptionModified: false })
  }

  /**
   * API にデータを送信
   */
  sendData = personalityQuiz => {
    if (_.isEmpty(this.props.personalityQuiz)) {
      return personalityQuizApi.postPersonalityQuiz(personalityQuiz)
    }

    const personalityQuizId = this.props.personalityQuiz.id

    return personalityQuizApi.patchPersonalityQuiz(personalityQuizId, {
      personalityQuizUpdateValues: personalityQuiz,
    })
  }

  testt = []
  /**
   * フォームの初期化
   * @param {Object} personalityQuiz - 診断データ
   */
  initializeFormValues(personalityQuiz) {
    const description = personalityQuiz.description

    this.initialFormValues.title = personalityQuiz.title
    this.initialFormValues.description = description
    this.initialFormValues.categoryId = _.get(personalityQuiz, 'category.id', undefined)
    this.initialFormValues.publishDatetime = moment(personalityQuiz.publishDatetime)
    this.initialFormValues['thumbnail.image.url'] = _.get(personalityQuiz, 'thumbnail.image.url', '')
    this.initialFormValues['thumbnail.image.copyright.url'] = _.get(personalityQuiz, 'thumbnail.image.copyright.url')
    this.initialFormValues['thumbnail.image.copyright.title'] = _.get(
      personalityQuiz,
      'thumbnail.image.copyright.title',
    )
    this.initialFormValues['cover.image.url'] = _.get(personalityQuiz, 'cover.image.url', '')
    this.initialFormValues['cover.image.copyright.url'] = _.get(personalityQuiz, 'cover.image.copyright.url')
    this.initialFormValues['cover.image.copyright.title'] = _.get(personalityQuiz, 'cover.image.copyright.title')
    // 結果一覧
    this.initialFormValues.results = _.cloneDeep(personalityQuiz.results)
    // 質問一覧
    this.initialFormValues.questions = _.cloneDeep(personalityQuiz.questions)

    this.setState({ description })

    logger.debug('initialize form values', this.initialFormValues)
  }

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 結果一覧を送信パラメータとして設定
    submitFormData.results = this.state.results
    // 質問一覧を送信パラメータとして設定
    submitFormData.questions = this.state.questions

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}
    initialData.title = initialFormValues.title
    initialData.description = initialFormValues.description
    initialData.publishDatetime = initialFormValues.publishDatetime
    initialData.categoryId = initialFormValues.categoryId

    // フォームに入っている値がドット記法のためオブジェクトに変換
    // 画像 url のみ default 値として state として空文字を設定しているため default 値として '' を指定
    _.set(initialData, 'thumbnail.image.url', _.get(initialFormValues, 'thumbnail.image.url', ''))
    _.set(initialData, 'thumbnail.image.copyright.title', _.get(initialFormValues, 'thumbnail.image.copyright.title'))
    _.set(initialData, 'thumbnail.image.copyright.url', _.get(initialFormValues, 'thumbnail.image.copyright.url'))
    _.set(initialData, 'cover.image.url', _.get(initialFormValues, 'cover.image.url', ''))
    _.set(initialData, 'cover.image.copyright.title', _.get(initialFormValues, 'cover.image.copyright.title'))
    _.set(initialData, 'cover.image.copyright.url', _.get(initialFormValues, 'cover.image.copyright.url'))

    initialData.questions = initialFormValues.questions
    initialData.results = initialFormValues.results

    if (_.isEmpty(initialData.questions)) {
      // 新規登録の場合、選択肢の順番をランダムにする
      if (_.has(submitFormData, 'questions')) {
        _.each(submitFormData.questions, questions => {
          if (_.has(questions, 'answers')) {
            questions.answers = _.shuffle(questions.answers)
          }
        })
      }
    }

    //  カバー画像を設定しない場合はサムネイル画像と同じものを送る
    if (!this.state.isCoverImageActive) {
      submitFormData.cover.image = submitFormData.thumbnail.image
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    if (_.has(requestParameters, 'results')) {
      // DB のデータが JSON 形式のため、一つでも結果に変更があった場合は全ての結果データを送信
      requestParameters.results = submitFormData.results
      // 結果一覧のダイナミックリンクは送らない
      _.each(_.get(requestParameters, 'results', []), result => _.unset(result, 'dynamicLink'))
    }

    if (_.has(requestParameters, 'questions')) {
      // DB のデータが JSON 形式のため、一つでも質問に変更があった場合は全ての質問データを送信
      requestParameters.questions = submitFormData.questions
    }

    // 公開日付
    const publishDatetime = this.state.publishDatetime
    if (!publishDatetime.isSame(moment(initialFormValues.publishDatetime))) {
      // 日付に変更があった場合
      requestParameters.publishDatetime = publishDatetime.toISOString()
    }

    // サムネイル画像の出典元に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'thumbnail.image.copyright.title'), '')) {
      _.set(requestParameters, 'thumbnail.image.copyright.title', ' ')
    }

    // サムネイル画像の出典元の URL に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'thumbnail.image.copyright.url'), '')) {
      _.set(requestParameters, 'thumbnail.image.copyright.url', ' ')
    }

    // カバー画像の出典元に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.title'), '')) {
      _.set(requestParameters, 'cover.image.copyright.title', ' ')
    }

    // カバー画像の出典元の URL に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.url'), '')) {
      _.set(requestParameters, 'cover.image.copyright.url', ' ')
    }

    // タイトル (FIXME: ファイルから入力の場合に差分なしとなるため、ひとまず変更がなくても送信)
    requestParameters.title = submitFormData.title
    // 説明 (FIXME: ファイルから入力の場合に差分なしとなるため、ひとまず変更がなくても送信)
    requestParameters.description = submitFormData.description

    logger.debug(
      'get request parameters',
      { requestParameters, initialData, submitFormData, initialFormValues },
      this.state.results,
    )

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  // 診断詳細の取得
  getPersonalityQuizDetail = personalityQuiz =>
    new Promise((resolve, reject) => {
      if (_.isEmpty(personalityQuiz)) {
        // 新規の場合は詳細取得しない
        resolve(personalityQuiz)

        return
      }

      const personalityQuizId = personalityQuiz.id

      personalityQuizApi
        .getPersonalityQuiz(personalityQuizId)
        .then(response => {
          // 詳細データを渡す
          resolve(response.data)
        })
        .catch(error => {
          logger.error(`get personality quiz id #${personalityQuizId} error`, error)

          reject(error)
        })
    })

  /**
   * .tsv をパースして画面に反映する
   * @param {string} tsvText - テキストファイル
   * @param {string} splitWord - スプリット対象にする文字
   */
  convertToPersonalityQuizData(tsvText, splitWord) {
    const lines = tsvText.split('\n')

    // タイトル
    let title = ''
    const titleRegExpKey = /【タイトル/
    // 説明文
    let description = ''
    const descriptionRegExpKey = /【説明文/

    // 各回答の点数を入れる配列
    const splitPointText = '点'
    let questionPoints = []
    const questionLines = []
    const questionPointRegExpKey = /質問文/
    const questionRegExpKey = /問\d/

    // 結果一覧で使用する
    const resultLines = {}
    const resultRegExpKey = /回答\d+/
    let isResultLine = false
    let resultNumber = 0

    // ファイルから必要な部分を抜き出す
    _.each(lines, line => {
      // タイトルを抜き出す
      if (titleRegExpKey.test(line)) {
        title = line.split(splitWord)[1]
      }

      // 説明を抜き出す
      if (descriptionRegExpKey.test(line)) {
        description = line.split(splitWord)[1]
      }

      // 各回答に対する点数の配列を抜き出す
      if (questionPointRegExpKey.test(line)) {
        const questionPointsWords = line.split(splitWord)
        // 空文字に関しては除外
        _.remove(questionPointsWords, word => _.isEmpty(word))
        questionPoints = questionPointsWords
      }

      // 各問題と回答を配列で抜き出す箇所
      if (questionRegExpKey.test(line)) {
        const questionWords = line.split(splitWord)
        // 空文字 と 問n に関しては除外
        _.remove(questionWords, word => questionRegExpKey.test(word) || _.isEmpty(word))
        questionLines.push(questionWords)
      }

      // 結果の行を抜き出す箇所
      if (resultRegExpKey.test(line)) {
        isResultLine = true
        resultNumber += 1
        resultLines[resultNumber] = line
      }

      // 結果の行を文字列結合する
      if (isResultLine) {
        const txt = _.isEmpty(line) ? '\n\n' : line
        resultLines[resultNumber] = resultLines[resultNumber].concat(txt)
      }
    })

    // 質問・回答一覧の表示に必要なオブジェクトの生成を行う
    const questions = []
    _.each(questionLines, questionLine => {
      const question = {
        question: '',
        answers: [],
      }
      _.each(questionLine, (questionWord, index) => {
        // 最初の配列に入っているものは問題文
        if (index === 0) {
          question.question = questionWord
          return
        }
        // 1番目以降から回答文
        const answer = {
          answer: questionWord,
          point: _.parseInt(questionPoints[index].split(splitPointText)[0]),
        }
        question.answers.push(answer)
      })

      questions.push(question)
    })

    // 結果一覧の表示に必要なオブジェクトの生成を行う
    const results = []
    _.each(resultLines, resultLine => {
      const resultWords = resultLine.split(splitWord)

      // ポイントだけ取得
      const pointRegExp = /(\d+)Pt\?(\d+)Pt/
      const points = resultWords[2].match(pointRegExp)
      const endPoint = points[2]

      const result = {
        resultPoint: parseInt(endPoint, 10),
        summary: resultWords[3],
        description: resultWords[4].replace(/^"|"$/g, ''), // 前後のダブルクオテーションを削除
      }

      results.push(result)
    })

    // タイトルと、概要をフォームに設定
    this.initializeFormValues({
      title,
      description,
    })
    this.refs.form.reset(this.initialFormValues)

    // 質問と結果一覧を設定
    this.setState({
      questions,
      results,
    })
  }

  render() {
    const publishDatetimeLabel = this.state.publishDatetime.format(PUBLISH_DATETIME_LABEL_FORMAT)
    // 更新 or 作成のボタンの状態
    const disabled =
      !this.state.isFormValid ||
      !this.state.isQuestionFormValid ||
      !this.state.isResultFormValid ||
      !this.state.isDescriptionValid ||
      (!this.state.isFormModified &&
        !this.state.isPublishDatetimeModified &&
        !this.state.isResultFormModified &&
        !this.state.isQuestionFormModified &&
        !this.state.isDescriptionModified)

    return (
      <Modal
        className="PersonalityQuizEditModal"
        size="fullscreen"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Grid columns="equal" verticalAlign="middle">
            <Grid.Column floated="left">
              {_.isEmpty(this.props.personalityQuiz) ? '診断の作成' : '診断の編集'}
            </Grid.Column>

            <Grid.Column floated="right">
              <Button
                primary
                content="ファイルから入稿"
                icon="file excel outline"
                labelPosition="right"
                floated="right"
                style={{ marginRight: '23px' }} // Modal の Close ボタン分
                onClick={this.handleSelectFileButtonClick}
              />

              <input
                className="isHidden"
                type="file"
                name="tsvFile"
                ref="tsvInput"
                accept=".tsv"
                onChange={this.handleSelectFileChange}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            <Grid columns="2" doubling>
              <Grid.Column width={10}>
                {/* 診断タイトル入力フィールド */}
                <Form.Input name="title" label="タイトル" placeholder="診断タイトルを入力してください" required />

                {/* 診断説明入力フィールド */}
                <Form.Field
                  name="description"
                  label="説明"
                  placeholder="概要を入力してください"
                  minRows={10}
                  maxRows={1000}
                  rows={1000}
                  required
                  control={TextareaAutosize}
                  onChange={this.handleDescriptionChange}
                  value={this.state.description}
                  error={!this.state.isDescriptionValid}
                />

                {/* カテゴリ入力フィールド */}
                <Form.Field required>
                  <label>カテゴリ</label>

                  <CategoriesDropdown
                    required
                    categoryId={this.state.categoryId}
                    onChange={this.handleCategoriesDropdownChange}
                  />

                  <Form.Input className="isHidden" value={this.state.categoryId} name="categoryId" required />
                </Form.Field>

                {/* 公開日時フィールド TODO: デザインに関してはお知らせ画面と一緒に修正( issue 作成済) */}
                <Form.Field required>
                  <label>公開日時</label>

                  <DateRangePicker
                    containerStyles={{ display: 'block' }}
                    singleDatePicker
                    timePicker
                    timePicker24Hour
                    drops="up"
                    locale={{
                      applyLabel: '確定',
                      cancelLabel: 'キャンセル',
                    }}
                    startDate={this.state.publishDatetime}
                    onEvent={this.handleDatePickerEvent}
                  >
                    <Input
                      name="publishDatetime"
                      icon="calendar"
                      iconPosition="left"
                      placeholder="公開日時を指定"
                      value={publishDatetimeLabel}
                    />
                  </DateRangePicker>

                  <Form.Input required className="isHidden" name="publishDatetime" readOnly />
                </Form.Field>
              </Grid.Column>

              {/* サムネイル画像入力フィールド */}
              <Grid.Column width={6}>
                <Segment.Group>
                  <Segment>
                    <Label attached="top" color="blue" content="サムネイル" />

                    <Form.Field required>
                      <label>画像</label>

                      <MediumInput
                        onChange={this.handleThumbnailImageInputChange}
                        mediumUrl={this.state.thumbnailImageUrlInputValue}
                      />

                      <Form.Input
                        className="isHidden"
                        name="thumbnail.image.url"
                        placeholder="サムネイル画像を選択してください"
                        required
                        readOnly
                        value={this.state.thumbnailImageUrlInputValue}
                      />
                    </Form.Field>

                    <Form.Input
                      label="出典元"
                      placeholder="出典元を入力してください"
                      name="thumbnail.image.copyright.title"
                    />

                    <Form.Input
                      label="出典元の URL"
                      placeholder="出典元の URL を入力してください"
                      name="thumbnail.image.copyright.url"
                      validations="isUrl"
                      validationErrors={{ isUrl: '無効な URL です' }}
                      errorLabel={<FormErrorLabel />}
                    />
                  </Segment>

                  {/* カバー画像を別の画像に設定する場合のトグル */}
                  <Segment>
                    <Checkbox
                      toggle
                      label="カバー画像を別の画像に設定"
                      checked={this.state.isCoverImageActive}
                      onChange={this.handleCoverImageToggleChange}
                    />
                  </Segment>

                  {/* カバー画像 */}
                  <Segment
                    className={classNames({
                      isHidden: !this.state.isCoverImageActive,
                    })}
                  >
                    <Label attached="top" color="blue" content="カバー" />

                    <Form.Field required={this.state.isCoverImageActive}>
                      <label>画像</label>

                      <MediumInput
                        mediumUrl={this.state.coverImageUrlInputValue}
                        onChange={this.handleCoverImageInputChange}
                      />

                      <Form.Input
                        className="isHidden"
                        name="cover.image.url"
                        placeholder="カバー画像を選択してください"
                        required={this.state.isCoverImageActive}
                        readOnly
                        value={this.state.coverImageUrlInputValue}
                      />
                    </Form.Field>

                    <Form.Input
                      label="出典元"
                      placeholder="出典元を入力してください"
                      name="cover.image.copyright.title"
                    />

                    <Form.Input
                      label="出典元の URL"
                      placeholder="出典元の URL を入力してください"
                      name="cover.image.copyright.url"
                      validations="isUrl"
                      validationErrors={{ isUrl: '無効な URL です' }}
                      errorLabel={<FormErrorLabel />}
                    />
                  </Segment>
                </Segment.Group>
              </Grid.Column>
            </Grid>
          </Form>

          <Divider />

          {/* 質問・回答一覧 */}
          <PersonalityQuizQuestionList
            questions={this.state.questions}
            onFormValueChanged={this.handlePersonalityQuizQuestionListFormValueChanged}
            onValid={this.handleQuestionFormValid}
            onInvalid={this.handleQuestionFormInvalid}
          />

          <Divider />

          {/* 結果一覧画面 */}
          <PersonalityQuizResultList
            results={this.state.results}
            onFormValueChanged={this.handlePersonalityQuizResultListFormValueChanged}
            onResultAdd={this.handlePersonalityQuizResultListResultAdd}
            onResultRemove={this.handlePersonalityQuizResultListResultRemove}
            onValid={this.handleResultFormValid}
            onInvalid={this.handleResultFormInvalid}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.props.personalityQuiz) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={disabled}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

PersonalityQuizEditModal.protoTypes = propTypes
PersonalityQuizEditModal.defaultProps = defaultProps

export default PersonalityQuizEditModal
