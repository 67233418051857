import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react'
import { NotificationApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('NotificationRemoveModal')
const notificationApi = new NotificationApi()
let deleteNotification

const propTypes = {
  /**
   * 削除対象のお知らせ
   */
  notification: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新に成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  open: false,
}

class NotificationRemoveModal extends Component {
  state = {
    isBusy: false,
    apiError: null,
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(deleteNotification)) {
      deleteNotification.cancel()
    }
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 削除ボタンを押したときのハンドラ
   */
  handleRemoveButtonClick = () => {
    const notificationId = this.props.notification.id

    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteNotification = CancelablePromise(notificationApi.deleteNotification(notificationId))
    deleteNotification.promise
      .then(() => {
        // 削除に成功したら一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.setState(
          {
            isBusy: false,
          },
          () => {
            if (this.props.onClose) {
              this.props.onClose()
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`remove notification id #${notificationId} error`, error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <Modal
        className="NotificationRemoveModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>お知らせの削除</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <p>お知らせをゴミ箱へ移動しますか？</p>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button negative content="ゴミ箱へ移動" onClick={this.handleRemoveButtonClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

NotificationRemoveModal.propTypes = propTypes
NotificationRemoveModal.defaultProps = defaultProps

export default NotificationRemoveModal
