import React from 'react'
import Panels from './Panels'
import { PanelApi } from 'trill-api-admin-client'
import _ from 'lodash'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('PanelsContainer')
const panelApi = new PanelApi()
let getPanels

export default class PanelsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      panels: null,
      permission: GetPermission('panel'),
    }

    this.retrivePanels = this.retrivePanels.bind(this)
  }

  async componentDidMount() {
    this.retrivePanels()
  }

  componentWillUnmount() {
    if (!_.isNil(getPanels)) {
      getPanels.cancel()
    }
  }

  async retrivePanels() {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    try {
      getPanels = CancelablePromise(panelApi.getPanels())

      const panelsResponse = await getPanels.promise
      logger.debug(`retrieve panels data`, { panelsResponse })

      const panels = panelsResponse.data

      this.setState({
        isBusy: false,
        panels,
      })
    } catch (errors) {
      if (errors.isCanceled) {
        return
      }

      logger.error(`retrieve panels error`, errors)

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  render() {
    return <Panels {...this.state} retrivePanels={this.retrivePanels} />
  }
}
