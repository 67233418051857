import React from 'react'
import { FormattedDate, FormattedTime, FormattedNumber } from 'react-intl'
import PropTypes from 'prop-types'
import { Popup, Label, Icon, Image, List } from 'semantic-ui-react'
import _ from 'lodash'
import moment from 'moment'

import DataTable from './DataTable'

const propTypes = {
  /**
   * 記事一覧
   */
  articles: PropTypes.array,

  /**
   * 並び替え
   */
  sort: PropTypes.object,

  /**
   * 選択記事一覧
   */
  selectedItems: PropTypes.object,

  /**
   * 現在表示中のページ
   */
  currentPage: PropTypes.number,

  /**
   * 1 ページあたりの表示件数
   */
  itemsPerPage: PropTypes.number,

  /**
   * 合計ページ数
   */
  totalPages: PropTypes.number,

  /**
   * 追加で表示するカラム
   */
  extColumns: PropTypes.array,

  /**
   * 選択の可否
   */
  selectable: PropTypes.bool,

  /**
   * テーブルのヘッダー or チェックボックスを選択したときに呼び出す外部関数
   */
  onSelectionChange: PropTypes.func,

  /**
   * テーブルのページ情報を変更したときに呼び出す外部関数
   */
  onPageChange: PropTypes.func,
}

const defaultProps = {
  articles: [],
  selectedItems: {},
  selectable: false,
  extColumns: [],
}

/**
 * 記事一覧テーブルを表示するコンポーネント
 */
const ArticleDataTable = props => {
  const columns = _.concat(
    [
      {
        label: 'ID',
        align: 'center',
        field: 'id',
      },
      {
        label: 'ステータス',
        align: 'center',
        render: item => {
          let color
          let status
          if (item.deletedAt) {
            color = 'grey'
            status = 'ゴミ箱'
          } else {
            switch (item.status) {
              case 'publish':
                color = 'green'
                status = '公開'
                break
              case 'pending':
                color = 'red'
                status = '保留'
                break
              case 'draft':
                color = 'yellow'
                status = '下書き'
                break
              default:
                color = 'grey'
                status = '不明'
            }
          }
          return <Label color={color}>{status}</Label>
        },
      },
      {
        label: 'サムネイル',
        align: 'center',
        render: item => {
          const src = _.get(item, 'thumbnail.image.url', '')
          const isReserved = moment(item.publishDatetime).isAfter(moment())
          const reservedIcon = 'checked calendar'
          // 予約記事の場合はラベルを表示する
          return (
            <div>
              {isReserved && (
                <Popup trigger={<Label corner icon={reservedIcon} />} content="予約中の記事です" inverted />
              )}

              <Image src={src} shape="rounded" size="tiny" centered />
            </div>
          )
        },
      },
      {
        label: 'タイトル',
        field: 'title',
        collapsing: false,
        cellClassName: 'DataTable__TitleCell',
      },
      {
        label: 'カテゴリ',
        field: 'categoryId',
        collapsing: false,
        cellClassName: 'DataTable__CategoryCell',
        render: item =>
          item.category && (
            <span>
              <Icon name="folder open" />
              {_.get(item, 'category.name', '未分類')}
            </span>
          ),
      },
      {
        label: '管理用カテゴリ',
        field: 'managementCategoryId',
        collapsing: false,
        cellClassName: 'DataTable__ManagementCategoryCell',
        render: item =>
          item.managementCategory && (
            <span>
              <Icon name="folder open" />
              {_.get(item, 'managementCategory.name', '未分類')}
            </span>
          ),
      },
      {
        label: 'メディア',
        collapsing: false,
        cellClassName: 'DataTable__MediaCell',
        render: item =>
          item.medium && (
            <span>
              <Icon name="rss" />
              {_.get(item, 'medium.name', '')}
            </span>
          ),
      },
      {
        label: '公開設定',
        align: 'center',
        render: item => (_.get(item, 'mediumItem.autoPublish', false) ? 'オート' : 'マニュアル'),
      },
      {
        label: 'Shannon',
        align: 'center',
        render: item => {
          const isShannonSubmissionAllowed = _.get(item, 'mediumItem.shannonSubmissionAllowed', false)

          return isShannonSubmissionAllowed ? (
            <Label style={{ backgroundColor: '#4521ba', borderColor: '#4521ba', color: '#fff' }}>連携</Label>
          ) : (
            <Label color="grey">非連携</Label>
          )
        },
      },
      {
        label: '登録日時',
        collapsing: false,
        cellClassName: 'DataTable__TimeKindCell',
        field: 'createdAt',
        render: item =>
          !_.isEmpty(item.createdAt) && (
            <span>
              <FormattedDate value={item.createdAt} day="numeric" month="long" year="numeric" weekday="narrow" />
              <br />
              <FormattedTime value={item.createdAt} hour="numeric" minute="numeric" second="numeric" />
            </span>
          ),
      },
      {
        label: '公開日時',
        collapsing: false,
        cellClassName: 'DataTable__TimeKindCell',
        field: 'publishDatetime',
        defaultSortOrder: 'desc',
        render: item => {
          const publishDatetime = _.isEmpty(item.publishDatetime) ? (
            '未設定'
          ) : (
            <span>
              <FormattedDate value={item.publishDatetime} day="numeric" month="long" year="numeric" weekday="narrow" />
              <br />
              <FormattedTime value={item.publishDatetime} hour="numeric" minute="numeric" second="numeric" />
            </span>
          )

          return publishDatetime
        },
      },
      {
        label: '更新日時',
        collapsing: false,
        cellClassName: 'DataTable__TimeKindCell',
        field: 'updatedAt',
        render: item =>
          !_.isEmpty(item.updatedAt) && (
            <span>
              <FormattedDate value={item.updatedAt} day="numeric" month="long" year="numeric" weekday="narrow" />
              <br />
              <FormattedTime value={item.updatedAt} hour="numeric" minute="numeric" second="numeric" />
            </span>
          ),
      },
    ],
    props.extColumns,
  )

  return (
    <DataTable
      items={props.articles}
      itemsPerPageOptions={props.itemsPerPageOptions}
      isTopFooter={props.isTopFooter}
      sort={props.sort}
      selectable={props.selectable}
      selectedItems={props.selectedItems}
      cellStyle={{ position: 'relative' }}
      columns={columns}
      rowKey="id"
      rowStyle={{
        height: '103px',
      }}
      rowStates={item => ({
        negative: _.isEqual(item.status, 'pending'),
        warning: _.isEqual(item.status, 'draft'),
        active: !!item.deletedAt,
      })}
      currentPage={props.currentPage}
      itemsPerPage={props.itemsPerPage}
      totalPages={props.totalPages}
      onSelectionChange={props.onSelectionChange}
      onPageChange={props.onPageChange}
    />
  )
}

ArticleDataTable.propTypes = propTypes
ArticleDataTable.defaultProps = defaultProps

export default ArticleDataTable
