import React, { Component } from 'react'
import _ from 'lodash'
import { Button, Menu } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import DataTable from './DataTable'
import MediumRelatedArticleEditModal from './MediumRelatedArticleEditModal'

const propTypes = {
  /**
   * メディア関連記事記事
   */
  articles: PropTypes.array,

  /**
   * メディア関連記事更時時に呼び出す外部関数
   *
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {Array<Object>} data.articles - メディア関連記事データ
   */
  onChange: PropTypes.func,
}

function createArticlesWithKey(articles) {
  const formattedArticles = _.cloneDeep(articles)
  _.each(formattedArticles, (item, index) => {
    item.key = index
  })

  return formattedArticles
}

function createArticlesWithoutKey(articles) {
  const formattedArticles = _.cloneDeep(articles)
  _.each(formattedArticles, item => {
    delete item.key
  })

  return formattedArticles
}

const defaultProps = {
  articles: [],
  open: false,
}

class MediumRelatedArticles extends Component {
  state = {
    articles: [],
    editArticle: {},
    isMediumRelatedArticleEditModalOpen: false,
  }

  constructor(props) {
    super(props)
    this.articles = createArticlesWithKey(props.articles)
  }

  componentDidMount() {
    this.setState({
      articles: this.articles,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.articles, this.props.articles)) {
      this.articles = createArticlesWithKey(nextProps.articles)
      this.setState({
        articles: this.articles,
      })
    }
  }

  handleCreateButtonClick = event => {
    event.preventDefault()
    this.setState({ isEditModalOpen: true, editArticle: {} })
  }

  handleEditModalClose = () => {
    this.setState({ isEditModalOpen: false, editArticle: null })
  }

  handleEditModalChange = ({ article }) => {
    const editedArticles = _.cloneDeep(this.articles)
    let isNewArticle = true

    _.each(editedArticles, (item, key) => {
      if (_.isEqual(item.key, article.key)) {
        editedArticles[key] = article
        isNewArticle = false
      }
    })

    if (isNewArticle || _.isEmpty(editedArticles)) {
      article.key = editedArticles.length
      editedArticles.push(article)
    }

    if (!_.isEqual(this.props.articles, editedArticles)) {
      this.setState({ articles: editedArticles }, () => {
        this.articles = editedArticles
        _.invoke(this.props, 'onChange', {
          ...this.props,
          articles: createArticlesWithoutKey(this.articles),
        })
        this.setState({ isEditModalOpen: false })
      })
    }
  }

  removeArticle(article) {
    const editedArticles = _.cloneDeep(this.articles)

    if (!_.isEqual(this.props.articles, editedArticles)) {
      _.remove(editedArticles, item => _.isEqual(item, article))

      this.setState({ articles: editedArticles }, () => {
        this.articles = editedArticles
        _.invoke(this.props, 'onChange', {
          ...this.props,
          articles: createArticlesWithoutKey(this.articles),
        })
        this.setState({ isEditModalOpen: false })
      })
    }
  }

  openEditModal(article) {
    this.setState({ isEditModalOpen: true, editArticle: article })
  }

  render() {
    return (
      <div className="MediumRelatedArticles">
        {!_.isEmpty(this.state.articles) && (
          <DataTable
            items={this.state.articles}
            columns={[
              {
                label: 'タイトル',
                field: 'title',
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group key={item.key} secondary>
                    <Button
                      icon="edit"
                      onClick={event => {
                        event.preventDefault()
                        this.openEditModal(item)
                      }}
                    />

                    <Button
                      icon="trash alternate outline"
                      onClick={event => {
                        event.preventDefault()
                        this.removeArticle(item)
                      }}
                    />
                  </Button.Group>
                ),
              },
            ]}
            compact
            rowKey="key"
          />
        )}

        {/* 新規作成ボタン */}
        <Menu secondary>
          <Menu.Item fitted>
            <Button
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.handleCreateButtonClick}
              disabled={_.size(this.state.articles) > 2}
            />
          </Menu.Item>
        </Menu>
        {/* カテゴリ作成・編集モーダル */}
        <MediumRelatedArticleEditModal
          article={this.state.editArticle}
          open={this.state.isEditModalOpen}
          onChange={this.handleEditModalChange}
          onClose={this.handleEditModalClose}
        />
      </div>
    )
  }
}

MediumRelatedArticles.propTypes = propTypes
MediumRelatedArticles.defaultProps = defaultProps

export default MediumRelatedArticles
