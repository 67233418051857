import React, { Component } from 'react'
import { Modal, Button, Form, Dimmer, Loader } from 'semantic-ui-react'
import LogLevel from '../LogLevel'

import _ from 'lodash'
import { CouponCompanyUpdateValues, CouponContact } from 'trill-api-admin-client'
import ApiErrorMessage from './ApiErrorMessage'

const logger = LogLevel.getLogger('CouponCompaniesEditModal')

class CouponCompaniesEditModal extends Component {
  static defaultProps = { permission: { hasUpdatePermission: false } }

  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      open: false,
      apiError: null,
      couponCompanyUpdateValues: this.newCouponCompanyUpdateValues(),
      initialCouponCompanyUpdateValues: null,
    }
  }

  openModal = () => {
    const couponCompanyUpdateValues = this.newCouponCompanyUpdateValues(_.cloneDeep(this.props.couponCompany))
    this.setState({
      open: true,
      couponCompanyUpdateValues,
      initialCouponCompanyUpdateValues: _.cloneDeep(couponCompanyUpdateValues),
    })
  }

  closeModal = () => this.setState({ open: false, apiError: null })

  newCouponCompanyUpdateValues = couponCompany => {
    const couponCompanyUpdateValues = new CouponCompanyUpdateValues()
    const couponContact = new CouponContact()
    couponCompanyUpdateValues.contact = couponContact

    // input value 初期化
    couponCompanyUpdateValues.name = ''
    couponCompanyUpdateValues.displayName = ''
    couponCompanyUpdateValues.contact.tel = ''
    couponCompanyUpdateValues.contact.url = ''

    if (couponCompany) {
      _.merge(couponCompanyUpdateValues, couponCompany)
      // 要らない要素を削る
      _.unset(couponCompanyUpdateValues, ['createdAt'])
      _.unset(couponCompanyUpdateValues, ['deletedAt'])
      _.unset(couponCompanyUpdateValues, ['updatedAt'])
    }

    return couponCompanyUpdateValues
  }

  handleChange = event => {
    const { couponCompanyUpdateValues } = this.state

    const key = event.target.name
    let value = event.target.value
    const type = _.get(event.target, 'type', '')
    if (value.length > 0 && type === 'number') {
      value = _.parseInt(value)
    }

    if (_.has(couponCompanyUpdateValues, key)) {
      _.set(couponCompanyUpdateValues, key, value)
    }

    this.setState({ couponCompanyUpdateValues }, () => {
      logger.debug('state', this.state.couponCompanyUpdateValues)
    })
  }

  getRequestParameters = (updateValues, defaultValues) => {
    // 差分を取得する関数
    logger.debug({ updateValues, defaultValues })
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(updateValues, defaultValues)

    return _.omitBy(
      _.pickBy(requestParameters, (v, p) => _.has(defaultValues, p)),
      v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v) && v !== '',
    )
  }

  handleUpdateButtonClick = async () => {
    const { couponCompanyId } = this.props
    const { couponCompanyUpdateValues, initialCouponCompanyUpdateValues } = this.state

    const requestParameters = this.getRequestParameters(couponCompanyUpdateValues, initialCouponCompanyUpdateValues)

    if (!_.isEmpty(requestParameters) && this.props.patchCouponCompany) {
      this.setState({ isBusy: true })
      await this.props.patchCouponCompany(couponCompanyId, requestParameters)
      this.setState({ isBusy: false, apiError: this.props.apiError })
    }

    if (!this.props.apiError) {
      this.closeModal()
    }
  }

  render() {
    const { open, isBusy, apiError, couponCompanyUpdateValues } = this.state
    const { name, displayName, contact } = couponCompanyUpdateValues
    const { tel, url } = contact
    const isButtonDisabled = !name || !displayName
    const { permission } = this.props
    const { hasUpdatePermission } = permission

    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasUpdatePermission} icon="edit" />}
      >
        <Modal.Header>クーポン提供企業の編集</Modal.Header>
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />

          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>

          <Form>
            <Form.Input required label="企業名" name="name" value={name} onChange={this.handleChange} />
            <Form.Input required label="表示名" name="displayName" value={displayName} onChange={this.handleChange} />
            <Form.Input label="電話番号（ハイフンあり）" name="contact.tel" value={tel} onChange={this.handleChange} />
            <Form.Input label="問い合わせURL" name="contact.url" value={url} onChange={this.handleChange} />{' '}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />

          <Button
            positive
            content="更新"
            type="button"
            disabled={isButtonDisabled}
            onClick={this.handleUpdateButtonClick}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CouponCompaniesEditModal
