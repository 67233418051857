import React, { Component } from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Menu, Icon, Popup, Divider, Button, Header, Dimmer, Loader, Image } from 'semantic-ui-react'
import _ from 'lodash'

import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import PanelCreateModal from '../../components/PanelCreateModal'
import PanelEditModal from '../../components/PanelEditModal'
import PanelDeleteModal from '../../components/PanelDeleteModal'

class Panels extends Component {
  static defaultProps = { permission: { hasUpdatePermission: false } }

  /**
   * テーブルのカラムを取得
   */
  getTableColomns = () => {
    const { permission } = this.props
    const { hasUpdatePermission } = permission
    const columns = [
      {
        label: '枠名',
        field: 'name',
        collapsing: false,
      },
      {
        label: 'バナー',
        align: 'center',
        render: item => {
          return <Image src={_.get(item, 'image', '')} centered size="large" shape="rounded" />
        },
      },
      {
        label: 'リンク先',
        align: 'center',
        render: item =>
          (_.has(item, 'anchorArticleId') && !_.isNil(item.anchorArticleId) && (
            <Popup
              inverted
              wide
              content={`記事ID : ${item.anchorArticleId}`}
              trigger={
                <Button
                  disabled={!hasUpdatePermission}
                  as="a"
                  href={`${process.env.REACT_APP_TRILL_WEB_URL}/articles/${item.anchorArticleId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="file text" />
                  記事
                </Button>
              }
            />
          )) ||
          (_.has(item, 'anchorUrl') && !_.isNil(item.anchorUrl) && (
            <Popup
              inverted
              wide
              content={item.anchorUrl}
              trigger={
                <Button as="a" href={`${item.anchorUrl}`} target="_blank" rel="noopener noreferrer">
                  <Icon name="external alternate" />
                  URL
                </Button>
              }
            />
          )),
      },
      {
        label: '更新日時',
        field: 'updatedAt',
        render: item =>
          !_.isEmpty(item.updatedAt) && (
            <span>
              <FormattedDate value={item.updatedAt} day="numeric" month="long" year="numeric" weekday="narrow" />
              <br />
              <FormattedTime value={item.updatedAt} hour="numeric" minute="numeric" second="numeric" />
            </span>
          ),
      },
      {
        label: '操作',
        align: 'center',
        render: item => (
          <Button.Group secondary>
            <PanelEditModal permission={permission} panel={item} retrivePanels={this.props.retrivePanels} />
            <PanelDeleteModal permission={permission} panel={item} retrivePanels={this.props.retrivePanels} />
          </Button.Group>
        ),
      },
    ]

    return columns
  }

  render() {
    const { permission } = this.props
    return (
      <div className="Panels">
        <Header as="h1">
          <Icon name="image" />

          <Header.Content>誘導バナー</Header.Content>
        </Header>

        {/* ローディング */}
        <Dimmer active={this.props.isBusy} inverted>
          <Loader />
        </Dimmer>

        {/* API エラーメッセージ */}
        <ApiErrorMessage error={this.props.apiError} />

        {/** 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <PanelCreateModal permission={permission} retrivePanels={this.props.retrivePanels} />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {!_.isEmpty(this.props.panels) && (
          <DataTable rowKey="id" items={this.props.panels} columns={this.getTableColomns()} />
        )}
      </div>
    )
  }
}

export default Panels
