import _ from 'lodash'

const AccessControl = (scopesData, scope) => {
  const crudr = ['create', 'read', 'update', 'delete', 'restore']

  const defaultPermission = (() => {
    const obj = {}
    crudr.forEach(item => {
      obj[`has${_.upperFirst(item)}Permission`] = false
    })
    return obj
  })()

  if (_.isNil(scope)) {
    return defaultPermission
  }

  const permission = Object.assign({}, defaultPermission)

  if (!_.isNil(scopesData)) {
    const scopesDataArray = scopesData.split(' ')
    crudr.forEach(item => {
      if (_.includes(scopesDataArray, `${item}:${scope}`)) permission[`has${_.upperFirst(item)}Permission`] = true
    })
  }

  return permission
}

export default AccessControl
