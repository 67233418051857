import React, { Component } from 'react'
import { Modal, Button, Dimmer, Loader, Input } from 'semantic-ui-react'
import { NotificationApi } from 'trill-api-admin-client'
import PropTypes from 'prop-types'
import { Form } from 'formsy-semantic-ui-react'
import TextareaAutosize from 'react-textarea-autosize'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import _ from 'lodash'
import moment from 'moment'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'
import { flattenObject } from '../util'

const logger = LogLevel.getLogger('NotificationEditModal')
const notificationApi = new NotificationApi()
let sendNotification

const propTypes = {
  /**
   * 編集対象のお知らせ (作成の場合は空)
   */
  notification: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  notification: {},
  open: false,
}

class NotificationEditModal extends Component {
  state = {
    isFormValid: false,
    isFormModified: false,
    isPublishDatetimeModified: false,
    isBusy: false,
    publishDatetime: moment(),
    apiError: null,
    description: '',
    isDescriptionValid: true,
    isDescriptionModified: false,
  }

  /**
   * 初期のフォームデータ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されているかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.notification, nextProps.notification)) {
      this.setState({
        publishDatetime: moment(_.get(nextProps, 'notification.publishDatetime', moment())),
        isFormModified: false,
        isPublishDatetimeModified: false,
      })

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.notification) {
        this.initializeFormValues(this.props.notification)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendNotification)) {
      sendNotification.cancel()
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = {
      isFormModified: false,
    }
    const flattenCurrentValues = flattenObject(currentValues)

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(flattenCurrentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })
    const formData = { ...submitFormData, description: this.state.description }

    sendNotification = CancelablePromise(this.sendData(this.getRequestParameters(formData)))
    sendNotification.promise
      .then(response => {
        const notification = response.data

        // 更新 or 作成に成功したらお知らせ一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.initializeFormValues(notification)

        resetForm(this.initialFormValues)

        this.setState(
          {
            isFormModified: false,
            isPublishDatetimeModified: false,
            isBusy: false,
          },
          () => {
            if (_.isEmpty(this.props.notification) && this.props.onClose) {
              // 新規作成の場合はモーダルを閉じる
              this.props.onClose()
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('submit notification error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }

    this.resetDescription()
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }

    this.resetDescription()
  }

  /**
   * 公開日時のイベントハンドラ
   */
  handleDatePickerEvent = (event, picker) => {
    if (event.type === 'apply') {
      const publishDatetime = picker.startDate
      const initialPublishDatetime = this.initialFormValues.publishDatetime
      const isPublishDatetimeModified = !moment(initialPublishDatetime).isSame(publishDatetime)

      this.setState({
        publishDatetime,
        isPublishDatetimeModified,
      })
    }
  }

  /**
   * Handler to be called when the description is changed
   */
  handleDescriptionChange = e => {
    const description = e.target.value
    let isDescriptionModified = false

    if (!_.isEqual(this.initialFormValues.description, description)) {
      isDescriptionModified = true
    }

    this.setState({ description, isDescriptionValid: !_.isEmpty(description), isDescriptionModified })
  }

  /**
   * reset state description
   */
  resetDescription = () => {
    this.setState({ description: '', isDescriptionValid: true, isDescriptionModified: false })
  }

  /**
   * フォームの初期化
   * @param {Object} notification - お知らせデータ
   */
  initializeFormValues(notification) {
    const description = notification.description
    this.initialFormValues.title = notification.title
    this.initialFormValues.description = description
    this.initialFormValues.publishDatetime = notification.publishDatetime

    this.initialFormValues['target.ios'] = _.get(notification, 'target.ios', false)
    this.initialFormValues['target.android'] = _.get(notification, 'target.android', false)
    this.initialFormValues['target.web'] = _.get(notification, 'target.web', false)

    this.setState({ description })
  }

  /**
   * API 送信時のリクエストパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}
    initialData.title = initialFormValues.title
    initialData.description = initialFormValues.description

    _.set(initialData, 'target.ios', _.get(initialFormValues, 'target.ios', false))
    _.set(initialData, 'target.android', _.get(initialFormValues, 'target.android', false))
    _.set(initialData, 'target.web', _.get(initialFormValues, 'target.web', false))

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    const publishDatetime = this.state.publishDatetime
    if (!publishDatetime.isSame(moment(this.initialFormValues.publishDatetime))) {
      requestParameters.publishDatetime = publishDatetime.toISOString()
    }

    logger.debug('get request parameters', { requestParameters })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  /**
   * API にデータを送信
   */
  sendData = submitNotificationData => {
    if (_.isEmpty(this.props.notification)) {
      return notificationApi.postNotification(submitNotificationData)
    }

    const notificationId = this.props.notification.id
    const notificationUpdateValues = submitNotificationData

    return notificationApi.patchNotification(notificationId, {
      notificationUpdateValues,
    })
  }

  render() {
    const publishDatetimeLabel = this.state.publishDatetime.format('LLL(dd)')
    const submitDisabled =
      !this.state.isFormValid ||
      !this.state.isDescriptionValid ||
      (!this.state.isFormModified && !this.state.isPublishDatetimeModified && !this.state.isDescriptionModified)

    return (
      <Modal
        className="NotificationEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.props.notification) ? 'お知らせの作成' : 'お知らせの編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/* タイトル入力フィールド */}
            <Form.Input name="title" label="タイトル" placeholder="タイトルを入力してください" required />

            {/* 内容入力フィールド */}
            <Form.Field
              name="description"
              label="内容"
              placeholder="内容を入力してください"
              minRows={10}
              maxRows={1000}
              rows={1000}
              required
              control={TextareaAutosize}
              onChange={this.handleDescriptionChange}
              value={this.state.description}
              error={!this.state.isDescriptionValid}
            />

            {/* OS 種別フィールド */}
            <Form.Field>
              <label>OS 種別</label>

              <Form.Group inline>
                <Form.Checkbox name="target.ios" label="iOS" />

                <Form.Checkbox name="target.android" label="Android" />

                {/* 現状 web でお知らせを出す箇所はないためコメントアウト (https://docs.google.com/spreadsheets/d/1KQZsHSeLgl8aob1pDx5kCT6pNtRg9OnFS6_xeeUNF0A/edit#gid=347281005) */}
                {/* <Form.Checkbox name='target.web' label='Web' /> */}
              </Form.Group>
            </Form.Field>

            {/* 公開日時入力フィールド */}
            <Form.Field required>
              <label>公開日時</label>

              <DateRangePicker
                singleDatePicker
                timePicker
                timePicker24Hour
                drops="up"
                locale={{
                  applyLabel: '確定',
                  cancelLabel: 'キャンセル',
                }}
                startDate={this.state.publishDatetime}
                onEvent={this.handleDatePickerEvent}
              >
                <Input
                  icon="calendar"
                  iconPosition="left"
                  placeholder="公開日時を指定"
                  value={publishDatetimeLabel}
                  readOnly
                  required
                />
              </DateRangePicker>
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.props.notification) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={submitDisabled}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

NotificationEditModal.propTypes = propTypes
NotificationEditModal.defaultProps = defaultProps

export default NotificationEditModal
