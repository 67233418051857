import React, { Component } from 'react'
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react'

import _ from 'lodash'
import ApiErrorMessage from './ApiErrorMessage'

class CouponBrandsUndoModal extends Component {
  static defaultProps = { permission: { hasRestorePermission: false } }

  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      open: false,
      apiError: null,
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false, apiError: null })

  handleUndoButtonClick = async () => {
    const { couponBrandId } = this.props

    if (this.props.putCouponBrand) {
      this.setState({ isBusy: true })
      await this.props.putCouponBrand(couponBrandId)
      this.setState({ isBusy: false, apiError: this.props.apiError })
    }

    if (!this.props.apiError) {
      this.closeModal()
    }
  }

  render() {
    const { open, isBusy, apiError } = this.state
    const { permission } = this.props
    const { hasRestorePermission } = permission

    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasRestorePermission} icon="undo" />}
      >
        {_.has(this.props, 'couponBrand.name') && <Modal.Header>{this.props.couponBrand.name} を戻す</Modal.Header>}
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />
          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>
          {_.has(this.props, 'couponBrand.name') && <div>{this.props.couponBrand.name} を戻しますか？</div>}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />

          <Button positive content="戻す" onClick={this.handleUndoButtonClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CouponBrandsUndoModal
