import React, { Component } from 'react'
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Header,
  Icon,
  Image,
  Loader,
  Menu,
  Modal,
  Statistic,
} from 'semantic-ui-react'
import { Input, Form } from 'formsy-semantic-ui-react'
import { SeriesApi } from 'trill-api-admin-client'
import _ from 'lodash'
import Store from 'store'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import FormErrorLabel from '../../components/FormErrorLabel'
import SeriesForm from '../../components/SeriesForm'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('Series')

const seriesApi = new SeriesApi()

let getSeries
let sendSeries
let deleteSeries
let restoreSeries

const SeriesStatus = {
  ALL: 'all',
  TRASH: 'trash',
}

const inputTextValidateRegex = /^((?!,).)*$/i

class Series extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      status: SeriesStatus.ALL,
      permission: GetPermission('series'),
      seriesList: [],
      isFormSearchValid: false,
      selectedSeries: null,
      isSeriesFormModalOpen: false,
      isSubmitSeriesForm: false,
      isSeriesFormValid: false,
      isSeriesFormModified: false,
      isSeriesDeleteModalOpen: false,
      isSeriesRestoreModalOpen: false,
    }

    _.each(SeriesStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 10,
          sorting: {},
          filtering: {
            key: 'creatorName',
            value: '',
          },
        },
      })
    })
  }

  componentDidMount() {
    const pageState = Store.get('seriesPageState')

    if (pageState) {
      const seriesList = pageState.seriesList
      const status = _.get(pageState, 'status', SeriesStatus.ALL)
      const tableData = _.get(pageState, status, this.state[status])

      this.setState(
        {
          status,
          seriesList,
          [status]: tableData,
        },
        () => {
          this.retrieveSeriesList()
        },
      )
    } else {
      this.retrieveSeriesList()
    }
  }

  componentWillUnmount() {
    if (!_.isNil(getSeries)) {
      getSeries.cancel()
    }

    if (!_.isNil(sendSeries)) {
      sendSeries.cancel()
    }

    if (!_.isNil(deleteSeries)) {
      deleteSeries.cancel()
    }

    if (!_.isNil(restoreSeries)) {
      restoreSeries.cancel()
    }
  }

  /**
   * Handler to be called when the status menu item is clicked
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveSeriesList()
      this.savePageState()
    })
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * Handler to be called when the search input is clicked
   */
  handleSearchInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.value = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * Handler to be called when the search input type is changed
   */
  handleSearchInputTypeDropdownChange = (event, { value }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.key = value
    tableData.filtering.value = ''

    this.setState({ [status]: tableData })
  }

  /**
   * Handler to be called when the search button is clicked
   */
  handleSearchButtonClick = () => {
    if (!this.state.isFormSearchValid) {
      return
    }

    this.retrieveSeriesList()
    this.savePageState()
  }

  /**
   * Handler to be called when the page or item per page of table is changed
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveSeriesList()
      this.savePageState()
    })
  }

  /**
   * Handler to be called when the sort of table is changed
   */
  handleDataTableSortChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveSeriesList()
      this.savePageState()
    })
  }

  /**
   * Handler to be called when the series form modal is opened
   */
  handleSeriesFormModalOpen = selectedSeries => {
    this.setState(
      {
        isSeriesFormModalOpen: true,
        isSubmitSeriesForm: false,
        isSeriesFormValid: false,
        isSeriesFormModified: false,
        selectedSeries,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the series form is changed
   */
  handleSeriesFormChange = (seriesChanged, isModified) => {
    this.setState({
      isSeriesFormModified: isModified,
      isSubmitSeriesForm: false,
    })
  }

  /**
   * Handler to be called when the series form is valid
   */
  handleSeriesFormValid = () => {
    this.setState({
      isSeriesFormValid: true,
    })
  }

  /**
   * Handler to be called when the series form is invalid
   */
  handleSeriesFormInvalid = () => {
    this.setState({
      isSeriesFormValid: false,
    })
  }

  /**
   * Handler to be called when the series form modal is closed
   */
  handleSeriesFormModalClose = () => {
    this.setState(
      {
        isSeriesFormModalOpen: false,
        isSubmitSeriesForm: false,
        isSeriesFormValid: false,
        isSeriesFormModified: false,
        selectedSeries: null,
        isBusy: false,
        apiError: null,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the submit button in series form modal is clicked
   */
  handleSubmitButtonSeriesFormModalClick = () => {
    this.setState({ isSubmitSeriesForm: true })
  }

  /**
   * Handler to be called when the series form is submitted
   */
  handleSeriesFormValidSubmit = series => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendSeries = CancelablePromise(
      _.isEmpty(this.state.selectedSeries)
        ? seriesApi.postSeries(series)
        : seriesApi.patchSeries(this.state.selectedSeries.id, { seriesUpdateValues: series }),
    )
    sendSeries.promise
      .then(() => {
        this.handleSeriesFormModalClose()
        this.retrieveSeriesList()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        const apiError =
          error.status === 422
            ? { header: 'エラーメッセージ', message: '追加する連載の内クリエイター容が不正な' }
            : error

        this.setState({
          isBusy: false,
          apiError,
          isSubmitSeriesForm: false,
        })
      })
  }

  /**
   * Handler to be called when the series delete modal is opened
   */
  handleSeriesDeleteModalOpen = selectedSeries => {
    this.setState(
      {
        isSeriesDeleteModalOpen: true,
        selectedSeries,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the series delete modal is closed
   */
  handleSeriesDeleteModalClose = () => {
    this.setState(
      {
        isSeriesDeleteModalOpen: false,
        selectedSeries: null,
        isBusy: false,
        apiError: null,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the submit button in series delete modal is clicked
   */
  handleSubmitButtonSeriesDeleteModalClick = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteSeries = CancelablePromise(seriesApi.deleteSeries(this.state.selectedSeries.id))
    deleteSeries.promise
      .then(() => {
        this.handleSeriesDeleteModalClose()
        this.retrieveSeriesList()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Handler to be called when the series restore modal is opened
   */
  handleSeriesRestoreModalOpen = selectedSeries => {
    this.setState(
      {
        isSeriesRestoreModalOpen: true,
        selectedSeries,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the series restore modal is closed
   */
  handleSeriesRestoreModalClose = () => {
    this.setState(
      {
        isSeriesRestoreModalOpen: false,
        selectedSeries: null,
        isBusy: false,
        apiError: null,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * Handler to be called when the submit button in creator restore modal is clicked
   */
  handleSubmitButtonSeriesRestoreModalClick = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    restoreSeries = CancelablePromise(seriesApi.putSeries(this.state.selectedSeries.id))
    restoreSeries.promise
      .then(() => {
        this.handleSeriesRestoreModalClose()
        this.retrieveSeriesList()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Call api get series list
   */
  retrieveSeriesList = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getSeries = CancelablePromise(seriesApi.getSeries(this.getRequestQuery()))
    getSeries.promise
      .then(response => {
        const responseHeader = response.header
        const seriesList = response.data

        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        logger.debug('retrieve series list', { seriesList })

        this.setState({
          isBusy: false,
          seriesList,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve series list error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          seriesList: [],
          [status]: tableData,
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Get request query during API communication
   */
  getRequestQuery = () => {
    // Get the state of the displayed table data
    const status = this.state.status
    const tableData = this.state[status]

    // Calculate the total page by dividing the setting tableData.totalItems by the setting tableData.itemsPerPage
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    // Search params
    const filtering = []
    filtering.push(`deletedAt IS${_.isEqual(status, SeriesStatus.TRASH) ? ' NOT ' : ' '}NULL`)

    if (!_.isEmpty(tableData.filtering.value) && tableData.filtering.value.match(inputTextValidateRegex)) {
      const { key, value } = tableData.filtering

      filtering.push(`${key} LIKE "%${value}%"`)
    }

    // Order params
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : '+'

      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // Returns an object except empty and not number
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * Save current page state in store
   */
  savePageState() {
    const seriesList = this.state.seriesList
    const status = this.state.status
    const tableData = this.state[status]
    const pageState = {
      seriesList,
      status,
      [status]: tableData,
    }

    logger.debug('save page state', { pageState })

    Store.set('seriesPageState', pageState)
  }

  /**
   * Get search input placeholder text
   */
  searchInputPlaceholder = key => {
    switch (key) {
      case 'id':
        return 'IDで検索'
      case 'creatorName':
        return 'クリエイター名で絞り込み'
      case 'title':
        return '連載タイトルで検索'
      default:
        return ''
    }
  }

  render() {
    const { status, permission, isSeriesFormModalOpen, isSeriesDeleteModalOpen, isSeriesRestoreModalOpen } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission
    const tableData = this.state[status]
    const hasModalBeShown = isSeriesFormModalOpen || isSeriesDeleteModalOpen || isSeriesRestoreModalOpen

    return (
      <div className="Series">
        <Header as="h1">
          <Icon name="book" />

          <Header.Content>連載</Header.Content>
        </Header>

        <Dimmer active={hasModalBeShown ? false : this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={SeriesStatus.ALL}
            active={_.isEqual(status, SeriesStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={SeriesStatus.TRASH}
            active={_.isEqual(status, SeriesStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={() => {
                this.handleSeriesFormModalOpen()
              }}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="seriesList-search"
                type="text"
                action
                placeholder={this.searchInputPlaceholder(tableData.filtering.key)}
                value={tableData.filtering.value}
                onChange={this.handleSearchInputChange}
                validations={{ matchRegexp: inputTextValidateRegex }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Dropdown
                  onChange={this.handleSearchInputTypeDropdownChange}
                  value={tableData.filtering.key}
                  selection
                  options={[
                    {
                      key: 'id',
                      value: 'id',
                      text: 'ID',
                    },
                    {
                      key: 'creatorName',
                      value: 'creatorName',
                      text: 'クリエイター名',
                    },
                    {
                      key: 'title',
                      value: 'title',
                      text: '連載タイトル',
                    },
                  ]}
                />

                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>
          </Form.Group>
        </Form>

        {(hasModalBeShown || _.isEmpty(this.state.apiError)) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        <ApiErrorMessage error={hasModalBeShown ? null : this.state.apiError} />

        {!_.isEmpty(this.state.seriesList) && (
          <DataTable
            rowKey="id"
            sort={tableData.sorting}
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSortChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            items={this.state.seriesList}
            cellStyle={{ position: 'relative' }}
            columns={[
              {
                label: 'ID',
                align: 'center',
                field: 'id',
                minWidth: '6em',
              },
              {
                label: 'サムネイル',
                align: 'center',
                render: item => {
                  const imageUrl = _.get(item, 'thumbnail.image.url', '')

                  return <Image src={imageUrl} centered size="tiny" shape="rounded" />
                },
              },
              {
                label: ' クリエイター名',
                field: 'creatorName',
                collapsing: false,
                minWidth: '20em',
                render: item => {
                  const creatorName = _.get(item, 'creator.name', '')

                  return creatorName
                },
              },
              {
                label: '連載タイトル',
                field: 'title',
                collapsing: false,
                minWidth: '20em',
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.handleSeriesFormModalOpen(item)
                      }}
                    />

                    {!_.isEqual(status, SeriesStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.handleSeriesDeleteModalOpen(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, SeriesStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.handleSeriesRestoreModalOpen(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {(hasCreatePermission || hasUpdatePermission) && (
          <Modal
            className="Series__FormModal"
            size="fullscreen"
            closeIcon
            open={this.state.isSeriesFormModalOpen}
            onClose={this.handleSeriesFormModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>
              {_.isEmpty(this.state.selectedSeries) ? '連載作品ページの作成' : '連載作品ページの編集'}
            </Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <SeriesForm
                series={this.state.selectedSeries}
                isSubmitForm={this.state.isSubmitSeriesForm}
                onFormChange={this.handleSeriesFormChange}
                onFormValid={this.handleSeriesFormValid}
                onFormInvalid={this.handleSeriesFormInvalid}
                onFormValidSubmit={this.handleSeriesFormValidSubmit}
              />
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleSeriesFormModalClose} />
              <Button
                positive
                content={_.isEmpty(this.state.selectedSeries) ? '保存' : '更新'}
                disabled={!this.state.isSeriesFormValid || !this.state.isSeriesFormModified}
                onClick={this.handleSubmitButtonSeriesFormModalClick}
              />
            </Modal.Actions>
          </Modal>
        )}

        {hasDeletePermission && (
          <Modal
            className="Series__DeleteModal"
            size="small"
            closeIcon
            open={this.state.isSeriesDeleteModalOpen}
            onClose={this.handleSeriesDeleteModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>連載作品ページの削除</Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <p>連載作品ページ {_.get(this.state, 'selectedSeries.title')} をゴミ箱へ移動しますか？</p>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleSeriesDeleteModalClose} />
              <Button negative content="ゴミ箱へ移動" onClick={this.handleSubmitButtonSeriesDeleteModalClick} />
            </Modal.Actions>
          </Modal>
        )}

        {hasRestorePermission && (
          <Modal
            className="Series__RestoreModal"
            size="tiny"
            closeIcon
            open={this.state.isSeriesRestoreModalOpen}
            onClose={this.handleSeriesRestoreModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>連載作品ページの公開</Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <p>{_.get(this.state, 'selectedSeries.title')} を戻しますか？</p>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleSeriesRestoreModalClose} />
              <Button positive content="戻す" onClick={this.handleSubmitButtonSeriesRestoreModalClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default Series
