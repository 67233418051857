import React, { Component } from 'react'
import _ from 'lodash'
import { Header, Divider, Icon, Menu, Button, Input } from 'semantic-ui-react'
import { OutsourcedArticleApi } from 'trill-api-admin-client'
import OutsourcedArticlesMessages from './OutsourcedArticlesMessages'
import LogLevel from '../LogLevel'
import OutsourcedArticlesDataTable from './OutsourcedArticlesDataTable'
import BulkUpdateArticlesFromOutsourcedArticlesModal from './BulkUpdateArticlesFromOutsourcedArticlesModal'
import GetPermission from '../GetPermission'

const logger = LogLevel.getLogger('OutsourcedArticlesContainer')

const outsourcedArticleApi = new OutsourcedArticleApi()

const itemsPerPage = 10

export default class OutsourcedArticlesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      tableData: {
        currentPage: 1,
        itemsPerPage,
        totalPages: 0,
        totalItems: 0,
      },
      outsourcedArticles: [],
      articlePermission: GetPermission('article'),
      outsourcedArticlePermission: GetPermission('outsourcedArticle'),
    }

    this.handleDataTablePageChange = this.handleDataTablePageChange.bind(this)
  }

  componentDidMount() {
    this.retrieveData()
  }

  retrieveData = async () => {
    const { tableData } = this.state

    this.setState({
      isBusy: true,
      apiError: null,
    })

    try {
      const responseoutsOurcedArticles = await outsourcedArticleApi.getOutsourcedArticles(this.getRequestQuery())

      const outsourcedArticles = responseoutsOurcedArticles.data

      const responseHeader = responseoutsOurcedArticles.header
      tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
      tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', itemsPerPage), 10)
      tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
      tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

      logger.debug(`get outsourcedArticles`, { responseoutsOurcedArticles, tableData })

      await this.setState({
        isBusy: false,
        outsourcedArticles,
        tableData,
      })

      logger.debug(`get outsourcedArticles`, this.state)
    } catch (error) {
      logger.error(`get outsourcedArticles error`, error)

      tableData.currentPage = 1
      tableData.itemsPerPage = itemsPerPage
      tableData.totalPages = 0
      tableData.totalItems = 0

      this.setState({
        outsourcedArticles: [],
        isBusy: false,
        tableData,
        apiError: error,
      })
    }
  }

  handleDataTablePageChange = (event, { currentPage: changeCurrentPage, itemsPerPage: changeItemsPerPage }) => {
    const { tableData } = this.state

    tableData.currentPage = changeCurrentPage
    tableData.itemsPerPage = changeItemsPerPage

    this.setState({ tableData }, () => {
      this.retrieveData()
    })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { tableData } = this.state

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage

    const query = {
      currentPage,
      itemsPerPage: tableData.itemsPerPage,
      articleId: this.state.searchId,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  postOutsourcedArticle = async () => {
    this.setState({
      apiError: null,
      isBusy: true,
      searchId: '',
      isBulkUpdateArticlesModalOpen: false,
    })

    try {
      await outsourcedArticleApi.postOutsourcedArticle()

      const { tableData } = this.state

      tableData.currentPage = 1

      this.setState({ tableData }, () => {
        this.retrieveData()
      })
    } catch (errors) {
      logger.error(`post outsourcedArticle error`, errors)

      this.setState({
        apiError: errors,
        isBusy: false,
      })
    }
  }

  changeShared = async item => {
    this.setState({
      isBusy: true,
    })
    try {
      await outsourcedArticleApi.patchOutsourcedArticle(item.articleId, {
        outsourcedArticleUpdateValues: {
          shared: !item.shared,
        },
      })

      item.shared = !item.shared
      this.setState({
        isBusy: false,
      })
    } catch (errors) {
      logger.error(`changeShared error`, errors)

      this.setState({
        isBusy: false,
      })
    }
  }

  patchOutsourcedArticle = async (articleId, values) => {
    try {
      await outsourcedArticleApi.patchOutsourcedArticle(articleId, { outsourcedArticleUpdateValues: values })

      return { patchSuccess: true }
    } catch (errors) {
      logger.error(`patch outsourcedArticle error`, errors)

      this.setState({
        apiError: errors,
        isBusy: false,
      })

      return { patchError: true }
    }
  }

  updateSearchArticleId = e => {
    this.setState({ searchId: e.target.value })
  }

  searchArticleId = () => {
    this.retrieveData()
  }

  resetSearchId = () => {
    this.setState(
      {
        searchId: '',
      },
      () => {
        this.retrieveData()
      },
    )
  }

  handleBulkUpdateArticlesModalOpen = () => {
    this.setState({ isBulkUpdateArticlesModalOpen: true })
  }

  handleBulkUpdateArticlesModalClose = () => {
    this.setState({ isBulkUpdateArticlesModalOpen: false })
  }

  render() {
    const { outsourcedArticles, articlePermission, outsourcedArticlePermission } = this.state
    const { hasUpdatePermission: hasUpdateArticlePermission } = articlePermission
    const {
      hasCreatePermission: hasCreateOutsourcedArticlePermission,
      hasUpdatePermission: hasUpdateOutsourcedArticlePermission,
    } = outsourcedArticlePermission
    return (
      <div>
        <Header as="h1">
          <Icon name="users" />
          <Header.Content>編集委託記事</Header.Content>
        </Header>
        <Menu secondary>
          <Menu.Item fitted>
            <Input
              name="outsource-articles-search"
              type="text"
              placeholder={'IDで検索'}
              value={this.state.searchId || ''}
              onChange={this.updateSearchArticleId}
            ></Input>
          </Menu.Item>
          <Menu.Item fitted>
            <Button
              disabled={!hasCreateOutsourcedArticlePermission}
              primary
              content="検索"
              icon="search"
              labelPosition="left"
              onClick={this.searchArticleId}
            />
          </Menu.Item>
          <Menu.Item fitted>
            <Button primary content="リセット" onClick={this.resetSearchId} />
          </Menu.Item>
        </Menu>
        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreateOutsourcedArticlePermission}
              primary
              content="新規作成"
              icon="write"
              labelPosition="left"
              onClick={this.postOutsourcedArticle}
            />
          </Menu.Item>
          <Menu.Item fitted>
            <Button
              disabled={!hasUpdateArticlePermission}
              primary
              content="記事の一括更新"
              icon="edit"
              labelPosition="left"
              onClick={this.handleBulkUpdateArticlesModalOpen}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        <OutsourcedArticlesMessages {...this.state} />

        {!_.isEmpty(outsourcedArticles) && (
          <OutsourcedArticlesDataTable
            {...this.state}
            hasUpdatePermission={hasUpdateOutsourcedArticlePermission}
            handleDataTablePageChange={this.handleDataTablePageChange}
            changeShared={this.changeShared}
            patchOutsourcedArticle={this.patchOutsourcedArticle}
          />
        )}

        {hasUpdateArticlePermission && (
          <BulkUpdateArticlesFromOutsourcedArticlesModal
            open={this.state.isBulkUpdateArticlesModalOpen}
            onClose={this.handleBulkUpdateArticlesModalClose}
          />
        )}
      </div>
    )
  }
}
