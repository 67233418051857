import React, { Component } from 'react'
import { Button, Dimmer, Divider, Grid, Header, Icon, Loader } from 'semantic-ui-react'
import { MangasPopularCreatorApi, CreatorApi } from 'trill-api-admin-client'
import _ from 'lodash'
import CreatorsDropdown from '../../components/CreatorsDropdown'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

import './PopularCreators.css'

const logger = LogLevel.getLogger('PopularCreators')

const popularCreatorApi = new MangasPopularCreatorApi()
const creatorApi = new CreatorApi()

let getPopularCreators
let sendPopularCreators
let getCreators

class PopularCreators extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      permission: GetPermission('mangasPopularCreator'),
      defaultPopularCreatorsObj: {},
      popularCreatorsObj: {},
      isChangePopularCreators: false,
      initialCreatorsData: {},
    }
  }

  componentDidMount() {
    this.retrievePopularCreators()
  }

  componentWillUnmount() {
    if (!_.isNil(getPopularCreators)) {
      getPopularCreators.cancel()
    }

    if (!_.isNil(sendPopularCreators)) {
      sendPopularCreators.cancel()
    }
  }

  /**
   * Handler to be called when the dropdown creator selection value is changed
   */
  handleCreatorDropdownChange = (key, creator) => {
    const { popularCreatorsObj, defaultPopularCreatorsObj } = this.state
    const newPopularCreatorsObj = {
      ...popularCreatorsObj,
      [key]: creator,
    }
    const newIsChangePopularCreators = !_.isEqual(
      _.get(newPopularCreatorsObj, `${key}.id`, null),
      _.get(defaultPopularCreatorsObj, `${key}.id`, null),
    )

    this.setState({
      popularCreatorsObj: newPopularCreatorsObj,
      isChangePopularCreators: newIsChangePopularCreators,
    })
  }

  /**
   * Get request creators query params
   */
  getRequestCreatorsQuery = exceptCreators => {
    // Search params
    const filtering = ['deleted_at IS NULL']
    _.each(exceptCreators, popularCreator => {
      if (!_.isEmpty(popularCreator)) {
        filtering.push(`id <> '${popularCreator.id}'`)
      }
    })

    // Order params
    const sorting = ['-id']

    // Query params
    const queryParams = {
      itemsPerPage: 10,
      currentPage: 1,
      filtering,
      sorting,
    }

    // Returns an object except empty and not number
    return _.omitBy(queryParams, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * Call api get list of creators
   */
  retrieveCreators = (exceptCreators, successCallback = () => {}, errorCallback = () => {}) => {
    getCreators = CancelablePromise(creatorApi.getCreators(this.getRequestCreatorsQuery(exceptCreators)))
    getCreators.promise
      .then(response => {
        const creators = response.data
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        const initialCreatorsData = {
          creators,
          totalPages,
          currentPage,
        }

        successCallback(initialCreatorsData)
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        errorCallback(error)
      })
  }

  /**
   * Call api get popular creators
   */
  retrievePopularCreators = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    getPopularCreators = CancelablePromise(popularCreatorApi.getPopularCreators())
    getPopularCreators.promise
      .then(response => {
        const popularCreatorsObj = response.data

        logger.debug('retrieve popular creators success', { popularCreatorsObj })
        if (_.isEmpty(this.state.initialCreatorsData)) {
          this.retrieveCreators(
            Object.values(popularCreatorsObj),
            initialCreatorsData => {
              this.setState({
                isBusy: false,
                defaultPopularCreatorsObj: popularCreatorsObj,
                popularCreatorsObj,
                isChangePopularCreators: false,
                initialCreatorsData,
              })
            },
            error => {
              this.setState({
                isBusy: false,
                apiError: error,
              })
            },
          )
        } else {
          this.setState({
            isBusy: false,
            defaultPopularCreatorsObj: popularCreatorsObj,
            popularCreatorsObj,
            isChangePopularCreators: false,
          })
        }
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve popular creators error', { error })
        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Handler to be called when the submit button is clicked
   */
  handleSubmitButtonClick = () => {
    const { isChangePopularCreators, popularCreatorsObj } = this.state

    if (!isChangePopularCreators) {
      return
    }

    const newMangasPopularCreator = Object.values(popularCreatorsObj).reduce(
      (previousValue, currentValue, currentIndex) => {
        return {
          ...previousValue,
          [`creatorId_${currentIndex + 1}`]: _.get(currentValue, 'id', 0),
        }
      },
      {},
    )

    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendPopularCreators = CancelablePromise(popularCreatorApi.postPopularCreator(newMangasPopularCreator))
    sendPopularCreators.promise
      .then(() => {
        logger.debug('send popular creators success')
        this.retrievePopularCreators()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send popular creators error', { error })
        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  render() {
    const { permission, popularCreatorsObj, isChangePopularCreators, initialCreatorsData } = this.state
    const { hasCreatePermission } = permission

    return (
      <div className="PopularCreators">
        <Header as="h1">
          <Icon name="users" />

          <Header.Content>人気クリエイター（TOP）</Header.Content>
        </Header>

        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Divider hidden clearing />

        <ApiErrorMessage error={this.state.apiError} />

        {hasCreatePermission && (
          <div className="PopularCreators__Form">
            <Grid>
              {Object.entries(popularCreatorsObj).map(([key, creator], index) => (
                <Grid.Column width={8} key={key}>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Header size="medium">NO.{index + 1}</Header>
                      </Grid.Column>
                      <Grid.Column>
                        <CreatorsDropdown
                          creator={creator}
                          initialCreatorsData={initialCreatorsData}
                          exceptCreators={_.filter(
                            Object.values(popularCreatorsObj),
                            popularCreator => !_.isEqual(popularCreator, creator),
                          )}
                          onChange={(event, { detail }) => this.handleCreatorDropdownChange(key, detail)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              ))}
            </Grid>

            <Divider hidden clearing />
            <Button
              positive
              content="更新"
              disabled={!isChangePopularCreators}
              onClick={this.handleSubmitButtonClick}
            />
          </div>
        )}
      </div>
    )
  }
}

export default PopularCreators
