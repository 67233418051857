import React, { Component } from 'react'
import { Modal, Button, Image, Dimmer, Loader } from 'semantic-ui-react'
import LogLevel from '../LogLevel'
import { PanelApi, NewPanel } from 'trill-api-admin-client'

import { Form } from 'formsy-semantic-ui-react'

import _ from 'lodash'
import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import CancelablePromise from '../CancelablePromise'

const logger = LogLevel.getLogger('PanelCreateModal')
const panelApi = new PanelApi()
const newPanel = new NewPanel()
let postPanel

class PanelCreateModal extends Component {
  static defaultProps = { permission: { hasCreatePermission: false } }

  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      apiError: null,
      open: false,
      anchorType: 'articleId',
    }
  }

  static anchorType = {
    URL: 'url',
    ARTICLE_ID: 'articleId',
  }

  componentDidMount() {
    this.resetState()
  }

  /* ComponentがDOMから削除される時に呼ばれます */
  componentWillUnmount() {
    logger.debug('componentWillUnmount')
    if (!_.isNil(postPanel)) {
      postPanel.cancel()
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  resetState = () => {
    this.setState(newPanel)
  }

  readFileAsDataURL = (targetName, files) => {
    const key = targetName
    const file = files[0]
    const reader = new FileReader()
    const opts = {}

    reader.onload = () => {
      opts[key] = reader.result
      this.setState(opts)
    }

    reader.readAsDataURL(file)
  }

  handleChange = event => {
    if (event.dataTransfer) {
      this.readFileAsDataURL(event.target.name, event.dataTransfer.files)
    } else if (event.target.files) {
      this.readFileAsDataURL(event.target.name, event.target.files)
    } else {
      const opts = {}
      const key = event.target.name
      const value = event.target.value
      const type = _.get(event.target, 'type', '')
      opts[key] = value
      if (value.length > 0 && type === 'number') {
        opts[key] = _.parseInt(value)
      }
      this.setState(opts)
    }
  }

  handleFormValidSubmit = async (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const panelValues = this.getRequestParameters(submitFormData)

    logger.debug('panelValues', { panelValues })

    try {
      postPanel = CancelablePromise(panelApi.postPanel(panelValues))
      await postPanel.promise

      this.resetState()

      if (this.props.retrivePanels) {
        this.props.retrivePanels()
      }

      this.setState(
        {
          isBusy: false,
        },
        () => {
          this.closeModal()
        },
      )
    } catch (errors) {
      if (errors.isCanceled) {
        return
      }

      logger.error(`error postPanel error`, { errors, panelValues })

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  getRequestParameters = submitFormData => {
    return _.omitBy(
      _.pickBy(submitFormData, (v, p) => _.has(newPanel, p)),
      v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v),
    )
  }

  handleanchorTypeDropdownChange = (event, { value }) => {
    this.setState({ anchorType: value })
  }

  render() {
    const {
      open,
      isBusy,
      apiError,
      name,
      image,
      positionName,
      order,
      anchorType,
      anchorArticleId,
      anchorUrl,
    } = this.state
    const { permission } = this.props
    const { hasCreatePermission } = permission
    return (
      <Modal
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasCreatePermission} primary content="作成" icon="write" labelPosition="right" />}
      >
        <Modal.Header>誘導バナーの作成</Modal.Header>
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />

          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>

          <Form onValidSubmit={this.handleFormValidSubmit}>
            <div>
              <Form.Input required label="枠名" name="name" value={name} onChange={this.handleChange} />

              <Form.Group widths="equal">
                <Form.Input
                  label="ポジション名 (TRILL Web サーバーから参照するための値)"
                  placeholder="英数字または - で入力してください。"
                  name="positionName"
                  value={positionName}
                  validations={{ matchRegexp: /^[a-z0-9-]*$/i }}
                  validationErrors={{
                    matchRegexp: '英数字または - で入力してください。',
                  }}
                  errorLabel={<FormErrorLabel />}
                  onChange={this.handleChange}
                />
                <Form.Input
                  label="管理ツールでの表示順"
                  placeholder="管理ツールでの表示順を指定してください"
                  name="order"
                  value={order}
                  type="number"
                  min="1"
                  validations="isNumeric"
                  validationErrors={{ isNumeric: '記事 ID は数値です' }}
                  errorLabel={<FormErrorLabel />}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Image src={image} />

              <Form.Input type="file" label="バナー" name="image" accept={['image/*']} onChange={this.handleChange} />
              <Form.Input className="isHidden" value={image} name="image" />

              <Form.Group widths="equal">
                <Form.Dropdown
                  label="種類"
                  name="anchorType"
                  compact
                  selection
                  options={[
                    { text: 'URL', value: 'url' },
                    { text: '記事ID', value: 'articleId' },
                  ]}
                  value={anchorType}
                  onChange={this.handleanchorTypeDropdownChange}
                />

                {anchorType === PanelCreateModal.anchorType.ARTICLE_ID && (
                  <Form.Input
                    label="記事ID"
                    placeholder="記事IDを指定してください"
                    name="anchorArticleId"
                    type="number"
                    min="1"
                    value={anchorArticleId}
                    onChange={this.handleChange}
                    validations="isNumeric"
                    validationErrors={{ isNumeric: '記事 ID は数値です' }}
                    errorLabel={<FormErrorLabel />}
                  />
                )}

                {anchorType === PanelCreateModal.anchorType.URL && (
                  <Form.Input
                    label="URL"
                    placeholder="誘導するURLを https:// から指定ください"
                    name="anchorUrl"
                    value={anchorUrl}
                    onChange={this.handleChange}
                    validations="isUrl"
                    validationErrors={{ isUrl: '無効な URL です' }}
                    errorLabel={<FormErrorLabel />}
                  />
                )}
              </Form.Group>
            </div>
            <Button primary content="作成" type="submit" />
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

export default PanelCreateModal
