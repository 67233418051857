import React, { Component } from 'react'
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react'
import LogLevel from '../LogLevel'
import { PanelApi } from 'trill-api-admin-client'

import _ from 'lodash'
import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'

const logger = LogLevel.getLogger('PanelDeleteModal')
const panelApi = new PanelApi()
let deletePanel

class PanelDeleteModal extends Component {
  static defaultProps = { permission: { hasDeletePermission: false } }

  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      apiError: null,
      open: false,
    }
  }

  /* ComponentがDOMから削除される時に呼ばれます */
  componentWillUnmount() {
    logger.debug('componentWillUnmount')
    if (!_.isNil(deletePanel)) {
      deletePanel.cancel()
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  handleDeleteButtonClick = async () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const panelId = this.props.panel.id

    try {
      deletePanel = CancelablePromise(panelApi.deletePanel(panelId))
      await deletePanel.promise

      if (this.props.retrivePanels) {
        this.props.retrivePanels()
      }

      this.setState(
        {
          isBusy: false,
        },
        () => {
          this.closeModal()
        },
      )
    } catch (errors) {
      if (errors.isCanceled) {
        return
      }

      logger.error(`error deletePanel #${panelId} error`, { errors })

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  render() {
    const { open, isBusy, apiError } = this.state
    const { permission } = this.props
    const { hasDeletePermission } = permission
    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasDeletePermission} icon="trash" />}
      >
        {_.has(this.props, 'panel.name') && <Modal.Header>{this.props.panel.name} を削除</Modal.Header>}
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />
          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>
          {_.has(this.props, 'panel.name') && <div>{this.props.panel.name} を削除しますか？</div>}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />

          <Button negative content="削除" onClick={this.handleDeleteButtonClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default PanelDeleteModal
