import React from 'react'
import { Button, Image, Label } from 'semantic-ui-react'
import { FormattedDate, FormattedTime } from 'react-intl'
import _ from 'lodash'

import DataTable from './DataTable'

import CouponViewStatus from '../enums/CouponViewStatus'
import CouponsRemoveModal from './CouponsRemoveModal'
import CouponsUndoModal from './CouponsUndoModal'

const CouponsDataTable = ({
  viewStatus,
  couponsItems,
  tableData,
  handleDataTableSelectionChange,
  handleDataTablePageChange,
  deleteCoupon,
  putCoupon,
  deleteApiError,
  putApiError,
  hasUpdatePermission,
  hasDeletePermission,
  hasRestorePermission,
}) => {
  const createButtonGroup = item => (
    <Button.Group secondary>
      <Button disabled={!hasUpdatePermission} as="a" icon="edit" href={`/coupon/${item.id}`} />
      {!_.isEqual(viewStatus, CouponViewStatus.TRASH) && (
        <CouponsRemoveModal
          hasDeletePermission={hasDeletePermission}
          coupon={item}
          couponId={item.id}
          apiError={deleteApiError}
          deleteCoupon={deleteCoupon}
        />
      )}
      {_.isEqual(viewStatus, CouponViewStatus.TRASH) && (
        <CouponsUndoModal
          hasRestorePermission={hasRestorePermission}
          coupon={item}
          couponId={item.id}
          apiError={putApiError}
          putCoupon={putCoupon}
        />
      )}
    </Button.Group>
  )

  const formatedTime = time => (
    <span>
      <FormattedDate value={time} day="numeric" month="long" year="numeric" weekday="narrow" />
      <br />
      <FormattedTime value={time} hour="numeric" minute="numeric" second="numeric" />
    </span>
  )

  const columns = [
    {
      label: 'ID',
      align: 'center',
      field: 'id',
    },
    {
      label: 'ステータス',
      align: 'center',
      render: item => {
        let color
        let status
        switch (item.status) {
          case 'publish':
            color = 'green'
            status = '公開'
            break
          case 'pending':
            color = 'red'
            status = '保留'
            break
          default:
            color = 'gray'
            status = '不明'
        }
        return <Label color={color}>{status}</Label>
      },
    },
    {
      label: '登録日時',
      field: 'createdAt',
      collapsing: false,
      render: item => !_.isEmpty(item.createdAt) && formatedTime(item.createdAt),
    },
    {
      label: 'サムネイル',
      render: item => {
        const url = _.get(item, 'image.thumbnail') ? (
          <Image src={_.get(item, 'image.thumbnail')} centered size="tiny" />
        ) : (
          ''
        )
        return url
      },
    },
    {
      label: 'クーポン画像',
      render: item => {
        const url = _.get(item, 'image.coupon') ? <Image src={_.get(item, 'image.coupon')} centered size="tiny" /> : ''
        return url
      },
    },
    {
      label: 'クーポンコード',
      collapsing: false,
      render: item => <span>{_.get(item, 'code', '')}</span>,
    },
    {
      label: '有効期間',
      collapsing: false,
      render: item => {
        const startDateOfUse = !_.isEmpty(item.startDateOfUse) && formatedTime(item.startDateOfUse)
        const endDateOfUse = !_.isEmpty(item.endDateOfUse) && formatedTime(item.endDateOfUse)
        return (
          !_.isEmpty(startDateOfUse) &&
          !_.isEmpty(endDateOfUse) && (
            <span>
              {startDateOfUse}
              <br />
              〜<br />
              {endDateOfUse}
            </span>
          )
        )
      },
    },
    {
      label: 'ブランド (表示名)',
      collapsing: false,
      render: item => item.couponBrand && <span>{_.get(item, 'couponBrand.displayName', '')}</span>,
    },
    {
      label: '優先度',
      field: 'priority',
    },
    {
      label: '重み',
      field: 'weight',
    },
    {
      label: 'スコア',
      field: 'score',
      render: item => <span>{_.round(_.get(item, 'score', 0) * 10000)}</span>,
    },
    {
      label: '操作',
      align: 'center',
      render: item => createButtonGroup(item),
    },
  ]

  return (
    <DataTable
      rowKey="id"
      columns={columns}
      items={couponsItems}
      itemsPerPage={tableData.itemsPerPage}
      currentPage={tableData.currentPage}
      totalPages={tableData.totalPages}
      sort={tableData.sorting}
      onSelectionChange={handleDataTableSelectionChange}
      onPageChange={handleDataTablePageChange}
    />
  )
}

export default CouponsDataTable
