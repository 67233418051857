import _ from 'lodash'
import moment from 'moment'

export function flattenObject(obj, parentKey) {
  const output = {}

  _.each(obj, (value, key) => {
    if (typeof value === 'object' && !moment.isMoment(value)) {
      if (parentKey) {
        Object.assign(output, flattenObject(value, `${parentKey}.${key}`))
      } else {
        Object.assign(output, flattenObject(value, key))
      }
    } else if (parentKey) {
      Object.assign(output, { [`${parentKey}.${key}`]: value })
    } else {
      output[key] = value
    }
  })

  return output
}

export function differenceObject(object, base) {
  const changes = (_object, _base) =>
    _.transform(_object, (result, value, key) => {
      if (!_.isEqual(value, _base[key])) {
        result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
      }
    })
  return changes(object, base)
}

export function getOutsourceArticleValue(article, attribute, defaultValue = '') {
  /**
   * Check whether the data has been updated after releasing the allowing to set thumbnail and publish datetime on the outsource site feature.
   * Released on March 13, 2024.
   */
  const isUpdatedAfterRelease = moment(article.updatedAt).isSameOrAfter(
    moment('2024/03/14 00:00:00', 'YYYY/MM/DD HH:mm:ss'),
    'day',
  )
  const outsourceValue = _.get(article, `outsourcedArticle.${attribute}`, defaultValue)
  const isExpectOutsourceValue =
    isUpdatedAfterRelease || _.isEmpty(outsourceValue) || !attribute.match(/^(thumbnail|cover|publishDatetime)/gi)

  if (isExpectOutsourceValue) {
    return outsourceValue
  }

  /**
   * Return the admin value in case there is no value and hasn't been updated after release
   * Applies to thumbnail, cover, and publish datetime
   */
  const adminValue = _.get(article, attribute, defaultValue)
  return adminValue
}

export function getTokenExpiredTime(authToken) {
  return moment()
    .utc()
    .add(authToken.expires_in, 'seconds')
    .format()
}
