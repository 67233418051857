import _ from 'lodash'
import Store from 'store'
import { Component } from 'react'
import { Button, Dimmer, Divider, Header, Icon, Loader, Menu, Modal, Statistic } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import GetPermission from '../../GetPermission'
import FormErrorLabel from '../../components/FormErrorLabel'
import LogLevel from '../../LogLevel'
import DataTable from '../../components/DataTable'
import CancelablePromise from '../../CancelablePromise'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import GameCategoryForm from '../../components/GameCategoryForm'
import { GameCategoryApi } from 'trill-api-admin-client'
import { SEARCH_INPUT_PATTERN } from '../../constants/pattern'

const logger = LogLevel.getLogger('GameCategories')

const gameCategoryApi = new GameCategoryApi()
let getGameCategoriesPromise
let postGameCategoryPromise
let deleteGameCategoryPromise
let restoreGameCategoryPromise

const GameCategoryStatus = {
  ALL: 'all',
  TRASH: 'trash',
}

const PAGE_STATE_STORE_KEY = 'gameCategoriesPageState'

function getApiErrorDetail(error) {
  return error.status === 422
    ? { header: 'エラーメッセージ', message: '入力されたゲームカテゴリ名は既に存在します' }
    : error
}

class GameCategories extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      status: GameCategoryStatus.ALL,
      permission: GetPermission('gameCategory'),

      isFormSearchValid: false,

      isSubmitGameCategoryForm: false,
      isGameCategoryFormValid: false,
      isGameCategoryFormModified: false,

      isGameCategoryFormModalOpen: false,
      isGameCategoryDeleteModalOpen: false,
      isGameCategoryRestoreModalOpen: false,

      gameCategories: [],
      selectedGameCategory: null,
    }

    _.each(GameCategoryStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 50,
          sorting: {},
          filtering: {
            search: '',
          },
        },
      })
    })
  }

  // After FIRST rendering, retrieve data from cache or request to populate
  componentDidMount() {
    const pageState = Store.get(PAGE_STATE_STORE_KEY)

    if (pageState) {
      const gameCategories = pageState.gameCategories
      const status = _.get(pageState, 'status', GameCategoryStatus.ALL)
      const tableData = _.get(pageState, status, this.state[status])

      this.setState(
        {
          status,
          gameCategories,
          [status]: tableData,
        },
        () => {
          this.retrieveGameCategories()
        },
      )
    } else {
      this.retrieveGameCategories()
    }
  }

  // Cancel all API requests on removing Component
  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getGameCategoriesPromise)) {
      getGameCategoriesPromise.cancel()
    }
    if (!_.isNil(postGameCategoryPromise)) {
      postGameCategoryPromise.cancel()
    }
    if (!_.isNil(deleteGameCategoryPromise)) {
      deleteGameCategoryPromise.cancel()
    }
    if (!_.isNil(restoreGameCategoryPromise)) {
      restoreGameCategoryPromise.cancel()
    }
  }

  /**
   * Request query for List API
   */
  getRequestQuery = () => {
    // Get the state of the displayed table data
    const status = this.state.status
    const tableData = this.state[status]

    //* ************* Pagination ************* */
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    //* ************* Filter ************* */
    const filtering = []

    // Filtered by Tab
    if (_.isEqual(status, GameCategoryStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    const searchValue = tableData.filtering.search
    if (!_.isEmpty(searchValue) && searchValue.match(SEARCH_INPUT_PATTERN)) {
      if (_.isFinite(Number(searchValue))) {
        filtering.push(`id = '${searchValue}'`)
      } else {
        filtering.push(`name LIKE '%${searchValue}%'`)
      }
    }

    //* ************* Sort ************* */
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : '+'

      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // Returns an object except empty and not number
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * Get all GameCategories and populate to DataTable
   */
  retrieveGameCategories = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getGameCategoriesPromise = CancelablePromise(gameCategoryApi.getGameCategories(this.getRequestQuery()))
    getGameCategoriesPromise.promise
      .then(response => {
        const responseHeader = response.header
        const gameCategories = response.data

        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        logger.debug('retrieve gameCategories', { gameCategories })

        this.setState({
          isBusy: false,
          gameCategories,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve gameCategories error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          gameCategories: [],
          [status]: tableData,
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Save current page state in store.
   * Page state relates to Data Table, Sort and Filter.
   * So it should be called on Data Table status changed
   */
  savePageState() {
    const gameCategories = this.state.gameCategories
    const status = this.state.status
    const tableData = this.state[status]
    const pageState = {
      gameCategories,
      status,
      [status]: tableData,
    }

    logger.debug('save page state', { pageState })

    Store.set('gameCategoriesPageState', pageState)
  }

  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveGameCategories()
      this.savePageState()
    })
  }

  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  handleSearchInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.search = data.value

    this.setState({ [status]: tableData })
  }

  handleSearchButtonClick = () => {
    if (!this.state.isFormSearchValid) {
      return
    }

    this.retrieveGameCategories()
    this.savePageState()
  }

  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveGameCategories()
      this.savePageState()
    })
  }

  handleDataTableSortChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveGameCategories()
      this.savePageState()
    })
  }

  handleGameCategoryFormModalOpen = (selectedGameCategory = null) => {
    this.setState({
      isGameCategoryFormModalOpen: true,
      isSubmitGameCategoryForm: false,
      isGameCategoryFormValid: false,
      isGameCategoryFormModified: false,
      selectedGameCategory,
    })
  }

  handleGameCategoryFormChange = (gameCategoryChanged, isModified) => {
    this.setState({
      isGameCategoryFormModified: isModified,
      isSubmitGameCategoryForm: false,
    })
  }

  handleGameCategoryFormValid = () => {
    this.setState({
      isGameCategoryFormValid: true,
    })
  }

  handleGameCategoryFormInvalid = () => {
    this.setState({
      isGameCategoryFormValid: false,
    })
  }

  handleGameCategoryFormModalClose = () => {
    this.setState({
      isGameCategoryFormModalOpen: false,
      isSubmitGameCategoryForm: false,
      isGameCategoryFormValid: false,
      isGameCategoryFormModified: false,
      selectedGameCategory: null,
      isBusy: false,
      apiError: null,
    })
  }

  handleGameCategoryFormChange = (gameCategoryChanged, isModified) => {
    this.setState({
      isGameCategoryFormModified: isModified,
      isSubmitGameCategoryForm: false,
    })
  }

  handleSubmitButtonGameCategoryFormModalClick = () => {
    this.setState({ isSubmitGameCategoryForm: true })
  }

  handleGameCategoryFormValidSubmit = gameCategory => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    postGameCategoryPromise = CancelablePromise(
      _.isEmpty(this.state.selectedGameCategory)
        ? gameCategoryApi.postGameCategory(gameCategory)
        : gameCategoryApi.patchGameCategory(this.state.selectedGameCategory.id, {
            gameCategoryUpdateValues: gameCategory,
          }),
    )
    postGameCategoryPromise.promise
      .then(() => {
        this.handleGameCategoryFormModalClose()
        this.retrieveGameCategories()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: getApiErrorDetail(error),
          isSubmitGameCategoryForm: false,
        })
      })
  }

  handleGameCategoryDeleteModalOpen = selectedGameCategory => {
    this.setState({
      isGameCategoryDeleteModalOpen: true,
      selectedGameCategory,
    })
  }

  handleGameCategoryDeleteModalClose = () => {
    this.setState({
      isGameCategoryDeleteModalOpen: false,
      selectedGameCategory: null,
      isBusy: false,
      apiError: null,
    })
  }

  handleSubmitButtonGameCategoryDeleteModalClick = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteGameCategoryPromise = CancelablePromise(
      gameCategoryApi.deleteGameCategory(this.state.selectedGameCategory.id),
    )
    deleteGameCategoryPromise.promise
      .then(() => {
        this.handleGameCategoryDeleteModalClose()
        this.retrieveGameCategories()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  handleGameCategoryRestoreModalOpen = selectedGameCategory => {
    this.setState({
      isGameCategoryRestoreModalOpen: true,
      selectedGameCategory,
    })
  }

  handleGameCategoryRestoreModalClose = () => {
    this.setState({
      isGameCategoryRestoreModalOpen: false,
      selectedGameCategory: null,
      isBusy: false,
      apiError: null,
    })
  }

  handleSubmitButtonGameCategoryRestoreModalClick = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    restoreGameCategoryPromise = CancelablePromise(gameCategoryApi.putGameCategory(this.state.selectedGameCategory.id))
    restoreGameCategoryPromise.promise
      .then(() => {
        this.handleGameCategoryRestoreModalClose()
        this.retrieveGameCategories()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: getApiErrorDetail(error),
        })
      })
  }

  render() {
    const {
      status,
      permission,
      isGameCategoryFormModalOpen,
      isGameCategoryDeleteModalOpen,
      isGameCategoryRestoreModalOpen,
    } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    const tableData = this.state[status]

    const disabledFormGameCategory = !this.state.isGameCategoryFormValid || !this.state.isGameCategoryFormModified
    const hasModalBeShown =
      isGameCategoryFormModalOpen || isGameCategoryDeleteModalOpen || isGameCategoryRestoreModalOpen

    return (
      <div className="GameCategories">
        <Header as="h1">
          <Icon name="block layout" />

          <Header.Content>ゲームカテゴリ</Header.Content>
        </Header>

        <Dimmer active={hasModalBeShown ? false : this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={GameCategoryStatus.ALL}
            active={_.isEqual(status, GameCategoryStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={GameCategoryStatus.TRASH}
            active={_.isEqual(status, GameCategoryStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="新規作成"
              icon="write"
              labelPosition="left"
              onClick={() => this.handleGameCategoryFormModalOpen()}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* ************* Filter Form ************* */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="game-categories-search"
                type="text"
                action
                placeholder="ID、ゲームカテゴリ名で絞り込み"
                value={tableData.filtering.search}
                onChange={this.handleSearchInputChange}
                validations={{ matchRegexp: SEARCH_INPUT_PATTERN }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>
          </Form.Group>
        </Form>

        {/* ************* Total Items Count ************* */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* ************* API Error Message ************* */}
        <ApiErrorMessage error={this.state.apiError} />

        {/* ************* Data Table ************* */}
        {!_.isEmpty(this.state.gameCategories) && (
          <DataTable
            rowKey="id"
            sort={tableData.sorting}
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSortChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            items={this.state.gameCategories}
            cellStyle={{ position: 'relative' }}
            columns={[
              {
                label: 'ID',
                align: 'center',
                field: 'id',
              },
              {
                label: 'カテゴリ',
                field: 'name',
                collapsing: false,
                render: item => (
                  <span>
                    <Icon name="folder open" style={{ marginRight: '0.5rem' }} />
                    {item.name}
                  </span>
                ),
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.handleGameCategoryFormModalOpen(item)
                      }}
                    />

                    {!_.isEqual(status, GameCategoryStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.handleGameCategoryDeleteModalOpen(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, GameCategoryStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.handleGameCategoryRestoreModalOpen(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* ************* Create/Update Modal ************* */}
        {(hasCreatePermission || hasUpdatePermission) && (
          <Modal
            className="GameCategories__FormModal"
            size="small"
            closeIcon
            open={this.state.isGameCategoryFormModalOpen}
            onClose={this.handleGameCategoryFormModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>
              {_.isEmpty(this.state.selectedGameCategory) ? 'ゲームカテゴリの作成' : 'ゲームカテゴリの編集'}
            </Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <GameCategoryForm
                gameCategory={this.state.selectedGameCategory}
                isSubmitForm={this.state.isSubmitGameCategoryForm}
                onFormChange={this.handleGameCategoryFormChange}
                onFormValid={this.handleGameCategoryFormValid}
                onFormInvalid={this.handleGameCategoryFormInvalid}
                onFormValidSubmit={this.handleGameCategoryFormValidSubmit}
              />
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleGameCategoryFormModalClose} />
              <Button
                positive
                content={_.isEmpty(this.state.selectedGameCategory) ? '保存' : '更新'}
                disabled={disabledFormGameCategory}
                onClick={this.handleSubmitButtonGameCategoryFormModalClick}
              />
            </Modal.Actions>
          </Modal>
        )}

        {/* ************* Delete Modal ************* */}
        {hasDeletePermission && (
          <Modal
            className="GameCategories__DeleteModal"
            size="small"
            closeIcon
            open={this.state.isGameCategoryDeleteModalOpen}
            onClose={this.handleGameCategoryDeleteModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>ゲームカテゴリの削除</Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <p>{_.get(this.state, 'selectedGameCategory.name')} をゴミ箱へ移動しますか？</p>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleGameCategoryDeleteModalClose} />
              <Button negative content="ゴミ箱へ移動" onClick={this.handleSubmitButtonGameCategoryDeleteModalClick} />
            </Modal.Actions>
          </Modal>
        )}

        {/* ************* Restore Modal ************* */}
        {hasRestorePermission && (
          <Modal
            className="GameCategories__RestoreModal"
            size="small"
            closeIcon
            open={this.state.isGameCategoryRestoreModalOpen}
            onClose={this.handleGameCategoryRestoreModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>ゲームカテゴリの公開</Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />
              <p>{_.get(this.state, 'selectedGameCategory.name')} を戻しますか？</p>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleGameCategoryRestoreModalClose} />
              <Button positive content="戻す" onClick={this.handleSubmitButtonGameCategoryRestoreModalClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default GameCategories
