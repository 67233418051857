import React from 'react'
import { Button, Icon, Image } from 'semantic-ui-react'
import _ from 'lodash'

import DataTable from './DataTable'

import CouponViewStatus from '../enums/CouponViewStatus'
import CouponBrandsEditModal from './CouponBrandsEditModal'
import CouponBrandsRemoveModal from './CouponBrandsRemoveModal'
import CouponBrandsUndoModal from './CouponBrandsUndoModal'

const CouponBrandsDataTable = ({
  viewStatus,
  couponBrandsItems,
  tableData,
  handleDataTableSelectionChange,
  handleDataTablePageChange,
  patchCouponBrand,
  deleteCouponBrand,
  putCouponBrand,
  patchApiError,
  deleteApiError,
  putApiError,
  permission,
}) => {
  const createButtonGroup = item => (
    <Button.Group secondary>
      <CouponBrandsEditModal
        permission={permission}
        couponBrand={item}
        couponBrandId={item.id}
        apiError={patchApiError}
        patchCouponBrand={patchCouponBrand}
      />
      {!_.isEqual(viewStatus, CouponViewStatus.TRASH) && (
        <CouponBrandsRemoveModal
          permission={permission}
          couponBrand={item}
          couponBrandId={item.id}
          apiError={deleteApiError}
          deleteCouponBrand={deleteCouponBrand}
        />
      )}
      {_.isEqual(viewStatus, CouponViewStatus.TRASH) && (
        <CouponBrandsUndoModal
          permission={permission}
          couponBrand={item}
          couponBrandId={item.id}
          apiError={putApiError}
          putCouponBrand={putCouponBrand}
        />
      )}
    </Button.Group>
  )

  const columns = [
    {
      label: 'ID',
      align: 'center',
      field: 'id',
    },
    {
      label: 'ブランド名',
      field: 'name',
    },
    {
      label: '表示名',
      field: 'displayName',
    },
    {
      label: 'ブランド保有企業',
      render: item => item.couponCompany && <span>{_.get(item, 'couponCompany.displayName', '')}</span>,
    },
    {
      label: 'ロゴマーク',
      render: item => {
        const url = _.get(item, 'image.logo') ? <Image src={_.get(item, 'image.logo')} centered size="tiny" /> : ''
        return url
      },
    },

    {
      label: '電話番号',
      render: item => item.contact && item.contact.tel && <span>{_.get(item, 'contact.tel', '')}</span>,
    },
    {
      label: '問い合わせURL',
      render: item => {
        const url = _.get(item, 'contact.url') ? (
          <a href={_.get(item, 'contact.url')} target="_blank" rel="noopener noreferrer">
            外部サイト
            <Icon name="external" />
          </a>
        ) : (
          ''
        )

        return url
      },
      collapsing: false,
    },
    {
      label: '優先度',
      field: 'priority',
    },
    {
      label: '操作',
      align: 'center',
      render: item => createButtonGroup(item),
    },
  ]

  return (
    <DataTable
      rowKey="id"
      columns={columns}
      items={couponBrandsItems}
      itemsPerPage={tableData.itemsPerPage}
      currentPage={tableData.currentPage}
      totalPages={tableData.totalPages}
      sort={tableData.sorting}
      onSelectionChange={handleDataTableSelectionChange}
      onPageChange={handleDataTablePageChange}
    />
  )
}

export default CouponBrandsDataTable
