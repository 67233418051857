import React, { Component } from 'react'
import { Header, Icon, Dimmer, Loader, Menu, Button, Divider, Modal, Statistic } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import SponsorStatus from '../../enums/SponsorStatus'
import _ from 'lodash'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import SponsorEditModal from '../../components/SponsorEditModal'
import SponsorRemoveModal from '../../components/SponsorRemoveModal'
import DataTable from '../../components/DataTable'

class Sponsors extends Component {
  static defaultProps = {
    permission: {
      hasCreatePermission: false,
      hasUpdatePermission: false,
      hasDeletePermission: false,
      hasRestorePermission: false,
    },
  }

  render() {
    const status = this.props.status
    const tableData = this.props[status]
    const { permission } = this.props
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    return (
      <div className="Sponsors">
        <Header as="h1">
          <Icon name="money" />

          <Header.Content>スポンサー</Header.Content>
        </Header>
        {/* ローディング */}
        <Dimmer active={this.props.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>
        {/* すべて・ゴミ箱切り替えメニュー */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={SponsorStatus.ALL}
            onClick={this.props.handleStatusMenuItemClick}
            active={_.isEqual(status, SponsorStatus.ALL)}
          />

          <Menu.Item
            content="ゴミ箱"
            name={SponsorStatus.TRASH}
            onClick={this.props.handleStatusMenuItemClick}
            active={_.isEqual(status, SponsorStatus.TRASH)}
          />
        </Menu>
        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.props.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>
        <Divider hidden clearing />
        {/* 検索エリア */}
        <Form onValid={this.props.handleFormSearchValid} onInvalid={this.props.handleFormSearchInvalid}>
          <Form.Field width={8}>
            <Input
              name="sponsors-search"
              type="text"
              placeholder="スポンサー名で検索"
              action
              fluid
              value={tableData ? tableData.filtering.name : null}
              onChange={this.props.handleSponsorNameInputChange}
              validations={{ matchRegexp: /^((?!,).)*$/i }}
              validationErrors={{
                matchRegexp: 'キーワードに不正な記号があるため検索できません',
              }}
              errorLabel={<FormErrorLabel />}
            >
              <input />

              <Button
                icon="search"
                onClick={this.props.handleSearchButtonClick}
                color="blue"
                disabled={!this.props.isFormSearchValid}
              />
            </Input>
          </Form.Field>
        </Form>
        {/* 件数表示メッセージ */}
        {_.isEmpty(this.props.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData ? tableData.totalItems : null}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}
        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.props.apiError} />
        {/* スポンサー一覧 */}
        {!_.isEmpty(this.props.sponsors) && (
          <DataTable
            sort={tableData.sorting}
            items={this.props.sponsors}
            currentPage={tableData.currentPage}
            itemsPerPage={tableData.itemsPerPage}
            totalPages={tableData.totalPages}
            onSelectionChange={this.props.handleDataTableSelectionChange}
            onPageChange={this.props.handleDataTablePageChange}
            rowKey="id"
            columns={[
              {
                label: 'スポンサー名',
                field: 'name',
                collapsing: false,
              },
              {
                label: 'アプリの保証PV',
                render: item =>
                  _.get(item, 'savingArticleMaximumViews.total') > 0
                    ? ''
                    : _.get(item, 'savingArticleMaximumViews.app'),
              },
              {
                label: 'ウェブの保証PV',
                render: item =>
                  _.get(item, 'savingArticleMaximumViews.total') > 0
                    ? ''
                    : _.get(item, 'savingArticleMaximumViews.web'),
              },
              {
                label: '合計保証PV',
                render: item =>
                  _.get(item, 'savingArticleMaximumViews.total') > 0
                    ? _.get(item, 'savingArticleMaximumViews.total')
                    : '',
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group key={item.id} secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.props.openSponsorEditModal(item)
                      }}
                    />

                    {!_.isEqual(status, SponsorStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.props.openSponsorRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, SponsorStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.props.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}
        {/* スポンサー作成・更新モーダル */}
        {hasUpdatePermission && (
          <SponsorEditModal
            sponsor={this.props.editSponsor}
            open={this.props.isSponsorEditModalOpen}
            onClose={this.props.handleSponsorEditModalClose}
            onSuccessDataChanged={this.props.handleSponsorDataChanged}
          />
        )}
        {/* スポンサー削除モーダル */}
        {hasDeletePermission && (
          <SponsorRemoveModal
            sponsor={this.props.removeSponsor}
            open={this.props.isSponsorRemoveModalOpen}
            onClose={this.props.handleSponsorRemoveModalClose}
            onSuccessDataChanged={this.props.handleSponsorDataChanged}
          />
        )}
        {/* 削除したスポンサーを戻すときに表示する確認用モーダル */}
        {hasRestorePermission && (
          <Modal
            size="tiny"
            coloseIcon={true}
            open={this.props.isUndoModalOpen}
            onClose={this.props.handleUndoModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>スポンサーの公開</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.props.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.props.undoApiError} />
              {_.get(this.props, 'undoSponsor.name', 'スポンサー')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.props.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.props.handleUndoModalPublishButtonClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default Sponsors
