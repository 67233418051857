import React, { Component } from 'react'
import { Header, Icon, Dimmer, Loader, Menu, Button, Divider, Label, Modal, Statistic } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import _ from 'lodash'
import { TagGroupApi, CategoryApi } from 'trill-api-admin-client'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import TagGroupEditModal from '../../components/TagGroupEditModal'
import TagGroupRemoveModal from '../../components/TagGroupRemoveModal'
import DataTable from '../../components/DataTable'
import CategoriesDropdown from '../../components/CategoriesDropdown'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('TagGroups')
const tagGroupApi = new TagGroupApi()
const categoryApi = new CategoryApi()
let getTagGroups
let putTagGroup
const getTagGroupTags = []
const getTagGroupCategories = []

/**
 * タグ・グループ一覧の状態
 */
const TagGroupStatus = {
  /** すべて */
  ALL: 'all',
  /** ゴミ箱 */
  TRASH: 'trash',
}

class TagGroups extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      tagGroups: [],
      isTagGroupEditModalOpen: false,
      isTagGroupRemoveModalOpen: false,
      editTagGroup: null,
      removeTagGroup: null,
      status: TagGroupStatus.ALL,
      isUndoModalOpen: false,
      undoTagGroup: null,
      apiError: null,
      undoApiError: null,
      permission: GetPermission('tagGroup'),
      isFormSearchValid: false,
    }

    _.each(TagGroupStatus, status => {
      _.extend(this.state, {
        [status]: {
          currentPage: 1,
          itemsPerPage: 10,
          totalPages: 0,
          totalItems: 0,
          sorting: { name: 'desc' },
          filtering: {
            name: '',
            categoryId: null,
          },
        },
      })
    })
  }

  componentDidMount() {
    this.retrieveTagGroups()
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getTagGroups)) {
      getTagGroups.cancel()
    }
    if (!_.isNil(putTagGroup)) {
      putTagGroup.cancel()
    }
    if (!_.isNil(getTagGroupTags)) {
      _.each(getTagGroupTags, getTagGroupTag => getTagGroupTag.cancel())
      getTagGroupTags.length = 0
    }
    if (!_.isNil(getTagGroupCategories)) {
      _.each(getTagGroupCategories, getTagGroupCategory => getTagGroupCategory.cancel())
      getTagGroupCategories.length = 0
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveTagGroups()
    })
  }

  /**
   * テーブルの並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveTagGroups()
    })
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveTagGroups()
    })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    this.setState({
      isTagGroupEditModalOpen: true,
      editTagGroup: {},
    })
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handleTagGroupEditModalClose = () => {
    this.setState({
      isTagGroupEditModalOpen: false,
      editTagGroup: null,
    })
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleTagGroupRemoveModalClose = () => {
    this.setState({
      isTagGroupRemoveModalOpen: false,
      removeTagGroup: null,
    })
  }

  /**
   * タグ・グループのデータに変更があったときのハンドラ
   */
  handleTagGroupDataChanged = () => {
    this.retrieveTagGroups()
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishModalClick = () => {
    const tagGroupId = this.state.undoTagGroup.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putTagGroup = CancelablePromise(tagGroupApi.putTagGroup(tagGroupId))
    putTagGroup.promise
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoTagGroup: null,
        })

        this.retrieveTagGroups()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`put tag group tagGroupId #${tagGroupId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoTagGroup: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoTagGroup: null,
    })
  }

  /**
   * タグ・グループ名で絞り込むインプットの値を変更したときのハンドラ
   */
  handleTagGroupNameInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.name = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * カテゴリで絞り込むドロップダウンの値を変更したときのハンドラ
   */
  handleCatgoriesDropdownChange = (event, { value }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.categoryId = value

    this.setState({ [status]: tableData }, () => {
      this.retrieveTagGroups()
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveTagGroups()
  }

  /**
   * 作成・更新モーダルを表示する関数
   * @param {Object} tagGroup - タグ・グループデータ
   */
  openTagGroupEditModal(tagGroup) {
    this.setState({
      isTagGroupEditModalOpen: true,
      editTagGroup: tagGroup,
    })
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} tagGroup - タグ・グループデータ
   */
  openTagGroupRemoveModal(tagGroup) {
    this.setState({
      isTagGroupRemoveModalOpen: true,
      removeTagGroup: tagGroup,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   * @param {Object} tagGroup - タグ・グループデータ
   */
  openUndoModal(tagGroup) {
    this.setState({
      isUndoModalOpen: true,
      undoTagGroup: tagGroup,
    })
  }

  /**
   * タグ・グループ一覧取得
   */
  retrieveTagGroups = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getTagGroups = CancelablePromise(tagGroupApi.getTagGroups(this.getRequestQuery()))
    getTagGroups.promise
      .then(response => {
        const responseHeader = response.header
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.currentPage = currentPage === 0 ? 1 : currentPage
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        getTagGroupTags.length = 0
        _.each(response.data, tagGroup => {
          getTagGroupTags.push(
            CancelablePromise(
              new Promise((resolve, reject) => {
                this.setTagGroupTags(tagGroup)
                  .then(() => {
                    resolve(tagGroup)
                  })
                  .catch(error => {
                    if (error.isCanceled) {
                      reject(error)
                      return
                    }

                    logger.error(`retrieve tag group tags tagGroup id #${tagGroup.id} error`, error)

                    // 一覧表示は行いたいので処理は続行
                    resolve(tagGroup)
                  })
              }),
            ),
          )
        })

        return Promise.all(_.map(getTagGroupTags, getTagGroupTag => getTagGroupTag.promise))
      })
      .then(tagGroups => {
        logger.debug('get tag groups', { tagGroups })

        getTagGroupCategories.length = 0
        _.each(tagGroups, tagGroup => {
          getTagGroupCategories.push(
            CancelablePromise(
              new Promise((resolve, reject) => {
                this.setTagGroupCategory(tagGroup)
                  .then(() => {
                    resolve(tagGroup)
                  })
                  .catch(error => {
                    if (error.isCanceled) {
                      reject(error)
                      return
                    }

                    logger.error(`retrieve tag group category tagGroup id #${tagGroup.id} error`, error)

                    // 一覧表示は行いたいので処理は続行
                    resolve(tagGroup)
                  })
              }),
            ),
          )
        })

        return Promise.all(_.map(getTagGroupCategories, getTagGroupCategory => getTagGroupCategory.promise))
      })
      .then(tagGroups => {
        this.setState({
          tagGroups,
          [status]: tableData,
          isBusy: false,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get tag groups error', { error })

        tableData.itemsPerPage = 10
        tableData.currentPage = 1
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          tagGroups: [],
          isBusy: false,
          [status]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * タグ・グループに関連するタグをタグ・グループに設定
   */
  setTagGroupTags = tagGroup =>
    new Promise((resolve, reject) => {
      tagGroupApi
        .getTagGroupTags(tagGroup.id)
        .then(response => {
          const tagGroupTags = response.data
          const responseHeader = response.header
          // 合計ページが 2 ページ以上あったら関連タグの項目には ... で表示を行う
          const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
          tagGroup.totalTagPages = totalPages
          tagGroup.tags = tagGroupTags

          resolve(tagGroup)
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * タグ・グループに関連するカテゴリをタグ・グループに設定
   */
  setTagGroupCategory = tagGroup =>
    new Promise((resolve, reject) => {
      const categoryId = tagGroup.categoryId

      if (!categoryId) {
        resolve(tagGroup)
        return
      }

      categoryApi
        .getCategory(categoryId)
        .then(response => {
          const category = response.data
          tagGroup.category = category

          resolve(tagGroup)
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const status = this.state.status
    const tableData = this.state[status]

    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage

    const itemsPerPage = tableData.itemsPerPage

    // フィルタリング
    const filtering = []
    if (_.isEqual(status, TagGroupStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    if (!_.isEmpty(tableData.filtering.name)) {
      let filteringName = tableData.filtering.name
      if (filteringName.match(/\,/)) { // eslint-disable-line
        filteringName = ''
      }

      filtering.push(`name LIKE "%${filteringName}%"`)
    }

    if (_.isNumber(tableData.filtering.categoryId) || !_.isEmpty(tableData.filtering.categoryId)) {
      filtering.push(`categoryId = ${tableData.filtering.categoryId}`)
    }

    // ソートを配列に変換
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * タグ・グループに関連するタグをレンダリング
   */
  renderRelatedTags = item => {
    logger.debug('render related tags', item)
    const renderTag = tag => <Label key={tag.id} content={tag.name} size="small" style={{ margin: '2px' }} />
    return (
      <div>
        {_.map(item.tags, tag => renderTag(tag))}

        {item.totalTagPages > 1 && <span>...</span>}
      </div>
    )
  }

  render() {
    const status = this.state.status
    const tableData = this.state[status]
    const { permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    return (
      <div className="TagGroups">
        <Header as="h1">
          <Icon name="tags" />

          <Header.Content>タグ・グループ</Header.Content>
        </Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {/* 公開・削除切り替えボタン */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={TagGroupStatus.ALL}
            active={_.isEqual(status, TagGroupStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={TagGroupStatus.TRASH}
            active={_.isEqual(status, TagGroupStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              onClick={this.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="tag-groups-search"
                type="text"
                placeholder="タグ・グループ名で検索"
                action
                value={tableData.filtering.name}
                onChange={this.handleTagGroupNameInputChange}
                validations={{ matchRegexp: /^((?!,).)*$/i }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />
                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>

            <Form.Field width={4}>
              <CategoriesDropdown
                ignoreTrashContents={false}
                categoryId={tableData.filtering.categoryId}
                fluid={false}
                onChange={this.handleCatgoriesDropdownChange}
                disabled={!this.state.isFormSearchValid}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/* タグ・グループ一覧 */}
        {!_.isEmpty(this.state.tagGroups) && (
          <DataTable
            rowKey="id"
            items={this.state.tagGroups}
            currentPage={tableData.currentPage}
            itemsPerPage={tableData.itemsPerPage}
            totalPages={tableData.totalPages}
            sort={tableData.sorting}
            onSelectionChange={this.handleDataTableSelectionChange}
            onPageChange={this.handleDataTablePageChange}
            columns={[
              {
                label: 'タグ・グループ名',
                field: 'name',
                minWidth: '20em',
              },
              {
                label: '関連するタグ',
                collapsing: false,
                minWidth: '30em',
                render: item => this.renderRelatedTags(item),
              },
              {
                label: 'カテゴリ名',
                field: 'categoryId',
                render: item => _.get(item, 'category.name', ''),
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group key={item.id} secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.openTagGroupEditModal(item)
                      }}
                    />

                    {!_.isEqual(status, TagGroupStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openTagGroupRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, TagGroupStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* タグ・グループ作成・更新モーダル */}
        {hasUpdatePermission && (
          <TagGroupEditModal
            tagGroup={this.state.editTagGroup}
            open={this.state.isTagGroupEditModalOpen}
            onClose={this.handleTagGroupEditModalClose}
            onSuccessDataChanged={this.handleTagGroupDataChanged}
          />
        )}

        {/* タグ・グループ削除モーダル */}
        {hasDeletePermission && (
          <TagGroupRemoveModal
            tagGroup={this.state.removeTagGroup}
            open={this.state.isTagGroupRemoveModalOpen}
            onClose={this.handleTagGroupRemoveModalClose}
            onSuccessDataChanged={this.handleTagGroupDataChanged}
          />
        )}

        {/* 削除したタグ・グループを戻すときに表示する確認用モーダル */}
        {hasRestorePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isUndoModalOpen}
            onClose={this.handleUndoModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>タグ・グループの公開</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.undoApiError} />
              {_.get(this.state, 'undoTagGroup.name', 'タグ・グループ')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.handleUndoModalPublishModalClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default TagGroups
