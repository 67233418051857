import React, { Component } from 'react'
import _ from 'lodash'
import { Header, Divider, Icon, Menu, Button } from 'semantic-ui-react'
import { CouponApi } from 'trill-api-admin-client'
import CouponViewStatus from '../enums/CouponViewStatus'
import CouponsMessages from './CouponsMessages'
import CouponsFilters from './CouponsFilters'
import LogLevel from '../LogLevel'
import CouponsDataTable from './CouponsDataTable'
import GetPermission from '../GetPermission'

const logger = LogLevel.getLogger('CouponsContainer')

const couponApi = new CouponApi()

export default class CouponsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      postApiError: null,
      patchApiError: null,
      putApiError: null,
      viewStatus: CouponViewStatus.ALL,
      tableData: {
        currentPage: 1,
        itemsPerPage: 50,
        totalPages: 0,
        totalItems: 0,
        sorting: { id: 'desc' },
        filtering: { name: '' },
      },
      isViewableInApp: null,
      couponsItems: [],
      permission: GetPermission('coupon'),
    }

    this.handleDataTableSelectionChange = this.handleDataTableSelectionChange.bind(this)
    this.handleDataTablePageChange = this.handleDataTablePageChange.bind(this)
    this.deleteCoupon = this.deleteCoupon.bind(this)
    this.putCoupon = this.putCoupon.bind(this)
  }

  componentDidMount() {
    this.retrieveData()
  }

  retrieveData = async () => {
    const { tableData } = this.state

    this.setState({
      isBusy: true,
      apiError: null,
    })

    try {
      const responseCoupons = await couponApi.getCoupons(this.getRequestQuery())

      const coupons = responseCoupons.data

      const responseHeader = responseCoupons.header
      tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
      tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)
      tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
      tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

      logger.debug(`get coupons`, { responseCoupons, tableData })

      await this.setState({
        isBusy: false,
        couponsItems: coupons,
        tableData,
      })

      logger.debug(`get coupons`, this.state)
    } catch (error) {
      logger.error(`get coupons error`, error)

      tableData.currentPage = 1
      tableData.itemsPerPage = 50
      tableData.totalPages = 0
      tableData.totalItems = 0

      this.setState({
        couponsItems: [],
        isBusy: false,
        tableData,
        apiError: error,
      })
    }
  }

  handleDataTableSelectionChange = (event, { sort }) => {
    logger.debug(`handleDataTableSelectionChange`, sort)
    const { tableData } = this.state
    tableData.sorting = sort

    this.setState({ tableData }, () => {
      this.retrieveData()
    })
  }

  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const { tableData } = this.state

    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ tableData }, () => {
      this.retrieveData()
    })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 表示中の View の状態
    const { viewStatus, tableData, isViewableInApp } = this.state

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage

    // 1 ページあたりに含めるデータの数
    const itemsPerPage = tableData.itemsPerPage

    const filtering = []
    if (_.isEqual(viewStatus, CouponViewStatus.TRASH)) {
      // 削除一覧取得の場合
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    if (isViewableInApp === true) {
      filtering.push(`status = 'publish'`)
      filtering.push('start_date_of_use <= now()')
      filtering.push('end_date_of_use > now()')
    }

    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  handleStatusMenuItemClick = (event, { name }) => {
    const { viewStatus, tableData } = this.state

    if (viewStatus !== name) {
      tableData.currentPage = 1
      tableData.itemsPerPage = 50
      tableData.totalPages = 0
      tableData.totalItems = 0
    }

    this.setState({ viewStatus: name, tableData }, () => {
      this.retrieveData()
    })
  }

  handleDataTableSelectionChange = (event, { sort }) => {
    logger.debug(`handleDataTableSelectionChange`, sort)
    const { tableData } = this.state
    tableData.sorting = sort

    this.setState({ tableData }, () => {
      this.retrieveData()
    })
  }

  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const { tableData } = this.state

    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ tableData }, () => {
      this.retrieveData()
    })
  }

  handleViewableInAppFilterDropdownChange = (event, { value }) => {
    const { isViewableInApp, tableData } = this.state
    if (isViewableInApp !== value) {
      tableData.currentPage = 1
      tableData.itemsPerPage = 50
      tableData.totalPages = 0
      tableData.totalItems = 0
    }
    this.setState({ isViewableInApp: value, tableData }, () => {
      this.retrieveData()
    })
  }

  deleteCoupon = async couponId => {
    this.setState({
      deleteApiError: null,
    })

    try {
      logger.debug(`delete coupon id`, couponId)

      await couponApi.deleteCoupon(couponId)

      this.retrieveData()
    } catch (errors) {
      logger.error(`delete coupon error`, errors)

      this.setState({
        deleteApiError: errors,
      })
    }
  }

  putCoupon = async couponId => {
    this.setState({
      putApiError: null,
    })

    try {
      logger.debug(`put coupon id`, couponId)

      await couponApi.putCoupon(couponId)

      this.retrieveData()
    } catch (errors) {
      logger.error(`put coupon error`, errors)

      this.setState({
        putApiError: errors,
      })
    }
  }

  render() {
    const { viewStatus, couponsItems, permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission
    return (
      <div>
        <Header as="h1">
          <Icon name="ticket" />
          <Header.Content>クーポン</Header.Content>
        </Header>
        {/* 切り替え */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={CouponViewStatus.ALL}
            onClick={this.handleStatusMenuItemClick}
            active={_.isEqual(viewStatus, CouponViewStatus.ALL)}
          />

          <Menu.Item
            content="ゴミ箱"
            name={CouponViewStatus.TRASH}
            onClick={this.handleStatusMenuItemClick}
            active={_.isEqual(viewStatus, CouponViewStatus.TRASH)}
          />
        </Menu>

        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              as="a"
              href="/coupon"
              content="新規作成"
              icon="write"
              labelPosition="left"
            />
          </Menu.Item>
        </Menu>

        {!_.isEqual(viewStatus, CouponViewStatus.TRASH) && (
          <div>
            <Divider hidden clearing />
            <CouponsFilters handleViewableInAppFilterDropdownChange={this.handleViewableInAppFilterDropdownChange} />
          </div>
        )}

        <Divider hidden clearing />

        <CouponsMessages {...this.state} />

        {!_.isEmpty(couponsItems) && (
          <CouponsDataTable
            {...this.state}
            hasUpdatePermission={hasUpdatePermission}
            hasDeletePermission={hasDeletePermission}
            hasRestorePermission={hasRestorePermission}
            handleDataTableSelectionChange={this.handleDataTableSelectionChange}
            handleDataTablePageChange={this.handleDataTablePageChange}
            deleteCoupon={this.deleteCoupon}
            putCoupon={this.putCoupon}
          />
        )}
      </div>
    )
  }
}
