import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CategoryApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('CategoriesDropdown')
const categoryApi = new CategoryApi()

let getSelectedCategories
let getCategories

const propTypes = {
  /**
   * カテゴリ ID
   */
  categoryId: PropTypes.number,

  /**
   * カテゴリID一覧
   */
  categoryIds: PropTypes.arrayOf(PropTypes.number),

  /**
   * 必須項目
   */
  required: PropTypes.bool,

  /**
   * 幅を最大にするかどうか
   */
  fluid: PropTypes.bool,

  /**
   * ゴミ箱にあるコンテンツを表示させたくない場合
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * カテゴリ項目を変更したときに呼び出す外部関数
   */
  onChange: PropTypes.func,

  /**
   * Whether disabled or not
   */
  disabled: PropTypes.bool,

  /**
   * 複数選択を許可するかどうか
   */
  multiple: PropTypes.bool,

  /**
   * クリア可能な設定を使用すると、ユーザーはドロップダウンから選択を削除できます。
   */
  clearable: PropTypes.bool,
}

const defaultProps = {
  categoryId: null,
  categoryIds: [],
  required: false,
  fluid: true,
  ignoreTrashContents: true,
  disabled: false,
  multiple: false,
  clearable: false,
}

class CategoriesDropdown extends Component {
  state = {
    isBusy: false,
    categories: [],
    selectedCategories: [],
    currentPage: 0,
    totalPages: 0,
  }

  /**
   * ドロップダウンの値を変更したかどうか
   */
  isDropdownValueChanged = false

  /**
   * カテゴリ名検索クエリ
   */
  categorySearchQuery = ''

  componentDidMount() {
    const { multiple, categoryId, categoryIds = [] } = this.props
    const selectedCategoryIds = multiple ? categoryIds : [categoryId]

    this.retrieveSelectedCategories(selectedCategoryIds).then(() => {
      this.retrieveCategories()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { multiple, categoryId, categoryIds = [] } = this.props
    const { multiple: nextMultiple, categoryId: nextCategoryId, categoryIds: nextCategoryIds = [] } = nextProps
    const selectedCategoryIds = multiple ? categoryIds : [categoryId]
    const nextSelectedCategoryIds = nextMultiple ? nextCategoryIds : [nextCategoryId]
    const newState = this.isDropdownValueChange
      ? {}
      : {
          categories: [],
          currentPage: 0,
          totalPages: 0,
        }

    // ドロップダウンで値を更新したとき以外はリロード
    if (!_.isEqual(selectedCategoryIds, nextSelectedCategoryIds)) {
      this.setState(newState, () => {
        this.retrieveSelectedCategories(nextSelectedCategoryIds).then(() => {
          this.retrieveCategories()
        })
      })
    }

    this.categorySearchQuery = ''
    this.isDropdownValueChange = false
  }

  componentWillUnmount() {
    if (!_.isNil(getCategories)) {
      getCategories.cancel()
    }
    if (!_.isNil(getSelectedCategories)) {
      getSelectedCategories.cancel()
    }
  }

  /**
   * ドロップダウンにカテゴリ名を入れて検索を行ったときのハンドラ
   */
  handleInfiniteDropdownSearch = searchQuery => {
    this.categorySearchQuery = searchQuery

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveCategories()
      },
    )
  }

  /**
   * ドロップダウンの値を変更したときのハンドラ
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    if (this.props.onChange) {
      const name = this.props.multiple ? 'categoryIds' : 'categoryId'

      this.isDropdownValueChange = true
      this.props.onChange(event, { name, value })
    }
  }

  /**
   * カテゴリ詳細の取得
   */
  retrieveSelectedCategories = categoryIds =>
    new Promise(resolve => {
      const selectedCategoryIds = _.filter(categoryIds, categoryId => !_.isNil(categoryId))

      if (_.isEmpty(selectedCategoryIds)) {
        resolve()

        return
      }

      this.setState({ isBusy: true })
      getSelectedCategories = CancelablePromise(
        categoryApi.getCategories(this.getSelectedRequestQuery(selectedCategoryIds)),
      )
      getSelectedCategories.promise
        .then(response => {
          const selectedCategories = response.data
          const categories = this.state.categories

          categories.unshift(...selectedCategories)
          this.setState(
            {
              categories: _.uniqBy(categories, 'id'),
              selectedCategories,
              isBusy: false,
            },
            () => resolve(),
          )
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          // カテゴリ詳細が取得できなくても処理は続行させたいため resolve
          this.setState({ isBusy: false }, () => resolve())
        })
    })

  /**
   * カテゴリ一覧の取得
   */
  retrieveCategories = () => {
    this.setState({ isBusy: true })

    getCategories = CancelablePromise(categoryApi.getCategories(this.getRequestQuery()))
    getCategories.promise
      .then(response => {
        let categories = _.concat(this.state.categories, response.data)
        const { selectedCategories } = this.state
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

        // id でソート
        categories = _.sortBy(categories, o => o.id * -1)
        // 選択中のカテゴリは先頭に表示
        categories.unshift(...selectedCategories)

        this.setState({
          isBusy: false,
          categories: _.uniqBy(categories, 'id'),
          totalPages,
          currentPage,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get categories error', error)

        this.setState({ isBusy: false })
      })
  }

  getSelectedRequestQuery = categoryIds => {
    // 絞り込み
    const filtering = []

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    const filterOperator = categoryIds.length > 1 ? 'IN' : '='
    const filterValue = categoryIds.join('__AND__')
    filtering.push(`id ${filterOperator} '${filterValue}'`)

    const query = {
      itemsPerPage: categoryIds.length,
      currentPage: 1,
      filtering,
      sorting: ['-id'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { currentPage, categories } = this.state

    // 絞り込み
    const filtering = []

    // カテゴリ名検索文字列があった場合は、カテゴリ名で絞り込むため filtering に追加
    if (!_.isEmpty(this.categorySearchQuery)) {
      filtering.push(`name LIKE '%${this.categorySearchQuery}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    // 取得済みのカテゴリ一覧は除外して一覧を取得: 取得済みのカテゴリを再取得しないため
    _.each(categories, category => filtering.push(`id <> '${category.id}'`))

    const query = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
      sorting: ['-id'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const { fluid, required, disabled, multiple, clearable, categoryId, categoryIds = [] } = this.props
    const options = _.map(this.state.categories, category => ({
      text: category.name,
      value: category.id,
      icon: 'folder open',
    }))

    if (!multiple && !required) {
      // 必須項目ではない場合、解除項目を先頭に付与
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }

    return (
      <InfiniteDropdown
        fluid={fluid}
        placeholder="カテゴリを選択"
        noResultsMessage="該当するカテゴリが見つかりません"
        loading={this.state.isBusy}
        options={options}
        value={multiple ? categoryIds : categoryId}
        onLoadMore={this.retrieveCategories}
        onSearch={this.handleInfiniteDropdownSearch}
        onChange={this.handleInfiniteDropdownChange}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
        disabled={disabled}
        multiple={multiple}
        clearable={clearable}
      />
    )
  }
}

CategoriesDropdown.propTypes = propTypes
CategoriesDropdown.defaultProps = defaultProps

export default CategoriesDropdown
