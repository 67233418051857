import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { CpApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('CpEditModal')
const cpApi = new CpApi()
let sendCp

const propTypes = {
  /**
   * 編集対象の CP (作成の場合は空)
   */
  cp: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * 作成・更新の API 通信が成功したときに呼び出す外部関数
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  cp: {},
  open: false,
}

class CpEditModal extends Component {
  state = {
    isFormValid: false,
    isFormModified: false,
    isBusy: false,
    apiError: null,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されてるかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.cp, nextProps.cp)) {
      this.setState({ isFormModified: false })

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.cp) {
        this.initializeFormValues(this.props.cp)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendCp)) {
      sendCp.cancel()
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(currentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendCp = CancelablePromise(this.sendData(submitFormData))
    sendCp.promise
      .then(response => {
        const cp = response.data

        // 更新 or 作成に成功したら CP 一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.initializeFormValues(cp)

        resetForm(this.initialFormValues)

        this.setState(
          {
            isFormModified: false,
            isBusy: false,
          },
          () => {
            if (_.isEmpty(this.props.cp) && this.props.onClose) {
              this.props.onClose()
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('handle form submit error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * フォームの初期化
   * @param {Object} cp - CP データ
   */
  initializeFormValues(cp) {
    this.initialFormValues.name = cp.name

    logger.debug('initialized form values', this.initialFormValues)
  }

  /**
   * API にデータを送信
   */
  sendData = submitCpData => {
    if (_.isEmpty(this.props.cp)) {
      return cpApi.postCp(submitCpData)
    }

    const cpId = this.props.cp.id
    return cpApi.patchCp(cpId, { cpUpdateValues: submitCpData })
  }

  render() {
    return (
      <Modal
        className="CpEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.props.cp) ? 'CP の作成' : 'CP の編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/* CP 名入力フィールド */}
            <Form.Input name="name" label="CP 名" placeholder="CP 名を入力してください" required />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.props.cp) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || !this.state.isFormModified}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

CpEditModal.propTypes = propTypes
CpEditModal.defaultProps = defaultProps

export default CpEditModal
