import React, { Component } from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Dimmer, Loader, Segment, Message, Icon, Image, Header } from 'semantic-ui-react'

import logoDark from '../../images/logo-dark.png'

class Home extends Component {
  state = {
    // helloWorld: {
    //   message: 'Hello, World!',
    //   name: 'trill-admin',
    //   timestamp: 1481699886554
    // },
    isBusy: false,
  }

  render() {
    const { isBusy, helloWorld } = this.state

    return (
      <Segment basic>
        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>
        <Segment basic padded>
          <Image src={logoDark} alt="TRILL" centered size="medium" />
          <Header textAlign="center">管理ツール</Header>
        </Segment>
        {helloWorld && (
          <Message info icon>
            <Icon name="idea" />

            <Message.Content>
              <Message.Header>{helloWorld.message}</Message.Header>

              <Message.List>
                <Message.Item>{helloWorld.name}</Message.Item>

                <Message.Item>
                  <FormattedDate value={helloWorld.timestamp} day="numeric" month="long" year="numeric" />
                  &nbsp;
                  <FormattedTime value={helloWorld.timestamp} hour="numeric" minute="numeric" second="numeric" />
                </Message.Item>
              </Message.List>
            </Message.Content>
          </Message>
        )}
      </Segment>
    )
  }
}

export default Home
