import React, { Component } from 'react'
import { Modal, Button, Dimmer, Loader, Image } from 'semantic-ui-react'

import _ from 'lodash'
import ApiErrorMessage from './ApiErrorMessage'

class CouponsRemoveModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      open: false,
      apiError: null,
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false, apiError: null })

  handleRemoveButtonClick = async () => {
    const { couponId } = this.props

    if (this.props.deleteCoupon) {
      this.setState({ isBusy: true })
      await this.props.deleteCoupon(couponId)
      this.setState({ isBusy: false, apiError: this.props.apiError })
    }

    if (!this.props.apiError) {
      this.closeModal()
    }
  }

  render() {
    const { open, isBusy, apiError } = this.state
    const { hasDeletePermission } = this.props
    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasDeletePermission} icon="trash" />}
      >
        {_.has(this.props, 'coupon.id') && <Modal.Header>クーポン ID {this.props.coupon.id} を削除</Modal.Header>}
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />
          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>
          {_.has(this.props, 'coupon.id') && (
            <div>
              クーポン ID <b>{this.props.coupon.id}</b> を削除しますか？
            </div>
          )}
          {_.has(this.props, 'coupon.image.thumbnail') && (
            <Image src={_.get(this.props, 'coupon.image.thumbnail')} centered size="tiny" />
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />

          <Button negative content="削除" onClick={this.handleRemoveButtonClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CouponsRemoveModal
