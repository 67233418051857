import React, { Component } from 'react'
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react'

import _ from 'lodash'
import ApiErrorMessage from './ApiErrorMessage'

class CouponsUndoModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      open: false,
      apiError: null,
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false, apiError: null })

  handleUndoButtonClick = async () => {
    const { couponId } = this.props

    if (this.props.putCoupon) {
      this.setState({ isBusy: true })
      await this.props.putCoupon(couponId)
      this.setState({ isBusy: false, apiError: this.props.apiError })
    }

    if (!this.props.apiError) {
      this.closeModal()
    }
  }

  render() {
    const { open, isBusy, apiError } = this.state
    const { hasRestorePermission } = this.props
    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasRestorePermission} icon="undo" />}
      >
        {_.has(this.props, 'coupon.id') && <Modal.Header>クーポン ID {this.props.coupon.id} を戻す</Modal.Header>}
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />
          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>
          {_.has(this.props, 'coupon.id') && (
            <div>
              クーポン ID <b>{this.props.coupon.id}</b> を戻しますか？
            </div>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />

          <Button positive content="戻す" onClick={this.handleUndoButtonClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CouponsUndoModal
