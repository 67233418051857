import React, { Component } from 'react'
import { Form, Message, Button } from 'semantic-ui-react'
import { Formik } from 'formik'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import '../ReactDatesCustomTheme'
import { Email, TrillAcceptedEmail } from '../Validation'

class PvReportForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      focusedInput: null,
    }
  }

  validate = values => {
    const errors = {}

    if (!values.startDate) errors.startDate = '開始日は必須です'
    if (!values.endDate) errors.endDate = '終了日は必須です'

    if (!values.email) {
      errors.email = '必須です'
    } else if (!Email(values.email)) {
      errors.email = 'メールアドレスの形式が正しくありません'
    } else if (!TrillAcceptedEmail(values.email)) {
      errors.email = '@yahoo-corp.jp か @trill-corp.jp のメールアドレスを入力してください'
    }

    return errors
  }

  handleSubmit = async (values, actions) => {
    const newMediaArticlePvReport = {
      startDate: values.startDate.format('YYYY-MM-DD'),
      endDate: values.endDate.format('YYYY-MM-DD'),
      email: values.email,
    }

    await this.props.postReportForm(newMediaArticlePvReport)

    if (this.props.success) actions.resetForm({ email: '' })
  }

  render() {
    const maximumDaysBetweenDates = 30
    return (
      <Formik initialValues={{ email: '' }} validate={this.validate} onSubmit={this.handleSubmit}>
        {({
          values,
          setFieldTouched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Input required label="レポート期間">
              <DateRangePicker
                startDate={values.startDate}
                startDateId="startDate"
                startDatePlaceholderText="開始日"
                endDate={values.endDate}
                endDateId="endDate"
                endDatePlaceholderText="終了日"
                isOutsideRange={day => {
                  if (this.state.focusedInput === 'endDate' && !values.startDate) {
                    return true
                  }

                  return (
                    this.state.focusedInput === 'endDate' &&
                    (day.isBefore(values.startDate) ||
                      day.isAfter(values.startDate.clone().add(maximumDaysBetweenDates, 'days')))
                  )
                }}
                onDatesChange={({ startDate, endDate }) => {
                  const shouldEndDateBeCleard = (() =>
                    startDate && endDate
                      ? startDate.isBefore(endDate.clone().subtract(maximumDaysBetweenDates, 'days')) ||
                        startDate.isAfter(endDate)
                      : false)()

                  setFieldValue('startDate', startDate)
                  setFieldValue('endDate', shouldEndDateBeCleard ? null : endDate)
                }}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                onClose={() => {
                  setFieldTouched('startDate', true)
                  setFieldTouched('endDate', true)
                }}
              />
            </Form.Input>
            {errors.startDate && touched.startDate ? <Message negative>{errors.startDate}</Message> : null}
            {errors.endDate && touched.endDate ? <Message negative>{errors.endDate}</Message> : null}
            <Form.Input
              required
              type="text"
              name="email"
              label="レポート送付先メールアドレス"
              placeholder="guesthouseid@trill-corp.jp"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.email && touched.email ? <Message negative>{errors.email}</Message> : null}
            <Button icon="cloud download" disabled={!isValid} type="submit" color="blue" content="ダウンロード" />
          </Form>
        )}
      </Formik>
    )
  }
}

export default PvReportForm
