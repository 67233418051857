import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { TagGroupApi } from 'trill-api-admin-client'
import { Modal, Button, Label, Dimmer, Loader, Grid, Segment, Divider } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'

import DataTable from './DataTable'
import CategoryDropdown from './CategoriesDropdown'
import ApiErrorMessage from './ApiErrorMessage'
import TagsDropdown from './TagsDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('TagGroupEditModal')
const tagGroupApi = new TagGroupApi()
let sendTagGroup
let getTagGroupTags
let deleteTagGroupTag
const putTagGroupTags = []

const propTypes = {
  /**
   * 編集対象のタグ・グループ
   */
  tagGroup: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したときに呼び出す外部関数
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  tagGroup: {},
  open: false,
}

class TagGroupEditModal extends Component {
  state = {
    tagGroup: {},
    isFormValid: false,
    isFormModified: false,
    isFormSearchValid: false,
    isBusy: false,
    isTagsBusy: false,
    apiError: null,
    relatedTagsError: null,
    categoryId: null,
    // 関連するタグ一覧表示用の state
    tags: [],
    totalItems: 0,
    totalPages: 0,
    itemsPerPage: 5,
    currentPage: 1,
    sorting: { name: 'asc' },
    filtering: { name: '' },
    isHiddenTagsTable: true,
    tagsDropdownValues: [],
    isAddModalOpen: false,
    isDeleteModalOpen: false,
    deleteTag: null,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されているかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.tagGroup, nextProps.tagGroup)) {
      this.setState(
        {
          tagGroup: nextProps.tagGroup,
          isFormModified: false,
          categoryId: _.get(nextProps, 'tagGroup.categoryId', null),
          tags: [],
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 5,
          sorting: { name: 'asc' },
          filtering: { name: '' },
          isBusy: true,
        },
        () => {
          this.retrieveTagGroupTags()
            .then(() => {
              this.setState({
                isBusy: false,
                isHiddenTagsTable: _.size(this.state.tags) === 0,
              })
            })
            .catch(() => {
              this.setState({ isBusy: false })
            })
        },
      )

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.tagGroup) {
        this.initializeFormValues(this.props.tagGroup)

        this.refs.form.reset(this.props.tagGroup)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendTagGroup)) {
      sendTagGroup.cancel()
    }
    if (!_.isNil(deleteTagGroupTag)) {
      deleteTagGroupTag.cancel()
    }
    if (!_.isNil(getTagGroupTags)) {
      getTagGroupTags.cancel()
    }
    if (!_.isEmpty(putTagGroupTags)) {
      _.each(putTagGroupTags, putTagGroupTag => putTagGroupTag.cancel())
      putTagGroupTags.length = 0
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }

    // 初期のフォームデータと比較
    _.each(currentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendTagGroup = CancelablePromise(this.sendData(submitFormData))
    sendTagGroup.promise
      .then(response => {
        const tagGroup = response.data

        // 更新 or 作成に成功したらタグ・グループ一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        // フォームデータの更新
        this.initializeFormValues(tagGroup)

        resetForm(this.initialFormValues)

        this.setState({
          isFormModified: false,
          isBusy: false,
          tagGroup,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send data error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * カテゴリ選択ドロップダウンの値を変更したときのハンドラ
   */
  handleCategoryDropdownChange = (event, { value }) => {
    this.setState({ categoryId: value })
  }

  /**
   * 関連タグテーブルのソート変更時のハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const sorting = sort

    this.setState({ sorting }, () => {
      this.retrieveTagGroupTags()
    })
  }

  /**
   * 関連タグテーブルのページ情報変更時のハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    this.setState(
      {
        currentPage,
        itemsPerPage,
      },
      () => {
        this.retrieveTagGroupTags()
      },
    )
  }

  /**
   * 関連タグ追加用モーダルの追加ボタンを押したときのハンドラ
   */
  handleAddModalApproveButtonClick = () => {
    this.setState(
      {
        isTagsBusy: true,
        // タグがなかった場合、テーブルにローディングを出せないため全体のローディングを出す
        isBusy: _.size(this.state.tags) === 0,
        relatedTagsError: null,
      },
      () => {
        const tagGroup = this.state.tagGroup

        putTagGroupTags.length = 0
        _.each(this.state.tagsDropdownValues, tagId => {
          putTagGroupTags.push(
            CancelablePromise(
              new Promise((resolve, reject) => {
                tagGroupApi
                  .putTagGroupTag(tagGroup.id, tagId)
                  .then(() => {
                    resolve()
                  })
                  .catch(error => {
                    if (error.isCanceled) {
                      reject(error)
                      return
                    }

                    logger.error(`put tag group tag tagGroupId #${tagGroup.id} tagId #${tagId} error`, error)

                    resolve()
                  })
              }),
            ),
          )
        })

        Promise.all(_.map(putTagGroupTags, putTagGroupTag => putTagGroupTag.promise))
          .then(() => {
            // 一覧の更新
            if (this.props.onSuccessDataChanged) {
              this.props.onSuccessDataChanged()
            }

            return this.retrieveTagGroupTags()
          })
          .then(() => {
            // 登録に成功したら TagsDropdown の値はリセット
            this.setState({
              tagsDropdownValues: [],
              isAddModalOpen: false,
              isBusy: false,
              isHiddenTagsTable: false,
            })
          })
          .catch(error => {
            if (error.isCanceled) {
              return
            }

            logger.error(`put tag group tag tagGroupId #${tagGroup.id} error`, error)

            this.setState({
              isBusy: false,
              isTagsBusy: false,
              relatedTagsError: error,
            })
          })
      },
    )
  }

  /**
   * 関連タグ削除用モーダルの削除ボタンを押したときのハンドラ
   */
  handleDeleteModalApproveButtonClick = () => {
    const tagGroup = this.state.tagGroup
    const tag = this.state.deleteTag

    this.setState({
      isTagsBusy: true,
      relatedTagsError: null,
    })

    deleteTagGroupTag = CancelablePromise(tagGroupApi.deleteTagGroupTag(tagGroup.id, tag.id))
    deleteTagGroupTag.promise
      .then(() => {
        // 関連タグに変更があったため一覧の更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        // 削除に成功したら削除対象として保存していた deleteTag はリセット
        this.setState({ deleteTag: null })

        // 関連タグを再度取得
        return this.retrieveTagGroupTags()
      })
      .then(() => {
        // 削除時に関連タグが 0 個の場合、関連タグテーブルを非表示に設定
        this.setState({
          isDeleteModalOpen: false,
          isHiddenTagsTable: _.size(this.state.tags) === 0,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`delete tag group tag tagGroupId #${tagGroup.id} tagId #${tag.id} error`, error)

        this.setState({
          isTagsBusy: false,
          relatedTagsError: error,
        })
      })
  }

  /**
   * タグ追加用モーダルに表示するタグドロップダウンからタグを変更したときのハンドラ
   */
  handleAddModalDropdownChange = (event, { value }) => {
    this.setState({ tagsDropdownValues: value })
  }

  /**
   * タグ追加用モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleAddModalCancelButtonClick = () => {
    this.setState({
      isAddModalOpen: false,
      tagsDropdownValues: [],
    })
  }

  /**
   * 関連タグ削除用モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleDeleteModalCancelButtonClick = () => {
    this.setState({
      deleteTag: null,
      isDeleteModalOpen: false,
    })
  }

  /**
   * タグ・グループ名で絞り込むインプットの値を変更したときのハンドラ
   */
  handleTagNameInputChange = (event, data) => {
    const filtering = this.state.filtering
    filtering.name = data.value

    this.setState({ filtering })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = event => {
    event.preventDefault()

    this.retrieveTagGroupTags()
  }

  /**
   * 関連タグ追加用モーダル画面を開く関数
   */
  openTagAddModal() {
    this.setState({ isAddModalOpen: true })
  }

  /**
   * 関連タグ削除用モーダルを開く関数
   * @param {Object} tag - 関連タグデータ
   */
  openDeleteModal(tag) {
    this.setState({
      isDeleteModalOpen: true,
      deleteTag: tag,
    })
  }

  /**
   * フォームの初期化
   * @param {Object} tagGroup - タグ・グループデータ
   */
  initializeFormValues(tagGroup) {
    this.initialFormValues.name = tagGroup.name
    this.initialFormValues.categoryId = tagGroup.categoryId
  }

  /**
   * API にデータを送信
   */
  sendData = submitTagGroupData => {
    const tagGroup = this.state.tagGroup
    if (_.isEmpty(tagGroup)) {
      if (_.isNil(submitTagGroupData.categoryId)) {
        _.unset(submitTagGroupData, 'categoryId')
      }

      return tagGroupApi.postTagGroup(submitTagGroupData)
    }

    const tagGroupId = tagGroup.id
    _.extend(submitTagGroupData, {
      categoryId: _.defaultTo(submitTagGroupData.categoryId, 0),
    })
    return tagGroupApi.patchTagGroup(tagGroupId, {
      tagGroupUpdateValues: submitTagGroupData,
    })
  }

  /**
   * 関連タグ一覧覧取得
   */
  retrieveTagGroupTags = () =>
    new Promise((resolve, reject) => {
      const tagGroup = this.state.tagGroup
      if (_.isEmpty(tagGroup) || !_.has(tagGroup, 'id')) {
        resolve()

        return
      }

      const tagGroupId = tagGroup.id

      this.setState({
        isTagsBusy: true,
        relatedTagsError: null,
      })

      getTagGroupTags = CancelablePromise(tagGroupApi.getTagGroupTags(tagGroupId, this.getRequestQuery()))
      getTagGroupTags.promise
        .then(response => {
          const tags = response.data
          const responseHeader = response.header
          const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
          const totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
          const itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 5), 10)
          const paginationCurrentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
          const currentPage = paginationCurrentPage === 0 ? 1 : paginationCurrentPage

          logger.debug('get tag group tags', {
            tags,
            totalPages,
            totalItems,
            currentPage,
            itemsPerPage,
          })
          this.setState({
            tags,
            totalPages,
            totalItems,
            currentPage,
            itemsPerPage,
            isTagsBusy: false,
          })

          resolve()
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          logger.error('retrieve tags error', { error })

          this.setState({
            tags: [],
            totalPages: 0,
            totalItems: 0,
            currentPage: 1,
            itemsPerPage: 5,
            isTagsBusy: false,
            relatedTagsError: error,
          })

          reject(error)
        })
    })

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const totalPages = Math.ceil(this.state.totalItems / this.state.itemsPerPage)
    const currentPage = totalPages > 0 && this.state.currentPage > totalPages ? totalPages : this.state.currentPage

    const itemsPerPage = this.state.itemsPerPage

    // ソート
    const sorting = _.map(this.state.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    // フィルタリング
    const filtering = []
    if (!_.isEmpty(this.state.filtering.name)) {
      let filteringName = this.state.filtering.name
      if (filteringName.match(/\,/)) { // eslint-disable-line
        filteringName = ''
      }

      filtering.push(`name LIKE "%${filteringName}%"`)
    }

    const query = {
      currentPage,
      itemsPerPage,
      sorting,
      filtering,
    }

    logger.debug('get request query', query)

    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const formErrorLabel = <Label color="red" pointing />

    return (
      <Modal
        className="TagGroupEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.state.tagGroup) ? 'タグ・グループの作成' : 'タグ・グループの編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* API エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            <Grid columns="equal">
              <Grid.Column>
                {/* タグ・グループ入力フィールド */}
                <Form.Input
                  name="name"
                  label="タグ・グループ名"
                  placeholder="タグ・グループ名を入力してください"
                  required
                />
              </Grid.Column>

              <Grid.Column>
                {/* カテゴリ入力フィールド */}
                <Form.Field>
                  <label>カテゴリ</label>

                  <CategoryDropdown categoryId={this.state.categoryId} onChange={this.handleCategoryDropdownChange} />

                  <Form.Input name="categoryId" value={this.state.categoryId} className="isHidden" />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>

          <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
            <Divider hidden clearing />

            {/* 関連タグ */}
            {!this.state.isHiddenTagsTable && (
              <Form.Field>
                <label>関連タグ</label>

                {/* 登録されている関連タグ */}
                <Segment loading={this.state.isTagsBusy} padded>
                  {/* 検索エリア */}
                  <Input
                    name="tag-group-search"
                    type="text"
                    placeholder="タグ名で検索"
                    action
                    fluid
                    value={this.state.filtering.name}
                    onChange={this.handleTagNameInputChange}
                    validations={{ matchRegexp: /^((?!,).)*$/i }}
                    validationErrors={{
                      matchRegexp: 'キーワードに不正な記号があるため検索できません',
                    }}
                    errorLabel={formErrorLabel}
                  >
                    <input />

                    <Button
                      icon="search"
                      onClick={this.handleSearchButtonClick}
                      disabled={!this.state.isFormSearchValid}
                    />
                  </Input>

                  {/* API エラーメッセージ */}
                  <ApiErrorMessage error={this.state.relatedTagsError} />

                  {!_.isEmpty(this.state.tags) && (
                    <div>
                      <Divider hidden />

                      {/* 関連タグ表示テーブル */}
                      <DataTable
                        items={this.state.tags}
                        sort={this.state.sorting}
                        currentPage={this.state.currentPage}
                        totalPages={this.state.totalPages}
                        itemsPerPage={this.state.itemsPerPage}
                        onSelectionChange={this.handleDataTableSelectionChange}
                        onPageChange={this.handleDataTablePageChange}
                        rowKey="id"
                        columns={[
                          {
                            collapsing: false,
                            label: 'タグ名',
                            field: 'name',
                          },
                          {
                            label: '操作',
                            align: 'center',
                            render: tag => (
                              <Button
                                secondary
                                key={tag.id}
                                icon="trash alternate outline"
                                onClick={event => {
                                  event.preventDefault()
                                  this.openDeleteModal(tag)
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  )}
                </Segment>
              </Form.Field>
            )}
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.state.tagGroup) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || !this.state.isFormModified}
          />

          {!_.isEmpty(this.state.tagGroup) && (
            <Button
              primary
              content="関連タグの追加"
              onClick={event => {
                event.preventDefault()
                this.openTagAddModal()
              }}
            />
          )}
        </Modal.Actions>

        {/* タグ追加用のモーダル画面 */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isAddModalOpen}
          onClose={this.handleAddModalCancelButtonClick}
          closeOnDimmerClick={false}
        >
          <Modal.Header>タグの追加</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.isTagsBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            <TagsDropdown value={this.state.tagsDropdownValues} onChange={this.handleAddModalDropdownChange} />
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleAddModalCancelButtonClick} />

            <Button positive content="追加" onClick={this.handleAddModalApproveButtonClick} />
          </Modal.Actions>
        </Modal>

        {/* タグ削除用のモーダル画面  */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isDeleteModalOpen}
          onClose={this.handleDeleteModalCancelButtonClick}
          closeOnDimmerClick={false}
        >
          <Modal.Header>タグの削除</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.isTagsBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* API エラーメッセージ */}
            <ApiErrorMessage error={this.state.relatedTagsError} />
            {_.get(this.state, 'deleteTag.name')} を削除しますか？
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleDeleteModalCancelButtonClick} />

            <Button negative content="削除" onClick={this.handleDeleteModalApproveButtonClick} />
          </Modal.Actions>
        </Modal>
      </Modal>
    )
  }
}

TagGroupEditModal.propTypes = propTypes
TagGroupEditModal.defaultProps = defaultProps

export default TagGroupEditModal
