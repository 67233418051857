import React from 'react'
import { Button } from 'semantic-ui-react'

import DataTable from './DataTable'

const OutsourcedArticlesCsvDataTable = ({
  outsourcedArticlesCsv,
  tableData,
  handleDataTablePageChange,
  handleDownloadOutsourcedArticlesCsv,
}) => {
  const columns = [
    {
      label: 'ID',
      align: 'center',
      render: item => item.id,
    },
    {
      label: 'ユーザー名',
      align: 'center',
      render: item => item.username,
    },
    {
      label: 'ステータス',
      align: 'center',
      render: item => item.finished,
    },
    {
      label: 'リクエスト数',
      align: 'center',
      render: item => item.requestedCount,
    },
    {
      label: '終了数',
      align: 'center',
      render: item => item.finishedCount,
    },
    {
      label: 'CSVダウンロード',
      align: 'center',
      render: item => {
        return (
          <Button
            disabled={!(item.finished === '完了')}
            primary
            icon="download"
            content="CSVダウンロード"
            onClick={() => handleDownloadOutsourcedArticlesCsv(item.id)}
          />
        )
      },
    },
  ]

  return (
    <DataTable
      rowKey="id"
      columns={columns}
      items={outsourcedArticlesCsv}
      itemsPerPage={tableData.itemsPerPage}
      currentPage={tableData.currentPage}
      totalPages={tableData.totalPages}
      onPageChange={handleDataTablePageChange}
    />
  )
}

export default OutsourcedArticlesCsvDataTable
