import React, { Component } from 'react'
import { Link } from 'react-router'
import { FormattedDate, FormattedTime } from 'react-intl'
import _ from 'lodash'
import {
  Header,
  Icon,
  Dimmer,
  Loader,
  Menu,
  Button,
  Divider,
  Message,
  Image,
  Modal,
  Dropdown,
  Popup,
  Label,
  Statistic,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import moment from 'moment'
import { TopApi } from 'trill-api-admin-client'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import RecommendedArticleRemoveModal from '../../components/RecommendedArticleRemoveModal'
import PublishDateRangePicker from '../../components/PublishDatetimeRangePicker'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('RecommendedArticles')
const topApi = new TopApi()
let getRecommendedArticles
let putRecommendedArticle

/**
 * 記事の状態
 * @enum {string}
 */
const RecommendedArticleStatus = {
  /** APP (1枠目) */
  APP: 'app',
  /** APP (2枠目) */
  APP_SECOND_ORDER: 'app_second_order',
  /** WEB */
  WEB: 'web',
  /** 予約中のWEB */
  RESERVED_WEB: 'reserved_web',
  /** ゴミ箱 */
  TRASH: 'trash',
}

class RecommendedArticles extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      isRecommendedArticleRemoveModalOpen: false,
      removeRecommendedArticle: null,
      status: RecommendedArticleStatus.WEB,
      articles: [],
      isUndoModalOpen: false,
      undoRecommendedArticle: null,
      apiError: null,
      undoApiError: null,
      currentDisplayedRecommendedArticles: {
        app: [],
        web: [],
      },
      permission: GetPermission('recommendedArticle'),
      isFormSearchValid: false,
    }

    _.each(RecommendedArticleStatus, status => {
      // APP の場合は公開日時でソート WEB の場合は順番でソート
      const sorting = _.startsWith(status, RecommendedArticleStatus.APP)
        ? { publishDatetime: 'desc' }
        : { order: 'asc' }

      _.extend(this.state, {
        [status]: {
          currentPage: 1,
          itemsPerPage: 50,
          totalPages: 0,
          sorting,
          filtering: {
            title: '',
            dateRangeStartAt: moment().startOf('month'),
            dateRangeEndAt: moment().endOf('month'),
            order: null,
            clearDateRange: true,
          },
        },
      })
    })
  }

  componentDidMount() {
    this.setState({ isBusy: true })

    // 現在表示中の編集部おすすめ記事を取得してから一覧を取得
    Promise.all([
      this.getCurrentDisplayedRecommendedArticles(RecommendedArticleStatus.APP),
      this.getCurrentDisplayedRecommendedArticles(RecommendedArticleStatus.WEB),
    ])
      .then(([app, web]) => {
        const currentDisplayedRecommendedArticles = this.state.currentDisplayedRecommendedArticles
        currentDisplayedRecommendedArticles.app = app
        currentDisplayedRecommendedArticles.web = web

        this.setState({ currentDisplayedRecommendedArticles }, () => {
          this.retrieveRecommendedArticles()
        })
      })
      .catch(error => {
        logger.error('get current displayed recommended articles error', error)

        this.retrieveRecommendedArticles()
      })
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getRecommendedArticles)) {
      getRecommendedArticles.cancel()
    }
    if (!_.isNil(putRecommendedArticle)) {
      putRecommendedArticle.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveRecommendedArticles()
    })
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleRecommendedArticleRemoveModalClose = () => {
    this.setState({
      isRecommendedArticleRemoveModalOpen: false,
      removeRecommendedArticle: null,
    })
  }

  /**
   * データテーブルのヘッダーをタップして並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveRecommendedArticles()
    })
  }

  /*
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveRecommendedArticles()
    })
  }

  /**
   * 編集部おすすめのデータに変更があったときのハンドラ
   */
  handleRecommendedArticleDataChanged = () => {
    this.setState({ isBusy: true })

    // 現在表示中の編集部おすすめ記事を取得してから一覧を取得
    Promise.all([
      this.getCurrentDisplayedRecommendedArticles(RecommendedArticleStatus.APP),
      this.getCurrentDisplayedRecommendedArticles(RecommendedArticleStatus.WEB),
    ])
      .then(([app, web]) => {
        const currentDisplayedRecommendedArticles = this.state.currentDisplayedRecommendedArticles
        currentDisplayedRecommendedArticles.app = app
        currentDisplayedRecommendedArticles.web = web

        this.setState({ currentDisplayedRecommendedArticles }, () => {
          this.retrieveRecommendedArticles()
        })
      })
      .catch(error => {
        logger.error('get current displayed recommended articles error', error)

        this.retrieveRecommendedArticles()
      })
  }

  /**
   * 公開範囲で絞り込むデータレンジピッカーの値に変更があったときのハンドラ
   */
  handleDateRangePickerEvent = (event, picker) => {
    if (event.type === 'apply' || event.type === 'cancel') {
      const status = this.state.status
      const tableData = this.state[status]
      let startDate
      let endDate
      let clearDateRange

      if (event.type === 'apply') {
        startDate = picker.startDate
        endDate = picker.endDate
        clearDateRange = false
      } else if (event.type === 'cancel') {
        startDate = moment().startOf('day')
        endDate = moment().endOf('day')
        clearDateRange = true
      }

      tableData.filtering.dateRangeStartAt = startDate
      tableData.filtering.dateRangeEndAt = endDate
      tableData.filtering.clearDateRange = clearDateRange

      this.setState({ [status]: tableData }, () => {
        this.retrieveRecommendedArticles()
      })
    }
  }

  /**
   * タイトルで絞り込むインプットの値を変更したときのハンドラ
   */
  handleTitleInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.title = data.value

    this.setState({ [status]: tableData })
  }

  handleOrderDropdownChange = (event, { value }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.order = value

    this.setState({ [status]: tableData }, () => {
      this.retrieveRecommendedArticles()
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveRecommendedArticles()
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const recommendedArticleId = this.state.undoRecommendedArticle.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putRecommendedArticle = CancelablePromise(topApi.putRecommendedArticle(recommendedArticleId))
    putRecommendedArticle.promise
      .then(() =>
        // 現在表示中の編集部おすすめ記事を取得してから一覧を取得
        Promise.all([
          this.getCurrentDisplayedRecommendedArticles(RecommendedArticleStatus.APP),
          this.getCurrentDisplayedRecommendedArticles(RecommendedArticleStatus.WEB),
        ]),
      )
      .then(([app, web]) => {
        const currentDisplayedRecommendedArticles = this.state.currentDisplayedRecommendedArticles
        currentDisplayedRecommendedArticles.app = app
        currentDisplayedRecommendedArticles.web = web

        this.setState(
          {
            isBusy: false,
            isUndoModalOpen: false,
            undoRecommendedArticle: null,
            currentDisplayedRecommendedArticles,
          },
          () => {
            this.retrieveRecommendedArticles()
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`put top recommendedArticleId #${recommendedArticleId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoRecommendedArticle: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoRecommendedArticle: null,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   */
  openUndoModal(recommendedArticle) {
    this.setState({
      isUndoModalOpen: true,
      undoRecommendedArticle: recommendedArticle,
    })
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} recommendedArticle - 編集部おすすめデータ
   */
  openRecommendedArticleRemoveModal(recommendedArticle) {
    this.setState({
      isRecommendedArticleRemoveModalOpen: true,
      removeRecommendedArticle: recommendedArticle,
    })
  }

  /**
   * 現在表示中の編集部おすすめ記事を取得
   */
  getCurrentDisplayedRecommendedArticles = target =>
    new Promise((resolve, reject) => {
      const header = {
        currentPage: 1,
        itemsPerPage: 1,
        sorting: ['-publishDatetime'],
      }

      if (_.isEqual(target, RecommendedArticleStatus.WEB)) {
        const getRecommendedArticlesPromises = []

        _.times(5, order => {
          const filtering = [`target = '${target}'`]
          filtering.push(`publishDatetime <= ${moment().toISOString()}`)
          filtering.push(`order = ${order + 1}`)
          filtering.push('deleted_at IS NULL')
          header.filtering = filtering

          getRecommendedArticlesPromises.push(topApi.getRecommendedArticles(header))
        })

        Promise.all(getRecommendedArticlesPromises)
          .then(results => {
            const displayedRecommendedArticles = _.map(results, result => result.data)

            resolve(_.flatten(displayedRecommendedArticles))
          })
          .catch(error => {
            reject(error)
          })
      } else {
        const filtering = [`target = '${target}'`]
        filtering.push(`publishDatetime <= ${moment().toISOString()}`)
        filtering.push('deleted_at IS NULL')
        header.filtering = filtering

        topApi
          .getRecommendedArticles(header)
          .then(result => {
            const displayedRecommendedArticle = result.data

            resolve(displayedRecommendedArticle)
          })
          .catch(error => {
            reject(error)
          })
      }
    })

  /**
   * 編集部おすすめ記事一覧を取得
   */
  retrieveRecommendedArticles = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getRecommendedArticles = CancelablePromise(topApi.getRecommendedArticles(this.getRequestQuery()))
    getRecommendedArticles.promise
      .then(response => {
        logger.debug('get recommended articles response', response)

        const articles = response.data
        const responseHeader = response.header
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)

        this.setState({
          isBusy: false,
          articles,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve recommended articles error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totaPage = 0
        tableData.totalItems = 0

        this.setState({
          isBusy: false,
          articles: [],
          [status]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 表示中のステータス
    const status = this.state.status
    // 表示中のテーブルデータを取得
    const tableData = this.state[status]

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    // 1 ページあたりに含めるデータお数
    const itemsPerPage = tableData.itemsPerPage

    const filtering = []
    if (_.isEqual(status, RecommendedArticleStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    if (_.isEqual(status, RecommendedArticleStatus.APP)) {
      // APP を選択している場合
      filtering.push("target = 'app'")
      filtering.push('order = 1')
    } else if (_.isEqual(status, RecommendedArticleStatus.APP_SECOND_ORDER)) {
      filtering.push("target = 'app'")
      filtering.push('order = 2')
    } else if (
      _.isEqual(status, RecommendedArticleStatus.WEB) ||
      _.isEqual(status, RecommendedArticleStatus.RESERVED_WEB)
    ) {
      // WEB or 予約中の WEB を選択している場合
      filtering.push("target = 'web'")
    }

    // 日付での絞り込み
    if (!tableData.filtering.clearDateRange && !_.isEqual(status, RecommendedArticleStatus.RESERVED_WEB)) {
      const dateRangeStartAt = tableData.filtering.dateRangeStartAt
      const dateRangeEndAt = tableData.filtering.dateRangeEndAt
      filtering.push(`publishDatetime >= '${dateRangeStartAt.toISOString()}'`)
      filtering.push(`publishDatetime <= '${dateRangeEndAt.toISOString()}'`)
    } else if (_.isEqual(status, RecommendedArticleStatus.RESERVED_WEB)) {
      // 予約のものに関しては未来しか表示しない
      filtering.push(`publishDatetime > '${moment().toISOString()}'`)
    }

    if (!_.isEmpty(tableData.filtering.title)) {
      let filteringTitle = tableData.filtering.title
      if (filteringTitle.match(/\,/)) { // eslint-disable-line
        filteringTitle = ''
      }

      // タイトルで検索する場合
      filtering.push(`title LIKE "%${filteringTitle}%"`)
    }

    if (!_.isNil(tableData.filtering.order)) {
      // 表示順で検索する場合
      filtering.push(`order = ${tableData.filtering.order}`)
    }

    // ソート
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * テーブルのカラムを取得
   */
  getTableColomns = () => {
    const status = this.state.status
    const { permission } = this.state
    const { hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission
    const columns = [
      {
        label: 'カバー',
        align: 'center',
        render: item => {
          // 予約中コンテンツかどうか
          const isReserved = moment(item.publishDatetime).isAfter(moment())

          // 現在表示中のもの
          let isDisplayed
          if (_.isEqual(status, RecommendedArticleStatus.WEB)) {
            isDisplayed = !_.isNil(
              _.find(
                this.state.currentDisplayedRecommendedArticles.web,
                recommendedArticle => recommendedArticle.id === item.id,
              ),
            )
          } else {
            isDisplayed = !_.isNil(
              _.find(
                this.state.currentDisplayedRecommendedArticles.app,
                recommendedArticle => recommendedArticle.id === item.id,
              ),
            )
          }

          return (
            <div>
              {isReserved && (
                <Popup inverted trigger={<Label corner icon="checked calendar" />} content="予約中の記事です" />
              )}

              {isDisplayed && <Popup inverted trigger={<Label corner icon="eye" />} content="現在表示中の記事です" />}

              <Image src={_.get(item, 'cover.image.url', '')} centered size="tiny" shape="rounded" />
            </div>
          )
        },
      },
      {
        label: 'タイトル',
        field: 'title',
        minWidth: '20em',
        collapsing: false,
      },
      {
        label: '公開日時',
        field: 'publishDatetime',
        collapsing: false,
        render: item =>
          !_.isEmpty(item.publishDatetime) && (
            <span>
              <FormattedDate value={item.publishDatetime} day="numeric" month="long" year="numeric" weekday="narrow" />
              <br />
              <FormattedTime value={item.publishDatetime} hour="numeric" minute="numeric" second="numeric" />
            </span>
          ),
      },
      {
        label: '操作',
        align: 'center',
        render: item => (
          <Button.Group secondary>
            <Button disabled={!hasUpdatePermission} as="a" icon="edit" href={`/recommended-article/${item.id}`} />

            {!_.isEqual(status, RecommendedArticleStatus.TRASH) && !_.isEqual(status, RecommendedArticleStatus.WEB) && (
              <Button
                disabled={!hasDeletePermission}
                icon="trash alternate outline"
                onClick={() => {
                  this.openRecommendedArticleRemoveModal(item)
                }}
              />
            )}

            {_.isEqual(status, RecommendedArticleStatus.TRASH) && (
              <Button
                disabled={!hasRestorePermission}
                icon="undo"
                onClick={() => {
                  this.openUndoModal(item)
                }}
              />
            )}
          </Button.Group>
        ),
      },
    ]

    // APP ではない場合はタイトルと公開日時の間に表示順項目を入れる
    if (
      !_.isEqual(status, RecommendedArticleStatus.APP) &&
      !_.isEqual(status, RecommendedArticleStatus.APP_SECOND_ORDER)
    ) {
      columns.splice(2, 0, {
        label: '表示順',
        field: 'order',
        align: 'center',
        collapsing: false,
      })
    }

    return columns
  }

  /**
   * メッセージのレンダリング
   */
  renderMessage = () => {
    const { status, articles, currentDisplayedRecommendedArticles } = this.state
    const isWeb = _.isEqual(status, RecommendedArticleStatus.WEB)
    const isReservedWeb = _.isEqual(status, RecommendedArticleStatus.RESERVED_WEB)
    // テーブルに表示する記事一覧
    const displayTableArticles = isWeb ? currentDisplayedRecommendedArticles.web : articles

    let content
    if (isReservedWeb && _.isEmpty(displayTableArticles)) {
      content = (
        <Message hidden={isWeb} info>
          <b>予約中の記事はありません。</b>
        </Message>
      )
    }
    return content
  }

  render() {
    const { status, articles, currentDisplayedRecommendedArticles, permission } = this.state
    const { hasCreatePermission, hasDeletePermission, hasRestorePermission } = permission
    const tableData = this.state[status]

    const isWeb = _.isEqual(status, RecommendedArticleStatus.WEB)
    // テーブルに表示する記事一覧 (WEB の場合は表示中の 5 つ固定で表示)
    const displayTableArticles = isWeb ? currentDisplayedRecommendedArticles.web : articles

    return (
      <div className="RecommendedArticles">
        <Header as="h1">
          <Icon name="thumbs outline up" />

          <Header.Content>編集部おすすめ</Header.Content>
        </Header>

        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="WEB"
            name={RecommendedArticleStatus.WEB}
            active={_.isEqual(status, RecommendedArticleStatus.WEB)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="WEB (予約中)"
            name={RecommendedArticleStatus.RESERVED_WEB}
            active={_.isEqual(status, RecommendedArticleStatus.RESERVED_WEB)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={RecommendedArticleStatus.TRASH}
            active={_.isEqual(status, RecommendedArticleStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item>
            <Button
              disabled={!hasCreatePermission}
              to={{
                pathname: '/recommended-article',
                query: {
                  // eslint-disable-next-line no-nested-ternary
                  target: _.endsWith(this.state.status, 'app')
                    ? 'app'
                    : this.state.status === 'app_second_order'
                    ? 'app_second_order'
                    : 'web',
                },
              }}
              primary
              content="作成"
              as={Link}
              icon="write"
              labelPosition="right"
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        {!isWeb && (
          <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
            <Form.Group>
              <Form.Field width={8}>
                <Input
                  name="recommended-article-search"
                  type="text"
                  placeholder="タイトルで検索"
                  action
                  value={tableData.filtering.title}
                  onChange={this.handleTitleInputChange}
                  validations={{ matchRegexp: /^((?!,).)*$/i }}
                  validationErrors={{
                    matchRegexp: 'キーワードに不正な記号があるため検索できません',
                  }}
                  errorLabel={<FormErrorLabel />}
                >
                  <input />

                  <Button
                    icon="search"
                    onClick={this.handleSearchButtonClick}
                    color="blue"
                    disabled={!this.state.isFormSearchValid}
                  />
                </Input>
              </Form.Field>

              {!_.isEqual(this.state.status, RecommendedArticleStatus.RESERVED_WEB) && (
                <Form.Field width={4}>
                  <PublishDateRangePicker
                    startDate={tableData.filtering.dateRangeStartAt}
                    endDate={tableData.filtering.dateRangeEndAt}
                    onEvent={this.handleDateRangePickerEvent}
                    clear={tableData.filtering.clearDateRange}
                    disabled={!this.state.isFormSearchValid}
                  />
                </Form.Field>
              )}

              {!_.isEqual(this.state.status, RecommendedArticleStatus.APP) &&
                !_.isEqual(this.state.status, RecommendedArticleStatus.APP_SECOND_ORDER) && (
                  <Form.Field>
                    <Dropdown
                      width={4}
                      required
                      selection
                      placeholder="表示順で絞り込み"
                      name="order"
                      options={[
                        { text: '解除', value: null, key: 0 },
                        { text: 1, value: 1, key: 1 },
                        { text: 2, value: 2, key: 2 },
                        { text: 3, value: 3, key: 3 },
                        { text: 4, value: 4, key: 4 },
                        { text: 5, value: 5, key: 5 },
                      ]}
                      value={this.state.order}
                      onChange={this.handleOrderDropdownChange}
                      disabled={!this.state.isFormSearchValid}
                    />
                  </Form.Field>
                )}
            </Form.Group>
          </Form>
        )}

        {/* 検索結果数 */}
        <Statistic horizontal size="mini" color="grey">
          <Statistic.Value>{tableData.totalItems}</Statistic.Value>
          <Statistic.Label>件</Statistic.Label>
        </Statistic>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && this.renderMessage()}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/** 編集部おすすめ一覧 */}
        {!_.isEmpty(displayTableArticles) && (
          <DataTable
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSelectionChange}
            rowKey="id"
            items={displayTableArticles}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={isWeb ? undefined : tableData.totalPages}
            sort={tableData.sorting}
            cellStyle={{ position: 'relative' }}
            columns={this.getTableColomns()}
          />
        )}

        {/* おすすめ記事削除モーダル */}
        {hasDeletePermission && (
          <RecommendedArticleRemoveModal
            recommendedArticle={this.state.removeRecommendedArticle}
            open={this.state.isRecommendedArticleRemoveModalOpen}
            onClose={this.handleRecommendedArticleRemoveModalClose}
            onSuccessDataChanged={this.handleRecommendedArticleDataChanged}
          />
        )}

        {/* 削除した編集部おすすめを戻すときに表示する確認用モーダル */}
        {hasRestorePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isUndoModalOpen}
            onClose={this.handleUndoModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>編集部おすすめの公開</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.undoApiError} />
              {_.get(this.state, 'undoRecommendedArticle.title')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.handleUndoModalPublishButtonClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default RecommendedArticles
