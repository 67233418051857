import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { SponsorApi } from 'trill-api-admin-client'
import { Modal, Button, Dimmer, Loader, Checkbox, Divider } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'
import { flattenObject } from '../util'

const logger = LogLevel.getLogger('SponsorEditModal')
const sponsorApi = new SponsorApi()
let sendSponsor

const propTypes = {
  /**
   * 編集対象のスポンサー (作成の場合は空)
   */
  sponsor: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したときに呼び出す外部関数
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  sponsor: {},
  open: false,
}

class SponsorEditModal extends Component {
  state = {
    isBusy: false,
    isFormValid: false,
    isFormModified: false,
    apiError: null,
    useSavingTotalPv: false,
  }

  /**
   * 初期のフォームデータ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されているかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.sponsor, nextProps.sponsor)) {
      this.setState({ isFormModified: false })

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.sponsor) {
        this.initializeFormValues(this.props.sponsor)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendSponsor)) {
      sendSponsor.cancel()
    }
  }

  /**
   * 合計保証PV利用のトグルを変更したときのハンドラ
   */
  handleUseavingTotalToggleChange = (event, { checked }) => {
    this.setState({ useSavingTotalPv: checked })
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }
    const flattenCurrentValues = flattenObject(currentValues)

    _.each(flattenCurrentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendSponsor = CancelablePromise(this.sendData(this.getRequestParameters(submitFormData)))
    sendSponsor.promise
      .then(response => {
        const sponsor = response.data

        // 更新 or 作成に成功したらお知らせ一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.initializeFormValues(sponsor)

        resetForm(this.initialFormValues)

        this.setState(
          {
            isFormModified: false,
            isBusy: false,
          },
          () => {
            if (_.isEmpty(this.props.sponsor) && this.props.onClose) {
              // 新規作成の場合はモーダルを閉じる
              this.props.onClose()
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('handle form valid submit send data error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * フォームの初期化
   * @param {Object} sponsor - スポンサーデータ
   */
  initializeFormValues(sponsor) {
    this.initialFormValues.name = sponsor.name
    this.setState({
      useSavingTotalPv: _.get(sponsor, 'savingArticleMaximumViews.total') > 0,
    })
    this.initialFormValues['savingArticleMaximumViews.app'] = _.get(sponsor, 'savingArticleMaximumViews.app')
    this.initialFormValues['savingArticleMaximumViews.web'] = _.get(sponsor, 'savingArticleMaximumViews.web')
    this.initialFormValues['savingArticleMaximumViews.total'] = _.get(sponsor, 'savingArticleMaximumViews.total')
    if (_.isEmpty(this.props.sponsor)) {
      this.initialFormValues['savingArticleMaximumViews.app'] = 20000
      this.initialFormValues['savingArticleMaximumViews.web'] = 0
      this.initialFormValues['savingArticleMaximumViews.total'] = 0
    }
  }

  /**
   * API にデータを送信
   */
  sendData = submitSponsorData => {
    if (_.isEmpty(this.props.sponsor)) {
      return sponsorApi.postSponsor(submitSponsorData)
    }

    const sponsorId = this.props.sponsor.id
    const sponsorUpdateValues = submitSponsorData
    return sponsorApi.patchSponsor(sponsorId, { sponsorUpdateValues })
  }

  /**
   * API 通信時のリクエストパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 送信データに空文字があれば 0 を入れる
    let savingArticleMaximumViewsApp = parseInt(_.get(submitFormData, 'savingArticleMaximumViews.app'), 10)
    let savingArticleMaximumViewsWeb = parseInt(_.get(submitFormData, 'savingArticleMaximumViews.web'), 10)
    let savingArticleMaximumViewsTotal = parseInt(_.get(submitFormData, 'savingArticleMaximumViews.total'), 10)

    // 合計保証PVを利用する場合は app, web に 0 を入れる
    if (this.state.useSavingTotalPv) {
      savingArticleMaximumViewsApp = 0
      savingArticleMaximumViewsWeb = 0
    } else {
      savingArticleMaximumViewsTotal = 0
    }

    _.set(
      submitFormData,
      'savingArticleMaximumViews.app',
      !_.isNaN(savingArticleMaximumViewsApp) ? parseInt(savingArticleMaximumViewsApp, 10) : 0,
    )
    _.set(
      submitFormData,
      'savingArticleMaximumViews.web',
      !_.isNaN(savingArticleMaximumViewsWeb) ? parseInt(savingArticleMaximumViewsWeb, 10) : 0,
    )
    _.set(
      submitFormData,
      'savingArticleMaximumViews.total',
      !_.isNaN(savingArticleMaximumViewsTotal) ? parseInt(savingArticleMaximumViewsTotal, 10) : 0,
    )

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}

    if (!_.isEmpty(this.props.sponsor)) {
      initialData.name = initialFormValues.name

      _.set(
        initialData,
        'savingArticleMaximumViews.app',
        parseInt(_.get(initialFormValues, 'savingArticleMaximumViews.app', 0), 10),
      )
      _.set(
        initialData,
        'savingArticleMaximumViews.web',
        parseInt(_.get(initialFormValues, 'savingArticleMaximumViews.web', 0), 10),
      )
      _.set(
        initialData,
        'savingArticleMaximumViews.total',
        parseInt(_.get(initialFormValues, 'savingArticleMaximumViews.total', 0), 10),
      )
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    logger.debug('get request parameters', {
      requestParameters,
      initialData,
      submitFormData,
    })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  render() {
    return (
      <Modal
        className="SponsorEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.props.sponsor) ? 'スポンサーの作成' : 'スポンサーの編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/* スポンサー名入力フィールド */}
            <Form.Input name="name" label="スポンサー名" placeholder="スポンサー名を入力してください" required />

            <Form.Group widths="equal">
              {/* アプリの保証PVフィールド */}
              <Form.Input
                label="アプリの保証PV"
                placeholder="アプリの保証PV"
                name="savingArticleMaximumViews.app"
                type="number"
                validations={{
                  isNumeric: true,
                }}
                disabled={this.state.useSavingTotalPv}
              />

              {/* ウェブの保証PVフィールド */}
              <Form.Input
                label="ウェブの保証PV"
                placeholder="ウェブの保証PV"
                name="savingArticleMaximumViews.web"
                type="number"
                validations={{
                  isNumeric: true,
                }}
                disabled={this.state.useSavingTotalPv}
              />
            </Form.Group>

            <Checkbox
              toggle
              label="合計保証PVを利用"
              name="savingTotal"
              checked={this.state.useSavingTotalPv}
              onChange={this.handleUseavingTotalToggleChange}
            />

            <Divider hidden />

            {/* 合計保証PVフィールド */}
            <Form.Input
              label="合計保証PV"
              placeholder="合計の保証PV"
              name="savingArticleMaximumViews.total"
              type="number"
              validations={{
                isNumeric: true,
              }}
              disabled={!this.state.useSavingTotalPv}
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.props.sponsor) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || !this.state.isFormModified}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

SponsorEditModal.propTypes = propTypes
SponsorEditModal.defaultProps = defaultProps

export default SponsorEditModal
