import _ from 'lodash'
import Store from 'store'
import { Component } from 'react'
import { Button, Dimmer, Divider, Header, Icon, Image, Label, Loader, Menu, Modal, Statistic } from 'semantic-ui-react'
import GetPermission from '../../GetPermission'
import LogLevel from '../../LogLevel'
import DataTable from '../../components/DataTable'
import CancelablePromise from '../../CancelablePromise'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import { FormattedDate } from 'react-intl'
import GameFilterContainer from '../../components/GameFilterContainer'
import { GameApi } from 'trill-api-admin-client'
import { differenceObject } from '../../util'
import { GameStatus, GameLocation } from '../../enums/GameEnum'

const logger = LogLevel.getLogger('Games')

const gameApi = new GameApi()
let getGamesPromise
let deleteGamePromise
let restoreGamePromise

const GameTableStatus = {
  ALL: 'all',
  TRASH: 'trash',
}

const GameLocationText = {
  [GameLocation.popular]: '無料の人気ゲーム',
  [GameLocation.recommended]: 'おすすめのゲーム',
}

const PAGE_STATE_STORE_KEY = 'gamesPageState'

class Games extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,

      status: GameTableStatus.ALL,
      permission: GetPermission('game'),

      isGameDeleteModalOpen: false,
      isGameRestoreModalOpen: false,

      games: [],
      selectedGame: null,
    }

    _.each(GameTableStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 50,
          sorting: {
            order: 'asc',
          },
          filtering: {
            search: '',
            searchKey: 'title',
            status: null,
            location: null,
            gameCategory: null,
          },
        },
      })
    })
  }

  /**
   * After FIRST rendering, retrieve data from cache or request to populate
   */
  componentDidMount() {
    const pageState = Store.get(PAGE_STATE_STORE_KEY)

    if (pageState) {
      const games = pageState.games
      // always show Tab ALL
      const status = GameTableStatus.ALL
      const tableData = _.get(pageState, status, this.state[status])
      // always sort by `order`
      tableData.sorting = {
        order: 'asc',
      }

      this.setState(
        {
          status,
          games,
          [status]: tableData,
        },
        () => {
          this.retrieveGames()
        },
      )
    } else {
      this.retrieveGames()
    }
  }

  /**
   * Cancel all API requests on removing Component
   */
  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getGamesPromise)) {
      getGamesPromise.cancel()
    }
    if (!_.isNil(deleteGamePromise)) {
      deleteGamePromise.cancel()
    }
    if (!_.isNil(restoreGamePromise)) {
      restoreGamePromise.cancel()
    }
  }

  /**
   * Request query for List API
   */
  getRequestQuery = () => {
    // Get the state of the displayed table data
    const status = this.state.status
    const tableData = this.state[status]

    //* ************* Pagination ************* */
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    //* ************* Filter ************* */
    const filtering = []

    // Filtered by Tab
    if (_.isEqual(status, GameTableStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    const {
      search: searchValue,
      searchKey,
      status: gameStatus,
      location: gameLocation,
      gameCategory,
    } = tableData.filtering

    // Filtered by Search keywords
    if (_.isEqual(searchKey, 'id')) {
      if (!_.isEmpty(searchValue)) {
        filtering.push(`id = '${searchValue}'`)
      }
    } else if (!_.isEmpty(searchValue)) {
      filtering.push(`${searchKey} LIKE '%${searchValue}%'`)
    }

    // Filtered by GameStatus
    if (_.includes(GameStatus, gameStatus)) {
      filtering.push(`status = '${gameStatus}'`)
    }

    // Filtered by GameLocation
    if (_.includes(GameLocation, gameLocation)) {
      filtering.push(`location = '${gameLocation}'`)
    }

    // Filtered by GameCategoryId
    const gameCategoryId = _.get(gameCategory, 'id', null)
    if (_.isNumber(gameCategoryId)) {
      filtering.push(`gameCategoryId = "${gameCategoryId}"`)
    }
    //* ************* Sort ************* */
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : '+'

      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // Returns an object except empty and not number
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * Get all Games and populate to Data Table
   */
  retrieveGames = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getGamesPromise = CancelablePromise(gameApi.getGames(this.getRequestQuery()))
    getGamesPromise.promise
      .then(response => {
        const responseHeader = response.header
        const games = response.data

        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        logger.debug('retrieve games', { games })

        this.setState({
          isBusy: false,
          games,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve games error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          games: [],
          [status]: tableData,
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Save current page state in store.
   * Page state relates to Data Table, Sort and Filter.
   * So it should be called on Data Table status changed
   */
  savePageState() {
    const games = this.state.games
    const status = this.state.status
    const tableData = this.state[status]
    const pageState = {
      games,
      status,
      [status]: tableData,
    }

    logger.debug('save page state', { pageState })

    Store.set(PAGE_STATE_STORE_KEY, pageState)
  }

  /**
   * Callback for page status changed
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveGames()
      this.savePageState()
    })
  }

  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveGames()
      this.savePageState()
    })
  }

  handleDataTableSortChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveGames()
      this.savePageState()
    })
  }

  /**
   * Callback for game filter value changes
   */
  handleFilterChange = filtering => {
    const status = this.state.status
    const tableData = this.state[status]
    // filtering is cloned, so tableData.filtering and filtering is not in same location
    const filteringDiff = differenceObject(filtering, tableData.filtering)

    if (_.isNil(filtering) || _.isEmpty(filteringDiff)) {
      return
    }

    tableData.filtering = filtering

    this.setState({ [status]: tableData }, () => {
      this.retrieveGames()
      this.savePageState()
    })
  }

  handleGameDeleteModalOpen = selectedGame => {
    this.setState({
      isGameDeleteModalOpen: true,
      selectedGame,
    })
  }

  handleGameRestoreModalOpen = selectedGame => {
    this.setState({
      isGameRestoreModalOpen: true,
      selectedGame,
    })
  }

  handleGameDeleteModalClose = () => {
    this.setState({
      isGameDeleteModalOpen: false,
      selectedGame: null,
      isBusy: false,
      apiError: null,
    })
  }

  handleGameRestoreModalClose = () => {
    this.setState({
      isGameRestoreModalOpen: false,
      selectedGame: null,
      isBusy: false,
      apiError: null,
    })
  }

  handleSubmitButtonGameDeleteModalClick = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteGamePromise = CancelablePromise(gameApi.deleteGame(this.state.selectedGame.id))
    deleteGamePromise.promise
      .then(() => {
        this.handleGameDeleteModalClose()
        this.retrieveGames()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  handleSubmitButtonGameRestoreModalClick = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    restoreGamePromise = CancelablePromise(gameApi.putGame(this.state.selectedGame.id))
    restoreGamePromise.promise
      .then(() => {
        this.handleGameRestoreModalClose()
        this.retrieveGames()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  render() {
    const { status, permission, isGameDeleteModalOpen, isGameRestoreModalOpen } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission

    const tableData = this.state[status]
    const hasModalBeShown = isGameDeleteModalOpen || isGameRestoreModalOpen

    return (
      <div className="Games">
        <Header as="h1">
          <Icon name="game" />

          <Header.Content>ゲーム</Header.Content>
        </Header>

        <Dimmer active={hasModalBeShown ? false : this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={GameTableStatus.ALL}
            active={_.isEqual(status, GameTableStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={GameTableStatus.TRASH}
            active={_.isEqual(status, GameTableStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="新規作成"
              icon="write"
              labelPosition="left"
              onClick={() => {
                this.props.router.push('/game')
              }}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* ************* Filter Form ************* */}
        <GameFilterContainer
          filtering={tableData.filtering}
          isShowStatusFilter={!_.isEqual(status, GameTableStatus.TRASH)}
          onFilterChange={this.handleFilterChange}
        />

        {/* ************* Total Items Count ************* */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* ************* API Error Message ************* */}
        <ApiErrorMessage error={this.state.apiError} />

        {/* ************* Data Table ************* */}
        {!_.isEmpty(this.state.games) && (
          <DataTable
            rowKey="id"
            sort={tableData.sorting}
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSortChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            items={this.state.games}
            cellStyle={{ position: 'relative' }}
            rowStates={item => ({
              active: !!item.deletedAt,
            })}
            columns={[
              {
                label: 'ID',
                align: 'center',
                field: 'id',
              },
              {
                label: 'ステータス',
                align: 'center',
                render: item => {
                  if (item.deletedAt) {
                    return <Label color="grey">ゴミ箱</Label>
                  }
                  switch (item.status) {
                    case GameStatus.publish:
                      return <Label color="green">公 開</Label>
                    case GameStatus.draft:
                      return <Label color="yellow">非 公 開</Label>
                    default:
                      return <Label color="grey">不明</Label>
                  }
                },
              },
              {
                label: 'サムネイル',
                align: 'center',
                render: item => {
                  const imageUrl = _.get(item, 'thumbnail.image.url', '')

                  return <Image src={imageUrl} centered size="tiny" shape="rounded" />
                },
              },
              {
                label: 'タイトル',
                field: 'title',
                minWidth: '20em',
                collapsing: false,
              },
              {
                label: '表示順',
                field: 'order',
                align: 'center',
              },
              {
                label: '公開日時',
                cellClassName: 'DataTable__TimeKindCell',
                minWidth: '10em',
                field: 'publishDatetime',
                render: item => {
                  const publishDatetime = _.isEmpty(item.publishDatetime) ? (
                    '未設定'
                  ) : (
                    <span>
                      <FormattedDate
                        value={item.publishDatetime}
                        day="numeric"
                        month="long"
                        year="numeric"
                        weekday="narrow"
                      />
                      <br />
                      <FormattedDate value={item.publishDatetime} hour="numeric" minute="numeric" second="numeric" />
                    </span>
                  )

                  return publishDatetime
                },
              },
              {
                label: '表示箇所',
                minWidth: '10em',
                render: item => _.get(GameLocationText, item.location, ''),
              },
              {
                label: 'ゲームカテゴリ',
                align: 'center',
                minWidth: '10em',
                render: item =>
                  item.gameCategory && (
                    <span>
                      <Icon name="folder open" />
                      {_.get(item, 'gameCategory.name', '未分類')}
                    </span>
                  ),
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Button disabled={!hasUpdatePermission} as="a" icon="edit" href={`/game/${item.id}`} />

                    {!_.isEqual(status, GameTableStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.handleGameDeleteModalOpen(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, GameTableStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.handleGameRestoreModalOpen(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* ************* Delete Modal ************* */}
        {hasDeletePermission && (
          <Modal
            className="Games__DeleteModal"
            size="small"
            closeIcon
            open={this.state.isGameDeleteModalOpen}
            onClose={this.handleGameDeleteModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>ゲームの削除</Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <p> {_.get(this.state, 'selectedGame.title')} をゴミ箱へ移動しますか？</p>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleGameDeleteModalClose} />
              <Button negative content="ゴミ箱へ移動" onClick={this.handleSubmitButtonGameDeleteModalClick} />
            </Modal.Actions>
          </Modal>
        )}

        {/* ************* Restore Modal ************* */}
        {hasRestorePermission && (
          <Modal
            className="Games__RestoreModal"
            size="small"
            closeIcon
            open={this.state.isGameRestoreModalOpen}
            onClose={this.handleGameRestoreModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>ゲームの公開</Modal.Header>

            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              <ApiErrorMessage error={this.state.apiError} />

              <p> {_.get(this.state, 'selectedGame.title')} を戻しますか？</p>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleGameRestoreModalClose} />
              <Button positive content="戻す" onClick={this.handleSubmitButtonGameRestoreModalClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default Games
