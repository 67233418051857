import _ from 'lodash'
import AccessControl from './AccessControl'
import Store from 'store'

const GetPermission = scope => {
  const authToken = Store.get('authToken')

  return AccessControl(_.get(authToken, 'scope'), scope)
}

export default GetPermission
