import React, { Component } from 'react'
import { Segment, Grid, Label, Message, Button } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import { DateRangePicker } from 'react-bootstrap-daterangepicker'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import MediumInput from './MediumInput'
import GameCategoriesDropdown from './GameCategoriesDropdown'
import FormErrorLabel from './FormErrorLabel'

import { flattenObject } from '../util'

import { GameLocation } from '../enums/GameEnum'
import { PUBLISH_DATETIME_LABEL_FORMAT } from '../constants/date_format'
import LogLevel from '../LogLevel'

const LocationOptionAttributes = {
  [GameLocation.popular]: {
    text: '無料の人気ゲーム',
    initialAspectRatio: 4 / 3,
    validationMessage: '画像サイズは4:3 (例：512 x 384px/72dpi) の比率を推奨しております。',
  },
  [GameLocation.recommended]: {
    text: 'おすすめのゲーム',
    initialAspectRatio: 1 / 1,
    validationMessage: '画像サイズは1:1 (例：512 x 512px/72dpi) の比率を推奨しております。',
  },
}

const GAME_MAX_ORDER = 50

const propTypes = {
  /**
   * Game to edit (empty object if create)
   */
  game: PropTypes.object,
  /**
   * Handler to be called when the form is changed
   */
  onFormChange: PropTypes.func,
  /**
   * Handler to be called when the form is valid
   */
  onFormValid: PropTypes.func,
  /**
   * Handler to be called when the form is invalid
   */
  onFormInvalid: PropTypes.func,
  /**
   * Handler to be called when the form is submitted
   */
  onFormValidSubmit: PropTypes.func,
}

const defaultPropTypes = {
  game: {},
}

const logger = LogLevel.getLogger('GameForm')

/**
 * Default value for DateTimePicker value (incase the value of input is null)
 */
function getDefaultDatePickerValue() {
  return moment()
    .add(1, 'days')
    .set({ minute: 0, second: 0 })
}

class GameForm extends Component {
  state = {
    id: null,
    location: GameLocation.popular,
    gameCategory: null,
    publishDatetime: null,
    // Add this state for handle confiction on value between Picker and Input, like: Input is null but Picker is today
    // The value should be a `moment`, this will be formatted latters
    publishDatetimeInputValue: null,

    thumbnailImageUrlInputValue: '',
    thumbnailImageError: null,

    isFormValid: false,
    isFormModified: false,
  }

  /**
   * Initial form values
   */
  initialFormValues = {}
  /**
   * Whether the input data of the form is initialized
   */
  isFormResetted = false

  /**
   * Extract Component state value from Game properties
   */
  extractGameProps = game => {
    const id = _.get(game, 'id', null)
    const location = _.get(game, 'location', null)
    const thumbnailImageUrlInputValue = _.get(game, 'thumbnail.image.url', '')
    const gameCategory = _.get(game, 'gameCategory', null)

    const gamePublishDatetime = _.get(game, 'publishDatetime', null)
    const publishDatetimeInputValue = _.isEmpty(gamePublishDatetime) ? null : moment(gamePublishDatetime)
    const publishDatetime = _.isEmpty(gamePublishDatetime) ? null : moment(gamePublishDatetime)

    return { id, location, thumbnailImageUrlInputValue, publishDatetime, publishDatetimeInputValue, gameCategory }
  }

  UNSAFE_componentWillMount() {
    const game = _.get(this.props, 'game', {})

    this.setState({
      ...this.extractGameProps(game),

      thumbnailImageError: null,
      isFormModified: false,
    })
    this.initializeFormValues(game)
    this.isFormResetted = false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.game, nextProps.game)) {
      const game = _.get(nextProps, 'game', {})

      this.setState({
        ...this.extractGameProps(game),

        thumbnailImageError: null,
        isFormModified: false,
      })
      this.initializeFormValues(game)

      if (this.refs.form) {
        this.refs.form.reset(this.initialFormValues)
      }
    }
  }

  componentDidUpdate() {
    if (this.refs.form && !this.isFormResetted) {
      this.refs.form.reset(this.initialFormValues)
      this.isFormResetted = true
    }

    // set initial value for DatePicker
    if (_.isEmpty(this.state.publishDatetime)) {
      this.setState({
        publishDatetime: getDefaultDatePickerValue(),
      })
    }
  }

  handleFormValueChange = (event, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleDatePickerEvent = (event, picker) => {
    if (event.type === 'apply') {
      this.setState({
        publishDatetime: picker.startDate,
        publishDatetimeInputValue: picker.startDate,
      })
    } else if (event.type === 'cancel') {
      // Add this to fix case (startDate != endDate)
      const defaultDatetime = getDefaultDatePickerValue()
      picker.setStartDate(defaultDatetime)
      picker.setEndDate(defaultDatetime)
      this.setState({
        publishDatetimeInputValue: null,
        publishDatetime: defaultDatetime,
      })
    }
  }

  /**
   * Handler to be called when the form is changed
   */
  handleFormChange = currentValues => {
    const isFormModified = !_.isEqual(flattenObject(currentValues), flattenObject(this.initialFormValues))

    if (!_.isEqual(this.state.isFormModified, isFormModified)) {
      this.setState({ isFormModified })
    }

    if (this.props.onFormChange) {
      this.props.onFormChange(this.getFormatGameFromValues(currentValues), isFormModified)
    }
  }

  /**
   * Handler to be called when the form is valid
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })

    if (this.props.onFormValid) {
      this.props.onFormValid()
    }
  }

  /**
   * Handler to be called when the form is invalid
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })

    if (this.props.onFormInvalid) {
      this.props.onFormInvalid()
    }
  }

  /**
   * Handler to be called when the form is submitted
   */
  handleFormValidSubmit = submitFormData => {
    if (this.props.onFormValidSubmit) {
      this.props.onFormValidSubmit(this.getFormatGameFromValues(submitFormData))
    }
  }

  /**
   * Handler to be called when the thumbnail image input form is changed
   */
  handleThumbnailImageInputChange = (event, { mediumUrl, error }) => {
    this.setState({
      thumbnailImageUrlInputValue: _.defaultTo(mediumUrl, ''),
      thumbnailImageError: error,
    })
  }

  /**
   * Initial form values
   */
  initializeFormValues(game) {
    if (_.isEmpty(game)) {
      this.initialFormValues = {
        displayInformation: '無料ゲーム',
      }
      return
    }

    this.initialFormValues.title = game.title
    this.initialFormValues.description = game.description
    this.initialFormValues.guide = game.guide
    this.initialFormValues.originalLink = game.originalLink
    this.initialFormValues.displayInformation = game.displayInformation
    this.initialFormValues.location = game.location
    this.initialFormValues.order = game.order
    this.initialFormValues.gameCategoryId = _.get(game, 'gameCategory.id', null)
    this.initialFormValues.publishDatetime = _.isEmpty(game.publishDatetime)
      ? ''
      : moment(game.publishDatetime).format(PUBLISH_DATETIME_LABEL_FORMAT)
    this.initialFormValues['thumbnail.image.url'] = _.get(game, 'thumbnail.image.url', '')
  }

  /**
   * Get format game from form values
   */
  getFormatGameFromValues = formValues => {
    const image = {
      url: _.get(formValues, 'thumbnail.image.url', ''),
    }
    const publishDatetime = _.isEmpty(formValues.publishDatetime)
      ? ''
      : moment(formValues.publishDatetime, PUBLISH_DATETIME_LABEL_FORMAT).toISOString()

    return {
      title: formValues.title,
      description: formValues.description,
      guide: formValues.guide,
      originalLink: formValues.originalLink,
      displayInformation: formValues.displayInformation,
      location: formValues.location,
      order: formValues.order ? +formValues.order : formValues.order,
      publishDatetime,
      gameCategoryId: formValues.gameCategoryId,
      thumbnail: { image },
    }
  }

  render() {
    const publishDatetimeLabel = this.state.publishDatetimeInputValue?.format(PUBLISH_DATETIME_LABEL_FORMAT)
    return (
      <Form
        ref="form"
        noValidate
        onChange={this.handleFormChange}
        onValid={this.handleFormValid}
        onInvalid={this.handleFormInvalid}
        onValidSubmit={this.handleFormValidSubmit}
      >
        <Grid columns={2} doubling>
          <Grid.Column width={10}>
            <Form.Input name="title" label="タイトル" placeholder="タイトルを入力してください" required />

            <Form.TextArea
              name="description"
              label="ゲーム概要"
              placeholder="ゲーム概要を入力してください"
              rows={4}
              required
            />

            <Form.TextArea name="guide" label="遊び方" placeholder="遊び方を入力してください" rows={5} required />

            <Form.Input
              required
              name="originalLink"
              label="ゲームのURL"
              placeholder="表示するゲームのURLを入力してください。例）https://html5.gamedistribution.com/****"
              validations="isUrl"
              validationErrors={{ isUrl: '無効な URL です' }}
              errorLabel={<FormErrorLabel />}
            />

            <Form.TextArea
              name="displayInformation"
              label="ゲーム表示情報"
              placeholder="ゲーム表示情報を入力してください"
              rows={1}
              required
            />

            <Button
              primary
              type="submit"
              content={_.isNil(this.state.id) ? '保存' : '更新'}
              icon="save"
              labelPosition="left"
              floated="right"
              disabled={!this.state.isFormValid || !this.state.isFormModified}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <Form.Dropdown
              selection
              required
              label="表示箇所"
              placeholder="表示箇所を選択してください"
              name="location"
              options={_.map(LocationOptionAttributes, (detail, key) => ({
                value: key,
                text: detail.text,
              }))}
              onChange={this.handleFormValueChange}
            />

            <Form.Dropdown
              required
              selection
              label="表示順"
              placeholder="表示する順番を選択してください"
              name="order"
              options={_.map(_.range(1, GAME_MAX_ORDER + 1), order => ({
                value: order,
                text: order,
              }))}
            />

            <Segment>
              <Label attached="top" color="blue" content="サムネイル" />

              <Form.Field required error={!_.isNil(this.state.thumbnailImageError)}>
                <label>画像</label>

                <MediumInput
                  mediumUrl={this.state.thumbnailImageUrlInputValue}
                  initialAspectRatio={_.get(LocationOptionAttributes, `${this.state.location}.initialAspectRatio`)}
                  onChange={this.handleThumbnailImageInputChange}
                />

                <Form.Input
                  required
                  readOnly
                  className="isHidden"
                  name="thumbnail.image.url"
                  placeholder="カバー画像を選択してください"
                  value={this.state.thumbnailImageUrlInputValue}
                />
              </Form.Field>

              <Message
                hidden={_.isNil(this.state.location)}
                content={_.get(LocationOptionAttributes, `${this.state.location}.validationMessage`)}
              />
            </Segment>

            <Form.Field required>
              <label>公開日時</label>

              <DateRangePicker
                containerStyles={{ display: 'block' }}
                singleDatePicker
                timePicker
                timePicker24Hour
                locale={{
                  applyLabel: '確定',
                  cancelLabel: '解除',
                }}
                drops="up"
                startDate={this.state.publishDatetime}
                onEvent={this.handleDatePickerEvent}
              >
                <Input
                  icon="calendar"
                  iconPosition="left"
                  placeholder="公開日時を指定"
                  name="publishDatetime"
                  value={publishDatetimeLabel}
                  readOnly
                  required
                />
              </DateRangePicker>
            </Form.Field>

            <Form.Field>
              <label>ゲームカテゴリ</label>

              <GameCategoriesDropdown
                name="gameCategory"
                placeholder="ゲームカテゴリを選択"
                ignoreTrashContents={true}
                selectedGameCategory={this.state.gameCategory}
                onChange={this.handleFormValueChange}
              />

              <Form.Input
                name="gameCategoryId"
                className="isHidden"
                value={_.get(this.state.gameCategory, 'id', null)}
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
      </Form>
    )
  }
}

GameForm.propTypes = propTypes
GameForm.defaultPropTypes = defaultPropTypes

export default GameForm
