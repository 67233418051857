import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Checkbox, Dropdown, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import _ from 'lodash'

import Pagination from './Pagination'

import './DataTable.css'

// TODO: Semantic-UI-React にプルリク
// https://github.com/Semantic-Org/Semantic-UI-React/blob/master/.github/CONTRIBUTING.md

const propTypes = {
  /**
   * 全てのアイテム
   */
  items: PropTypes.array.isRequired,

  /**
   * １ページあたりの表示アイテム数のリスト オプション
   */
  itemsPerPageOptions: PropTypes.array,

  /**
   * １ページあたりの表示アイテム数
   */
  itemsPerPage: PropTypes.number,

  /**
   * １ページあたりの表示アイテム数の初期値
   */
  defaultItemsPerPage: PropTypes.number,

  /**
   * 現在のページ番号
   */
  currentPage: PropTypes.number,

  /**
   * 現在のページ番号の初期値
   */
  defaultCurrentPage: PropTypes.number,

  /**
   * 合計ページ数
   */
  totalPages: PropTypes.number,

  /**
   * 並び替えオプション
   */
  sort: PropTypes.object,

  /**
   * 並べ替えが適用されている場合、基準となるフィールド以外のフィールドの並べ替えオプション
   *
   * # 参考リンク
   * - [_.orderBy](https://lodash.com/docs/4.17.4#orderBy)
   */
  defaultSorts: PropTypes.object,

  /**
   * 行を選択できるようにするかどうか
   */
  selectable: PropTypes.bool,

  /**
   * テーブルの列のデータ
   */
  columns: PropTypes.array.isRequired,

  /**
   * テーブルの行のキー
   */
  rowKey: PropTypes.string,

  /**
   * 行に適用するプロパティ
   */
  rowStates: PropTypes.func,

  /**
   * 行に適用するスタイル
   */
  rowStyle: PropTypes.object,

  /**
   * セルに適用するスタイル
   */
  cellStyle: PropTypes.object,

  /**
   * 選択中のアイテム
   */
  selectedItems: PropTypes.object,

  /**
   * トップのテーブルでは、フッターが表示されるか非表示になります。
   */
  isTopFooter: PropTypes.bool,

  /**
   * ページ変更（現在のページ番号、または１ページあたり表示アイテム数の変更）イベントのハンドラ
   *
   * @param {SyntheticEvent} event - React の SyntheticEvent
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {number} data.currentPage - 現在のページ番号
   * @param {number} [data.itemsPerPage] - １ページあたりに表示するアイテム数（変更があった場合）
   */
  onPageChange: PropTypes.func,

  /**
   * 並べ替えオプション変更イベントのハンドラ
   *
   * @param {SyntheticEvent} event - React の SyntheticEvent
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {Object} data.sort - 並び替えオプション
   */
  onSortChange: PropTypes.func,

  /**
   * アイテム選択イベントのハンドラ
   *
   * @param {SyntheticEvent} event - React の SyntheticEvent
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {Object.<string, boolean>} data.selectedItems - 選択されたアイテム一覧（`$itemKey: true`）
   */
  onSelectionChange: PropTypes.func,
}

const defaultProps = {
  itemsPerPageOptions: [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
  ],
  defaultItemsPerPage: 5,
  defaultCurrentPage: 1,
  selectable: false,
  isTopFooter: false,
  rowKey: 'key',
}

class DataTable extends Component {
  state = {
    itemsPerPage: 5,
    currentPage: 1,
    sort: {},
    pageCount: 1,
    items: [],
    selectedItems: {},
  }

  /**
   * 全てのアイテム
   */
  items = null

  /**
   * １ページあたりの表示アイテム数
   */
  itemsPerPage = 1

  /**
   * 現在のページ番号
   */
  currentPage = 1

  /**
   * 並び替え用オプション
   */
  sort = {}

  /**
   * 全てのページ
   */
  pages = null

  /**
   * 選択中のアイテム
   */
  selectedItems = {}

  constructor(props) {
    super(props)

    this.items = props.items
    this.itemsPerPage = _.defaultTo(props.itemsPerPage, props.defaultItemsPerPage)
    this.currentPage = _.defaultTo(props.currentPage, props.defaultCurrentPage)
    this.sort = _.get(props, 'sort', {})
    this.selectedItems = _.get(props, 'selectedItems', {})

    _.extend(this.state, {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      sort: this.sort,
    })
  }

  UNSAFE_componentWillMount() {
    this.setState({ items: this.items })
  }

  componentDidMount() {
    this.containerRef.addEventListener('scroll', this.handleContainerScrollOrResize)
    window.addEventListener('resize', this.handleContainerScrollOrResize)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.items, this.props.items)) {
      this.items = nextProps.items
      this.setState({ items: this.items })
    }

    if (!_.isEqual(nextProps.itemsPerPage, this.props.itemsPerPage)) {
      this.itemsPerPage = nextProps.itemsPerPage
      this.setState({ itemsPerPage: this.itemsPerPage })
    }

    if (!_.isEqual(nextProps.currentPage, this.props.currentPage)) {
      this.currentPage = nextProps.currentPage
      this.setState({ currentPage: this.currentPage })
    }

    if (!_.isEqual(nextProps.selectedItems, this.props.selectedItems)) {
      this.selectedItems = nextProps.selectedItems
      this.setState({ selectedItems: this.selectedItems })
    }

    if (!_.isEqual(nextProps.sort, this.props.sort)) {
      this.sort = nextProps.sort
      this.setState({ sort: this.sort })
    }
  }

  componentDidUpdate() {
    this.handleContainerScrollOrResize()
  }

  componentWillUnmount() {
    this.containerRef.removeEventListener('scroll', this.handleContainerScrollOrResize)
    window.removeEventListener('resize', this.handleContainerScrollOrResize)
  }

  handleItemsPerPageDropdownChange = (event, { value }) => {
    this.itemsPerPage = value

    if (this.props.onPageChange) {
      this.props.onPageChange(event, {
        ...this.props,
        itemsPerPage: this.itemsPerPage,
      })
    }
  }

  handlePaginationChange = (event, { currentPage }) => {
    this.currentPage = currentPage

    this.setState({
      currentPage: this.currentPage,
    })

    if (this.props.onPageChange) {
      this.props.onPageChange(event, {
        ...this.props,
        currentPage: this.currentPage,
      })
    }
  }

  handleHeaderCellClick = (event, { field, defaultSortOrder }) => {
    if (_.isNil(field)) {
      return
    }

    if (!_.isNil(this.sort[field])) {
      this.sort[field] = this.sort[field] === 'asc' ? 'desc' : 'asc'
    } else {
      // 今は１フィールドのみ並べ替え出来る
      _.unset(this.sort, _.keys(this.sort))
      this.sort[field] = _.isNil(defaultSortOrder) ? 'asc' : 'desc'
    }

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(event, {
        ...this.props,
        sort: this.sort,
        isHeaderCellClick: true,
      })
    }
  }

  handleHeaderRowCheckboxChange = event => {
    event.stopPropagation()

    const selectedItems = this.state.selectedItems || {}

    const selectedItemsKeys = _.map(_.keys(selectedItems), selectedItemKey => _.parseInt(selectedItemKey))
    const itemsKeys = _.map(this.state.items, item => item[this.props.rowKey])

    if (selectedItemsKeys.length > 0 && _.intersection(selectedItemsKeys, itemsKeys).length > 0) {
      // 現在表示されているアイテムの選択状態を解除
      _.forEach(itemsKeys, key => {
        _.unset(selectedItems, key)
      })
    } else {
      // 現在表示されているアイテムを全て選択状態に
      _.forEach(itemsKeys, key => {
        _.set(selectedItems, key, true)
      })
    }

    this.selectedItems = selectedItems

    this.setState({
      selectedItems,
    })

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(event, {
        ...this.props,
        selectedItems,
        isHeaderCellClick: false,
      })
    }
  }

  handleBodyRowCheckboxChange = (event, { checked, value }) => {
    event.stopPropagation()

    const selectedItems = this.state.selectedItems || {}

    if (checked) {
      selectedItems[value] = checked
    } else {
      delete selectedItems[value]
    }

    this.setState({
      selectedItems,
    })

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(event, { ...this.props, selectedItems })
    }
  }

  handleContainerScrollOrResize = () => {
    const containerWidth = this.containerRef.offsetWidth
    const scrollWidth = this.containerRef.scrollWidth
    const scrollLeft = this.containerRef.scrollLeft

    if (scrollWidth > containerWidth) {
      if (scrollLeft > 1) {
        this.rootRef.classList.add('DataTable--overflowLeft')
      } else {
        this.rootRef.classList.remove('DataTable--overflowLeft')
      }

      if (scrollLeft < Math.floor(scrollWidth - containerWidth)) {
        this.rootRef.classList.add('DataTable--overflowRight')
      } else {
        this.rootRef.classList.remove('DataTable--overflowRight')
      }
    } else {
      this.rootRef.classList.remove('DataTable--overflowLeft')
      this.rootRef.classList.remove('DataTable--overflowRight')
    }
  }

  render() {
    const isItemsEmpty = _.isEmpty(this.props.items)

    const selectedItemsKeys = _.keys(this.state.selectedItems)
    const itemsKeys = _.map(this.state.items, item => item[this.props.rowKey])

    const isSelectedAll =
      selectedItemsKeys.length > 0 &&
      _.intersectionBy(selectedItemsKeys, itemsKeys, _.toString).length === itemsKeys.length

    const isSelectedIndeterminate =
      selectedItemsKeys.length > 0 &&
      _.intersectionBy(selectedItemsKeys, itemsKeys, _.toString).length > 0 &&
      _.intersectionBy(selectedItemsKeys, itemsKeys, _.toString).length < itemsKeys.length

    return (
      <div
        className="DataTable"
        ref={ref => {
          this.rootRef = ref
        }}
      >
        <div
          className="DataTable__Container"
          ref={ref => {
            this.containerRef = ref
          }}
        >
          <Table
            celled
            unstackable
            selectable
            collapsing={this.props.collapsing}
            padded={this.props.padded}
            compact={this.props.compact}
            size={this.props.size}
            className={classNames({ sortable: !isItemsEmpty })}
          >
            {!_.isUndefined(this.props.totalPages) && this.props.isTopFooter && (
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{ cursor: 'auto' }}
                    colSpan={this.props.columns.length + (this.props.selectable ? 1 : 0)}
                  >
                    <Popup
                      position="right center"
                      inverted
                      content="１ページあたりの表示件数"
                      trigger={
                        <Dropdown
                          compact
                          selection
                          className="downward"
                          options={this.props.itemsPerPageOptions}
                          value={this.state.itemsPerPage}
                          disabled={isItemsEmpty}
                          onChange={this.handleItemsPerPageDropdownChange}
                        />
                      }
                    />

                    <Pagination
                      floated="right"
                      pageCount={this.props.totalPages}
                      currentPage={this.state.currentPage}
                      onChange={this.handlePaginationChange}
                      disabled={isItemsEmpty}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            )}

            <Table.Header>
              <Table.Row>
                {this.props.selectable && (
                  <Table.HeaderCell
                    collapsing
                    selectable
                    textAlign="center"
                    onClick={event => this.handleHeaderRowCheckboxChange(event)}
                  >
                    <Checkbox
                      checked={isSelectedAll}
                      indeterminate={isSelectedIndeterminate}
                      onChange={this.handleHeaderRowCheckboxChange}
                    />
                  </Table.HeaderCell>
                )}

                {this.props.columns.map((column, index) => (
                  <Table.HeaderCell
                    key={index}
                    textAlign={column.align}
                    collapsing={_.defaultTo(column.collapsing, true)}
                    className={classNames({
                      sorted: this.state.sort[column.field],
                      ascending: this.state.sort[column.field] === 'asc',
                      descending: this.state.sort[column.field] === 'desc',
                      disabled: _.isNil(column.field),
                    })}
                    style={{ minWidth: column.minWidth }}
                    onClick={event => this.handleHeaderCellClick(event, column)}
                  >
                    {column.labelRender ? column.labelRender() : column.label}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!_.isEmpty(this.state.items) &&
                this.state.items.map(item => (
                  <Table.Row
                    key={item[this.props.rowKey]}
                    active={_.get(this.state.selectedItems, item[this.props.rowKey], false)}
                    style={this.props.rowStyle}
                    {...(this.props.rowStates && this.props.rowStates(item))}
                  >
                    {this.props.selectable && (
                      <Table.Cell
                        collapsing
                        selectable
                        textAlign="center"
                        onClick={event =>
                          this.handleBodyRowCheckboxChange(event, {
                            value: item[this.props.rowKey],
                            checked: _.get(this.state.selectedItems, item[this.props.rowKey], false),
                          })
                        }
                      >
                        <Checkbox
                          value={item[this.props.rowKey]}
                          checked={_.get(this.state.selectedItems, item[this.props.rowKey], false)}
                          onChange={this.handleBodyRowCheckboxChange}
                        />
                      </Table.Cell>
                    )}

                    {this.props.columns.map((column, index) => (
                      <Table.Cell
                        key={index}
                        textAlign={column.align}
                        collapsing={_.defaultTo(column.collapsing, true)}
                        style={this.props.cellStyle}
                        className={column.cellClassName}
                      >
                        {column.render ? column.render(item) : item[column.field]}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
            </Table.Body>
            {!_.isUndefined(this.props.totalPages) && (
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan={this.props.columns.length + (this.props.selectable ? 1 : 0)}>
                    {/* FIXME: スクロールする際にツールチップが付いて来ちゃう（コンテキストをコンテンツ領域にしないと？） */}
                    <Popup
                      position="right center"
                      inverted
                      content="１ページあたりの表示件数"
                      trigger={
                        <Dropdown
                          compact
                          selection
                          className="upward"
                          options={this.props.itemsPerPageOptions}
                          value={this.state.itemsPerPage}
                          disabled={isItemsEmpty}
                          onChange={this.handleItemsPerPageDropdownChange}
                        />
                      }
                    />

                    <Pagination
                      floated="right"
                      pageCount={this.props.totalPages}
                      currentPage={this.state.currentPage}
                      onChange={this.handlePaginationChange}
                      disabled={isItemsEmpty}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </div>
      </div>
    )
  }
}

DataTable.propTypes = propTypes
DataTable.defaultProps = defaultProps

export default DataTable
