import React, { Component, createRef } from 'react'
import { Segment, Grid, Label } from 'semantic-ui-react'
import TextareaAutosize from 'react-textarea-autosize'
import { Form } from 'formsy-semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormErrorLabel from './FormErrorLabel'
import MediumInput from './MediumInput'

const propTypes = {
  /**
   * Creator to edit (empty object if create)
   */
  creator: PropTypes.object,
  /**
   * the form auto submitted when value is true
   */
  isSubmitForm: PropTypes.bool,
  /**
   * Handler to be called when the form is changed
   */
  onFormChange: PropTypes.func,
  /**
   * Handler to be called when the form is valid
   */
  onFormValid: PropTypes.func,
  /**
   * Handler to be called when the form is invalid
   */
  onFormInvalid: PropTypes.func,
  /**
   * Handler to be called when the form is submitted
   */
  onFormValidSubmit: PropTypes.func,
  /**
   * Handler to be called when introduction change
   */
  onIntroductionChange: PropTypes.func,
}

const defaultPropTypes = {
  creator: {},
}

const defaultCreatorLink = new Array(3).fill({})

class CreatorForm extends Component {
  state = {
    thumbnailImageUrlInputValue: '',
    thumbnailImageError: null,
    introduction: '',
  }

  /**
   * Initial form values
   */
  initialFormValues = {}
  /**
   * Whether the input data of the form is initialized
   */
  isFormResetted = false

  UNSAFE_componentWillMount() {
    const thumbnailImageUrlInputValue = _.get(this.props, 'creator.thumbnail.image.url', '')
    const introduction = _.get(this.props, 'creator.introduction', '')

    this.setState({
      isFormModified: false,
      thumbnailImageUrlInputValue,
      thumbnailImageError: null,
      introduction,
    })
    this.initializeFormValues(this.props.creator)
    this.isFormResetted = false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.isSubmitForm, this.props.isSubmitForm)) {
      if (this.refs.form && nextProps.isSubmitForm) {
        this.refs.form.submit()
      }
    }
  }

  componentDidUpdate() {
    if (this.refs.form && !this.isFormResetted) {
      this.refs.form.reset(this.initialFormValues)
      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // To Do
  }

  /**
   * Handler to be called when the form is changed
   */
  handleFormChange = currentValues => {
    if (this.props.onFormChange) {
      const { introduction, ...intiFormValues } = this.initialFormValues
      const isModified = !_.isEqual(intiFormValues, currentValues)

      this.props.onFormChange(this.getFormatCreatorFromFormValues(currentValues), isModified)
    }
  }

  /**
   * Handler to be called when the form is valid
   */
  handleFormValid = () => {
    if (this.props.onFormValid) {
      this.props.onFormValid()
    }
  }

  /**
   * Handler to be called when the form is invalid
   */
  handleFormInvalid = () => {
    if (this.props.onFormInvalid) {
      this.props.onFormInvalid()
    }
  }

  /**
   * Handler to be called when the form is submitted
   */
  handleFormValidSubmit = submitFormData => {
    if (this.props.onFormValidSubmit) {
      this.props.onFormValidSubmit(this.getFormatCreatorFromFormValues(submitFormData))
    }
  }

  /**
   * Handler to be called when the thumbnail image input form is changed
   */
  handleThumbnailImageInputChange = (event, { mediumUrl, error }) => {
    this.setState({
      thumbnailImageUrlInputValue: _.defaultTo(mediumUrl, ''),
      thumbnailImageError: error,
    })
  }

  /**
   * Handler to be called when the textarea introduction is changed
   */
  handleIntroductionChange = e => {
    if (this.props.onIntroductionChange) {
      const introduction = e.target.value
      const isIntroductionModified = !_.isEqual(this.initialFormValues.introduction, introduction)
      const isIntroductionValid = !_.isEmpty(introduction)
      this.props.onIntroductionChange(isIntroductionValid, isIntroductionModified)
      this.setState({ introduction })
    }
  }

  /**
   * Inital form values
   */
  initializeFormValues(creator) {
    if (_.isEmpty(creator)) {
      this.initialFormValues = {}
      return
    }

    const creatorLinks = [...(creator.links || []), ...defaultCreatorLink].slice(0, 3)

    this.initialFormValues.name = creator.name
    this.initialFormValues.introduction = creator.introduction
    creatorLinks.forEach((link, index) => {
      this.initialFormValues[`link_${index + 1}.title`] = _.get(link, 'title', '')
      this.initialFormValues[`link_${index + 1}.url`] = _.get(link, 'url', '')
    })
    this.initialFormValues.order = creator.order ? creator.order.toString() : creator.order
    this.initialFormValues['thumbnail.image.url'] = _.get(creator, 'thumbnail.image.url', '')
    this.initialFormValues['thumbnail.image.copyright.title'] = _.get(creator, 'thumbnail.image.copyright.title', '')
    this.initialFormValues['thumbnail.image.copyright.url'] = _.get(creator, 'thumbnail.image.copyright.url', '')
  }

  /**
   * Get format creator from form values
   */
  getFormatCreatorFromFormValues = formValues => {
    const links = defaultCreatorLink.map((value, index) => ({
      title: _.get(formValues, `link_${index + 1}.title`, ''),
      url: _.get(formValues, `link_${index + 1}.url`, ''),
    }))
    const image = {
      url: _.get(formValues, 'thumbnail.image.url', ''),
      copyright: {
        title: _.get(formValues, 'thumbnail.image.copyright.title', ''),
        url: _.get(formValues, 'thumbnail.image.copyright.url', ''),
      },
    }

    return {
      name: formValues.name,
      introduction: this.state.introduction,
      links,
      order: formValues.order ? +formValues.order : formValues.order,
      thumbnail: { image },
    }
  }

  render() {
    return (
      <Form
        ref="form"
        noValidate
        onChange={this.handleFormChange}
        onValid={this.handleFormValid}
        onInvalid={this.handleFormInvalid}
        onValidSubmit={this.handleFormValidSubmit}
      >
        <Grid columns="2" doubling>
          <Grid.Column width={10}>
            <Form.Input name="name" label="クリエイター名" placeholder="クリエイター名を入力してください" required />

            <Form.Field
              label="プロフィール文"
              placeholder="プロフィール文を入力してください"
              name="introduction"
              minRows={10}
              rows={1000}
              maxRows={1000}
              control={TextareaAutosize}
              required
              onChange={this.handleIntroductionChange}
              value={this.state.introduction}
              error={!this.props.isIntroductionValid}
            />

            <Form.Field>
              <label>プロフィール文内リンク</label>

              <Form.Group>
                <Form.Input
                  name="link_1.title"
                  label="・テキスト"
                  placeholder="テキストを入力してください"
                  width={5}
                  validations={{
                    isRequired: (values, value) => {
                      return !(_.isEmpty(value) && !_.isEmpty(values['link_1.url']))
                    },
                  }}
                />

                <Form.Input
                  label="・URL"
                  placeholder="URLを入力してください"
                  name="link_1.url"
                  width={7}
                  validations={{
                    isUrl: true,
                    isRequired: (values, value) => {
                      return !(_.isEmpty(value) && !_.isEmpty(values['link_1.title']))
                    },
                  }}
                  validationErrors={{ isUrl: '無効な URL です' }}
                  errorLabel={<FormErrorLabel />}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  name="link_2.title"
                  label="・テキスト"
                  placeholder="テキストを入力してください"
                  width={5}
                  validations={{
                    isRequired: (values, value) => {
                      return !(_.isEmpty(value) && !_.isEmpty(values['link_2.url']))
                    },
                  }}
                />

                <Form.Input
                  label="・URL"
                  placeholder="URLを入力してください"
                  name="link_2.url"
                  width={7}
                  validations={{
                    isUrl: true,
                    isRequired: (values, value) => {
                      return !(_.isEmpty(value) && !_.isEmpty(values['link_2.title']))
                    },
                  }}
                  validationErrors={{ isUrl: '無効な URL です' }}
                  errorLabel={<FormErrorLabel />}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  name="link_3.title"
                  label="・テキスト"
                  placeholder="テキストを入力してください"
                  width={5}
                  validations={{
                    isRequired: (values, value) => {
                      return !(_.isEmpty(value) && !_.isEmpty(values['link_3.url']))
                    },
                  }}
                />

                <Form.Input
                  label="・URL"
                  placeholder="URLを入力してください"
                  name="link_3.url"
                  width={7}
                  validations={{
                    isUrl: true,
                    isRequired: (values, value) => {
                      return !(_.isEmpty(value) && !_.isEmpty(values['link_3.title']))
                    },
                  }}
                  validationErrors={{ isUrl: '無効な URL です' }}
                  errorLabel={<FormErrorLabel />}
                />
              </Form.Group>
            </Form.Field>

            <Form.Group>
              <Form.Input
                type="number"
                name="order"
                label="一覧ページ表示順位"
                width={5}
                validations={{
                  isPositiveNumber: (values, value) =>
                    (!_.isEmpty(value) && value > 0 && value % 1 === 0) || _.isEmpty(value),
                }}
                validationErrors={{
                  isPositiveNumber: '正数で入力してください。',
                }}
                errorLabel={<FormErrorLabel />}
              />
            </Form.Group>
          </Grid.Column>

          <Grid.Column width={6}>
            <Segment.Group>
              <Segment>
                <Label attached="top" color="blue" content="画像" />

                <Form.Field required>
                  <label>画像</label>

                  <MediumInput
                    mediumUrl={this.state.thumbnailImageUrlInputValue}
                    onChange={this.handleThumbnailImageInputChange}
                  />

                  <Form.Input
                    className="isHidden"
                    name="thumbnail.image.url"
                    placeholder="サムネイル画像を選択してください"
                    required
                    readOnly
                    value={this.state.thumbnailImageUrlInputValue}
                  />
                </Form.Field>

                <Form.Input
                  label="出典元"
                  placeholder="出典元を入力してください"
                  name="thumbnail.image.copyright.title"
                />

                <Form.Input
                  label="出典元の URL"
                  placeholder="出典元の URL を入力してください"
                  name="thumbnail.image.copyright.url"
                  validations="isUrl"
                  validationErrors={{ isUrl: '無効な URL です' }}
                  errorLabel={<FormErrorLabel />}
                />
              </Segment>
            </Segment.Group>
          </Grid.Column>
        </Grid>
      </Form>
    )
  }
}

CreatorForm.propTypes = propTypes
CreatorForm.defaultPropTypes = defaultPropTypes

export default CreatorForm
