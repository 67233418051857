const ArticleStatus = {
  /** 公開 */
  PUBLISH: 'publish',
  /** 保留 */
  PENDING: 'pending',
  /** 下書き */
  DRAFT: 'draft',
  /** ゴミ箱 */
  TRASH: 'trash',
}

export default ArticleStatus
