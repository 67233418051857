import React, { Component } from 'react'
import _ from 'lodash'
import { Modal, Button, Form, Image, Dimmer, Loader, Divider } from 'semantic-ui-react'
import w2aCreativeTypes from '../enums/w2aCreativeTypes'
import { W2aApi, CreativeUpdateValues } from 'trill-api-admin-client'
import ApiErrorMessage from '../components/ApiErrorMessage'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('W2aCreativeEditModal')

const w2aApi = new W2aApi()
const creativeUpdateValues = new CreativeUpdateValues()

class W2aCreativeEditModal extends Component {
  static defaultProps = { permission: { hasUpdatePermission: false } }

  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }

    this.initialFormValues = _.cloneDeep(this.props.w2aCreative)
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  initialize = () => {
    this.setState(this.props.w2aCreative)
    this.openModal()
  }

  readFileAsDataURL = (targetName, files) => {
    const key = targetName
    const file = files[0]
    const reader = new FileReader()
    const obj = {}

    reader.onload = () => {
      obj[key] = reader.result
      this.setState(obj)
    }

    reader.readAsDataURL(file)
  }

  handleChange = event => {
    if (event.dataTransfer) {
      this.readFileAsDataURL(event.target.name, event.dataTransfer.files)
    } else if (event.target.files) {
      this.readFileAsDataURL(event.target.name, event.target.files)
    } else {
      const obj = {}
      const key = event.target.name
      const value = event.target.value
      obj[key] = value
      this.setState(obj)
    }
  }

  handleSubmit = async event => {
    event.preventDefault()

    this.setState({ isBusy: true })

    const newObj = Object.keys(creativeUpdateValues)
      .filter(key => this.state[key] !== this.initialFormValues[key])
      .reduce((obj, key) => {
        obj[key] = this.state[key]
        return obj
      }, {})

    if (!_.isEmpty(newObj)) {
      await w2aApi
        .patchW2aCreative(this.props.w2aCreative.w2aId, this.props.w2aCreative.id, {
          creativeUpdateValues: newObj,
        })
        .then(
          response => {
            logger.debug('PATCH w2a creative API called successfully. Returned response: ', response)
            this.setState({ isBusy: false })
            this.closeModal()
            this.props.getW2aCreatives(this.props.w2aCreative.w2aId)
            _.assign(this.initialFormValues, newObj)
          },
          error => {
            logger.debug(error)
            this.setState({ isBusy: false, apiError: error })
          },
        )
    } else {
      this.setState({ isBusy: false })
      this.closeModal()
    }
  }

  render() {
    const { open, alt, image, color, text, testParam, apiError, isBusy } = this.state
    const { type, permission } = this.props
    const { hasUpdatePermission } = permission
    return (
      <Modal
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasUpdatePermission} icon="edit" onClick={this.initialize} />}
      >
        <Modal.Header>編集</Modal.Header>

        <Modal.Content>
          <ApiErrorMessage error={apiError} />
          <Dimmer active={isBusy} inverted>
            <Loader>読み込み中</Loader>
          </Dimmer>
          <Form onSubmit={this.handleSubmit}>
            {type === w2aCreativeTypes.IMAGE && (
              <div>
                <Image src={image} />
                <Divider />

                <Form.Input label="画像" type="file" name="image" accept={['image/*']} onChange={this.handleChange} />
                <Divider />
                <Form.Input
                  label="alt属性"
                  name="alt"
                  placeholder="alt属性を入力してください"
                  value={alt}
                  onChange={this.handleChange}
                />
              </div>
            )}

            {type === w2aCreativeTypes.IMAGE_AND_BUTTON && (
              <div>
                <Image src={image} />
                <Divider />

                <Form.Input label="画像" type="file" name="image" accept={['image/*']} onChange={this.handleChange} />
                <Divider />
                <Form.Input
                  label="alt属性"
                  name="alt"
                  placeholder="alt属性を入力してください"
                  value={alt}
                  onChange={this.handleChange}
                />
                <Divider />
                <Form.Input
                  label="ボタンカラー（カラーコード）"
                  name="color"
                  placeholder="ボタンカラー（カラーコード） を入力してください"
                  value={color}
                  onChange={this.handleChange}
                />
              </div>
            )}

            {type === w2aCreativeTypes.TEXT && (
              <Form.TextArea
                label="テキスト"
                name="text"
                placeholder="テキストを入力してください"
                value={text}
                onChange={this.handleChange}
              />
            )}

            <Divider />
            <Form.Input
              label="テストパラメータ"
              name="testParam"
              placeholder="テストパラメータを入力してください"
              value={testParam}
              onChange={this.handleChange}
            />
            <Button positive content="更新" type="submit" />
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

export default W2aCreativeEditModal
