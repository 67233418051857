import React, { Component } from 'react'
import { Message, Grid, Segment, Label, Button, Header, Dimmer, Loader, Popup, Menu } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import TextareaAutosize from 'react-textarea-autosize'
import classNames from 'classnames'
import _ from 'lodash'

import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import PublishDatetimeRangePicker from './PublishDatetimeRangePicker'
import MediumInput from '../components/MediumInput'
import ArticleDataTable from '../components/ArticleDataTable'
import CategoriesDropdown from '../components/CategoriesDropdown'
import MediaDropdown from '../components/MediaDropdown'
import { PUBLISH_DATETIME_LABEL_FORMAT } from '../constants/date_format'

const initialAspectRatio = 16 / 9 // クロップ比率

class RecommendedArticle extends Component {
  render() {
    const publishDatetime = this.props.publishDatetime
    const publishDatetimeLabel = publishDatetime.format(PUBLISH_DATETIME_LABEL_FORMAT)
    const formErrorLabel = <Label color="red" pointing />
    const formValidationErrors = { isUrl: '無効な URL です' }

    return (
      <div className="RecommendedArticle">
        <Header>
          {_.isEmpty(this.props.recommendedArticle) ? '編集部おすすめ記事の作成' : '編集部おすすめ記事の編集'}
        </Header>

        {/* ローディング */}
        <Dimmer active={this.props.isBusy} inverted>
          <Loader />
        </Dimmer>

        {/* API エラーメッセージ */}
        <ApiErrorMessage error={this.props.apiError} />

        <Form
          ref={this.props.setRef}
          noValidate
          onChange={this.props.handleFormChange}
          onValid={this.props.handleFormValid}
          onInvalid={this.props.handleFormInvalid}
          onValidSubmit={this.props.handleFormValidSubmit}
        >
          <Grid columns="2" doubling>
            <Grid.Column width={10}>
              <Form.Group widths="equal">
                {/* 記事 ID 入力フィールド */}
                <Form.Field>
                  <Form.Input
                    required
                    label="記事 ID"
                    type="number"
                    placeholder="記事 ID を入力してください"
                    name="articleId"
                    action
                    onChange={this.props.handleArticleIdInputChange}
                  >
                    <input />

                    <Button icon="search" onClick={this.props.handleSearchArticleButtonClick} />
                  </Form.Input>
                </Form.Field>

                {/* 公開日時入力フィールド */}
                <Form.Field required>
                  <label>公開日時</label>

                  <DateRangePicker
                    containerStyles={{ display: 'block' }}
                    singleDatePicker
                    timePicker
                    timePicker24Hour
                    locale={{
                      applyLabel: '確定',
                      cancelLabel: 'キャンセル',
                    }}
                    startDate={this.props.publishDatetime}
                    onEvent={this.props.handleDatePickerEvent}
                  >
                    <Input
                      name="recommended-article-calendar"
                      icon="calendar"
                      iconPosition="left"
                      placeholder="公開日時を指定"
                      value={publishDatetimeLabel}
                      readOnly
                      required
                    />
                  </DateRangePicker>
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Dropdown
                  required
                  selection
                  label="対象プロダクト"
                  placeholder="対象とするプロダクトを選択してください"
                  name="target"
                  options={[
                    { text: 'WEB', value: 'web', key: 'WEB' },
                    { text: '--  APP(1枠目)', value: 'app', key: 'APP' },
                    { text: '--  APP(2枠目)', value: 'app_second_order', key: 'APP_SECOND_ORDER' },
                  ]}
                  value={this.props.target}
                  onChange={this.props.handleTargetDropdownChange}
                />

                {/* 表示順入力フォーム (APP の場合は 1 固定のため変更できないように制御。公開中の WEB の場合は更新時のみ表示順を変更できないように制御) */}
                <Form.Dropdown
                  required
                  selection
                  label="表示順"
                  placeholder="表示する順番を選択してください"
                  disabled={
                    !_.isEqual(this.props.target, 'web') ||
                    (this.props.disabledOrder && !_.isEmpty(this.props.recommendedArticle))
                  }
                  name="order"
                  options={[
                    { text: 1, value: 1, key: 1 },
                    { text: 2, value: 2, key: 2 },
                    { text: 3, value: 3, key: 3 },
                    { text: 4, value: 4, key: 4 },
                    { text: 5, value: 5, key: 5 },
                  ]}
                  value={this.props.order}
                  onChange={this.props.handleOrderDropdownChange}
                />
              </Form.Group>

              {/* タイトル入力フィールド */}
              <Form.Field
                minRows={10}
                maxRows={1000}
                rows={1000}
                control={TextareaAutosize}
                name="title"
                label="タイトル"
                placeholder="タイトルを入力してください"
                onChange={this.props.handleChangeTitle}
                value={this.props.title}
              />

              {/* ボタン */}
              <Menu secondary floated="right">
                <Menu.Item fitted>
                  <Button
                    type="submit"
                    content={_.isEmpty(this.props.recommendedArticle) ? '保存' : '更新'}
                    icon="save"
                    labelPosition="left"
                    primary
                    disabled={
                      !this.props.isFormValid ||
                      !this.props.isFormModified ||
                      _.isEqual(this.props.articleStatus, 'pending')
                    }
                  />
                </Menu.Item>
              </Menu>
            </Grid.Column>

            <Grid.Column width={6}>
              <Segment>
                <Label attached="top" color="blue" content="カバー" />

                {/* カバー入力フィールド */}
                <Form.Field required>
                  <label>画像</label>

                  <MediumInput
                    mediumUrl={this.props.coverImageUrlInputValue}
                    staticImageUrls={this.props.coverStaticImageUrls}
                    initialAspectRatio={initialAspectRatio}
                    onChange={this.props.handleCoverImageInputChange}
                    disableUndoButton={this.props.disableUndoButton}
                  />

                  <Form.Input
                    className="isHidden"
                    name="cover.image.url"
                    placeholder="カバー画像を選択してください"
                    required
                    readOnly
                    value={this.props.coverImageUrlInputValue}
                  />
                </Form.Field>

                {/* カバー画像の出典元入力フィールド */}
                <Form.Input
                  label="出典元"
                  placeholder="出典元を入力してください"
                  name="cover.image.copyright.title"
                  value={this.props.coverImageCopyrightTitleInputValue}
                />

                {/* カバー画像の出典元 URL 入力フィールド */}
                <Form.Input
                  label="出典元の URL"
                  placeholder="出典元の URL を入力してください"
                  name="cover.image.copyright.url"
                  value={this.props.coverImageCopyrightUrlInputValue}
                  validations="isUrl"
                  validationErrors={{ isUrl: '無効な URL です' }}
                  errorLabel={<FormErrorLabel />}
                />
              </Segment>

              {/* カバー動画の設定フィールド */}
              <Segment
                className={classNames({
                  isHidden: !this.props.isVideoActive,
                })}
              >
                <Label attached="top" color="blue" content="カバー動画" />

                <Message info>動画記事が設定されています。</Message>

                <Form.Field>
                  <label>カバー動画</label>

                  <Popup
                    inverted
                    content="カバー画像に設定している画像をカバーとして表示します"
                    trigger={
                      <Form.Checkbox
                        toggle
                        name="options.video.image.use"
                        label="カバー動画に画像を設定"
                        checked={this.props.isVideoImageUse}
                        onChange={this.props.handleVideoCoverStateCheckChange}
                      />
                    }
                  />
                </Form.Field>

                <Form.Field
                  className={classNames({
                    isHidden: this.props.isVideoImageUse,
                  })}
                  error={!_.isNil(this.props.coverVideoError)}
                  style={{ marginBottom: 0 }}
                >
                  <MediumInput
                    mediumUrl={this.props.coverVideoUrlInputValue}
                    maxSize={1}
                    minWidth={640}
                    minHeight={396}
                    accepts={['video/mp4']}
                    onChange={this.props.handleCoverVideoInputChange}
                  />

                  <Form.Input
                    className="isHidden"
                    name="cover.video.url"
                    placeholder="サムネイル動画を選択してください"
                    readOnly
                    required={this.props.isVideoActive && !this.props.isVideoImageUse}
                    value={this.props.coverVideoUrlInputValue}
                  />
                </Form.Field>

                <div
                  className={classNames({
                    isHidden: !(this.props.coverVideoUrlInputValue && !this.props.isVideoImageUse),
                  })}
                  style={{ marginTop: '1rem' }}
                >
                  <Form.Input
                    name="cover.video.copyright.title"
                    label="出典元"
                    placeholder="出典元を入力してください"
                  />

                  <Form.Input
                    name="cover.video.copyright.url"
                    label="出典元の URL"
                    placeholder="出典元の URL を入力してください"
                    validations="isUrl"
                    validationErrors={formValidationErrors}
                    errorLabel={formErrorLabel}
                  />
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </Form>

        <Header size="tiny">記事一覧</Header>

        <Segment loading={this.props.isArticlesBusy}>
          {/* 検索エリア */}
          <Form onValid={this.props.handleFormSearchValid} onInvalid={this.props.handleFormSearchInvalid}>
            <Form.Group widths="equal">
              <Form.Field>
                <PublishDatetimeRangePicker
                  startDate={this.props.filtering.dateRangeStartAt}
                  endDate={this.props.filtering.dateRangeEndAt}
                  onEvent={this.props.handleDateRangePickerEvent}
                  clear={this.props.filtering.clearDateRange}
                  disabled={!this.props.isFormSearchValid}
                />
              </Form.Field>

              <Form.Field>
                <Input
                  name="recommended-article-search"
                  type="text"
                  placeholder="タイトルで検索"
                  action
                  value={this.props.filtering.title}
                  onChange={this.props.handleTitleInputChange}
                  validations={{ matchRegexp: /^((?!,).)*$/i }}
                  validationErrors={{
                    matchRegexp: 'キーワードに不正な記号があるため検索できません',
                  }}
                  errorLabel={<FormErrorLabel />}
                >
                  <input />

                  <Button
                    icon="search"
                    onClick={this.props.handleSearchButtonClick}
                    disabled={!this.props.isFormSearchValid}
                  />
                </Input>
              </Form.Field>

              <Form.Field>
                <Form.Field>
                  <CategoriesDropdown
                    categoryId={this.props.filtering.categoryId}
                    fluid={false}
                    onChange={this.props.handleCatgoriesDropdownChange}
                    disabled={!this.props.isFormSearchValid}
                  />
                </Form.Field>
              </Form.Field>

              <Form.Field>
                <Form.Field>
                  <MediaDropdown
                    mediumId={this.props.filtering.mediumId}
                    fluid={false}
                    onChange={this.props.handleMediaDropdownChange}
                    disabled={!this.props.isFormSearchValid}
                  />
                </Form.Field>
              </Form.Field>
            </Form.Group>
          </Form>

          {/* 件数表示メッセージ */}
          {_.isEmpty(this.props.articleApiError) && (
            <Message info>
              {this.props.totalItems} 件が該当します
              {_.size(this.props.articles) > 0 && ' / おすすめ記事に設定したい記事を選択してください'}
            </Message>
          )}

          {/* API エラーメッセージ */}
          <ApiErrorMessage error={this.props.articleApiError} />

          {!_.isEmpty(this.props.articles) && (
            <ArticleDataTable
              articles={this.props.articles}
              sort={this.props.sorting}
              selectable={false}
              currentPage={this.props.currentPage}
              itemsPerPage={this.props.itemsPerPage}
              totalPages={this.props.totalPages}
              onSelectionChange={this.props.handleDataTableSelectionChange}
              onPageChange={this.props.handleDataTablePageChange}
              extColumns={[
                {
                  label: '操作',
                  align: 'center',
                  render: item => (
                    <Button
                      secondary
                      icon="arrow up"
                      onClick={event => {
                        event.preventDefault()
                        this.props.selectArticle(item.id)
                      }}
                    />
                  ),
                },
              ]}
            />
          )}
        </Segment>
      </div>
    )
  }
}

export default RecommendedArticle
