import React, { Component } from 'react'
import LogLevel from '../LogLevel'

import { CouponApi, NewCoupon, CouponImage, CouponStatus } from 'trill-api-admin-client'

import _ from 'lodash'
import moment from 'moment'
import Coupon from './Coupon'

const logger = LogLevel.getLogger('CouponContainer')

const couponApi = new CouponApi()
const couponStatus = new CouponStatus()

class CouponContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      apiError: null,
      isDateRangeSet: false,
      couponValues: this.newCouponValues(),
      initialCouponValues: null,
      isNew: _.isNil(_.get(props.routeParams, 'id', null)),
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const couponId = this.props.routeParams.id
    logger.debug('couponId', couponId)
    logger.debug('this.state.isNew', this.state.isNew)

    if (couponId) {
      this.setCoupon(couponId)
    }
  }

  setCoupon = async couponId => {
    const { couponValues } = this.state

    this.setState({
      isBusy: true,
      apiError: null,
    })

    try {
      logger.debug(`get coupon id`, couponId)

      const responseCoupon = await couponApi.getCoupon(couponId)

      const coupon = responseCoupon.data

      _.merge(couponValues, coupon)

      if (_.has(couponValues, 'couponBrand')) {
        couponValues.couponBrandId = _.get(couponValues, 'couponBrand.id')
        // 要らない要素を削る
        _.unset(couponValues, ['couponBrand'])
        _.unset(couponValues, ['createdAt'])
        _.unset(couponValues, ['deletedAt'])
        _.unset(couponValues, ['updatedAt'])
      }

      logger.debug(`couponValues`, couponValues)

      this.setState({
        isBusy: false,
        couponValues,
        initialCouponValues: _.cloneDeep(couponValues),
        isDateRangeSet: true,
      })
    } catch (errors) {
      logger.error(`get coupon id error`, errors)

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  newCouponValues = () => {
    const couponValues = new NewCoupon()
    const couponImage = new CouponImage()

    couponValues.image = couponImage

    // input value 初期化
    couponValues.code = ''
    couponValues.notes = ''
    couponValues.termsOfUse = ''
    couponValues.weight = ''
    couponValues.priority = ''
    couponValues.startDateOfUse = moment().startOf('day')
    couponValues.endDateOfUse = moment().endOf('day')
    couponValues.status = couponStatus.pending

    logger.debug('couponValues', couponValues)

    return couponValues
  }

  readFileAsDataURL = (targetName, files) => {
    const { couponValues } = this.state
    const key = targetName
    const file = files[0]
    const reader = new FileReader()

    reader.onload = () => {
      if (_.has(couponValues, key)) {
        _.set(couponValues, key, reader.result)
      }
      this.setState({ couponValues })
    }

    reader.readAsDataURL(file)
  }

  handleChange = event => {
    if (event.dataTransfer) {
      this.readFileAsDataURL(event.target.name, event.dataTransfer.files)
    } else if (event.target.files) {
      this.readFileAsDataURL(event.target.name, event.target.files)
    } else {
      const { couponValues } = this.state

      const key = event.target.name
      let value = event.target.value
      const type = _.get(event.target, 'type', '')
      if (value.length > 0 && type === 'number') {
        value = _.parseInt(value)
      }

      if (_.has(couponValues, key)) {
        _.set(couponValues, key, value)
      }

      this.setState({ couponValues }, () => {
        logger.debug('state', this.state.couponValues)
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault()

    const couponId = this.props.routeParams.id
    const { couponValues } = this.state

    if (couponId) {
      this.patchCoupon(couponId, couponValues)
    } else {
      this.postCoupon(couponValues)
    }
  }

  postCoupon = async newCoupon => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    try {
      const requestParameters = _.cloneDeep(newCoupon)
      // 公開日時を string にしてセット
      requestParameters.startDateOfUse = requestParameters.startDateOfUse.toISOString()
      requestParameters.endDateOfUse = requestParameters.endDateOfUse.toISOString()
      // weight が設定されていない場合 1
      if (_.isEqual(requestParameters.weight, '')) {
        requestParameters.weight = 1
      }
      // priority が設定されていない場合リセット
      if (_.isEqual(requestParameters.priority, '')) {
        _.unset(requestParameters, ['priority'])
      }

      logger.debug(`post coupon param`, requestParameters)

      const postResponse = await couponApi.postCoupon(requestParameters)

      const couponId = _.get(postResponse, 'data.id')

      await this.setCoupon(couponId)

      this.setState({ isNew: false }, () => {
        this.props.router.push(`/coupon/${couponId}`)
      })
    } catch (errors) {
      logger.error(`post coupon error`, errors)

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  patchCoupon = async (couponId, couponUpdateValues) => {
    const { initialCouponValues } = this.state

    this.setState({
      isBusy: true,
      apiError: null,
    })

    try {
      const requestParameters = this.getRequestParameters(couponUpdateValues, initialCouponValues)

      if (_.isEmpty(requestParameters)) {
        logger.debug('requestParameters empty')
        this.setState({ isBusy: false })
      } else {
        await couponApi.patchCoupon(couponId, { couponUpdateValues: requestParameters })

        this.setCoupon(couponId)
      }
    } catch (errors) {
      logger.error(`patch coupon error`, errors)

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  getRequestParameters = (updateValues, defaultValues) => {
    // 差分を取得する関数
    logger.debug({ updateValues, defaultValues })
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(updateValues, defaultValues)

    // 公開日時を string にしてセット
    if (_.has(requestParameters, 'startDateOfUse')) {
      requestParameters.startDateOfUse = requestParameters.startDateOfUse.toISOString()
    }
    if (_.has(requestParameters, 'endDateOfUse')) {
      requestParameters.endDateOfUse = requestParameters.endDateOfUse.toISOString()
    }

    // weight が設定されていない場合 1
    if (_.has(requestParameters, 'weight') && _.isEqual(requestParameters.weight, '')) {
      requestParameters.weight = 1
    }
    // priority が設定されていない場合リセット
    if (_.has(requestParameters, 'priority') && _.isEqual(requestParameters.priority, '')) {
      requestParameters.priority = 0
    }

    return _.omitBy(
      _.pickBy(requestParameters, (v, p) => _.has(defaultValues, p)),
      v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v) && v !== '',
    )
  }

  handleDateRangePickerEvent = (event, picker) => {
    logger.debug({ event, picker })
    const { couponValues } = this.state
    if (event.type === 'apply' || event.type === 'cancel') {
      let isDateRangeSet

      if (event.type === 'apply') {
        couponValues.startDateOfUse = picker.startDate
        couponValues.endDateOfUse = picker.endDate
        isDateRangeSet = true
      } else if (event.type === 'cancel') {
        couponValues.startDateOfUse = moment().startOf('day')
        couponValues.endDateOfUse = moment().endOf('day')
        isDateRangeSet = false
      }

      this.setState({ couponValues, isDateRangeSet }, () => {
        logger.debug(this.state)
      })
    }
  }

  handleStatusDropdownClick = (event, { value }) => {
    const { couponValues } = this.state
    if (value !== couponValues.status) {
      couponValues.status = value
      this.setState({ couponValues })
    }
  }

  handleCouponsDropdownChange = (event, { value }) => {
    const { couponValues } = this.state
    couponValues.couponBrandId = value
    this.setState({ couponValues })
  }

  render() {
    const { apiError } = this.props

    return (
      <Coupon
        {...this.state}
        apiError={apiError}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleDateRangePickerEvent={this.handleDateRangePickerEvent}
        handleCouponsDropdownChange={this.handleCouponsDropdownChange}
        handleStatusDropdownClick={this.handleStatusDropdownClick}
      />
    )
  }
}

export default CouponContainer
