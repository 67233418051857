import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'

const RecommendedTagsFormattedDateAndTime = ({ updatedAt }) => {
  return (
    <span>
      更新日時: &nbsp;
      <FormattedDate value={updatedAt} day="numeric" month="long" year="numeric" weekday="narrow" />
      &nbsp;
      <FormattedTime value={updatedAt} hour="numeric" minute="numeric" />
    </span>
  )
}

export default RecommendedTagsFormattedDateAndTime
