import React, { Component } from 'react'
import { Modal, Dimmer, Loader, Button, Form, Divider, Grid, Message } from 'semantic-ui-react'
import ApiErrorMessage from './ApiErrorMessage'
import { Formik } from 'formik'
import { Email } from '../Validation'

class UserCreateModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      open: false,
      apiError: null,
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false, apiError: null })

  validate = values => {
    const errors = {}

    if (!values.username) {
      errors.username = '必須です'
    } else if (!Email(values.username)) {
      errors.username = 'メールアドレスの形式が正しくありません'
    }

    if (!values.password) errors.password = '必須です'
    if (!values.confirmPassword) errors.confirmPassword = '必須です'
    if (values.password !== values.confirmPassword) errors.confirmPassword = 'パスワードが一致しません'

    return errors
  }

  handleSubmit = async values => {
    this.setState({ isBusy: false })

    const { username, password, role } = values
    const newUser = { username, password, role }

    await this.props.postUser(newUser)

    this.setState({ isBusy: false, apiError: this.props.apiError }, () => {
      if (!this.props.apiError) {
        this.closeModal()
      }
    })
  }

  render() {
    const { open, isBusy, apiError } = this.state

    return (
      <Modal
        size="small"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button primary content="新規作成" icon="write" labelPosition="left" />}
      >
        <Modal.Header>ユーザーの作成</Modal.Header>
        <Dimmer active={isBusy} inverted>
          <Loader />
        </Dimmer>
        <Modal.Content>
          <ApiErrorMessage error={apiError} />

          <Formik
            initialValues={{ username: '', password: '', confirmPassword: '', role: 'viewer' }}
            validate={this.validate}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              setFieldTouched,
              setFieldValue,
              isValid,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Input
                  required
                  type="text"
                  name="username"
                  label="ユーザー名（メールアドレス）"
                  placeholder="guesthouseid@trill-corp.jp"
                  value={values.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.username && touched.username ? <Message negative>{errors.username}</Message> : null}

                <Form.Input
                  required
                  type="password"
                  name="password"
                  label="パスワード"
                  placeholder="パスワードを入力"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.password && touched.password ? <Message negative>{errors.password}</Message> : null}

                <Form.Input
                  required
                  type="password"
                  name="confirmPassword"
                  label="パスワードを再入力"
                  placeholder="パスワードを再入力"
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <Message negative>{errors.confirmPassword}</Message>
                ) : null}

                <Form.Select
                  required
                  label="権限"
                  name="role"
                  placeholder="権限を選択"
                  value={values.role}
                  options={[
                    { text: '閲覧者', value: 'viewer' },
                    { text: 'アナリスト', value: 'analyst' },
                    { text: '編集者', value: 'editor' },
                    { text: 'オーナー', value: 'owner' },
                  ]}
                  onBlur={(e, { name, value }) => setFieldTouched(name, value)}
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                />

                <Divider hidden />
                <Grid>
                  <Grid.Column>
                    <Button
                      type="submit"
                      floated="right"
                      onClick={null}
                      disabled={!isValid}
                      color="blue"
                      content="作成"
                    />

                    <Button type="button" floated="right" content="キャンセル" onClick={this.closeModal} />
                  </Grid.Column>
                </Grid>
              </Form>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    )
  }
}

export default UserCreateModal
