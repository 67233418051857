import React from 'react'
import _ from 'lodash'
import { Statistic } from 'semantic-ui-react'
import ApiErrorMessage from './ApiErrorMessage'

const CouponCompaniesMessages = ({ apiError, tableData, couponCompaniesItems }) => {
  return (
    <div>
      {/* 件数表示メッセージ */}
      {_.isEmpty(apiError) && !_.isEmpty(tableData) && (
        <Statistic horizontal size="mini" color="grey">
          <Statistic.Value>{tableData.totalItems}</Statistic.Value>
          <Statistic.Label>件</Statistic.Label>
        </Statistic>
      )}
      {/* エラーメッセージ */}
      <ApiErrorMessage error={apiError} />
    </div>
  )
}

export default CouponCompaniesMessages
