import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Dimmer, Loader, Message, Header, Divider } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { ArticleApi } from 'trill-api-admin-client'
import moment from 'moment'

import FormErrorLabel from './FormErrorLabel'
import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import { getOutsourceArticleValue } from '../util'
import ArticleStatus from '../enums/ArticleStatus'

const articleApi = new ArticleApi()
let sendBulkUpdateArticles

const MAX_BULK_UPDATE_SIZE = 20

const propTypes = {
  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,
}

const defaultProps = {
  open: false,
}

class BulkUpdateArticlesFromOutsourcedArticlesModal extends Component {
  state = {
    isBusy: false,
    isFormValid: false,
    apiError: null,
    check: {},
    result: {},
  }

  componentWillUnmount() {
    if (!_.isNil(sendBulkUpdateArticles)) {
      sendBulkUpdateArticles.cancel()
    }
  }

  handleFormChange = () => {
    const stateReset = {}

    if (!_.isEmpty(this.state.check)) {
      stateReset.check = {}
    }

    if (!_.isEmpty(this.state.result)) {
      stateReset.result = {}
    }

    if (!_.isEmpty(stateReset)) {
      this.setState(stateReset)
    }
  }

  /**
   * フォームの値が妥当なときに呼ばれるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼ばれるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  articleIds = () => {
    const formValues = this.refs.form.getCurrentValues()

    return _.get(formValues, 'articleIds', '')
      .split(',')
      .map(articleId => +articleId)
  }

  handlePreCheckResult = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const articleIds = this.articleIds()
    const getArticlePromises = articleIds.map(articleId =>
      articleApi
        .getArticle(+articleId)
        .then(response => response.data)
        .catch(() => null),
    )
    const getArticleStatus = articleObjects => {
      return _.mapValues(articleObjects, (article, articleId) => {
        if (!article) {
          return {
            status: '404',
            message: `ID (${articleId})の記事が見つかりません。`,
          }
        }

        if (!article.outsourcedArticle) {
          return {
            status: '404_outsource',
            message: `ID (${articleId})の委託記事が見つかりません。`,
          }
        }

        let missingAttributes = ['title', 'summary', 'description', 'thumbnail']
        const adminArticle = {}
        const outsourceArticle = {}
        const updateArticle = {}
        _.each(['title', 'summary', 'description', 'publishDatetime', 'thumbnail', 'cover'], attribute => {
          adminArticle[attribute] = _.get(article, attribute, null)
          outsourceArticle[attribute] = getOutsourceArticleValue(article, attribute, null)

          if (outsourceArticle[attribute]) {
            missingAttributes = _.filter(missingAttributes, missAttribute => !_.isEqual(missAttribute, attribute))
          }

          if (!_.isEqual(adminArticle[attribute], outsourceArticle[attribute])) {
            updateArticle[attribute] = outsourceArticle[attribute]
          }
        })

        if (!_.isEmpty(missingAttributes)) {
          return {
            status: '400',
            message: `ID (${articleId})の記事に必須項目%{${missingAttributes.join(', ')}}が入力されていません。`,
          }
        }

        if (_.isEmpty(updateArticle)) {
          return {
            status: '304',
            message: `ID (${articleId})の記事に変更内容がありません。`,
          }
        }

        if (_.isEqual(_.get(article, 'status', null), ArticleStatus.PUBLISH) && !outsourceArticle.publishDatetime) {
          return {
            status: '200_empty_publish_datetime_warning',
            message: `ID (${articleId})は公開済の記事です。日時設定の削除により記事が非公開となります。`,
          }
        }

        if (
          _.isEqual(_.get(article, 'status', null), ArticleStatus.PUBLISH) &&
          adminArticle.publishDatetime &&
          outsourceArticle.publishDatetime &&
          moment(adminArticle.publishDatetime).isSameOrBefore(moment(), 'minute') &&
          moment(outsourceArticle.publishDatetime).isAfter(moment(), 'minute')
        ) {
          return {
            status: '200_future_publish_datetime_warning',
            message: `ID (${articleId})は公開済の記事です。日時設定の変更により公開予約ステータスに変更されます。`,
          }
        }

        return {
          status: '200',
          message: `ID (${articleId})の記事更新が可能です。`,
        }
      })
    }

    Promise.all(getArticlePromises).then(articles => {
      this.setState({
        isBusy: false,
        check: getArticleStatus(_.zipObject(articleIds, articles)),
      })
    })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const articlesBulkUpdateValues = {
      articleIds: this.articleIds(),
    }
    sendBulkUpdateArticles = CancelablePromise(articleApi.bulkUpdateFromOutsourceArticle(articlesBulkUpdateValues))
    sendBulkUpdateArticles.promise
      .then(response => {
        const result = _.get(response, 'data.result', {})

        this.setState({
          isBusy: false,
          result,
          check: {},
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
          result: {},
          check: {},
        })
      })
  }

  /**
   * 削除ボタンを押したときのハンドラ
   */
  handleSubmitButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    this.setState({
      isBusy: false,
      apiError: null,
      result: {},
      check: {},
    })

    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const messageObject = {}

    if (!_.isEmpty(this.state.check)) {
      messageObject.title = (
        <>
          取り込み対象記事の確認結果は以下の通りです。
          <br /> 問題がなければ、[保存] ボタンを選択してください。
        </>
      )
      messageObject.data = this.state.check
    }

    if (!_.isEmpty(this.state.result)) {
      messageObject.title = <>記事の結果が一括で更新されました。</>
      messageObject.data = this.state.result
    }

    return (
      <Modal
        className="BulkUpdateArticlesFromOutsourcedArticlesModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>記事の一括更新</Modal.Header>

        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          <ApiErrorMessage error={this.state.apiError} />

          {!_.isEmpty(messageObject) && (
            <>
              <Message info>
                <Message.Header>{messageObject.title}</Message.Header>
                <Message.List>
                  {_.map(_.keys(messageObject.data), articleId => {
                    const articleResult = messageObject.data[articleId]
                    let headerColor = 'red'

                    if (articleResult.status === '200') {
                      headerColor = 'green'
                    } else if (
                      articleResult.status === '200_empty_publish_datetime_warning' ||
                      articleResult.status === '200_future_publish_datetime_warning'
                    ) {
                      headerColor = 'orange'
                    } else if (articleResult.status === '304') {
                      headerColor = 'blue'
                    }

                    return (
                      <Message.Item key={articleId}>
                        <Header as="h5" color={headerColor} style={{ fontWeight: 'normal' }}>
                          {articleResult.message || ''}
                        </Header>
                      </Message.Item>
                    )
                  })}
                </Message.List>
              </Message>

              <Divider hidden />
            </>
          )}

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            <Form.Input
              required
              name="articleIds"
              label="記事のIDのリスト"
              placeholder="記事IDの一覧を入力してください。例）1234567,1234568,1234569"
              validations={{
                isValidArticleId: (values, value) =>
                  value && !value.split(',').some(item => Number.isNaN(Number(item))),
                isValidArticleIdSize: (values, value) =>
                  value && value.split(',').filter(item => !Number.isNaN(Number(item))).length <= MAX_BULK_UPDATE_SIZE,
              }}
              validationErrors={{
                isValidArticleId: '無効な記事IDです',
                isValidArticleIdSize: `更新できる記事の最大数は${MAX_BULK_UPDATE_SIZE}です`,
              }}
              errorLabel={<FormErrorLabel />}
            />
          </Form>
        </Modal.Content>

        {_.isEmpty(this.state.result) && (
          <Modal.Actions>
            {_.isEmpty(this.state.check) ? (
              <Button primary content="確認" onClick={this.handlePreCheckResult} disabled={!this.state.isFormValid} />
            ) : (
              <>
                <Button content="キャンセル" onClick={this.handleModalClose} />
                <Button
                  primary
                  content="保存"
                  onClick={this.handleSubmitButtonClick}
                  disabled={!this.state.isFormValid}
                />
              </>
            )}
          </Modal.Actions>
        )}
      </Modal>
    )
  }
}

BulkUpdateArticlesFromOutsourcedArticlesModal.propTypes = propTypes
BulkUpdateArticlesFromOutsourcedArticlesModal.defaultProps = defaultProps

export default BulkUpdateArticlesFromOutsourcedArticlesModal
