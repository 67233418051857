import React, { Component } from 'react'
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react'
import { W2aApi, CreativeUpdateValues } from 'trill-api-admin-client'
import ApiErrorMessage from '../components/ApiErrorMessage'
import _ from 'lodash'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('W2aCreativeClearModal')

const w2aApi = new W2aApi()
const creativeUpdateValues = new CreativeUpdateValues()

class W2aCreativeClearModal extends Component {
  static defaultProps = { permission: { hasUpdatePermission: false } }

  state = { open: false }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  clearW2aCreative = async w2aCreative => {
    const newObj = Object.keys(creativeUpdateValues)
      .filter(key => !_.isEmpty(w2aCreative[key]))
      .reduce((obj, key) => {
        obj[key] = ''
        return obj
      }, {})

    this.setState({ isBusy: true })

    if (!_.isEmpty(newObj)) {
      await w2aApi
        .patchW2aCreative(w2aCreative.w2aId, w2aCreative.id, {
          creativeUpdateValues: newObj,
        })
        .then(
          response => {
            logger.debug('PATCH w2a creative API called successfully. Returned response: ', response)
            this.setState({ isBusy: false })
            this.closeModal()
            this.props.getW2aCreatives(w2aCreative.w2aId)
          },
          error => {
            logger.debug(error)
            this.setState({ isBusy: false, apiError: error })
          },
        )
    } else {
      this.setState({ isBusy: false })
      this.closeModal()
    }
  }

  render() {
    const { w2aCreative, permission } = this.props
    const { hasUpdatePermission } = permission
    const { open, isBusy, apiError } = this.state
    return (
      <Modal
        closeIcon
        size="mini"
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasUpdatePermission} icon="trash" onClick={this.openModal} />}
      >
        <Modal.Header>内容をクリアする</Modal.Header>
        <Modal.Content>
          <ApiErrorMessage error={apiError} />
          <Dimmer active={isBusy} inverted>
            <Loader>読み込み中</Loader>
          </Dimmer>
          <Button positive content="クリア" onClick={() => this.clearW2aCreative(w2aCreative)} />
        </Modal.Content>
      </Modal>
    )
  }
}

export default W2aCreativeClearModal
