/**
 * Email アドレスのパターン
 *
 * # 参考リンク
 * - [Email Address Regular Expression That 99.99% Works](http://emailregex.com/)
 */
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape

/**
 * Not allowed `,` in search input text
 */
export const SEARCH_INPUT_PATTERN = /^[^,]+$/i
