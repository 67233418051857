import React, { Component } from 'react'
import _ from 'lodash'
import { Header, Divider, Dimmer, Loader } from 'semantic-ui-react'
import ApiErrorMessage from '../components/ApiErrorMessage'
import { TagApi } from 'trill-api-admin-client'
import RecommendedTags from './RecommendedTags'
import RecommendedTagsEditModal from './RecommendedTagsEditModal'
import LogLevel from '../LogLevel'
import GetPermission from '../GetPermission'

const logger = LogLevel.getLogger('RecommendedTagsContainer')

const tagApi = new TagApi()

export default class RecommendedTagsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      recommendedTags: null,
      updatedAt: '',
      permission: GetPermission('recommendedTag'),
    }

    this.postRecommendedTags = this.postRecommendedTags.bind(this)
  }

  componentDidMount() {
    this.getRecommendedTags()
  }

  getRecommendedTags = async () => {
    this.setState({ isBusy: true })

    try {
      const responseRecommendedTags = await tagApi.getRecommendedTags()

      const recommendedTags = responseRecommendedTags.data
      let updatedAt
      if (recommendedTags.length > 0) {
        updatedAt = _.get(_.head(recommendedTags), 'createdAt')
      }

      logger.debug(`get recommended tags`, { recommendedTags, updatedAt })

      this.setState({
        isBusy: false,
        recommendedTags,
        updatedAt,
      })
    } catch (errors) {
      logger.error(`get recommended tags error`, errors)

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  postRecommendedTags = async recommendedTagsIds => {
    this.setState({ isBusy: true })

    try {
      const postRecommendedTags = _.compact(recommendedTagsIds)

      logger.debug(`post param recommended tags`, postRecommendedTags)

      const responseRecommendedTags = await tagApi.postRecommendedTags({ recommendedTags: postRecommendedTags })

      const recommendedTags = responseRecommendedTags.data
      let updatedAt
      if (recommendedTags.length > 0) {
        updatedAt = _.get(_.head(recommendedTags), 'createdAt')
      }

      logger.debug(`post recommended tags`, { recommendedTags, updatedAt })

      this.setState({
        isBusy: false,
        recommendedTags,
        updatedAt,
      })
    } catch (errors) {
      logger.error(`post recommended tags error`, errors)

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  render() {
    const { recommendedTags, apiError, isBusy } = this.state
    return (
      <div>
        <ApiErrorMessage error={apiError} />

        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Header as="h1">話題・人気のキーワード</Header>

        <Divider />

        {recommendedTags && <RecommendedTags {...this.state} />}

        <Divider hidden />

        <RecommendedTagsEditModal postRecommendedTags={this.postRecommendedTags} {...this.state} />
      </div>
    )
  }
}
