import React, { Component } from 'react'
import { Router, Route, IndexRoute, Redirect, browserHistory } from 'react-router'
import { Loader } from 'semantic-ui-react'

// React Intl - 文字列フォーマット・ライブラリ（多言語対応もしてくれる）
// 参考：https://medium.freecodecamp.com/internationalization-in-react-7264738274a0#.l7xa9xobr
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import ja from 'react-intl/locale-data/ja'

// Semantic UI のスタイル読み込み
// TODO: テーマをカスタマイズする場合は、別リポジトリで開発したパッケージを読み込む
import 'semantic-ui-css/semantic.min.css'

import _ from 'lodash'

import moment from 'moment'
import 'moment/locale/ja'

import store from 'store'
import storeObservePlugin from 'store/plugins/observe'

import { ApiClient, AuthApi } from 'trill-api-admin-client'

import SignIn from './views/SignIn'

import Main from './views/Main'
import Home from './views/Main/Home'
import Article from './views/Main/ArticleContainer'
import ArticlePreview from './views/Main/ArticlePreview'
import Articles from './views/Main/Articles'
import Categories from './views/Main/Categories'
import Creators from './views/Main/Creators'
import Feeds from './views/Main/Feeds'
import Users from './views/Main/Users'
import Tags from './views/Main/Tags'
import Notifications from './views/Main/Notifications'
import Panels from './views/Main/PanelsContainer'
import Matomes from './views/Main/Matomes'
import PersonalityQuizzes from './views/Main/PersonalityQuizzes'
import CpsMedia from './views/Main/CpsMedia'
import RecommendedArticle from './views/Main/RecommendedArticleContainer'
import RecommendedArticles from './views/Main/RecommendedArticles'
import Series from './views/Main/Series'
import PopularCreators from './views/Main/PopularCreators'
import Sponsors from './views/Main/SponsorsContainer'
import TagGroups from './views/Main/TagGroups'
import W2a from './views/Main/W2aContainer'
import GameCategories from './views/Main/GameCategories'
import Games from './views/Main/Games'
import Game from './views/Main/GameContainer'
import RecommendedTagsContainer from './components/RecommendedTagsContainer'
import ManagementCategories from './views/Main/ManagementCategories'
import CouponCompanies from './components/CouponCompaniesContainer'
import CouponBrands from './components/CouponBrandsContainer'
import Coupons from './components/CouponsContainer'
import Coupon from './components/CouponContainer'
import PasswordUpdate from './components/PasswordUpdate'
import PvReportContainer from './components/PvReportContainer'
import OutsourcedArticlesContainer from './components/OutsourcedArticlesContainer'
import OutsourcedArticlesCsvContainer from './components/OutsourcedArticlesCsvContainer'
import RealtimeReport from './views/Main/GoogleAnalytics/RealtimeReport'

import LogLevel from './LogLevel'

import './App.css'
import { getTokenExpiredTime } from './util'

// 言語データ設定
addLocaleData([...en, ...ja])

// 言語判定
const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

// Moment の言語設定
moment.locale(language)

// Store モジュールを初期化 (https://www.npmjs.com/package/store-js#using-plugins)
store.addPlugin(storeObservePlugin)

// TRILL API クライアントの初期化
const apiClient = ApiClient.instance
apiClient.basePath = process.env.REACT_APP_TRILL_API_BASE_PATH

const authApi = new AuthApi()

const logger = LogLevel.getLogger('App')

class App extends Component {
  state = {
    isInitialized: false,
  }

  apiClientOAuth = apiClient.authentications['OAuth2'] // eslint-disable-line dot-notation

  authToken = null

  refreshTokenTimer = null

  startRefreshTokenTimer = () => {
    const expiredSeconds = this.authToken.expires_in - Math.random() * 100
    this.refreshTokenTimer = setTimeout(this.refreshToken, _.min([expiredSeconds * 1000, 0x7fffffff]))
  }

  refreshToken = () => {
    authApi
      .publishToken('refresh_token', process.env.REACT_APP_TRILL_API_OAUTH_CLIENT_ID, {
        refreshToken: this.authToken['refresh_token'], // eslint-disable-line dot-notation
        scope: this.authToken['scope'], // eslint-disable-line dot-notation
      })
      .then(
        response => {
          const authToken = response.data

          // set new field for checking expired time in client side
          authToken.expires_at = getTokenExpiredTime(authToken)

          logger.debug('published auth token', authToken)

          store.set('authToken', authToken)

          // 次回のトークン更新を予約（`0x7FFFFFFF` はタイマーで対応出来る最大の待ち時間）
          this.startRefreshTokenTimer()

          // token 更新前に画面が表示されて Authorization Error が発生するのを回避するためにリフレッシュ完了後に初期化完了とする (リロード時など)
          this.setState({ isInitialized: true })
        },
        error => {
          logger.error(error)

          // 更新が失敗した場合は、現在の認証トークンは不正なので削除（サインイン画面に繊維）
          store.remove('authToken')
        },
      )
  }

  componentDidMount() {
    store.observe('authToken', (authToken, prevAuthToken) => {
      logger.debug('observe auth token', { authToken, prevAuthToken })

      if (authToken) {
        this.authToken = authToken
        this.apiClientOAuth.accessToken = this.authToken['access_token'] // eslint-disable-line dot-notation

        if (!prevAuthToken) {
          // 最初の初期化が完了した直後、または（サインアウト状態から）サインインした場合

          // TODO: ユーザー情報を取得
          // UserApi.getUser().then((response) => {
          //   const user = response.data;
          //
          //   // TODO: 管理権限が付与されていないアカウントの場合、承認待ち案内の表示
          //   if (user.role === 'user') {
          //     browserHistory.push('/account-registration-step-guide');
          //     return;
          //   }
          //
          //   // TODO: アドレス確認メールの送信と案内の表示
          //   if (!user.emailVerified) {
          //     browserHistory.push('/confirm-email-guide');
          //     return;
          //   }
          // });

          if (this.state.isInitialized) {
            // 初期化が完了した後に（サインアウト状態から）サインインした場合

            browserHistory.replace('/')

            // 最初のトークン更新処理を予約（`0x7FFFFFFF` はタイマーで対応出来る最大の待ち時間）
            this.startRefreshTokenTimer()
          } else {
            let newExpiresIn = 0
            const expiresAtMoment = moment(authToken.expires_at)

            if (expiresAtMoment.isValid()) {
              newExpiresIn = expiresAtMoment.utc().diff(moment.utc(), 'second')
            }

            if (newExpiresIn > 0) {
              authToken.expires_in = newExpiresIn
              store.set('authToken', authToken)

              this.setState({ isInitialized: true })
              this.startRefreshTokenTimer()
            } else {
              this.refreshToken()
            }
          }
        }
      } else {
        this.authToken = null
        this.apiClientOAuth.accessToken = null

        if (this.refreshTokenTimer) {
          clearTimeout(this.refreshTokenTimer)
        }

        browserHistory.replace('/sign-in')

        this.setState({ isInitialized: true })
      }
    })

    // Tab 間でも storage の更新を監視したいため window に storage の listener を設定
    // @see: https://github.com/marcuswestin/store.js/issues/267#issuecomment-392828439
    window.addEventListener('storage', this.handleStorageValueChanged)
  }

  /**
   * 別タブで local storage の値が変わったときのハンドラ
   * Authorization Error が発生するため authToken を更新
   */
  handleStorageValueChanged = () => {
    const authToken = store.get('authToken')

    if (!authToken) {
      // store に authToken がない場合 (他 tab でログアウトされた場合)
      this.authToken = null
      this.apiClientOAuth.accessToken = null

      if (this.refreshTokenTimer) {
        clearTimeout(this.refreshTokenTimer)
      }

      browserHistory.replace('/sign-in')
      return
    }

    const prevAuthToken = this.authToken
    this.authToken = authToken
    this.apiClientOAuth.accessToken = authToken['access_token'] // eslint-disable-line dot-notation

    if (!prevAuthToken) {
      // 他の tab でログインした場合
      browserHistory.replace('/')
    }
  }

  handleRouteEnter = (nextState, replace) => {
    logger.debug('handle route enter', nextState)

    if (this.authToken) {
      if (_.includes(['/sign-in', '/sign-up'], nextState.location.pathname)) {
        replace({
          pathname: '/',
        })
      }

      // TODO: 権限ごとにアクセス可能なルーティングを出し分け
      // const authTokenScopes = this.authToken.scope.split(' ');
    } else if (!_.includes(['/sign-in', '/sign-up'], nextState.location.pathname)) {
      replace({
        pathname: '/sign-in',
        state: {
          nextPathname: nextState.location.pathname,
        },
      })
    }
  }

  render() {
    if (this.state.isInitialized) {
      return (
        <IntlProvider locale={language}>
          <Router history={browserHistory}>
            <Route path="/sign-in" component={SignIn} onEnter={this.handleRouteEnter} />
            {/* 誰でもサインアップできる状態になっているためコメントアウト */}
            {/* <Route path='/sign-up' component={SignUp} onEnter={this.handleRouteEnter} /> */}

            <Route path="/" component={Main} onEnter={this.handleRouteEnter}>
              <IndexRoute component={Home} />
              {/* TODO: 仕様がまだ未確定のためコメントアウト */}
              {/* <Route path='dashboard' component={Dashboard} onEnter={this.handleRouteEnter} /> */}
              <Route path="article" component={Article} onEnter={this.handleRouteEnter} />
              <Route path="article/:id" component={Article} onEnter={this.handleRouteEnter} />
              <Route path="article-preview/:id" component={ArticlePreview} onEnter={this.handleRouteEnter} />
              <Route path="articles" component={Articles} onEnter={this.handleRouteEnter} />
              <Route path="articles/:status" component={Articles} onEnter={this.handleRouteEnter} />
              <Route path="creators" component={Creators} onEnter={this.handleRouteEnter} />
              <Route path="feeds" component={Feeds} onEnter={this.handleRouteEnter} />
              <Route path="categories" component={Categories} onEnter={this.handleRouteEnter} />
              <Route path="tags" component={Tags} onEnter={this.handleRouteEnter} />
              <Route path="tag-groups" component={TagGroups} onEnter={this.handleRouteEnter} />
              <Route path="users" component={Users} onEnter={this.handleRouteEnter} />
              <Route path="notifications" component={Notifications} onEnter={this.handleRouteEnter} />
              <Route path="matomes" component={Matomes} onEnter={this.handleRouteEnter} />
              <Route path="personality-quizzes" component={PersonalityQuizzes} onEnter={this.handleRouteEnter} />
              <Route path="cps-media" component={CpsMedia} onEnter={this.handleRouteEnter} />
              <Route path="recommended-article" component={RecommendedArticle} onEnter={this.handleRouteEnter} />
              <Route path="recommended-article/:id" component={RecommendedArticle} onEnter={this.handleRouteEnter} />
              <Route path="recommended-articles" component={RecommendedArticles} onEnter={this.handleRouteEnter} />
              <Route path="sponsors" component={Sponsors} onEnter={this.handleRouteEnter} />
              <Route path="panels" component={Panels} onEnter={this.handleRouteEnter} />
              <Route path="w2a" component={W2a} onEnter={this.handleRouteEnter} />
              <Route path="game-categories" component={GameCategories} onEnter={this.handleRouteEnter} />
              <Route path="games" component={Games} onEnter={this.handleRouteEnter} />
              <Route path="game/:id" component={Game} onEnter={this.handleRouteEnter} />
              <Route path="game" component={Game} onEnter={this.handleRouteEnter} />
              <Route path="recommended-tags" component={RecommendedTagsContainer} onEnter={this.handleRouteEnter} />
              <Route path="management-categories" component={ManagementCategories} onEnter={this.handleRouteEnter} />
              <Route path="coupon-companies" component={CouponCompanies} onEnter={this.handleRouteEnter} />
              <Route path="coupon-brands" component={CouponBrands} onEnter={this.handleRouteEnter} />
              <Route path="coupons" component={Coupons} onEnter={this.handleRouteEnter} />
              <Route path="coupon" component={Coupon} onEnter={this.handleRouteEnter} />
              <Route path="coupon/:id" component={Coupon} onEnter={this.handleRouteEnter} />
              <Route path="password-update" component={PasswordUpdate} onEnter={this.handleRouteEnter} />
              <Route path="pv-report" component={PvReportContainer} onEnter={this.handleRouteEnter} />
              <Route
                path="outsourced-articles"
                component={OutsourcedArticlesContainer}
                onEnter={this.handleRouteEnter}
              />
              <Route
                path="outsourced-articles-csv"
                component={OutsourcedArticlesCsvContainer}
                onEnter={this.handleRouteEnter}
              />
              <Route path="manga-series" component={Series} onEnter={this.handleRouteEnter} />
              <Route path="manga-popular-creators" component={PopularCreators} onEnter={this.handleRouteEnter} />
              <Route
                path="/google-analytics/realtime-report"
                component={RealtimeReport}
                onEnter={this.handleRouteEnter}
              />
            </Route>

            <Redirect from="*" to="/" />
          </Router>
        </IntlProvider>
      )

      // TODO: メールアドレス・パスワード認証に関連する画面の実装とメールテンプレートの修正
      // - メールアドレスの確認
      // - パスワードの再設定
      // - メールアドレスの変更
    }
    return <Loader active>読み込み中</Loader>
  }
}

export default App
