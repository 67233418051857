import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MediumApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('MediaDropdown')
const mediumApi = new MediumApi()

let getSelectedMedia
let getMedia

const propTypes = {
  /**
   * メディア ID
   */
  mediumId: PropTypes.number,

  /**
   * メディアID一覧
   */
  mediumIds: PropTypes.arrayOf(PropTypes.number),

  /**
   * 必須項目 (解除項目を追加するかどうか)
   */
  required: PropTypes.bool,

  /**
   * 幅を最大にするかどうか
   */
  fluid: PropTypes.bool,

  /**
   * ゴミ箱にあるコンテンツを表示させたくない場合
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * メディア項目を変更したときに呼び出す外部関数
   */
  onChange: PropTypes.func,

  /**
   * Whether disabled or not
   */
  disabled: PropTypes.bool,

  /**
   * 複数選択を許可するかどうか
   */
  multiple: PropTypes.bool,

  /**
   * クリア可能な設定を使用すると、ユーザーはドロップダウンから選択を削除できます。
   */
  clearable: PropTypes.bool,
}

const defaultProps = {
  mediumId: null,
  mediumIds: [],
  required: false,
  fluid: true,
  ignoreTrashContents: true,
  disabled: false,
  multiple: false,
  clearable: false,
}

/**
 * メディア選択用のコンポーネント
 */
class MediaDropdown extends Component {
  state = {
    isBusy: false,
    media: [],
    selectedMedia: [],
    currentPage: 0,
    totalPages: 0,
  }

  /**
   * ドロップダウンの値を変更したかどうか
   */
  isDropdownValueChanged = false

  /**
   * メディア名検索クエリ
   */
  mediumSearchQuery = ''

  componentDidMount() {
    const { multiple, mediumId, mediumIds = [] } = this.props
    const selectedMediumIds = multiple ? mediumIds : [mediumId]

    this.retrieveSelectedMedia(selectedMediumIds).then(() => {
      this.retrieveMedia()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { multiple, mediumId, mediumIds = [] } = this.props
    const { multiple: nextMultiple, mediumId: nextMediumId, mediumIds: nextMediumIds = [] } = nextProps
    const selectedMediumIds = multiple ? mediumIds : [mediumId]
    const nextSelectedMediumIds = nextMultiple ? nextMediumIds : [nextMediumId]
    const newState = this.isDropdownValueChange
      ? {}
      : {
          media: [],
          currentPage: 0,
          totalPages: 0,
        }

    // ドロップダウンで値を更新したとき以外はリロード (ex. 記事一覧のタブを更新したとき)
    if (!_.isEqual(selectedMediumIds, nextSelectedMediumIds)) {
      this.setState(newState, () => {
        this.retrieveSelectedMedia(nextSelectedMediumIds).then(() => {
          this.retrieveMedia()
        })
      })
    }

    this.mediumSearchQuery = ''
    this.isDropdownValueChanged = false
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getMedia)) {
      getMedia.cancel()
    }
    if (!_.isNil(getSelectedMedia)) {
      getSelectedMedia.cancel()
    }
  }

  /**
   * ドロップダウンにメディア名を入れて検索を行ったときのハンドラ
   */
  handleInfiniteDropdownSearch = searchQuery => {
    this.mediumSearchQuery = searchQuery

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveMedia()
      },
    )
  }

  /**
   * ドロップダウンの値を変更したときのハンドラ
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    if (this.props.onChange) {
      const name = this.props.multiple ? 'mediumIds' : 'mediumId'
      const selectedMedia = this.props.multiple
        ? _.filter(this.state.media, media => value.some(mediumId => _.isEqual(mediumId, media.id)))
        : _.find(this.state.media, ['id', value])

      this.isDropdownValueChanged = true
      this.props.onChange(event, { name, value, detail: selectedMedia })
    }
  }

  /**
   * メディア詳細の取得
   */
  retrieveSelectedMedia = mediumIds =>
    new Promise(resolve => {
      const selectedMediumIds = _.filter(mediumIds, mediumId => !_.isNil(mediumId))

      if (_.isEmpty(selectedMediumIds)) {
        resolve()
        return
      }

      this.setState({ isBusy: true })
      getSelectedMedia = CancelablePromise(mediumApi.getMedia(this.getSelectedRequestQuery(selectedMediumIds)))
      getSelectedMedia.promise
        .then(response => {
          const selectedMedia = response.data
          const media = this.state.media

          media.unshift(...selectedMedia)
          this.setState(
            {
              media: _.uniqBy(media, 'id'),
              selectedMedia,
              isBusy: false,
            },
            () => resolve(),
          )
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          // メディア詳細が取得できなくても処理は続行させたいため resolve
          this.setState({ isBusy: false }, () => resolve())
        })
    })

  /**
   * メディア一覧の取得
   */
  retrieveMedia = () => {
    this.setState({ isBusy: true })

    getMedia = CancelablePromise(mediumApi.getMedia(this.getRequestQuery()))
    getMedia.promise
      .then(response => {
        let media = _.concat(this.state.media, response.data)
        const { selectedMedia } = this.state
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

        // id でソート
        media = _.sortBy(media, o => o.id * -1)
        // 選択中のメディアは先頭に表示
        media.unshift(...selectedMedia)

        this.setState({
          isBusy: false,
          media: _.uniqBy(media, 'id'),
          totalPages,
          currentPage,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get media error', error)

        this.setState({ isBusy: false })
      })
  }

  getSelectedRequestQuery = mediumIds => {
    // 絞り込み
    const filtering = []

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    const filterOperator = mediumIds.length > 1 ? 'IN' : '='
    const filterValue = mediumIds.join('__AND__')
    filtering.push(`id ${filterOperator} '${filterValue}'`)

    const query = {
      itemsPerPage: mediumIds.length,
      currentPage: 1,
      filtering,
      sorting: ['-id'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { currentPage, media } = this.state

    // 絞り込み
    const filtering = []

    // メディア名検索文字列があった場合は、メディア名で絞り込むため filtering に追加
    if (!_.isEmpty(this.mediumSearchQuery)) {
      filtering.push(`name LIKE '%${this.mediumSearchQuery}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    // 取得済みのメディア一覧は除外して一覧を取得: 取得済みのタグを再取得しないため
    _.each(media, medium => filtering.push(`id <> '${medium.id}'`))

    const query = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
      sorting: ['-id'],
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const { fluid, required, disabled, multiple, clearable, mediumId, mediumIds = [] } = this.props
    const options = _.map(this.state.media, medium => ({
      text: medium.name,
      value: medium.id,
      icon: 'rss',
    }))

    if (!multiple && !required) {
      // 必須項目ではない場合、解除項目を先頭に付与
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }

    return (
      <InfiniteDropdown
        placeholder="メディアを選択"
        noResultsMessage="該当するメディアが見つかりません"
        loading={this.state.isBusy}
        fluid={fluid}
        options={options}
        value={multiple ? mediumIds : mediumId}
        onLoadMore={this.retrieveMedia}
        onSearch={this.handleInfiniteDropdownSearch}
        onChange={this.handleInfiniteDropdownChange}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
        disabled={disabled}
        multiple={multiple}
        clearable={clearable}
      />
    )
  }
}

MediaDropdown.propTypes = propTypes
MediaDropdown.defaultProps = defaultProps

export default MediaDropdown
