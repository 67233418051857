import React from 'react'
import { Button, Form, Image, Dimmer, Loader, Divider, Dropdown, Icon, Grid, Header } from 'semantic-ui-react'

import _ from 'lodash'
import moment from 'moment'
import ApiErrorMessage from './ApiErrorMessage'
import CouponBrandsDropdown from './CouponBrandsDropdown'
import DatetimeRangePicker from './DatetimeRangePicker'

const Coupon = ({
  apiError,
  isNew,
  isBusy,
  couponValues,
  isDateRangeSet,
  handleSubmit,
  handleChange,
  handleDateRangePickerEvent,
  handleCouponsDropdownChange,
  handleStatusDropdownClick,
}) => {
  const {
    id,
    couponBrandId,
    code,
    image,
    notes,
    termsOfUse,
    startDateOfUse,
    endDateOfUse,
    weight,
    priority,
    status,
  } = couponValues

  const { thumbnail, coupon } = image

  const isButtonDisabled =
    _.isNil(couponBrandId) ||
    couponBrandId === '' ||
    _.isNil(thumbnail) ||
    _.isNil(coupon) ||
    !termsOfUse ||
    !isDateRangeSet

  const couponDropDownStatus = {
    publish: {
      icon: 'unhide',
      text: '公開',
      color: 'green',
    },
    pending: {
      icon: 'hide',
      text: '保留',
      color: 'red',
    },
  }

  return (
    <div>
      <Header as="h1">
        <Icon name="file text outline" />
        <Header.Content>{isNew ? 'クーポンの新規作成' : `クーポン ID ${id} の編集`}</Header.Content>
      </Header>

      {/* エラーメッセージ */}
      <ApiErrorMessage error={apiError} />

      {/* ローディング */}
      <Dimmer active={isBusy} inverted>
        <Loader />
      </Dimmer>

      <Form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Column width={4}>
            <Form.Field required>
              <label>サムネイル</label>
              <Image src={thumbnail} size="small" />
              <Form.Input type="file" name="image.thumbnail" accept={['image/*']} onChange={handleChange} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field required>
              <label>クーポン画像</label>
              <Image src={coupon} size="small" />
              <Form.Input type="file" name="image.coupon" accept={['image/*']} onChange={handleChange} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field>
              <label>ステータス</label>
              <Button.Group color={_.get(couponDropDownStatus, `${status}.color`)}>
                <Button
                  icon={_.get(couponDropDownStatus, `${status}.icon`)}
                  content={_.get(couponDropDownStatus, `${status}.text`)}
                  type="button"
                />
                <Dropdown className="button icon" floating trigger={<span />}>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      key="publish"
                      icon="unhide"
                      text="公開"
                      value="publish"
                      onClick={handleStatusDropdownClick}
                    />
                    <Dropdown.Item
                      key="pending"
                      icon="hide"
                      text="保留"
                      value="pending"
                      onClick={handleStatusDropdownClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            </Form.Field>

            <Divider hidden />

            <Form.Input
              label="クーポンコード"
              name="code"
              value={code}
              placeholder="コードを入力"
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid>

        <Divider hidden />

        <Form.Input label="有効期間" required>
          <DatetimeRangePicker
            startDate={moment(startDateOfUse)}
            endDate={moment(endDateOfUse)}
            onEvent={handleDateRangePickerEvent}
            clear={!isDateRangeSet}
            placeholder="範囲を指定"
          />
        </Form.Input>

        <Grid>
          <Grid.Column width={8}>
            <Form.Input label="備考" name="notes" value={notes} placeholder="最大利用数など" onChange={handleChange} />
          </Grid.Column>

          <Grid.Column width={8}>
            <Form.Field required>
              <label>ブランド</label>
              <CouponBrandsDropdown
                ignoreTrashContents={false}
                couponBrandId={couponBrandId}
                fluid={true}
                onChange={handleCouponsDropdownChange}
              />
            </Form.Field>
          </Grid.Column>
        </Grid>

        <Divider hidden />

        <Grid>
          <Grid.Column width={8}>
            <Form.TextArea
              required
              label="利用条件"
              name="termsOfUse"
              placeholder="利用条件を入力"
              value={termsOfUse}
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column width={8}>
            <Form.Input
              label="優先度"
              name="priority"
              value={priority}
              type="number"
              placeholder="1以上の整数を入力。1,2,3,…の順でクーポンフィードに優先表示"
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column width={8}>
            <Form.Input
              label="重み"
              name="weight"
              value={weight}
              type="number"
              placeholder="未入力の場合は1"
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid>

        <Divider hidden />

        <Button primary content={isNew ? '作成' : '更新'} type="submit" disabled={isButtonDisabled} />
      </Form>
    </div>
  )
}

export default Coupon
