import React from 'react'
import RecommendedArticle from '../../components/RecommendedArticle'

import { TopApi, ArticleApi } from 'trill-api-admin-client'
import _ from 'lodash'
import moment from 'moment'

import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'

import TrillDescription from 'trill-description'

const logger = LogLevel.getLogger('RecommendedArticleContainer')
const topApi = new TopApi()
const articleApi = new ArticleApi()
let getRecommendedArticle
let patchRecommendedArticle
let getArticle
let getArticles

export default class RecommendedArticleContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      target: 'web',
      order: 1,
      title: '',
      isBusy: false,
      isFormModified: false,
      isFormValid: false,
      isFormSearchValid: false,
      coverImageUrlInputValue: '',
      coverStaticImageUrls: [],
      coverImageCopyrightTitleInputValue: '',
      coverImageCopyrightUrlInputValue: '',
      coverImageError: null,
      isVideoActive: false,
      isVideoImageUse: false,
      coverVideoUrlInputValue: '',
      coverVideoError: null,
      apiError: null,
      articleApiError: null,
      publishDatetime: moment(),
      selectedArticleId: 0, // 選択中の記事 id (記事一覧から除外するために使用)
      articles: [],
      isArticlesBusy: false,
      currentPage: 1,
      itemsPerPage: 5,
      sorting: { publishDatetime: 'desc' },
      filtering: {
        title: '',
        categoryId: null,
        mediumId: null,
        dateRangeStartAt: moment().startOf('month'), // 絞り込み日時の開始日( moment )
        dateRangeEndAt: moment().endOf('month'), // 絞り込み日時の終了日( moment )
        clearDateRange: true,
      },
      totalPages: 0,
      totalItems: 0,
      articleStatus: 'publish',
      recommendedArticle: {},
      recommendedArticleCoverImages: [],
      disabledOrder: false,
      disableUndoButton: false,
    }

    this.selectArticle = this.selectArticle.bind(this)
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * 初期のデータ
   * API 送信時の差分取得のために使用
   */
  initialData = {}

  /**
   * フォームの入力データが初期化されているかどうか
   */
  isFormResetted = false

  /**
   * 検索で使用する記事 ID
   */
  searchArticleId = 0

  async componentDidMount() {
    const recommendedArticleId = this.props.routeParams.id

    if (!_.isNil(recommendedArticleId)) {
      this.setState({
        recommendedArticleId,
      })

      await this.retrieveRecommendedArticle(recommendedArticleId)

      this.setState({
        isFormInitialized: true,
      })
    } else {
      const { target } = this.props.location.query
      this.setState(
        {
          target,
        },
        () => {
          this.initialFormValues.target = target
          this.form.reset(this.initialFormValues)
        },
      )
      this.retrieveArticles()
    }
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState) {
    if (this.form && !this.isFormResetted) {
      if (!_.isEmpty(this.state.recommendedArticle)) {
        this.initializeFormValues(this.state.recommendedArticle)
        this.searchArticleId = this.initialFormValues.articleId

        this.form.reset(this.initialFormValues)
      } else {
        this.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  /* ComponentがDOMから削除される時に呼ばれます */
  componentWillUnmount() {
    if (!_.isNil(getArticle)) {
      getArticle.cancel()
    }
    if (!_.isNil(getArticles)) {
      getArticles.cancel()
    }
    if (!_.isNil(getRecommendedArticle)) {
      getRecommendedArticle.cancel()
    }
    if (!_.isNil(patchRecommendedArticle)) {
      patchRecommendedArticle.cancel()
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(currentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const formData = { ...submitFormData, title: this.state.title }
    patchRecommendedArticle = CancelablePromise(this.sendData(this.getRequestParameters(formData)))
    patchRecommendedArticle.promise
      .then(response => {
        const recommendedArticle = response.data
        const recommendedArticleId = recommendedArticle.id

        this.initializeFormValues(recommendedArticle)
        this.searchArticleId = this.initialFormValues.articleId

        resetForm(this.initialFormValues)

        if (_.isEmpty(this.state.recommendedArticle)) {
          this.setState(
            {
              isFormModified: false,
              isBusy: false,
              recommendedArticle,
              recommendedArticleId,
              disableUndoButton: true,
            },
            () => {
              this.props.router.push(`/recommended-article/${recommendedArticleId}`)
            },
          )
        } else {
          this.setState({
            isFormModified: false,
            isBusy: false,
            disableUndoButton: true,
          })
        }
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send data error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * 公開日時の情報を変更したときのイベントハンドラ
   */
  handleDatePickerEvent = (event, picker) => {
    if (event.type === 'apply') {
      const initialPublishDatetime = this.initialFormValues.publishDatetime
      const isFormModified = !moment(initialPublishDatetime).isSame(picker.startDate)

      this.setState({
        publishDatetime: picker.startDate,
        isFormModified,
      })
    }
  }

  /**
   * カバー画像を変更したときのハンドラ
   */
  handleCoverImageInputChange = (event, { mediumUrl, error }) => {
    const coverImageUrl = _.defaultTo(mediumUrl, '')
    const recommendedArticleCoverImageIndex = _.findIndex(this.state.recommendedArticleCoverImages, {
      url: coverImageUrl,
    })

    if (_.isNumber(recommendedArticleCoverImageIndex) && recommendedArticleCoverImageIndex !== -1) {
      const recommendedArticleCoverImage = this.state.recommendedArticleCoverImages[recommendedArticleCoverImageIndex]
      // state と同じ場合変化しないので、一度 '' でリセットする
      this.setState(
        {
          coverImageCopyrightTitleInputValue: '',
          coverImageCopyrightUrlInputValue: '',
        },
        () => {
          this.setState({
            coverImageCopyrightTitleInputValue: _.get(recommendedArticleCoverImage, 'copyright.title'),
            coverImageCopyrightUrlInputValue: _.get(recommendedArticleCoverImage, 'copyright.url'),
          })
        },
      )
    }

    this.setState({
      coverImageUrlInputValue: coverImageUrl,
      coverImageError: error,
      disableUndoButton: false,
    })
  }

  /**
   * 動画のサムネイルを画像と同じものに設定するハンドラ
   */
  handleVideoCoverStateCheckChange = (event, { checked }) => {
    this.setState({ isVideoImageUse: checked })
  }

  /**
   * カバー動画を変更したときのハンドラ
   */
  handleCoverVideoInputChange = (event, { mediumUrl, error }) => {
    this.setState({
      coverVideoUrlInputValue: _.defaultTo(mediumUrl, ''),
      coverVideoError: error,
    })
  }

  /**
   * データテーブルのヘッダーをタップして並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const sorting = sort

    this.setState({ sorting }, () => {
      this.retrieveArticles()
    })
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    this.setState(
      {
        currentPage,
        itemsPerPage,
      },
      () => {
        this.retrieveArticles()
      },
    )
  }

  /**
   * 記事 ID で記事を検索するボタンを押したときのハンドラ
   */
  handleSearchArticleButtonClick = event => {
    event.preventDefault()
    this.selectArticle(this.searchArticleId)
  }

  /**
   * 記事 ID 入力インプットを変更したときのハンドラ
   */
  handleArticleIdInputChange = (event, data) => {
    this.searchArticleId = _.parseInt(data.value)
  }

  /**
   * 記事一覧を公開日付で絞り込むための情報を変更したときのイベントハンドラ
   */
  handleDateRangePickerEvent = (event, picker) => {
    if (event.type === 'apply' || event.type === 'cancel') {
      let startDate
      let endDate
      let clearDateRange

      if (event.type === 'apply') {
        startDate = picker.startDate
        endDate = picker.endDate
        clearDateRange = false
      } else if (event.type === 'cancel') {
        startDate = moment().startOf('day')
        endDate = moment().endOf('day')
        clearDateRange = true
      }

      // 日付で検索を行う
      const filtering = this.state.filtering
      filtering.dateRangeStartAt = startDate
      filtering.dateRangeEndAt = endDate
      filtering.clearDateRange = clearDateRange

      this.setState({ filtering }, () => {
        this.retrieveArticles()
      })
    }
  }

  /**
   * 記事一覧をタイトルで絞り込むためのインプットを変更したときのハンドラ
   */
  handleTitleInputChange = (event, data) => {
    const filtering = this.state.filtering
    filtering.title = data.value

    this.setState({ filtering })
  }

  /**
   * 記事一覧をカテゴリで絞り込むためのドロップダウンの値を変更したときのハンドラ
   */
  handleCatgoriesDropdownChange = (event, { value }) => {
    const filtering = this.state.filtering
    filtering.categoryId = value

    this.setState({ filtering }, () => {
      this.retrieveArticles()
    })
  }

  /**
   * 記事一覧をメディアで絞り込むためのドロップダウンの値を変更したときのハンドラ
   */
  handleMediaDropdownChange = (event, { value }) => {
    const filtering = this.state.filtering
    filtering.mediumId = value

    this.setState({ filtering }, () => {
      this.retrieveArticles()
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveArticles()
  }

  /**
   * 対象デバイスのドロップダウンを変更したときのハンドラ
   */
  handleTargetDropdownChange = (event, { value }) => {
    const partialState = {
      target: value,
    }
    if (_.isEqual(value, 'app')) {
      partialState.order = 1
    }

    this.setState(partialState)
  }

  /**
   * 表示順のドロップダウンを変更したときのハンドラ
   */
  handleOrderDropdownChange = (event, { value }) => {
    this.setState({ order: value })
  }

  /**
   * Handler to be called when the title change
   */
  handleChangeTitle = event => {
    const title = event.target.value
    const isFormModified = !_.isEqual(this.initialData.title, title)
    this.setState({ title, isFormModified })
  }

  /**
   * 編集部おすすめ記事の詳細取得
   * @param {number} recommendedArticleId - 編集部おすすめ記事の ID
   */
  async retrieveRecommendedArticle(recommendedArticleId) {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    // 記事, 記事本文に関連するタグ, 記事タイトルに関連するタグを取得
    try {
      getRecommendedArticle = CancelablePromise(topApi.getRecommendedArticle(recommendedArticleId))

      const recommendedArticleResponse = await getRecommendedArticle.promise
      logger.debug(`retrieved recommended article #${recommendedArticleId} data`, { recommendedArticleResponse })

      const recommendedArticle = recommendedArticleResponse.data

      const coverVideoUrlInputValue = _.get(recommendedArticle, 'cover.video.url', '')
      const isVideoImageUse = !/.+\.mp4$/.test(coverVideoUrlInputValue) && !_.isEmpty(coverVideoUrlInputValue)

      getArticle = CancelablePromise(articleApi.getArticle(_.get(recommendedArticle, 'article.id')))
      const articleResponse = await getArticle.promise
      const article = articleResponse.data

      const recommendedArticleCoverImages = await this.getDescriptionImages(_.get(article, 'description', ''))
      recommendedArticleCoverImages.push({
        url: _.get(recommendedArticle, 'cover.image.url', ''),
        copyright: {
          title: _.get(recommendedArticle, 'cover.image.copyright.title'),
          url: _.get(recommendedArticle, 'cover.image.copyright.url'),
        },
      })

      const coverStaticImageUrls = _.map(recommendedArticleCoverImages, recommendedArticleCoverImage => {
        return recommendedArticleCoverImage.url
      })

      let recommendedArticleTarget = _.get(recommendedArticle, 'target', 'app')
      if (_.get(recommendedArticle, 'order') === 2 && _.get(recommendedArticle, 'target') === 'app') {
        recommendedArticleTarget = 'app_second_order'
      }

      this.initializeFormValues(recommendedArticle)
      this.searchArticleId = this.initialFormValues.articleId
      this.form.reset(this.initialFormValues)

      this.setState(
        {
          recommendedArticle,
          isBusy: false,
          target: recommendedArticleTarget,
          order: _.get(recommendedArticle, 'order', 1),
          isFormModified: false,
          coverImageUrlInputValue: _.get(recommendedArticle, 'cover.image.url', ''),
          coverVideoUrlInputValue: isVideoImageUse ? '' : coverVideoUrlInputValue,
          isVideoActive: !_.isEmpty(coverVideoUrlInputValue),
          isVideoImageUse,
          publishDatetime: moment(_.get(recommendedArticle, 'publishDatetime', moment())),
          selectedArticleId: _.get(recommendedArticle, 'article.id', 0),
          currentPage: 1,
          itemsPerPage: 5,
          sorting: { publishDatetime: 'desc' },
          filtering: {
            title: '',
            categoryId: null,
            mediumId: null,
            dateRangeStartAt: moment().startOf('month'), // 絞り込み日時の開始日( moment )
            dateRangeEndAt: moment().endOf('month'), // 絞り込み日時の終了日( moment )
            clearDateRange: true,
          },
          recommendedArticleCoverImages,
          coverStaticImageUrls,
        },
        () => {
          // 選択中の記事 id 以外の一覧を取得するため setState が終了したあとに一覧を取得する
          this.retrieveArticles()
        },
      )

      this.isFormResetted = false
    } catch (errors) {
      if (errors.isCanceled) {
        return
      }

      logger.error(`retrieve recommended article #${recommendedArticleId} error`, errors)

      this.setState({
        isBusy: false,
        apiError: errors,
      })
    }
  }

  /**
   * 記事一覧を取得
   */
  retrieveArticles = () => {
    this.setState({
      isArticlesBusy: true,
      articleApiError: null,
    })

    if (!_.isNil(getArticles)) {
      getArticles.cancel()
    }
    getArticles = CancelablePromise(articleApi.getArticles(this.getRequestQuery()))
    getArticles.promise
      .then(response => {
        const articles = response.data
        const responseHeader = response.header
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        const itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 5), 10)
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        const totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        this.setState({
          isArticlesBusy: false,
          articles,
          totalPages,
          totalItems,
          currentPage,
          itemsPerPage,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get articles error', error)

        this.setState({
          isArticlesBusy: false,
          articles: [],
          totalItems: 0,
          totalPages: 0,
          itemsPerPage: 5,
          currentPage: 1,
          articleApiError: error,
        })
      })
  }

  /**
   * 記事本文から画像ソースを抽出
   *
   * @param {string} description - 記事本文
   * @return {Array} 抽出した画像ソースの配列
   */
  async getDescriptionImages(description) {
    const URL_PATTERN = new RegExp(
      '(http|ftp|https)://[\\w-]+(\\.[\\w-]+)*([\\w.,@?^=%&amp;:/~+#-]*[\\w@?^=%&amp;/~+#-])?',
    )
    const articleDescriptionImages = []

    try {
      const trillDescription = await TrillDescription.parse(description)

      _.each(trillDescription.images, image => {
        if (URL_PATTERN.test(image.attribs.src)) {
          const imageData = {
            url: image.attribs.src,
            copyright: {
              title: '',
              url: '',
            },
          }

          // eslint-disable-next-line
          _.each(image.next.next.children, imagechildren => {
            if (imagechildren.name === 'a') {
              imageData.copyright.url = imagechildren.attribs.href
              imageData.copyright.title = imagechildren.children[0].data
              return false
            }
            if (!_.isNil(imagechildren.data)) {
              imageData.copyright.title = imagechildren.data
            }
          })

          articleDescriptionImages.push(imageData)
        }
      })
    } catch (error) {
      logger.error('trill description parse error', error)
    }

    return articleDescriptionImages
  }

  /**
   * 記事一覧から記事を選択、記事 ID から検索を実行したときの関数
   *
   * @param {number} articleId - 記事 ID
   */
  async selectArticle(articleId) {
    this.setState({
      apiError: null,
      isBusy: true,
    })
    try {
      if (!_.isNil(getArticle)) {
        getArticle.cancel()
      }
      getArticle = CancelablePromise(articleApi.getArticle(articleId))
      const articleResponse = await getArticle.promise
      const article = articleResponse.data

      // 記事を入力
      await this.resetArticle(article)

      this.setState({ isBusy: false })
    } catch (error) {
      logger.error(`get article article id #${this.searchArticleId} error `, error)

      this.setState({
        apiError: new Error('存在しない記事 ID です'),
        isBusy: false,
      })
    }
  }

  /**
   * 記事データをリセットします
   *
   * @param {Object} article - 記事データ
   */
  async resetArticle(article) {
    // 選択した記事をフォームに反映する
    const selectRecommendedArticleFormValues = {}
    const coverImageUrlInputValue = _.get(article, 'thumbnail.image.url', '')
    const coverVideoUrlInputValue = _.get(article, 'thumbnail.video.url', '')
    const selectedArticleId = article.id
    selectRecommendedArticleFormValues.title = article.title
    selectRecommendedArticleFormValues.articleId = selectedArticleId
    selectRecommendedArticleFormValues['cover.image.url'] = coverImageUrlInputValue
    selectRecommendedArticleFormValues['cover.image.copyright.title'] = _.get(
      article,
      'thumbnail.image.copyright.title',
    )
    selectRecommendedArticleFormValues['cover.image.copyright.url'] = _.get(article, 'thumbnail.image.copyright.url')
    selectRecommendedArticleFormValues['cover.video.url'] = coverVideoUrlInputValue
    selectRecommendedArticleFormValues['cover.video.copyright.title'] = _.get(
      article,
      'thumbnail.video.copyright.title',
    )
    selectRecommendedArticleFormValues['cover.video.copyright.url'] = _.get(article, 'thumbnail.video.copyright.url')

    selectRecommendedArticleFormValues.target = this.state.target
    selectRecommendedArticleFormValues.order = this.state.order
    selectRecommendedArticleFormValues['options.video.image.use'] =
      !/.+\.mp4$/.test(coverVideoUrlInputValue) && !_.isEmpty(coverVideoUrlInputValue)

    const recommendedArticleCoverImages = await this.getDescriptionImages(_.get(article, 'description', ''))
    recommendedArticleCoverImages.push({
      url: selectRecommendedArticleFormValues['cover.image.url'],
      copyright: {
        title: selectRecommendedArticleFormValues['cover.image.copyright.title'],
        url: selectRecommendedArticleFormValues['cover.image.copyright.url'],
      },
    })

    const coverStaticImageUrls = _.map(recommendedArticleCoverImages, recommendedArticleCoverImage => {
      return recommendedArticleCoverImage.url
    })

    if (this.form) {
      // 記事 ID で検索するフォームにも入るため
      this.searchArticleId = selectedArticleId

      // 選択した記事でフォームを初期化
      this.form.reset(selectRecommendedArticleFormValues)

      this.setState({
        coverImageUrlInputValue,
        selectedArticleId,
        articleStatus: article.status,
        apiError: _.isEqual(article.status, 'pending') ? new Error('保留中の記事が選ばれています。') : null,
        isVideoActive: !_.isEmpty(coverVideoUrlInputValue),
        isVideoImageUse: selectRecommendedArticleFormValues['options.video.image.use'],
        coverVideoUrlInputValue,
        recommendedArticleCoverImages,
        coverStaticImageUrls,
      })
    }
  }

  /**
   * フォームの初期化
   * @param {Object} recommendedArticle - 編集部おすすめデータ
   */
  initializeFormValues(recommendedArticle) {
    let recommendedArticleTarget = _.get(recommendedArticle, 'target', 'web')
    if (_.get(recommendedArticle, 'order') === 2 && _.get(recommendedArticle, 'target') === 'app') {
      recommendedArticleTarget = 'app_second_order'
    }

    this.initialFormValues.articleId = _.get(recommendedArticle, 'article.id')
    this.initialFormValues.title = recommendedArticle.title
    this.initialFormValues.publishDatetime = recommendedArticle.publishDatetime
    this.initialFormValues.target = recommendedArticleTarget
    this.initialFormValues.order = _.get(recommendedArticle, 'order', 1)
    this.initialFormValues['cover.image.url'] = _.get(recommendedArticle, 'cover.image.url', '')
    this.initialFormValues['cover.image.copyright.title'] = _.get(recommendedArticle, 'cover.image.copyright.title')
    this.initialFormValues['cover.image.copyright.url'] = _.get(recommendedArticle, 'cover.image.copyright.url')
    this.initialFormValues['cover.video.url'] = _.get(recommendedArticle, 'cover.video.url', '')
    this.initialFormValues['cover.video.copyright.title'] = _.get(recommendedArticle, 'cover.video.copyright.title')
    this.initialFormValues['cover.video.copyright.url'] = _.get(recommendedArticle, 'cover.video.copyright.url')
    const coverVideoUrlInputValue = _.get(recommendedArticle, 'cover.video.url', '')
    this.initialFormValues['options.video.image.use'] =
      !/.+\.mp4$/.test(coverVideoUrlInputValue) && !_.isEmpty(coverVideoUrlInputValue)

    this.setState({ title: recommendedArticle.title })
    logger.debug('initialized form values', this.initialFormValues)
  }

  /**
   * API にデータを送信
   */
  sendData = data => {
    if (_.isEmpty(this.state.recommendedArticle)) {
      return topApi.postRecommendedArticle(data)
    }
    const recommendedArticleId = this.state.recommendedArticle.id

    return topApi.patchRecommendedArticle(recommendedArticleId, {
      recommendedArticleUpdateValues: data,
    })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(this.state.totalItems / this.state.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && this.state.currentPage > totalPage ? totalPage : this.state.currentPage
    const itemsPerPage = this.state.itemsPerPage

    // フィルタリング
    const filtering = []
    filtering.push('deletedAt IS NULL')

    // 期間での絞り込み
    if (!this.state.filtering.clearDateRange) {
      const dateRangeStartAt = this.state.filtering.dateRangeStartAt
      const dateRangeEndAt = this.state.filtering.dateRangeEndAt
      filtering.push(`publishDatetime >= '${dateRangeStartAt.toISOString()}'`)
      filtering.push(`publishDatetime <= '${dateRangeEndAt.toISOString()}'`)
    }

    if (!_.isEmpty(this.state.filtering.title)) {
      let filteringTitle = this.state.filtering.title
      if (filteringTitle.match(/\,/)) { // eslint-disable-line
        filteringTitle = ''
      }

      // タイトルで検索する場合
      filtering.push(`title LIKE "%${filteringTitle}%"`)
    }

    const categoryId = this.state.filtering.categoryId
    if (_.isNumber(categoryId) || !_.isEmpty(categoryId)) {
      filtering.push(`categoryId = ${categoryId}`)
    }

    const mediumId = this.state.filtering.mediumId
    if (_.isNumber(mediumId) || !_.isEmpty(mediumId)) {
      filtering.push(`mediumId = ${mediumId}`)
    }

    // 選択中の記事 ID は除外
    if (_.isEmpty(this.state.selectedArticleId)) {
      filtering.push(`id <> ${this.state.selectedArticleId}`)
    }

    // ソートを配列に変換
    const sorting = _.map(this.state.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    let initialFormValues = {}
    const initialData = {}

    if (!_.isEmpty(this.state.recommendedArticle)) {
      // 変更前のフォームの値
      initialFormValues = this.initialFormValues
      initialData.articleId = _.get(initialFormValues, 'article.id')
      initialData.title = initialFormValues.title
    }

    // フォームに入っている値がドット記法のためオブジェクトに変換
    // 画像 url のみ default 値として state として空文字を設定しているため default 値として '' を指定
    _.set(initialData, 'cover.image.url', _.get(initialFormValues, 'cover.image.url', ''))
    _.set(initialData, 'cover.image.copyright.title', _.get(initialFormValues, 'cover.image.copyright.title'))
    _.set(initialData, 'cover.image.copyright.url', _.get(initialFormValues, 'cover.image.copyright.url'))
    _.set(initialData, 'cover.video.url', _.get(initialFormValues, 'cover.video.url', ''))
    _.set(initialData, 'cover.video.copyright.title', _.get(initialFormValues, 'cover.video.copyright.title'))
    _.set(initialData, 'cover.video.copyright.url', _.get(initialFormValues, 'cover.video.copyright.url'))

    // 判定用のためにフォームに入れている値は送らない
    _.unset(submitFormData, 'options')

    // 動画記事が設定されていて、動画サムネイルを画像に設定する場合
    if (this.state.isVideoActive && this.state.isVideoImageUse) {
      submitFormData.cover.video = submitFormData.cover.image
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    // 差分データに公開日時が入っていた場合
    if (!_.isEmpty(requestParameters.publishDatetime)) {
      _.extend(requestParameters, {
        publishDatetime: this.state.publishDatetime.toISOString(),
      })
    }

    const publishDatetime = this.state.publishDatetime
    if (!publishDatetime.isSame(moment(initialFormValues.publishDatetime))) {
      // 日付に変更があった場合
      requestParameters.publishDatetime = publishDatetime.toISOString()
    }

    // 記事ID があった場合 (数値に変換)
    if (_.has(requestParameters, 'articleId')) {
      requestParameters.articleId = _.parseInt(requestParameters.articleId)
    }

    // カバー画像の出典元に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.title'), '')) {
      _.set(requestParameters, 'cover.image.copyright.title', ' ')
      _.set(requestParameters, 'cover.image.copyright.url', '')
    }

    // カバー画像の出典元の URL に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.url'), '')) {
      _.set(requestParameters, 'cover.image.copyright.url', ' ')
    }

    // カバー動画の出典元に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'cover.video.copyright.title'), '')) {
      _.set(requestParameters, 'cover.video.copyright.title', ' ')
      _.set(requestParameters, 'cover.video.copyright.url', '')
    }

    // カバー動画の出典元の URL に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'cover.video.copyright.url'), '')) {
      _.set(requestParameters, 'cover.video.copyright.url', ' ')
    }

    // 対象プロダクトが APP(1枠目) が選択された
    if (_.has(requestParameters, 'target') && _.get(requestParameters, 'target') === 'app') {
      requestParameters.order = 1
    }

    // 対象プロダクトが APP(2枠目) が選択された
    if (_.has(requestParameters, 'target') && _.get(requestParameters, 'target') === 'app_second_order') {
      requestParameters.target = 'app'
      requestParameters.order = 2
    }

    // 動画から普通のカバー画像に戻す場合はカバー動画をリセット
    if (!_.isEmpty(_.get(initialData, 'cover.video.url')) && !this.state.isVideoActive) {
      _.merge(requestParameters, {
        cover: {
          video: {
            url: ' ',
            copyright: {
              title: ' ',
              url: ' ',
            },
          },
        },
      })
    }

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v) && !_.isString(v))
  }

  render() {
    return (
      <RecommendedArticle
        {...this.state}
        setRef={elem => {
          this.form = elem
        }}
        handleFormChange={this.handleFormChange}
        handleFormValid={this.handleFormValid}
        handleFormInvalid={this.handleFormInvalid}
        handleFormValidSubmit={this.handleFormValidSubmit}
        handleFormSearchValid={this.handleFormSearchValid}
        handleFormSearchInvalid={this.handleFormSearchInvalid}
        handleDatePickerEvent={this.handleDatePickerEvent}
        handleCoverImageInputChange={this.handleCoverImageInputChange}
        handleVideoCoverStateCheckChange={this.handleVideoCoverStateCheckChange}
        handleCoverVideoInputChange={this.handleCoverVideoInputChange}
        handleDataTableSelectionChange={this.handleDataTableSelectionChange}
        handleDataTablePageChange={this.handleDataTablePageChange}
        handleSearchArticleButtonClick={this.handleSearchArticleButtonClick}
        handleArticleIdInputChange={this.handleArticleIdInputChange}
        handleDateRangePickerEvent={this.handleDateRangePickerEvent}
        handleTitleInputChange={this.handleTitleInputChange}
        handleCatgoriesDropdownChange={this.handleCatgoriesDropdownChange}
        handleMediaDropdownChange={this.handleMediaDropdownChange}
        handleSearchButtonClick={this.handleSearchButtonClick}
        handleTargetDropdownChange={this.handleTargetDropdownChange}
        handleOrderDropdownChange={this.handleOrderDropdownChange}
        handleChangeTitle={this.handleChangeTitle}
        selectArticle={this.selectArticle}
      />
    )
  }
}
