import React, { Component } from 'react'
import _ from 'lodash'
import { Header, Divider, Icon, Menu } from 'semantic-ui-react'
import { CouponApi } from 'trill-api-admin-client'
import CouponViewStatus from '../enums/CouponViewStatus'
import CouponCompaniesMessages from './CouponCompaniesMessages'
import CouponCompaniesDataTable from './CouponCompaniesDataTable'
import CouponCompaniesCreateModal from './CouponCompaniesCreateModal'
import LogLevel from '../LogLevel'
import GetPermission from '../GetPermission'

const logger = LogLevel.getLogger('CouponCompaniesContainer')

const couponApi = new CouponApi()

export default class CouponCompaniesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      postApiError: null,
      patchApiError: null,
      putApiError: null,
      viewStatus: CouponViewStatus.ALL,
      tableData: {
        currentPage: 1,
        itemsPerPage: 50,
        totalPages: 0,
        totalItems: 0,
        sorting: { id: 'desc' },
        filtering: { name: '' },
      },
      couponCompaniesItems: [],
      permission: GetPermission('coupon'),
    }

    this.handleDataTableSelectionChange = this.handleDataTableSelectionChange.bind(this)
    this.handleDataTablePageChange = this.handleDataTablePageChange.bind(this)
    this.patchCouponCompany = this.patchCouponCompany.bind(this)
    this.deleteCouponCompany = this.deleteCouponCompany.bind(this)
    this.putCouponCompany = this.putCouponCompany.bind(this)
  }

  componentDidMount() {
    this.retrieveData()
  }

  retrieveData = async () => {
    const { tableData } = this.state

    this.setState({
      isBusy: true,
      apiError: null,
    })

    try {
      const responseCouponCompanies = await couponApi.getCouponCompanies(this.getRequestQuery())

      const couponCompanies = responseCouponCompanies.data

      const responseHeader = responseCouponCompanies.header
      tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
      tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)
      tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
      tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

      logger.debug(`get coupon companies`, { responseCouponCompanies, tableData })

      await this.setState({
        isBusy: false,
        couponCompaniesItems: couponCompanies,
        tableData,
      })

      logger.debug(`get coupon companies`, this.state)
    } catch (error) {
      logger.error(`get coupon companies error`, error)

      tableData.currentPage = 1
      tableData.itemsPerPage = 50
      tableData.totalPages = 0
      tableData.totalItems = 0

      this.setState({
        couponCompaniesItems: [],
        isBusy: false,
        tableData,
        apiError: error,
      })
    }
  }

  handleStatusMenuItemClick = (event, { name }) => {
    const { viewStatus, tableData } = this.state

    if (viewStatus !== name) {
      tableData.currentPage = 1
      tableData.itemsPerPage = 50
      tableData.totalPages = 0
      tableData.totalItems = 0
    }

    this.setState({ viewStatus: name, tableData }, () => {
      this.retrieveData()
    })
  }

  handleDataTableSelectionChange = (event, { sort }) => {
    logger.debug(`handleDataTableSelectionChange`, sort)
    const { tableData } = this.state
    tableData.sorting = sort

    this.setState({ tableData }, () => {
      this.retrieveData()
    })
  }

  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const { tableData } = this.state

    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ tableData }, () => {
      this.retrieveData()
    })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 表示中の View の状態
    const { viewStatus, tableData } = this.state

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage

    // 1 ページあたりに含めるデータの数
    const itemsPerPage = tableData.itemsPerPage

    const filtering = []
    if (_.isEqual(viewStatus, CouponViewStatus.TRASH)) {
      // 削除一覧取得の場合
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  postCouponCompany = async NewCouponCompany => {
    this.setState({
      postApiError: null,
    })

    try {
      logger.debug(`post params`, NewCouponCompany)

      await couponApi.postCouponCompany(NewCouponCompany)

      this.retrieveData()
    } catch (errors) {
      logger.error(`post couponCompany error`, errors)

      this.setState({
        postApiError: errors,
      })
    }
  }

  patchCouponCompany = async (couponCompanyId, couponCompanyUpdateValues) => {
    this.setState({
      patchApiError: null,
    })

    try {
      logger.debug(`patch couponCompany param`, couponCompanyUpdateValues)

      await couponApi.patchCouponCompany(couponCompanyId, { couponCompanyUpdateValues })

      this.retrieveData()
    } catch (errors) {
      logger.error(`patch couponCompany error`, errors)

      this.setState({
        patchApiError: errors,
      })
    }
  }

  deleteCouponCompany = async couponCompanyId => {
    this.setState({
      deleteApiError: null,
    })

    try {
      logger.debug(`delete couponCompany id`, couponCompanyId)

      await couponApi.deleteCouponCompany(couponCompanyId)

      this.retrieveData()
    } catch (errors) {
      logger.error(`delete couponCompany error`, errors)

      this.setState({
        deleteApiError: errors,
      })
    }
  }

  putCouponCompany = async couponCompanyId => {
    this.setState({
      putApiError: null,
    })

    try {
      logger.debug(`put couponCompany id`, couponCompanyId)

      await couponApi.putCouponCompany(couponCompanyId)

      this.retrieveData()
    } catch (errors) {
      logger.error(`put couponCompany error`, errors)

      this.setState({
        putApiError: errors,
      })
    }
  }

  render() {
    const { viewStatus, couponCompaniesItems, postApiError, permission } = this.state

    return (
      <div>
        <Header as="h1">
          <Icon name="building" />
          <Header.Content>クーポン提供企業</Header.Content>
        </Header>

        {/* 切り替え */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={CouponViewStatus.ALL}
            onClick={this.handleStatusMenuItemClick}
            active={_.isEqual(viewStatus, CouponViewStatus.ALL)}
          />

          <Menu.Item
            content="ゴミ箱"
            name={CouponViewStatus.TRASH}
            onClick={this.handleStatusMenuItemClick}
            active={_.isEqual(viewStatus, CouponViewStatus.TRASH)}
          />
        </Menu>

        {/* 新規作成ボタン */}
        <Menu secondary floated="right">
          <Menu.Item fitted>
            <CouponCompaniesCreateModal
              permission={permission}
              apiError={postApiError}
              postCouponCompany={this.postCouponCompany}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        <CouponCompaniesMessages {...this.state} />

        {!_.isEmpty(couponCompaniesItems) && (
          <CouponCompaniesDataTable
            {...this.state}
            handleDataTableSelectionChange={this.handleDataTableSelectionChange}
            handleDataTablePageChange={this.handleDataTablePageChange}
            patchCouponCompany={this.patchCouponCompany}
            deleteCouponCompany={this.deleteCouponCompany}
            putCouponCompany={this.putCouponCompany}
          />
        )}
      </div>
    )
  }
}
