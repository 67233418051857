import React, { Component } from 'react'
import { ArticleApi } from 'trill-api-admin-client'
import {
  Dimmer,
  Loader,
  Header,
  Icon,
  Button,
  List,
  Menu,
  Divider,
  Popup,
  Sidebar,
  Label,
  Modal,
  Statistic,
  Grid,
} from 'semantic-ui-react'

import Store from 'store'
import { Form } from 'formsy-semantic-ui-react'

import _ from 'lodash'
import moment from 'moment'
import encoding from 'encoding-japanese'

import ApiErrorMessage from '../../components/ApiErrorMessage'
import ArticleDataTable from '../../components/ArticleDataTable'
import CategoriesDropdown from '../../components/CategoriesDropdown'
import GetPermission from '../../GetPermission'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import ArticleFilterContainer from '../../components/ArticleFilterContainer'
import { differenceObject } from '../../util'

const logger = LogLevel.getLogger('Articles')
const articleApi = new ArticleApi()
let getArticles
let deleteArticle
const changeStatusArticles = []
const restoreArticles = []

/**
 * 記事一覧に表示するタグの数
 */
const TAG_LABELS_UPPER_LIMIT = 3
const MAX_ARTICLES_CSV_ROWS = 10000

const ITEMS_PER_PAGE_OPTIONS = [
  { text: '50', value: 50 },
  { text: '100', value: 100 },
  { text: '300', value: 300 },
  { text: '500', value: 500 },
  { text: '1000', value: 1000 },
]
const DEFAULT_ITEMS_PER_PAGE = 300

/**
 * 記事の状態
 * @enum {string}
 */
const ArticleStatus = {
  /** すべて */
  ALL: 'all',
  /** 公開 */
  PUBLISH: 'publish',
  /** 保留 */
  PENDING: 'pending',
  /** 下書き */
  DRAFT: 'draft',
  /** ゴミ箱 */
  TRASH: 'trash',
}

class Articles extends Component {
  /**
   * タグを絞り込むときに使用する検索クエリ
   */
  tagSearchQuery = ''

  constructor(props) {
    super(props)

    this.state = {
      status: ArticleStatus.ALL,

      isBusy: false,

      deleteArticle: null,
      isDeleteModalOpen: false,

      selectedItems: {},
      changeStatus: '',
      changeCategoryId: null,
      isChangeStatusModalOpen: false,
      apiError: null,
      statusChangeError: null,

      isDownloadCsvModalOpen: false,

      permission: GetPermission('article'),

      isFormSearchValid: false,

      csv: {
        filename: '',
        content: '',
      },
    }

    _.extend(this.state, {
      pageState: {
        articles: [], // 表示中の記事データ
        totalPages: 0, // 合計ページ数
        totalItems: 0, // 合計記事数
        currentPage: 1, // 現在のページ
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE, // 1 ページあたりの表示件数
        sorting: { id: 'desc' }, // 並び順
        filtering: {
          textType: 'keyword',
          text: '',
          mediumIds: [],
          categoryIds: [],
          managementCategoryIds: [],
          sponsorIds: [],
          type: null,
          mediumItem: {
            autoPublish: null,
            shannonSubmissionAllowed: null,
          },
          tagIds: [],
          createDateRangeStartAt: moment().startOf('day'),
          createDateRangeEndAt: moment().endOf('day'),
          clearCreateDateRange: true,
          publishDateRangeStartAt: moment().startOf('day'),
          publishDateRangeEndAt: moment().endOf('day'),
          clearPublishDateRange: true,
          isIncludingTrashArticle: true,
        },
      },
    })

    this.url = `${window.location.protocol}//${window.location.host}`

    this.handlePageRestoredFromBfCache = this.handlePageRestoredFromBfCache.bind(this)

    logger.debug('constructor', this.state)
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('pageshow', this.handlePageRestoredFromBfCache)

    if (this.props.isPathChanged) {
      Store.remove('articlesPageState')

      this.retrieveArticles()
    } else {
      // 前回のページ表示状態を読み込む
      const articlesPageState = Store.get('articlesPageState')

      const status = _.get(articlesPageState, 'status', ArticleStatus.ALL)
      const partialState = { status }

      // ページの状態を復元
      _.set(partialState, 'pageState', this.getArticleDataTableStateFromSave(articlesPageState))

      this.setState(partialState, () => {
        this.retrieveArticles()
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('pageshow', this.handlePageRestoredFromBfCache)

    // eslint-disable-line
    if (!_.isNil(getArticles)) {
      getArticles.cancel()
    }
    if (!_.isNil(deleteArticle)) {
      deleteArticle.cancel()
    }
    if (!_.isEmpty(changeStatusArticles)) {
      _.each(changeStatusArticles, changeStatusArticle => changeStatusArticle.cancel())
      changeStatusArticles.length = 0
    }
    if (!_.isEmpty(restoreArticles)) {
      _.each(restoreArticles, restoreArticle => restoreArticle.cancel())
      restoreArticles.length = 0
    }
  }

  handlePageRestoredFromBfCache(event) {
    if (event.persisted) {
      window.location.reload()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * テーブルの並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { selectedItems, sort, isHeaderCellClick }) => {
    // TODO: 編集部オススメや特集へ設定など
    if (isHeaderCellClick) {
      const tableData = this.state.pageState
      tableData.sorting = sort

      this.setState({ pageState: tableData }, () => {
        this.retrieveArticles().then(() => {
          this.savePageState()
        })
      })
    } else {
      this.setState({ selectedItems })
    }
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const tableData = this.state.pageState
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ pageState: tableData }, () => {
      this.retrieveArticles().then(() => {
        this.savePageState()
      })
    })
  }

  /**
   * すべて or 公開中 or 保留 or 下書き or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    const tableData = this.state.pageState

    if (this.state.status !== name) {
      tableData.articles = []
      tableData.totalPages = 0
      tableData.totalItems = 0
      tableData.currentPage = 1
      tableData.itemsPerPage = DEFAULT_ITEMS_PER_PAGE
    }

    this.setState({ status: name, pageState: tableData }, () => {
      this.retrieveArticles().then(() => {
        this.savePageState()
      })
    })
  }

  handleFilterChange = filtering => {
    const tableData = this.state.pageState
    const filteringDiff = differenceObject(filtering, tableData.filtering)
    if (_.isNil(filtering) || _.isEmpty(filteringDiff)) {
      return
    }

    tableData.filtering = filtering

    this.setState({ pageState: tableData }, () => {
      const filteringDiffKeys = _.keys(filteringDiff)
      if (_.isEqual(filteringDiffKeys, ['text'])) {
        setTimeout(() => {
          this.savePageState()
        })
      } else if (_.isEqual(filteringDiffKeys, ['textType'])) {
        this.savePageState()
      } else {
        this.retrieveArticles().then(() => {
          this.savePageState()
        })
      }
    })
  }

  /**
   * 記事の状態を変更するモーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleChangeStatusModalCancelButtonClick = () => {
    this.setState({ isChangeStatusModalOpen: false })
  }

  /**
   * 記事の状態を変更するモーダル画面を閉じたときのハンドラ
   */
  handleChangeStatusModalClose = () => {
    this.setState({ isChangeStatusModalOpen: false })
  }

  /**
   * 記事の状態を変更するモーダル画面の変更ボタンを押したときのハンドラ
   */
  handleChangeStatusModalApproveButtonClick = () => {
    this.setState(
      {
        isBusy: true,
        statusChangeError: null,
      },
      () => {
        const isDeleted = _.isEqual(this.state.changeStatus, ArticleStatus.TRASH)
        // Promise を入れる配列の初期化を行う
        changeStatusArticles.length = 0
        restoreArticles.length = 0
        _.forEach(this.state.selectedItems, (value, articleId) => {
          if (isDeleted) {
            // 削除の場合
            changeStatusArticles.push(CancelablePromise(articleApi.deleteArticle(articleId)))
          } else {
            // 更新の場合
            const articleUpdateValues = _.isEqual(this.state.changeStatus, 'change')
              ? { categoryId: _.defaultTo(this.state.changeCategoryId, 0) }
              : { status: this.state.changeStatus }

            changeStatusArticles.push(CancelablePromise(articleApi.patchArticle(articleId, { articleUpdateValues })))
            if (_.isEqual(this.state.status, ArticleStatus.TRASH)) {
              // 削除から更新をする場合、まず削除から戻す
              restoreArticles.push(CancelablePromise(articleApi.putArticle(articleId)))
            }
          }
        })

        Promise.all(_.map(restoreArticles, restoreArticle => restoreArticle.promise))
          .then(() => Promise.all(_.map(changeStatusArticles, changeStatusArticle => changeStatusArticle.promise)))
          .then(() => {
            this.setState(
              {
                isBusy: false,
                isChangeStatusModalOpen: false,
              },
              () => {
                this.retrieveArticles()
              },
            )
          })
          .catch(error => {
            if (error.isCanceled) {
              return
            }

            logger.error('change article status error', error)

            this.setState({
              isBusy: false,
              statusChangeError: error,
            })
          })
      },
    )
  }

  /**
   * 記事削除用モーダル画面を閉じたときのハンドラ
   */
  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false,
      deleteArticle: null,
    })
  }

  /**
   * 記事削除用モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleDeleteModalCancelButtonClick = () => {
    this.setState({ isDeleteModalOpen: false, deleteArticle: null })
  }

  /**
   * 記事削除用モーダル画面の変更ボタンを押したときのハンドラ
   */
  handleDeleteModalApproveButtonClick = () => {
    this.setState({
      isBusy: true,
      statusChangeError: null,
    })

    deleteArticle = CancelablePromise(articleApi.deleteArticle(this.state.deleteArticle.id))
    deleteArticle.promise
      .then(() => {
        logger.debug(`deleted article: ${this.state.deleteArticle.id}`)

        this.setState({
          isBusy: false,
          isDeleteModalOpen: false,
          deleteArticle: null,
        })

        this.retrieveArticles()
      })
      .catch(error => {
        logger.error('article delete error', error)

        this.setState({
          isBusy: false,
          statusChangeError: error,
        })
      })
  }

  /**
   * 選択解除ボタンを押したときのハンドラ
   */
  handleDeselectButtonClick = () => {
    this.setState({ selectedItems: {} })
  }

  handleChangeStatusModalCategoryChange = (event, { value }) => {
    this.setState({ changeCategoryId: value })
  }

  /**
   * 記事の状態を変更するモーダル画面を表示する関数
   */
  openChangeStatusModal = status => {
    const changeStatus = status
    this.setState({
      isChangeStatusModalOpen: true,
      changeStatus,
      statusChangeError: null,
      changeCategoryId: null,
    })
  }

  /**
   * 記事の削除用モーダル画面を表示する関数
   */
  openDeleteModal = article => {
    this.setState({
      isDeleteModalOpen: true,
      deleteArticle: article,
      statusChangeError: null,
    })
  }

  /**
   * 記事一覧の取得
   */
  retrieveArticles = () =>
    new Promise((resolve, reject) => {
      const tableData = this.state.pageState

      this.setState({
        isBusy: true,
        selectedItems: {},
        apiError: null,
      })

      getArticles = CancelablePromise(articleApi.getArticles(this.getRequestQuery()))
      getArticles.promise
        .then(response => {
          // FIXME: 操作を妨害せずに、変更があった部分だけ自然に表示を更新したい
          // （変更があります。「更新」ボタンで記事一覧を更新してください！的な）
          const articles = response.data
          const responseHeader = response.header
          tableData.articles = articles
          tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
          tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', _.size(articles)), 10)
          tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
          tableData.itemsPerPage = parseInt(
            _.get(responseHeader, 'pagination-itemsperpage', DEFAULT_ITEMS_PER_PAGE),
            10,
          )

          logger.debug(`articles changed (total articles count: ${articles.length}) response`, response)

          this.setState(
            {
              isBusy: false,
              pageState: tableData,
            },
            () => resolve(),
          )
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          logger.error('retrieve articles error', error)

          tableData.articles = []
          tableData.itemsPerPage = DEFAULT_ITEMS_PER_PAGE
          tableData.currentPage = 1
          tableData.totalPages = 0
          tableData.totalItems = 0

          this.setState(
            {
              isBusy: false,
              pageState: tableData,
              apiError: error,
            },
            () => {
              this.savePageState()

              reject(error)
            },
          )
        })
    })

  /**
   * API 通信時に使用する header を取得
   */
  getRequestQuery() {
    const status = this.state.status
    const tableData = this.state.pageState
    const filtering = []

    // 期間での絞り込み
    if (!tableData.filtering.clearCreateDateRange) {
      const createDateRangeStartAt = tableData.filtering.createDateRangeStartAt
      const createDateRangeEndAt = tableData.filtering.createDateRangeEndAt
      filtering.push(`createdAt >= '${createDateRangeStartAt.toISOString()}'`)
      filtering.push(`createdAt <= '${createDateRangeEndAt.toISOString()}'`)
    }

    if (!tableData.filtering.clearPublishDateRange) {
      const publishDateRangeStartAt = tableData.filtering.publishDateRangeStartAt
      const publishDateRangeEndAt = tableData.filtering.publishDateRangeEndAt
      filtering.push(`publishDatetime >= '${publishDateRangeStartAt.toISOString()}'`)
      filtering.push(`publishDatetime <= '${publishDateRangeEndAt.toISOString()}'`)
    }

    if (
      !tableData.filtering.clearPublishDateRange &&
      (_.isEqual(status, ArticleStatus.ALL) || _.isEqual(status, ArticleStatus.TRASH))
    ) {
      // Purpose: Using status index, to speed up query
      filtering.push(
        `status IN '${[ArticleStatus.PUBLISH, ArticleStatus.PENDING, ArticleStatus.DRAFT].join('__AND__')}'`,
      )
    }

    // カテゴリでの絞り込み
    if (!_.isEmpty(tableData.filtering.categoryIds)) {
      const filterCategoryOperator = tableData.filtering.categoryIds.length > 1 ? 'IN' : '='
      const filterCategoryValue = tableData.filtering.categoryIds.join('__AND__')
      filtering.push(`categoryId ${filterCategoryOperator} '${filterCategoryValue}'`)
    }

    if (!_.isEmpty(tableData.filtering.managementCategoryIds)) {
      const filterCategoryOperator = tableData.filtering.managementCategoryIds.length > 1 ? 'IN' : '='
      const filterCategoryValue = tableData.filtering.managementCategoryIds.join('__AND__')
      filtering.push(`managementCategoryId ${filterCategoryOperator} '${filterCategoryValue}'`)
    }

    // メディアでの絞り込み
    if (!_.isEmpty(tableData.filtering.mediumIds)) {
      const filterMediumOperator = tableData.filtering.mediumIds.length > 1 ? 'IN' : '='
      const filterMediumValue = tableData.filtering.mediumIds.join('__AND__')
      filtering.push(`mediumId ${filterMediumOperator} '${filterMediumValue}'`)
    }

    // スポンサーでの絞り込み
    if (!_.isEmpty(tableData.filtering.sponsorIds)) {
      const filterSponsorOperator = tableData.filtering.sponsorIds.length > 1 ? 'IN' : '='
      const filterSponsorValue = tableData.filtering.sponsorIds.join('__AND__')
      filtering.push(`sponsorId ${filterSponsorOperator} '${filterSponsorValue}'`)
    }

    // タグで絞り込む
    // TODO: API 側の実装終了時に追加 (https://github.com/trill-corp/trill-api/issues/232)
    // _.each(tableData.filtering.tagIds, tagId => logger.debug(`filtering tag is #${tagId}`));

    // 記事の状態での絞り込み (すべてとゴミ箱以外のタブは filtering の status パラメータを指定する)
    if (!_.isEqual(status, ArticleStatus.ALL) && !_.isEqual(status, ArticleStatus.TRASH)) {
      filtering.push(`status = '${status}'`)
    }

    // スポコン記事で絞り込む
    if (_.isEqual(tableData.filtering.type, 'sponsored')) {
      filtering.push('sponsor_id IS NOT NULL')
    }

    // 動画記事で絞り込む
    if (_.isEqual(tableData.filtering.type, 'video_only')) {
      filtering.push("thumbnail LIKE '%video%'")
      filtering.push("cover LIKE '%video%'")
    }

    // 動画記事の候補で絞り込む
    if (_.isEqual(tableData.filtering.type, 'video_cantidate_only')) {
      filtering.push("description LIKE '%www.youtube.com/embed%'")
      filtering.push("thumbnail NOT LIKE '%video%'")
      filtering.push("cover NOT LIKE '%video%'")
    }

    _.mapKeys(tableData.filtering.mediumItem, (value, key) => {
      if (_.isNull(value)) {
        return
      }

      filtering.push(`mediumItem${_.upperFirst(key)} = ${value}`)
    })

    // 削除記事
    if (_.isEqual(status, ArticleStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else if (!tableData.filtering.isIncludingTrashArticle) {
      filtering.push('deletedAt IS NULL')
    }

    // 文字列検索
    if (!_.isEmpty(tableData.filtering.text) && !_.isEmpty(tableData.filtering.textType)) {
      let filteringText = tableData.filtering.text
      if (filteringText.match(/\,/)) { // eslint-disable-line
        filteringText = ''
      }

      const textType = tableData.filtering.textType
      const text = _.isEqual(textType, 'id')
        ? `${textType} = '${filteringText}'`
        : `${textType} LIKE '%${filteringText}%'`

      filtering.push(text)
    }

    // ページネーション情報
    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage

    const itemsPerPage = tableData.itemsPerPage

    // ソート順
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      filtering,
      sorting,
      currentPage,
      itemsPerPage,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * Store に保存するための記事状態を返却
   * @param {Object} pageState -
   */
  getArticleDataTableStateForSave(pageState) {
    const tableData = this.state.pageState
    const createDateRangeStartAt = tableData.filtering.createDateRangeStartAt
    const createDateRangeEndAt = tableData.filtering.createDateRangeEndAt
    const publishDateRangeStartAt = tableData.filtering.publishDateRangeStartAt
    const publishDateRangeEndAt = tableData.filtering.publishDateRangeEndAt

    // 保存した日を保存
    if (moment().isSame(createDateRangeStartAt, 'day') && moment().isSame(createDateRangeEndAt, 'day')) {
      _.set(pageState, 'pageState_createAtRangeWidthToday', moment().format('YYYY-MM-DD'))
    } else {
      _.unset(pageState, 'pageState_createAtRangeWidthToday')
    }

    if (moment().isSame(publishDateRangeStartAt, 'day') && moment().isSame(publishDateRangeEndAt, 'day')) {
      _.set(pageState, 'pageState_publishDateTimeRangeWidthToday', moment().format('YYYY-MM-DD'))
    } else {
      _.unset(pageState, 'pageState_publishDateTimeRangeWidthToday')
    }

    logger.debug('get article data table state for save', tableData)
    return tableData
  }

  /**
   * ページの状態を保存
   */
  savePageState() {
    const status = this.state.status
    const articlesPageState = { status }

    _.set(articlesPageState, 'pageState', this.getArticleDataTableStateForSave(articlesPageState))

    Store.set('articlesPageState', articlesPageState)
  }

  /**
   * Store から state に設定するための記事状態を返却
   * @param {Object} savedState - Store に保存していたデータ
   */
  getArticleDataTableStateFromSave(savedState) {
    const tableData = _.get(savedState, 'pageState', this.state.pageState)

    const createDateRangeStartAt = moment(tableData.filtering.createDateRangeStartAt)
    const createDateRangeEndAt = moment(tableData.filtering.createDateRangeEndAt)
    const createAtRangeWidthToday = _.get(savedState, 'pageState_createAtRangeWidthToday')

    const publishDateRangeStartAt = moment(tableData.filtering.publishDateRangeStartAt)
    const publishDateRangeEndAt = moment(tableData.filtering.publishDateRangeEndAt)
    const publishDateTimeRangeWidthToday = _.get(savedState, 'pageState_publishDateTimeRangeWidthToday')

    const currentPage = tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    // 日付範囲の指定が「今日」の場合、日付が変わったら日付範囲を現在の「今日」に変更してページ番号を１に戻す
    if (!_.isNil(createAtRangeWidthToday) && !moment().isSame(createAtRangeWidthToday, 'day')) {
      tableData.filtering.createDateRangeStartAt = moment().startOf('day')
      tableData.filtering.createDateRangeEndAt = moment().endOf('day')
      tableData.currentPage = 1
    } else if (!_.isNil(publishDateTimeRangeWidthToday) && !moment().isSame(publishDateTimeRangeWidthToday, 'day')) {
      tableData.filtering.publishDateRangeStartAt = moment().startOf('day')
      tableData.filtering.publishDateRangeEndAt = moment().endOf('day')
      tableData.currentPage = 1
    } else {
      if (!_.isNil(createDateRangeStartAt) && !_.isNil(createDateRangeEndAt)) {
        tableData.filtering.createDateRangeStartAt = createDateRangeStartAt
        tableData.filtering.createDateRangeEndAt = createDateRangeEndAt
      }

      if (!_.isNil(publishDateRangeStartAt) && !_.isNil(publishDateRangeEndAt)) {
        tableData.filtering.publishDateRangeStartAt = publishDateRangeStartAt
        tableData.filtering.publishDateRangeEndAt = publishDateRangeEndAt
      }

      if (!_.isNil(tableData.currentPage)) {
        tableData.currentPage = currentPage
      }

      if (!_.isNil(tableData.itemsPerPage)) {
        tableData.itemsPerPage = itemsPerPage
      }
    }

    logger.debug('get article data table state from save', tableData)
    return tableData
  }

  /**
   * 記事に関連するタグをレンダリング
   * @param {Object} article - 記事データ
   */
  renderTags(article) {
    if (_.isUndefined(article.tags) || _.isEmpty(this.tags)) {
      return null
    }

    // フィルタリング中のタグが先頭になるように並び順を調整
    const tags = this.state.pageState.filtering.tagIds
    const orderedTagIds = _.union(_.defaultTo(tags, []), _.defaultTo(article.tags, []))

    const renderTagLabels = () => (
      <div>
        {_.slice(orderedTagIds, 0, TAG_LABELS_UPPER_LIMIT).map(tagId => (
          <Label icon="tag" horizontal key={tagId} content={this.tags[tagId].name} size="small" />
        ))}
        {orderedTagIds.length > TAG_LABELS_UPPER_LIMIT && <span>...</span>}
      </div>
    )

    const renderTagsPopup = () => (
      <Popup trigger={renderTagLabels()}>
        <Popup.Content>
          {orderedTagIds.map(tagId => (
            <Label icon="tag" horizontal key={tagId} content={this.tags[tagId].name} size="small" />
          ))}
        </Popup.Content>
      </Popup>
    )

    if (_.size(orderedTagIds) > TAG_LABELS_UPPER_LIMIT) {
      return renderTagsPopup()
    }

    return renderTagLabels()
  }

  downloadCsvDataAsFile = () => {
    const { data, filename, contentType } = this.state.csv

    const uniArray = encoding.stringToCode(data)
    const sjisArray = encoding.convert(uniArray, {
      to: 'UTF-8',
      from: 'UNICODE',
    })
    const unit8Array = new Uint8Array(sjisArray)
    /**
     * BOM document https://en.wikipedia.org/wiki/Byte_order_mark#UTF-8
     * Prepend UTF-8 BOM (hexadecimal) byte sequence EF BB BF to data
     */
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), unit8Array], { type: contentType })

    const link = document.createElement('a')
    const url = (window.URL || window.webkitURL).createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('charset', 'utf-8')
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setTimeout(() => (window.URL || window.webkitURL).revokeObjectURL(link.href), 200)
  }

  handleDownloadArticlesCsv = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const currentPage = 1
    const requestQuery = {
      ...this.getRequestQuery(),
      itemsPerPage: MAX_ARTICLES_CSV_ROWS,
      currentPage,
    }
    articleApi
      .downloadArticlesCsv(requestQuery)
      .then(response => {
        logger.debug('download articles csv success', response)
        const responseHeader = response.header
        const isHasNextPage = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10) > currentPage
        const contentType = _.get(responseHeader, 'content-type', 'text/tsv; charset=utf-8')
        const getContentDispositionFilename = () => {
          const filenameRegex = /filename[^;=\n]*=(['"].*?\2|[^;\n]*)/
          const contentDisposition = _.get(responseHeader, 'content-disposition', '')
          const filenameMatches = contentDisposition.match(filenameRegex)

          return filenameMatches && filenameMatches[1] ? filenameMatches[1].replace(/['"]/g, '') : 'articles.tsv'
        }

        this.setState(
          {
            isBusy: false,
            csv: {
              data: response.data,
              filename: getContentDispositionFilename(),
              contentType,
            },
            isDownloadCsvModalOpen: isHasNextPage,
          },
          () => {
            if (!isHasNextPage) {
              this.downloadCsvDataAsFile()
            }
          },
        )
      })
      .catch(error => {
        logger.error('download articles csv error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  handleDownloadCsvModalCancelButtonClick = () => {
    this.setState({ isDownloadCsvModalOpen: false })
  }

  handleDownloadCsvModalSubmitButtonClick = () => {
    this.downloadCsvDataAsFile()
    this.setState({ isDownloadCsvModalOpen: false })
  }

  render() {
    const { isBusy, status, permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission } = permission
    const tableData = this.state.pageState
    const articles = tableData.articles
    const sorting = tableData.sorting
    const itemsPerPage = tableData.itemsPerPage
    const currentPage = tableData.currentPage
    const totalPages = tableData.totalPages
    const totalItems = tableData.totalItems

    const changeStatusModalApproveButtonContent = {
      publish: '公開にする',
      pending: '保留にする',
      draft: '下書きに戻す',
      trash: 'ゴミ箱へ移動',
      change: '変更する',
    }

    // NOTE: 表示レイアウトの切り替えは機能的に必須じゃないので保留
    // const activeLayout = 'table';

    return (
      <div className="Articles">
        <Header as="h1" style={{ marginBottom: 0 }}>
          <Icon name="file text outline" />

          <Header.Content>記事</Header.Content>
        </Header>

        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated style={{ marginTop: '1rem' }}>
          <Menu.Item
            content="すべて"
            name={ArticleStatus.ALL}
            active={status === ArticleStatus.ALL}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="公開中"
            name={ArticleStatus.PUBLISH}
            active={status === ArticleStatus.PUBLISH}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="保留"
            name={ArticleStatus.PENDING}
            active={status === ArticleStatus.PENDING}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="下書き"
            name={ArticleStatus.DRAFT}
            active={status === ArticleStatus.DRAFT}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={ArticleStatus.TRASH}
            active={status === ArticleStatus.TRASH}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        {/* FIXME: スクロールする際にツールチップが付いて来ちゃう（コンテキストをコンテンツ領域にしないと？） */}

        {/* FIXME: 幅節約のためにアイコンボタンをメインで */}

        <Menu secondary floated="right" style={{ marginTop: '1rem' }}>
          {/* NOTE: 表示レイアウトの切り替えは機能的に必須じゃないので保留 */}
          {/* <Menu.Item fitted>
            <Button.Group basic>
              <Popup inverted wide
                content='表示レイアウトをテーブルに変更します。'
                trigger={
                  <Button icon='table' toggle active={activeLayout === 'table'} />
                } />

              <Popup inverted wide
                content='表示レイアウトをカードに変更します。'
                trigger={
                  <Button icon='block layout' toggle active={activeLayout === 'card'} />
                } />

              <Popup inverted wide
                content='表示レイアウトをリストに変更します。'
                trigger={
                  <Button icon='list layout' toggle active={activeLayout === 'list'} />
                } />
            </Button.Group>
          </Menu.Item> */}

          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={() => {
                this.props.router.push('/article')
              }}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        <ArticleFilterContainer
          filtering={tableData.filtering}
          status={status}
          isFormSearchValid={this.state.isFormSearchValid}
          handleFormSearchValid={this.handleFormSearchValid}
          handleFormSearchInvalid={this.handleFormSearchInvalid}
          handleFilterChange={this.handleFilterChange}
          handleSearchButtonClick={this.retrieveArticles}
        />

        <Grid verticalAlign="bottom" centered style={{ margin: '0 -1rem' }}>
          <Grid.Row columns={2}>
            <Grid.Column floated="left" width={4}>
              {articles && _.isEmpty(this.state.apiError) && (
                <Statistic horizontal size="mini" color="grey">
                  <Statistic.Value>{totalItems}</Statistic.Value>
                  <Statistic.Label>件</Statistic.Label>
                </Statistic>
              )}
            </Grid.Column>
            <Grid.Column floated="right" width={12}>
              <Button
                disabled={_.isEmpty(articles)}
                primary
                floated="right"
                icon="download"
                labelPosition="right"
                content="TSVダウンロード"
                onClick={this.handleDownloadArticlesCsv}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/* 記事一覧 */}
        {articles && articles.length > 0 && (
          <ArticleDataTable
            isTopFooter
            articles={articles}
            itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
            sort={sorting}
            selectable={true}
            selectedItems={this.state.selectedItems}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            onSelectionChange={this.handleDataTableSelectionChange}
            onPageChange={this.handleDataTablePageChange}
            extColumns={[
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Popup
                      inverted
                      wide
                      content="記事の編集"
                      trigger={
                        <Button
                          disabled={!hasUpdatePermission}
                          as="a"
                          icon="edit"
                          href={`${this.url}/article/${item.id}`}
                        />
                      }
                    />

                    <Popup
                      inverted
                      wide
                      content="記事本文のプレビューを表示"
                      trigger={<Button as="a" icon="eye" href={`/article-preview/${item.id}`} target="_blank" />}
                    />

                    {status !== ArticleStatus.TRASH && !item.deletedAt && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openDeleteModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* 記事削除モーダル */}
        {hasDeletePermission && (
          <Modal
            closeIcon={true}
            open={this.state.isDeleteModalOpen}
            onClose={this.handleDeleteModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>記事の削除</Modal.Header>

            <Dimmer active={isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.statusChangeError} />

              <p>記事をゴミ箱へ移動しますか？</p>
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleDeleteModalCancelButtonClick} />

              <Button negative content="ゴミ箱へ移動" onClick={this.handleDeleteModalApproveButtonClick} />
            </Modal.Actions>
          </Modal>
        )}

        {/* 選択された記事のステータス変更モーダル */}
        <Modal
          closeIcon={true}
          open={this.state.isChangeStatusModalOpen}
          onClose={this.handleChangeStatusModalClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            {_.isEqual(this.state.changeStatus, 'change') && '選択された記事のデータを変更'}

            {!_.isEqual(this.state.changeStatus, 'change') && '選択された記事のステータスを変更'}
          </Modal.Header>

          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* エラーメッセージ */}
            <ApiErrorMessage error={this.state.statusChangeError} />

            {/* 一括変更の場合 */}
            {_.isEqual(this.state.changeStatus, 'change') && (
              <Form>
                <CategoriesDropdown
                  fluid={true}
                  categoryId={this.state.changeCategoryId}
                  onChange={this.handleChangeStatusModalCategoryChange}
                />
              </Form>
            )}

            <List divided relaxed>
              {!_.isEmpty(this.state.selectedItems) &&
                _.map(this.state.selectedItems, (value, id) => {
                  const selectedArticle = _.find(articles, article => article.id === parseInt(id, 10))

                  if (_.isNil(selectedArticle)) {
                    return null
                  }

                  return (
                    <List.Item key={id}>
                      <List.Icon name="file text outline" size="large" verticalAlign="middle" />

                      <List.Content>
                        <List.Header>{selectedArticle.title}</List.Header>

                        <List.Description>{selectedArticle.slug}</List.Description>
                      </List.Content>
                    </List.Item>
                  )
                })}
            </List>
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleChangeStatusModalCancelButtonClick} />

            <Button
              positive={this.state.changeStatus !== ArticleStatus.TRASH}
              negative={this.state.changeStatus === ArticleStatus.TRASH}
              content={`${changeStatusModalApproveButtonContent[this.state.changeStatus]}`}
              onClick={this.handleChangeStatusModalApproveButtonClick}
            />
          </Modal.Actions>
        </Modal>

        <Modal size="tiny" open={this.state.isDownloadCsvModalOpen} closeOnDimmerClick={false}>
          <Modal.Header>注意！</Modal.Header>

          <Modal.Content>
            <p>
              TSV最大出力件数は1万件までです。1万件を超える結果はTSVに出力されませんが、ダウンロードを実行しますか？
            </p>
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleDownloadCsvModalCancelButtonClick} />

            <Button positive content="ダウンロード" onClick={this.handleDownloadCsvModalSubmitButtonClick} />
          </Modal.Actions>
        </Modal>

        <Sidebar
          as={Menu}
          className="massive icon"
          animation="overlay"
          direction="bottom"
          visible={!_.isEmpty(this.state.selectedItems)}
        >
          {/* TODO: 記事アイテム選択時の各メニューの機能を実装 */}

          <Popup
            inverted
            wide
            content="すべての選択を解除します。"
            trigger={
              <Menu.Item onClick={this.handleDeselectButtonClick}>
                <Label color="red" size="large">
                  <Icon name="check square" />
                  {_.size(this.state.selectedItems)}
                </Label>
              </Menu.Item>
            }
          />

          {this.state.status !== ArticleStatus.PUBLISH && (
            <Popup
              inverted
              wide
              content="選択された記事を公開します。"
              trigger={
                <Menu.Item
                  onClick={() => {
                    this.openChangeStatusModal(ArticleStatus.PUBLISH)
                  }}
                >
                  <Icon name="unhide" />
                </Menu.Item>
              }
            />
          )}

          {this.state.status !== ArticleStatus.PENDING && (
            <Popup
              inverted
              wide
              content="選択された記事を保留にします。"
              trigger={
                <Menu.Item
                  onClick={() => {
                    this.openChangeStatusModal(ArticleStatus.PENDING)
                  }}
                >
                  <Icon name="hide" />
                </Menu.Item>
              }
            />
          )}

          {this.state.status !== ArticleStatus.DRAFT && (
            <Popup
              inverted
              wide
              content="選択された記事を下書きに戻します。"
              trigger={
                <Menu.Item
                  onClick={() => {
                    this.openChangeStatusModal(ArticleStatus.DRAFT)
                  }}
                >
                  <Icon name="wait" />
                </Menu.Item>
              }
            />
          )}

          {this.state.status !== ArticleStatus.TRASH && (
            <Popup
              inverted
              wide
              content="選択された記事をゴミ箱へ移動します。"
              trigger={
                <Menu.Item
                  name={ArticleStatus.TRASH}
                  onClick={() => {
                    this.openChangeStatusModal(ArticleStatus.TRASH)
                  }}
                >
                  <Icon name="trash" />
                </Menu.Item>
              }
            />
          )}

          {this.state.status !== ArticleStatus.TRASH && (
            <Popup
              inverted
              wide
              content="ここから記事カテゴリの一括変更できます。"
              trigger={
                <Menu.Item
                  name="change"
                  onClick={() => {
                    this.openChangeStatusModal('change')
                  }}
                >
                  <Icon name="exchange" />
                </Menu.Item>
              }
            />
          )}
        </Sidebar>
      </div>
    )
  }
}

export default Articles
