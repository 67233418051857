import React, { Component } from 'react'
import { Modal, Button, Form, Dimmer, Loader } from 'semantic-ui-react'
import LogLevel from '../LogLevel'

import _ from 'lodash'
import ApiErrorMessage from './ApiErrorMessage'
import { NewCouponCompany, CouponContact } from 'trill-api-admin-client'

const logger = LogLevel.getLogger('CouponCompaniesCreateModal')

class CouponCompaniesCreateModal extends Component {
  static defaultProps = { permission: { hasCreatePermission: false } }

  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      open: false,
      apiError: null,
      newCouponCompany: this.createNewCouponCompany(),
    }
  }

  createNewCouponCompany = () => {
    const newCouponCompany = new NewCouponCompany()
    const couponContact = new CouponContact()
    newCouponCompany.contact = couponContact

    newCouponCompany.name = ''
    newCouponCompany.displayName = ''
    newCouponCompany.contact.tel = ''
    newCouponCompany.contact.url = ''

    return newCouponCompany
  }

  openModal = () => this.setState({ open: true })

  closeModal = () => this.setState({ open: false, apiError: null })

  handleChange = event => {
    const { newCouponCompany } = this.state

    const key = event.target.name
    let value = event.target.value
    const type = _.get(event.target, 'type', '')
    if (value.length > 0 && type === 'number') {
      value = _.parseInt(value)
    }

    if (_.has(newCouponCompany, key)) {
      _.set(newCouponCompany, key, value)
    }

    this.setState({ newCouponCompany }, () => {
      logger.debug('this.state.newCouponCompany', this.state.newCouponCompany)
    })
  }

  handleCreateButtonClick = async () => {
    const { newCouponCompany } = this.state

    if (this.props.postCouponCompany) {
      this.setState({ isBusy: true })
      await this.props.postCouponCompany(newCouponCompany)
      this.setState({ isBusy: false, apiError: this.props.apiError })
    }

    if (!this.props.apiError) {
      this.setState({ newCouponCompany: this.createNewCouponCompany() }, () => {
        this.closeModal()
      })
    }
  }

  render() {
    const { open, isBusy, apiError, newCouponCompany } = this.state
    const { name, displayName, contact } = newCouponCompany
    const { tel, url } = contact
    const isButtonDisabled = !name || !displayName
    const { permission } = this.props
    const { hasCreatePermission } = permission

    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={
          <Button disabled={!hasCreatePermission} primary content="新規作成" icon="write" labelPosition="left" />
        }
      >
        <Modal.Header>クーポン提供企業の新規作成</Modal.Header>
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />

          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>

          <Form>
            <Form.Input required label="企業名" name="name" value={name} onChange={this.handleChange} />
            <Form.Input required label="表示名" name="displayName" value={displayName} onChange={this.handleChange} />
            <Form.Input label="電話番号（ハイフンあり）" name="contact.tel" value={tel} onChange={this.handleChange} />
            <Form.Input label="問い合わせURL" name="contact.url" value={url} onChange={this.handleChange} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />
          <Button
            positive
            content="作成"
            type="button"
            disabled={isButtonDisabled}
            onClick={this.handleCreateButtonClick}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CouponCompaniesCreateModal
