import React, { Component } from 'react'
import { Modal, Button, Image, Dimmer, Loader } from 'semantic-ui-react'
import LogLevel from '../LogLevel'
import { PanelApi, PanelUpdateValues } from 'trill-api-admin-client'

import { Form } from 'formsy-semantic-ui-react'

import _ from 'lodash'
import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import CancelablePromise from '../CancelablePromise'

const logger = LogLevel.getLogger('PanelEditModal')
const panelApi = new PanelApi()
const panelUpdateValues = new PanelUpdateValues()
let patchPanel

class PanelEditModal extends Component {
  static defaultProps = { permission: { hasUpdatePermission: false } }

  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      apiError: null,
      open: false,
    }

    this.initialPanelValues = _.cloneDeep(this.props.panel)
  }

  static anchorType = {
    URL: 'url',
    ARTICLE_ID: 'articleId',
  }

  componentDidMount() {
    this.initialize()
  }

  /* ComponentがDOMから削除される時に呼ばれます */
  componentWillUnmount() {
    logger.debug('componentWillUnmount')
    if (!_.isNil(patchPanel)) {
      patchPanel.cancel()
    }
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  initialize = () => {
    if (!_.isEmpty(this.initialPanelValues)) {
      this.initialPanelValues.anchorType = !_.isNil(this.initialPanelValues.anchorArticleId)
        ? PanelEditModal.anchorType.ARTICLE_ID
        : PanelEditModal.anchorType.URL
      this.setState(this.initialPanelValues)
    }
  }

  readFileAsDataURL = (targetName, files) => {
    const key = targetName
    const file = files[0]
    const reader = new FileReader()
    const opts = {}

    reader.onload = () => {
      opts[key] = reader.result
      this.setState(opts)
    }

    reader.readAsDataURL(file)
  }

  handleChange = event => {
    if (event.dataTransfer) {
      this.readFileAsDataURL(event.target.name, event.dataTransfer.files)
    } else if (event.target.files) {
      this.readFileAsDataURL(event.target.name, event.target.files)
    } else {
      const opts = {}
      const key = event.target.name
      const value = event.target.value
      const type = _.get(event.target, 'type', '')
      opts[key] = value
      if (value.length > 0 && type === 'number') {
        opts[key] = _.parseInt(value)
      }
      this.setState(opts)
    }
  }

  handleFormValidSubmit = async (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const panelId = this.state.id
    const panelUpdateRequestParameters = this.getRequestParameters(submitFormData)

    if (_.isEmpty(panelUpdateRequestParameters)) {
      logger.debug('panelUpdateRequestParameters empty', submitFormData, this.initialPanelValues)
      this.setState(
        {
          isBusy: false,
        },
        () => {
          this.closeModal()
        },
      )
    } else {
      logger.debug('panelUpdateRequestParameters', { panelUpdateRequestParameters })

      try {
        patchPanel = CancelablePromise(
          panelApi.patchPanel(panelId, { panelUpdateValues: panelUpdateRequestParameters }),
        )
        await patchPanel.promise

        _.assign(this.initialPanelValues, submitFormData)
        this.initialize()

        if (this.props.retrivePanels) {
          this.props.retrivePanels()
        }

        this.setState(
          {
            isBusy: false,
          },
          () => {
            this.closeModal()
          },
        )
      } catch (errors) {
        if (errors.isCanceled) {
          return
        }

        logger.error(`error patchPanel #${panelId} error`, { errors, panelUpdateRequestParameters })

        this.setState({
          isBusy: false,
          apiError: errors,
        })
      }
    }
  }

  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, this.initialPanelValues)

    return _.omitBy(
      _.pickBy(requestParameters, (v, p) => _.has(panelUpdateValues, p)),
      v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v),
    )
  }

  handleanchorTypeDropdownChange = (event, { value }) => {
    this.setState({ anchorType: value })
  }

  render() {
    const {
      open,
      isBusy,
      apiError,
      name,
      image,
      positionName,
      order,
      anchorType,
      anchorArticleId,
      anchorUrl,
    } = this.state
    const { permission } = this.props
    const { hasUpdatePermission } = permission
    return (
      <Modal
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasUpdatePermission} icon="edit" onClick={this.initialize} />}
      >
        <Modal.Header>{name}</Modal.Header>
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />

          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>

          <Form onValidSubmit={this.handleFormValidSubmit}>
            <div>
              <Form.Input label="枠名" name="name" value={name} onChange={this.handleChange} />

              <Form.Group widths="equal">
                <Form.Input
                  label="ポジション名 (TRILL Web サーバーから参照するための値)"
                  placeholder="英数字または - で入力してください。"
                  name="positionName"
                  value={positionName}
                  validations={{ matchRegexp: /^[a-z0-9-]*$/i }}
                  validationErrors={{
                    matchRegexp: '英数字または - で入力してください。',
                  }}
                  errorLabel={<FormErrorLabel />}
                  onChange={this.handleChange}
                />
                <Form.Input
                  label="管理ツールでの表示順"
                  placeholder="管理ツールでの表示順を指定してください"
                  name="order"
                  value={order}
                  type="number"
                  min="1"
                  validations="isNumeric"
                  validationErrors={{ isNumeric: '記事 ID は数値です' }}
                  errorLabel={<FormErrorLabel />}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Image src={image} />

              <Form.Input type="file" label="バナー" name="image" accept={['image/*']} onChange={this.handleChange} />
              <Form.Input className="isHidden" value={image} name="image" />

              <Form.Group widths="equal">
                <Form.Dropdown
                  label="種類"
                  name="anchorType"
                  compact
                  selection
                  options={[
                    { text: 'URL', value: 'url' },
                    { text: '記事ID', value: 'articleId' },
                  ]}
                  value={anchorType}
                  onChange={this.handleanchorTypeDropdownChange}
                />

                {anchorType === PanelEditModal.anchorType.ARTICLE_ID && (
                  <Form.Input
                    label="記事ID"
                    placeholder="記事IDを指定してください"
                    name="anchorArticleId"
                    type="number"
                    min="1"
                    value={anchorArticleId}
                    onChange={this.handleChange}
                    validations="isNumeric"
                    validationErrors={{ isNumeric: '記事 ID は数値です' }}
                    errorLabel={<FormErrorLabel />}
                  />
                )}

                {anchorType === PanelEditModal.anchorType.URL && (
                  <Form.Input
                    label="URL"
                    placeholder="誘導するURLを https:// から指定ください"
                    name="anchorUrl"
                    value={anchorUrl}
                    onChange={this.handleChange}
                    validations="isUrl"
                    validationErrors={{ isUrl: '無効な URL です' }}
                    errorLabel={<FormErrorLabel />}
                  />
                )}
              </Form.Group>
            </div>
            <Button positive content="更新" type="submit" />
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

export default PanelEditModal
