import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { SeriesApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'

const seriesApi = new SeriesApi()
let getSeries

const propTypes = {
  /**
   * Selected series value in the dropdown.
   */
  series: PropTypes.object,

  /**
   * The series id parameter for the search series list
   */
  creatorIdSearchParams: PropTypes.number,

  /**
   * Is the dropdown value required?
   */
  required: PropTypes.bool,

  /**
   * Is the dropdown disabled?
   */
  disabled: PropTypes.bool,

  /**
   * Does the dropdown maximize the width?
   */
  fluid: PropTypes.bool,

  /**
   * Is the trash can item ignored in the dropdown?
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * Handler to be called when the dropdown value is changed
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  series: null,
  required: false,
  disabled: false,
  fluid: true,
  ignoreTrashContents: true,
}

/**
 * Dropdown series selection component
 */
class SeriesDropdown extends Component {
  state = {
    isBusy: false,
    seriesList: [],
    currentPage: 0,
    totalPages: 0,
  }

  /**
   * Is the dropdown value changed?
   */
  isDropdownValueChanged = false

  /**
   * Series title search params
   */
  seriesTitleSearchParams = ''

  componentDidMount() {
    this.retrieveSeriesList()
  }

  componentWillReceiveProps(nextProps) {
    const { series: currentSeries, creatorIdSearchParams: currentCreatorIdSearchParams } = this.props
    const { series: nextSeries, creatorIdSearchParams: nextCreatorIdSearchParams } = nextProps

    if (!_.isEqual(currentSeries, nextSeries) || !_.isEqual(currentCreatorIdSearchParams, nextCreatorIdSearchParams)) {
      if (this.isDropdownValueChanged) {
        this.retrieveSeriesList()
      } else {
        this.setState(
          {
            seriesList: [],
            currentPage: 0,
            totalPages: 0,
          },
          () => {
            this.retrieveSeriesList()
          },
        )
      }
    }

    this.seriesTitleSearchParams = ''
    this.isDropdownValueChanged = false
  }

  componentWillUnmount() {
    if (!_.isNil(getSeries)) {
      getSeries.cancel()
    }
  }

  /**
   * Handler to be called when dropdown search value is changed
   */
  handleInfiniteDropdownSearch = searchValue => {
    this.seriesTitleSearchParams = searchValue

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveSeriesList()
      },
    )
  }

  /**
   * Handler to be called when dropdown value is changed
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    if (this.props.onChange) {
      const selectedSeries = _.find(this.state.seriesList, ['id', value])

      this.isDropdownValueChanged = true
      this.props.onChange(event, { name: 'series', value, detail: selectedSeries })
    }
  }

  /**
   * Get list of series
   */
  retrieveSeriesList = () => {
    this.setState({ isBusy: true })

    getSeries = CancelablePromise(seriesApi.getSeries(this.getRequestQuery()))
    getSeries.promise
      .then(response => {
        let seriesList = _.concat(this.state.seriesList, response.data)
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

        seriesList = _.sortBy(seriesList, o => o.id * -1)

        if (!_.isEmpty(this.props.series)) {
          seriesList.unshift(this.props.series)
        }

        this.setState({
          isBusy: false,
          seriesList: _.uniqBy(seriesList, 'id'),
          totalPages,
          currentPage,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({ isBusy: false })
      })
  }

  /**
   * Get request query params
   */
  getRequestQuery = () => {
    const { currentPage, seriesList } = this.state

    // Search params
    const filtering = []
    filtering.push(`creator_id = ${this.props.creatorIdSearchParams || 'NULL'}`)

    if (!_.isEmpty(this.seriesTitleSearchParams)) {
      filtering.push(`title LIKE '%${this.seriesTitleSearchParams}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    _.each(seriesList, series => filtering.push(`id <> '${series.id}'`))

    // Order params
    const sorting = ['-id']

    // Query params
    const queryParams = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
      sorting,
    }

    // Returns an object except empty and not number
    return _.omitBy(queryParams, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const options = _.map(this.state.seriesList, series => ({
      icon: 'book',
      value: series.id,
      text: series.title,
    }))

    if (!this.props.required) {
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }

    const creatorId = _.get(this.props.series, 'id', undefined)

    return (
      <InfiniteDropdown
        placeholder="連載タイトルで検索"
        noResultsMessage="該当する連載タイトルが見つかりません"
        disabled={this.props.disabled}
        fluid={this.props.fluid}
        value={creatorId}
        options={options}
        loading={this.state.isBusy}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
        onLoadMore={this.retrieveSeriesList}
        onSearch={this.handleInfiniteDropdownSearch}
        onChange={this.handleInfiniteDropdownChange}
      />
    )
  }
}

SeriesDropdown.propTypes = propTypes
SeriesDropdown.defaultProps = defaultProps

export default SeriesDropdown
