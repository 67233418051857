import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CreatorApi } from 'trill-api-admin-client'

import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'

const creatorApi = new CreatorApi()
let getCreators

const propTypes = {
  /**
   * Creator
   */
  creator: PropTypes.object,

  /**
   * Initial Creators Data
   */
  initialCreatorsData: PropTypes.object,

  /**
   * Except Creators
   */
  exceptCreators: PropTypes.array,

  /**
   * Is the dropdown value required?
   */
  required: PropTypes.bool,

  /**
   * Is the dropdown disabled?
   */
  disabled: PropTypes.bool,

  /**
   * Does the dropdown maximize the width?
   */
  fluid: PropTypes.bool,

  /**
   * Is the trash can item ignored in the dropdown?
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * Handler to be called when the dropdown value is changed
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  creator: null,
  initialCreatorsData: null,
  exceptCreators: [],
  required: false,
  disabled: false,
  fluid: true,
  ignoreTrashContents: true,
}

/**
 * Dropdown creator selection component
 */
class CreatorsDropdown extends Component {
  state = {
    isBusy: false,
    creators: [],
    currentPage: 0,
    totalPages: 0,
  }

  /**
   * Is the dropdown value changed?
   */
  isDropdownValueChanged = false

  /**
   * Creator name search text
   */
  creatorNameSearchText = ''

  componentDidMount() {
    const { initialCreatorsData } = this.props

    if (!_.isEmpty(initialCreatorsData)) {
      const creators = _.sortBy(_.get(initialCreatorsData, 'creators', []), o => o.id * -1)
      const totalPages = _.get(initialCreatorsData, 'totalPages', 0)
      const currentPage = _.get(initialCreatorsData, 'currentPage', 0)

      if (!_.isEmpty(this.props.creator)) {
        creators.unshift(this.props.creator)
      }

      this.setState({
        creators: _.uniqBy(creators, 'id'),
        totalPages,
        currentPage,
      })
    } else {
      this.retrieveCreators()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.creator, nextProps.creator)) {
      if (this.isDropdownValueChanged) {
        this.retrieveCreators()
      } else {
        this.setState(
          {
            creators: [],
            currentPage: 0,
            totalPages: 0,
          },
          () => {
            this.retrieveCreators()
          },
        )
      }

      this.creatorNameSearchText = ''
      this.isDropdownValueChanged = false
    }

    if (!_.isEqual(this.props.exceptCreators, nextProps.exceptCreators)) {
      const creators = _.filter(this.state.creators, creator => !_.some(nextProps.exceptCreators, { id: creator.id }))

      this.setState({
        creators,
      })
    }
  }

  componentWillUnmount() {
    if (!_.isNil(getCreators)) {
      getCreators.cancel()
    }
  }

  /**
   * Handler to be called when dropdown search value is changed
   */
  handleInfiniteDropdownSearch = searchValue => {
    this.creatorNameSearchText = searchValue

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveCreators()
      },
    )
  }

  /**
   * Handler to be called when dropdown value is changed
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    if (this.props.onChange) {
      const selectedCreator = _.find(this.state.creators, ['id', value])

      this.isDropdownValueChanged = true
      this.props.onChange(event, { name: 'creator', value, detail: selectedCreator })
    }
  }

  /**
   * Get list of creators
   */
  retrieveCreators = () => {
    this.setState({ isBusy: true })

    getCreators = CancelablePromise(creatorApi.getCreators(this.getRequestQuery()))
    getCreators.promise
      .then(response => {
        let creators = _.concat(this.state.creators, response.data)
        const responseHeader = response.header
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

        creators = _.sortBy(creators, o => o.id * -1)

        if (!_.isEmpty(this.props.creator)) {
          creators.unshift(this.props.creator)
        }

        this.setState({
          isBusy: false,
          creators: _.uniqBy(creators, 'id'),
          totalPages,
          currentPage,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({ isBusy: false })
      })
  }

  /**
   * Get request query params
   */
  getRequestQuery = () => {
    const { currentPage, creators } = this.state
    const { exceptCreators } = this.props

    // Search params
    const filtering = []
    if (!_.isEmpty(this.creatorNameSearchText)) {
      filtering.push(`name LIKE '%${this.creatorNameSearchText}%'`)
    }

    if (this.props.ignoreTrashContents) {
      filtering.push('deleted_at IS NULL')
    }

    _.each(creators, creator => filtering.push(`id <> '${creator.id}'`))

    if (!_.isEmpty(exceptCreators)) {
      _.each(exceptCreators, creator => {
        if (!_.isEmpty(creator)) {
          filtering.push(`id <> '${creator.id}'`)
        }
      })
    }

    // Order params
    const sorting = ['-id']

    // Query params
    const queryParams = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
      sorting,
    }

    // Returns an object except empty and not number
    return _.omitBy(queryParams, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const options = _.map(this.state.creators, creator => ({
      icon: 'user',
      value: creator.id,
      text: creator.name,
    }))

    if (!this.props.required) {
      options.unshift({
        text: '解除',
        icon: 'remove',
        value: null,
      })
    }

    const creatorId = _.get(this.props.creator, 'id', undefined)

    return (
      <InfiniteDropdown
        placeholder="クリエイターを選択"
        noResultsMessage="該当するクリエイターが見つかりません"
        disabled={this.props.disabled}
        fluid={this.props.fluid}
        value={creatorId}
        options={options}
        loading={this.state.isBusy}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
        onLoadMore={this.retrieveCreators}
        onSearch={this.handleInfiniteDropdownSearch}
        onChange={this.handleInfiniteDropdownChange}
      />
    )
  }
}

CreatorsDropdown.propTypes = propTypes
CreatorsDropdown.defaultProps = defaultProps

export default CreatorsDropdown
