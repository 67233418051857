import React, { Component } from 'react'
import W2a from './W2a'
import { W2aApi } from 'trill-api-admin-client'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('W2aContainer')

const w2aApi = new W2aApi()

export default class W2aContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      w2aCreatives: {},
      isBusy: false,
      apiError: null,
      permission: GetPermission('w2a'),
    }

    this.getW2aCreatives = this.getW2aCreatives.bind(this)
  }

  componentDidMount() {
    this.getW2a()
  }

  getW2a = () => {
    this.setState({ isBusy: true })

    w2aApi.getW2a().then(
      response => {
        logger.debug('GET w2a API called successfully. Returned response: ', response)
        const w2aCategories = response.data
        w2aCategories.forEach(w2aCategory => this.getW2aCreatives(w2aCategory.id))

        this.setState({ w2aCategories: response.data, isBusy: false })
      },
      error => {
        logger.debug(error)
        this.setState({ isBusy: false, apiError: error })
      },
    )
  }

  getW2aCreatives = async w2aCategoryId => {
    this.setState({ isBusy: true })

    await w2aApi.getW2aCreatives(w2aCategoryId).then(
      response => {
        logger.debug('GET w2a creatives API called successfully. Returned response: ', response)
        const stateW2aCreatives = this.state.w2aCreatives
        const w2aCreatives = response.data

        w2aCreatives.forEach(w2aCreative => {
          w2aCreative.w2aId = w2aCategoryId
        })

        stateW2aCreatives[w2aCategoryId] = w2aCreatives
        this.setState({ stateW2aCreatives, isBusy: false })
      },
      error => {
        logger.debug(error)
        this.setState({ isBusy: false, apiError: error })
      },
    )
  }

  render() {
    return (
      <W2a {...this.state} apiError={this.state.apiError} getW2a={this.getW2a} getW2aCreatives={this.getW2aCreatives} />
    )
  }
}
