import React, { Component } from 'react'
import LogLevel from '../LogLevel'
import { Header, Icon, Dimmer, Loader, Form, Button, Message } from 'semantic-ui-react'
import ApiErrorMessage from './ApiErrorMessage'
import { UserApi } from 'trill-api-admin-client'
import Store from 'store'
import { Formik } from 'formik'
import _ from 'lodash'

const logger = LogLevel.getLogger('PasswordUpdate')
const userApi = new UserApi()

class PasswordUpdate extends Component {
  state = { success: false }

  validate = values => {
    const errors = {}

    if (!values.currentPassword) errors.currentPassword = '必須です'
    if (!values.newPassword) errors.newPassword = '必要です'
    if (!values.confirmNewPassword) errors.confirmNewPassword = '必須です'
    if (values.newPassword !== values.confirmNewPassword) errors.confirmNewPassword = 'パスワードが一致しません'

    return errors
  }

  handleSubmit = async (values, actions) => {
    const { currentPassword, newPassword } = values
    const passwordUpdateValues = { currentPassword, newPassword }

    const currentUser = Store.get('currentUser')

    await this.patchPassword(_.get(currentUser, 'userId'), passwordUpdateValues)

    if (_.get(this.state, 'apiError.status') === 401) {
      actions.resetForm({ currentPassword: '', newPassword, confirmNewPassword: newPassword })
    } else {
      actions.resetForm({ currentPassword: '', newPassword: '', confirmNewPassword: '' })
    }
  }

  patchPassword = async (userId, passwordUpdateValues) => {
    this.setState({ isBusy: true, apiError: null })

    try {
      const response = await userApi.patchPassword(userId, passwordUpdateValues)

      logger.debug(`Patch password called successfully. Response: ${_.get(response, 'statusCode')}`)

      this.setState({ isBusy: false, success: true })
    } catch (error) {
      logger.error(`Patch password error. ${error.status} ${error.message}.`)

      this.setState({ isBusy: false, apiError: error, success: false })
    }
  }

  render() {
    const { isBusy, apiError, success } = this.state

    return (
      <div>
        <Header as="h1">
          <Icon name="key" />
          <Header.Content>パスワードの変更</Header.Content>
        </Header>
        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {_.get(apiError, 'status') === 401 ? (
          <Message negative>
            <Message.Header>
              <Icon name="exclamation circle" />
              現在のパスワードが正しくありません
            </Message.Header>
          </Message>
        ) : (
          <ApiErrorMessage error={apiError} />
        )}

        {success && (
          <Message positive>
            <Message.Header>
              <Icon name="check circle" />
              パスワードが変更されました
            </Message.Header>
          </Message>
        )}

        <Formik
          initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        >
          {({ values, touched, errors, isValid, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Input
                required
                type="password"
                label="現在のパスワード"
                placeholder="現在のパスワード"
                name="currentPassword"
                value={values.currentPassword || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.currentPassword && touched.currentPassword}
              />
              {errors.currentPassword && touched.currentPassword ? (
                <Message negative>{errors.currentPassword}</Message>
              ) : null}

              <Form.Input
                required
                type="password"
                label="新しいパスワード"
                placeholder="新しいパスワード"
                name="newPassword"
                value={values.newPassword || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.newPassword && errors.newPassword}
              />
              {errors.newPassword && touched.newPassword ? <Message negative>{errors.newPassword}</Message> : null}

              <Form.Input
                required
                type="password"
                label="新しいパスワードを再入力"
                placeholder="新しいパスワードを再入力"
                name="confirmNewPassword"
                value={values.confirmNewPassword || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirmNewPassword && touched.confirmNewPassword}
              />
              {errors.confirmNewPassword && touched.confirmNewPassword ? (
                <Message negative>{errors.confirmNewPassword}</Message>
              ) : null}
              <Button disabled={!isValid} positive content="更新" type="submit" />
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}

export default PasswordUpdate
