import React, { Component } from 'react'
import { Grid, Header, Tab } from 'semantic-ui-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Form } from 'formsy-semantic-ui-react'

const propTypes = {
  /**
   * 診断質問
   */
  questions: PropTypes.array,

  /**
   * フォームの必須項目が全て埋まったときに呼び出す外部関数
   */
  onValid: PropTypes.func,

  /**
   * フォームの必須項目に漏れがあるときに呼び出す外部関数
   */
  onInvalid: PropTypes.func,

  /**
   * フォームの値に変更があったときに呼び出す外部関数
   */
  onFormValueChanged: PropTypes.func,
}

const defaultProps = {
  questions: [],
}

class PersonalityQuizQuestionList extends Component {
  /**
   * フォーム変更時のハンドラ
   */
  handleFormChange = (event, data, index) => {
    const questionData = _.extend(
      {},
      {
        name: data.name,
        value: data.value,
        index,
        answerIndex: -1,
      },
    )

    if (this.props.onFormValueChanged && this.props.questions) {
      this.props.onFormValueChanged(questionData)
    }
  }

  /**
   * 回答テキストエリアのフォームに変更があったときのハンドラ
   */
  handleAnswerFormChange = (event, data, index, answerIndex) => {
    const questionData = {
      name: data.name,
      value: data.value,
      index,
      answerIndex,
    }

    if (this.props.onFormValueChanged && this.props.questions) {
      this.props.onFormValueChanged(questionData)
    }
  }

  /**
   * 質問をレンダリング
   * @param {Object} question - 質問データ
   * @param {string} key - key 情報
   */
  renderQuestion(question, index) {
    const questionText = _.defaultTo(question.question, '')
    const answers = _.defaultTo(question.answers, '')

    return (
      <Tab.Pane key={index}>
        <Form.TextArea
          required
          label="質問"
          name="question"
          rows={3}
          placeholder="質問を入力してください。"
          value={questionText}
          onChange={(event, data) => this.handleFormChange(event, data, index)}
        />

        <Grid columns={5} doubling>
          {_.map(answers, (answer, answerIndex) => {
            const answerTitle = `回答 ${parseInt(answerIndex, 10) + 1}`
            return (
              <Grid.Column key={answerIndex}>
                <Form.Input
                  required
                  label={answerTitle}
                  name="answer"
                  placeholder={`${answerTitle} を入力してください`}
                  value={answer.answer}
                  onChange={(event, data) => this.handleAnswerFormChange(event, data, index, answerIndex)}
                />

                <Form.Input
                  name="point"
                  fluid
                  value={answer.point}
                  readOnly
                  placeholder="点数"
                  onChange={(event, data) => this.handleAnswerFormChange(event, data, index, answerIndex)}
                />
              </Grid.Column>
            )
          })}
        </Grid>
      </Tab.Pane>
    )
  }

  render() {
    return (
      <Form
        className="PersonalityQuizQuestionList"
        ref="form"
        noValidate
        onValid={this.props.onValid}
        onInvalid={this.props.onInvalid}
      >
        <Header size="tiny">問題 (質問/回答) 一覧</Header>

        <Tab
          renderActiveOnly={false}
          onTabChange={this.handleTabChange}
          grid={{ tabWidth: 3, paneWidth: 13 }}
          menu={{ fluid: true, vertical: true, tabular: true }}
          panes={_.map(this.props.questions, (question, key) => {
            const menuItem = `第 ${parseInt(key, 10) + 1} 問`
            return {
              menuItem,
              pane: this.renderQuestion(question, key),
            }
          })}
        />
      </Form>
    )
  }
}

PersonalityQuizQuestionList.propTypes = propTypes
PersonalityQuizQuestionList.defaultProps = defaultProps

export default PersonalityQuizQuestionList
