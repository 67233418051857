import React, { Component } from 'react'
import { Segment, Image, Button, Message, Dimmer, Loader, Header } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'

import _ from 'lodash'

import store from 'store'

import { AuthApi } from 'trill-api-admin-client'

import LogLevel from '../LogLevel'

import FormErrorLabel from '../components/FormErrorLabel'

import logoDark from '../images/logo-dark.png'
import './SignIn.css'
import { getTokenExpiredTime } from '../util'

const logger = LogLevel.getLogger('SignIn')

const authApi = new AuthApi()

class SignIn extends Component {
  state = {
    isFormValid: false,
    signInError: null,
    isBusy: false,
  }

  handleFormValid = () => {
    this.setState({
      isFormValid: true,
    })
  }

  handleFormInvalid = () => {
    this.setState({
      isFormValid: false,
    })
  }

  handleFormValidSubmit = submitFormData => {
    this.setState({ isBusy: true })

    authApi
      .publishToken('password', process.env.REACT_APP_TRILL_API_OAUTH_CLIENT_ID, {
        username: submitFormData.email,
        password: submitFormData.password,
      })
      .then(
        response => {
          this.setState({ isBusy: false })

          const authToken = response.data

          // set new field for checking expired time in client side
          authToken.expires_at = getTokenExpiredTime(authToken)

          logger.debug('published auth token', authToken)

          store.set('authToken', authToken)
        },
        error => {
          logger.error(error)

          const partialState = {
            isBusy: false,
          }

          if (error.status === 400) {
            partialState.signInError = {
              message: 'メールアドレスまたはパスワードが正しくありません。',
            }
          } else {
            partialState.signInError = {
              message: '予期せぬエラーが発生しました。',
            }
          }

          this.setState(partialState)
        },
      )
  }

  render() {
    const formValidationErrors = {
      isEmail: 'メールアドレスの形式が正しくありません',
    }

    return (
      <div className="SignIn">
        <Dimmer active={this.state.isBusy} inverted page>
          <Loader>サインイン中</Loader>
        </Dimmer>

        <Image src={logoDark} alt="TRILL" centered size="medium" />
        <Header textAlign="center">管理ツール</Header>

        <div className="SignIn__FormContainer">
          <Segment>
            <Form
              onValid={this.handleFormValid}
              onInvalid={this.handleFormInvalid}
              onValidSubmit={this.handleFormValidSubmit}
            >
              <Form.Field>
                <Input
                  name="email"
                  type="text"
                  icon="user"
                  iconPosition="left"
                  placeholder="メールアドレス"
                  validations="isEmail"
                  validationErrors={formValidationErrors}
                  errorLabel={<FormErrorLabel />}
                  required
                />
              </Form.Field>

              <Form.Field>
                <Input
                  name="password"
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="パスワード"
                  required
                />
              </Form.Field>

              {!_.isEmpty(this.state.signInError) && <Message negative content={this.state.signInError.message} />}

              <Button fluid primary content="サインイン" disabled={!this.state.isFormValid} />

              {/* TODO: パスワード再設定フォーム */}
              {/* <Link to='/password-reset' className='SignIn__PasswordResetLink'>
                パスワードを忘れた場合
              </Link> */}
            </Form>
          </Segment>

          {/* 誰でもサインアップできる状態になっているためコメントアウト */}
          {/* <Link to='/sign-up' className='SignIn__SignUpLink'>
            まだアカウントがない場合
          </Link> */}
        </div>
      </div>
    )
  }
}

export default SignIn
