import React, { Component } from 'react'
import { Label } from 'semantic-ui-react'
import _ from 'lodash'

class FormErrorLabel extends Component {
  render() {
    if (_.isEmpty(this.props.children)) {
      // エラーメッセージが空の場合は何も表示しない
      return null
    }

    return (
      <Label color="red" pointing>
        {this.props.children}
      </Label>
    )
  }
}

export default FormErrorLabel
