import React, { Component } from 'react'
import { FormattedDate } from 'react-intl'
import { PersonalityQuizApi } from 'trill-api-admin-client'
import {
  Header,
  Icon,
  Dimmer,
  Loader,
  Image,
  Button,
  Menu,
  Divider,
  Modal,
  Popup,
  Label,
  Statistic,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import _ from 'lodash'
import moment from 'moment'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import CategoriesDropdown from '../../components/CategoriesDropdown'
import PersonalityQuizEditModal from '../../components/PersonalityQuizEditModal'
import PersonalityQuizRemoveModal from '../../components/PersonalityQuizRemoveModal'
import PublishDateRangePicker from '../../components/PublishDatetimeRangePicker'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('PersonalityQuizzes')
const personalityQuizApi = new PersonalityQuizApi()
let getPersonalityQuizzes
let putPersonalityQuiz

/**
 * 記事の状態
 * @enum {string}
 */
const PersonalityQuizStatus = {
  /** すべて */
  ALL: 'all',
  /** ゴミ箱 */
  TRASH: 'trash',
}

/**
 * 診断一覧画面を表示するコンポーネント
 */
class PersonalityQuizzes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      personalityQuizzes: [],
      isBusy: false,
      editPersonalityQuiz: null,
      isPersonalityQuizEditModalOpen: false,
      removePersonalityQuiz: null,
      isPersonalityQuizRemoveModalOpen: false,
      status: PersonalityQuizStatus.ALL,
      isUndoModal: false,
      undoPersonalityQuiz: null,
      apiError: null,
      undoApiError: null,
      permission: GetPermission('personalityQuiz'),
      isFormSearchValid: false,
    }

    _.each(PersonalityQuizStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 50,
          sorting: { publishDatetime: 'desc' },
          filtering: {
            title: '',
            categoryId: null,
            dateRangeStartAt: moment().startOf('month'),
            dateRangeEndAt: moment().endOf('month'),
            clearDateRange: true,
          },
        },
      })
    })
  }

  componentDidMount() {
    this.retrievePersonalityQuizzes()
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getPersonalityQuizzes)) {
      getPersonalityQuizzes.cancel()
    }
    if (!_.isNil(putPersonalityQuiz)) {
      putPersonalityQuiz.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrievePersonalityQuizzes()
    })
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handlePersonalityQuizEditModalClose = () => {
    this.setState({
      isPersonalityQuizEditModalOpen: false,
      editPersonalityQuiz: null,
    })
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handlePersonalityQuizRemoveModalClose = () => {
    this.setState({
      isPersonalityQuizRemoveModalOpen: false,
      removePersonalityQuiz: null,
    })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    this.setState({
      isPersonalityQuizEditModalOpen: true,
      editPersonalityQuiz: {},
    })
  }

  /**
   * 診断のデータに変更があったときのハンドラ
   */
  handlePersonalityQuizDataChanged = () => {
    this.retrievePersonalityQuizzes()
  }

  /**
   * 公開日付範囲で絞り込む情報に関するイベントハンドラ
   */
  handleDateRangePickerEvent = (event, picker) => {
    if (event.type === 'apply' || event.type === 'cancel') {
      let startDate
      let endDate
      let clearDateRange

      if (event.type === 'apply') {
        startDate = picker.startDate
        endDate = picker.endDate
        clearDateRange = false
      } else if (event.type === 'cancel') {
        startDate = moment().startOf('day')
        endDate = moment().endOf('day')
        clearDateRange = true
      }

      const status = this.state.status
      const tableData = this.state[status]
      tableData.filtering.dateRangeStartAt = startDate
      tableData.filtering.dateRangeEndAt = endDate
      tableData.filtering.clearDateRange = clearDateRange

      this.setState({ [status]: tableData }, () => {
        this.retrievePersonalityQuizzes()
      })
    }
  }

  /**
   * タイトル名で絞り込む値を変更したときのハンドラ
   */
  handleTitleInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.title = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * カテゴリで絞り込む値を変更したときのハンドラ
   */
  handleCatgoriesDropdownChange = (event, { value }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.categoryId = value

    this.setState({ [status]: tableData }, () => {
      this.retrievePersonalityQuizzes()
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrievePersonalityQuizzes()
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrievePersonalityQuizzes()
    })
  }

  /**
   * データテーブルの並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrievePersonalityQuizzes()
    })
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const personalityQuizId = this.state.undoPersonalityQuiz.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putPersonalityQuiz = CancelablePromise(personalityQuizApi.putPersonalityQuiz(personalityQuizId))
    putPersonalityQuiz.promise
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoPersonalityQuiz: null,
        })

        this.retrievePersonalityQuizzes()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`put personality quiz personalityQuizId #${personalityQuizId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoPersonalityQuiz: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoPersonalityQuiz: null,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   */
  openUndoModal(personalityQuiz) {
    this.setState({
      isUndoModalOpen: true,
      undoPersonalityQuiz: personalityQuiz,
    })
  }

  /**
   * 作成・更新モーダルを表示する関数
   * @param {Object} personalityQuiz - 診断データ
   */
  opnePersonalityQuizEditModal(personalityQuiz) {
    this.setState({
      isPersonalityQuizEditModalOpen: true,
      editPersonalityQuiz: personalityQuiz,
    })
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} personalityQuiz - 診断データ
   */
  openPersonalityQuizRemoveModal(personalityQuiz) {
    this.setState({
      isPersonalityQuizRemoveModalOpen: true,
      removePersonalityQuiz: personalityQuiz,
    })
  }

  /**
   * 診断一覧の取得
   */
  retrievePersonalityQuizzes() {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getPersonalityQuizzes = CancelablePromise(personalityQuizApi.getPersonalityQuizzes(this.getRequestQuery()))
    getPersonalityQuizzes.promise
      .then(response => {
        const personalityQuizzes = response.data
        const responseHeader = response.header
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)

        this.setState({
          personalityQuizzes,
          isBusy: false,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('personality quizzes error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totaPage = 0
        tableData.totalItems = 0

        this.setState({
          isBusy: false,
          personalityQuizzes: [],
          [status]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const status = this.state.status
    const tableData = this.state[status]

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    const filtering = []
    if (_.isEqual(status, PersonalityQuizStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    // 期間での絞り込み
    if (!tableData.filtering.clearDateRange) {
      const dateRangeStartAt = tableData.filtering.dateRangeStartAt
      const dateRangeEndAt = tableData.filtering.dateRangeEndAt
      filtering.push(`publishDatetime >= '${dateRangeStartAt.toISOString()}'`)
      filtering.push(`publishDatetime <= '${dateRangeEndAt.toISOString()}'`)
    }

    if (_.isNumber(tableData.filtering.categoryId) || !_.isEmpty(tableData.filtering.categoryId)) {
      filtering.push(`categoryId = ${tableData.filtering.categoryId}`)
    }

    if (!_.isEmpty(tableData.filtering.title)) {
      let filteringTitle = tableData.filtering.title
      if (filteringTitle.match(/\,/)) { // eslint-disable-line
        filteringTitle = ''
      }
      // タイトルで検索する場合
      filtering.push(`title LIKE "%${filteringTitle}%"`)
    }

    // ソートを配列に変換
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const status = this.state.status
    const tableData = this.state[status]
    const { permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission
    return (
      <div className="PersonalityQuizzes">
        <Header as="h1">
          <Icon name="lab" />

          <Header.Content>診断</Header.Content>
        </Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={PersonalityQuizStatus.ALL}
            active={_.isEqual(status, PersonalityQuizStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={PersonalityQuizStatus.TRASH}
            active={_.isEqual(status, PersonalityQuizStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="personality-quiz-search"
                type="text"
                placeholder="タイトルで検索"
                action
                value={tableData.filtering.title}
                onChange={this.handleTitleInputChange}
                validations={{ matchRegexp: /^((?!,).)*$/i }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>

            <Form.Field width={4}>
              <PublishDateRangePicker
                startDate={tableData.filtering.dateRangeStartAt}
                endDate={tableData.filtering.dateRangeEndAt}
                onEvent={this.handleDateRangePickerEvent}
                clear={tableData.filtering.clearDateRange}
                disabled={!this.state.isFormSearchValid}
              />
            </Form.Field>

            <Form.Field width={4}>
              <CategoriesDropdown
                ignoreTrashContents={false}
                categoryId={tableData.filtering.categoryId}
                fluid={false}
                onChange={this.handleCatgoriesDropdownChange}
                disabled={!this.state.isFormSearchValid}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {!_.isEmpty(this.state.personalityQuizzes) && (
          <DataTable
            sort={tableData.sorting}
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSelectionChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            items={this.state.personalityQuizzes}
            cellStyle={{ position: 'relative' }}
            rowKey="id"
            columns={[
              {
                label: 'ID',
                align: 'center',
                field: 'id',
              },
              {
                label: 'サムネイル',
                align: 'center',
                render: item => {
                  const isReserved = moment(item.publishDatetime).isAfter(moment())
                  const src = _.get(item, 'thumbnail.image.url')
                  return (
                    <div>
                      {isReserved && (
                        <Popup inverted trigger={<Label corner icon="checked calendar" />} content="予約中の記事です" />
                      )}
                      <Image src={src} shape="rounded" size="tiny" centered />
                    </div>
                  )
                },
              },
              {
                label: 'タイトル',
                field: 'title',
                minWidth: '20em',
                collapsing: false,
              },
              {
                label: 'カテゴリー',
                field: 'categoryId',
                render: item => {
                  const categoryName = _.get(item, 'category.name', '未分類')
                  return (
                    <span>
                      <Icon name="folder open" />
                      {categoryName}
                    </span>
                  )
                },
              },
              {
                label: '公開日時',
                field: 'publishDatetime',
                render: item => {
                  const timestamp = moment(item.publishDatetime)
                  return (
                    <span>
                      <FormattedDate value={timestamp} day="numeric" month="long" year="numeric" weekday="narrow" />
                      <br />
                      <FormattedDate value={timestamp} hour="numeric" minute="numeric" second="numeric" />
                    </span>
                  )
                },
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.opnePersonalityQuizEditModal(item)
                      }}
                    />

                    {!_.isEqual(status, PersonalityQuizStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openPersonalityQuizRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, PersonalityQuizStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* 診断作成・編集モーダル */}
        {hasCreatePermission && (
          <PersonalityQuizEditModal
            personalityQuiz={this.state.editPersonalityQuiz}
            open={this.state.isPersonalityQuizEditModalOpen}
            onClose={this.handlePersonalityQuizEditModalClose}
            onSuccessDataChanged={this.handlePersonalityQuizDataChanged}
          />
        )}

        {/* 診断削除モーダル */}
        {hasDeletePermission && (
          <PersonalityQuizRemoveModal
            personalityQuiz={this.state.removePersonalityQuiz}
            open={this.state.isPersonalityQuizRemoveModalOpen}
            onClose={this.handlePersonalityQuizRemoveModalClose}
            onSuccessDataChanged={this.handlePersonalityQuizDataChanged}
          />
        )}

        {hasRestorePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isUndoModalOpen}
            onClose={this.handleUndoModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>診断の公開</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.undoApiError} />
              {_.get(this.state, 'undoPersonalityQuiz.title')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.handleUndoModalPublishButtonClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default PersonalityQuizzes
