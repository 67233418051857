import React, { Component } from 'react'
import _ from 'lodash'
import { Header, Divider, Icon, Menu, Button, Input } from 'semantic-ui-react'
import { OutsourcedArticleApi } from 'trill-api-admin-client'
import { CSVLink } from 'react-csv'
import OutsourcedArticlesMessages from './OutsourcedArticlesMessages'
import LogLevel from '../LogLevel'
import OutsourcedArticlesCsvDataTable from './OutsourcedArticlesCsvDataTable'
import GetPermission from '../GetPermission'

const logger = LogLevel.getLogger('OutsourcedArticlesCsvContainer')

const outsourcedArticleApi = new OutsourcedArticleApi()

export default class OutsourcedArticlesCsvContainer extends Component {
  constructor(props) {
    super(props)

    this.csvLink = React.createRef()
    this.state = {
      isBusy: false,
      invalidInput: false,
      apiError: null,
      tableData: {
        currentPage: 1,
        itemsPerPage: 10,
        totalPages: 0,
        totalItems: 0,
      },
      outsourcedCsvArticles: [],
      csvDownload: {
        filename: '',
        data: [],
      },
      permission: GetPermission('outsourcedArticle'),
    }

    this.handleDataTablePageChange = this.handleDataTablePageChange.bind(this)
  }

  componentDidMount() {
    this.retrieveData()
  }

  retrieveData = async () => {
    const { tableData } = this.state

    this.setState({
      isBusy: true,
      apiError: null,
      createCount: 1,
    })

    try {
      const responseCsv = await outsourcedArticleApi.getOutsourcedCsvArticles(this.getRequestQuery())
      const outsourcedArticlesCsv = responseCsv.data
      const responseHeader = responseCsv.header

      tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
      tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
      tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
      tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

      logger.debug(`get outsourcedCsvArticles`, { responseCsv, tableData })

      await this.setState({
        isBusy: false,
        outsourcedArticlesCsv,
        tableData,
      })

      logger.debug(`get outsourcedCsvArticles`, this.state)
    } catch (error) {
      logger.error(`get outsourcedCsvArticles error`, error)

      tableData.currentPage = 1
      tableData.itemsPerPage = 10
      tableData.totaPage = 0
      tableData.totalItems = 0

      this.setState({
        outsourcedArticlesCsv: [],
        isBusy: false,
        tableData,
        apiError: error,
      })
    }
  }

  handleDataTablePageChange = (event, { currentPage: changeCurrentPage, itemsPerPage: changeItemsPerPage }) => {
    const { tableData } = this.state

    tableData.currentPage = changeCurrentPage
    tableData.itemsPerPage = changeItemsPerPage

    this.setState({ tableData }, () => {
      this.retrieveData()
    })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { tableData } = this.state

    // Calculate the total page by dividing the setting tableData.totalItems by the setting tableData.itemsPerPage
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    const query = {
      currentPage,
      itemsPerPage,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  updateCreateCount = e => {
    this.setState({ createCount: e.target.value })
  }

  createData = async () => {
    const count = this.state.createCount
    this.setState({
      isBusy: true,
      apiError: null,
    })
    try {
      const responseCsv = await outsourcedArticleApi.postOutsourcedCsvArticles(count)
      logger.debug(`post outsourcedCsvArticles`, { responseCsv })

      await this.setState({
        isBusy: false,
      })
      logger.debug(` outsourcedCsvArticles`, this.state)
      await this.retrieveData()
    } catch (error) {
      logger.error(`get outsourcedCsvArticles error`, error)
      this.setState({
        outsourcedArticlesCsv: [],
        isBusy: false,
        apiError: error,
      })
    }
  }

  handleDownloadOutsourcedArticlesCsv = async id => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    try {
      const response = await outsourcedArticleApi.getOutsourcedArticlesCsv(id)
      logger.debug('get getOutsourcedArticlesCsv success', { response })

      const getContentDispositionFilename = () => {
        const filenameRegex = /filename[^;=\n]*=(['"].*?\2|[^;\n]*)/
        const contentDisposition = _.get(response.header, 'content-disposition', '')
        const filenameMatches = contentDisposition.match(filenameRegex)

        return filenameMatches && filenameMatches[1] ? filenameMatches[1].replace(/['"]/g, '') : 'outsource_links.csv'
      }

      this.setState(
        {
          isBusy: false,
          csvDownload: {
            filename: getContentDispositionFilename(),
            data: response.data,
          },
        },
        () => {
          this.csvLink.current.link.click()
        },
      )
    } catch (error) {
      logger.error(`get getOutsourcedArticlesCsv error`, error)
      this.setState({
        isBusy: false,
        apiError: error,
      })
    }
  }

  render() {
    const { outsourcedArticlesCsv, permission } = this.state
    const { hasCreatePermission, hasUpdatePermission } = permission

    return (
      <div>
        <Header as="h1">
          <Icon name="users" />
          <Header.Content>編集委託記事CSV</Header.Content>
        </Header>

        <Menu secondary>
          <Menu.Item fitted>
            <Input
              name="outsource-articles-csv-create"
              type="text"
              placeholder={'編集委託記事作成'}
              value={this.state.createCount || '1'}
              onChange={this.updateCreateCount}
            ></Input>
          </Menu.Item>

          <Menu.Item fitted>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="left"
              onClick={this.createData}
            />
          </Menu.Item>

          <Menu.Item fitted>
            <Button primary content="リフレッシュ" onClick={this.retrieveData} />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        <OutsourcedArticlesMessages {...this.state} />

        <CSVLink
          filename={this.state.csvDownload.filename}
          data={this.state.csvDownload.data}
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />

        {!_.isEmpty(outsourcedArticlesCsv) && (
          <OutsourcedArticlesCsvDataTable
            {...this.state}
            hasUpdatePermission={hasUpdatePermission}
            handleDataTablePageChange={this.handleDataTablePageChange}
            handleDownloadOutsourcedArticlesCsv={this.handleDownloadOutsourcedArticlesCsv}
          />
        )}
      </div>
    )
  }
}
