import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { TagApi } from 'trill-api-admin-client'
import { Modal, Button, Label, Dimmer, Loader, Grid, Segment, Divider, Popup } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import TextareaAutosize from 'react-textarea-autosize'

import DataTable from './DataTable'
import TagsDropdown from './TagsDropdown'
import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'
import { flattenObject } from '../util'

const logger = LogLevel.getLogger('TagEditModal')
const tagApi = new TagApi()
let sendTag
const putTagRelatedTags = []
let deleteTagRelatedTag

const propTypes = {
  /**
   * 編集対象のタグ（作成の場合は空）
   */
  tag: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  tag: {},
  open: false,
}

class TagEditModal extends Component {
  state = {
    tag: {},
    isFormValid: false,
    isFormModified: false,
    isFormSearchValid: false,
    isBusy: false,
    apiError: null,
    relatedTagsError: null,
    // 関連タグ
    relatedTags: [],
    totalItems: 0,
    totalPages: 0,
    itemsPerPage: 5,
    currentPage: 1,
    sorting: { name: 'asc' },
    filtering: { name: '' },
    isTagsBusy: false,
    isHiddenTagsTable: false,
    tagsDropdownValues: [],
    isAddModalOpen: false,
    isDeleteModalOpen: false,
    description: '',
    isDescriptionModified: false,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されてるかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.tag, nextProps.tag)) {
      this.setState(
        {
          tag: nextProps.tag,
          isFormModified: false,
          relatedTags: [],
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 5,
          sorting: { name: 'asc' },
          filtering: { name: '' },
          // 初回のみ関連タグ表示まで isBusy を true に設定
          isBusy: true,
        },
        () => {
          this.retrieveRelatedTags()
            .then(() => {
              this.setState({
                isBusy: false,
                isHiddenTagsTable: _.size(this.state.relatedTags) === 0,
              })
            })
            .catch(() => {
              this.setState({ isBusy: false })
            })
        },
      )

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.tag) {
        this.initializeFormValues(this.props.tag)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendTag)) {
      sendTag.cancel()
    }
    if (!_.isNil(deleteTagRelatedTag)) {
      deleteTagRelatedTag.cancel()
    }
    if (!_.isEmpty(putTagRelatedTags)) {
      _.each(putTagRelatedTags, putTagRelatedTag => putTagRelatedTag.cancel())
      putTagRelatedTags.length = 0
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }
    const flattenCurrentValues = flattenObject(currentValues)

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(flattenCurrentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const formData = { ...submitFormData, description: this.state.description }

    sendTag = CancelablePromise(this.sendData(this.getRequestParameters(formData)))
    sendTag.promise
      .then(response => {
        const tag = response.data

        // 更新 or 作成に成功したらタグ一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        // フォームデータを更新して初期化
        this.initializeFormValues(tag)

        resetForm(this.initialFormValues)

        this.setState({
          isFormModified: false,
          isBusy: false,
          tag,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send tag data error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 関連タグテーブルのソート変更時のハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const sorting = sort

    this.setState({ sorting }, () => {
      this.retrieveRelatedTags()
    })
  }

  /**
   * 関連タグテーブルのページ情報変更時のハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    this.setState(
      {
        currentPage,
        itemsPerPage,
      },
      () => {
        this.retrieveRelatedTags()
      },
    )
  }

  /**
   * 関連タグ追加用モーダルの追加ボタンを押したときのハンドラ
   */
  handleAddModalApproveButtonClick = () => {
    this.setState(
      {
        isTagsBusy: true,
        // タグがなかった場合、テーブルにローディングを出せないため全体のローディングを出す
        isBusy: _.size(this.state.tags) === 0,
        relatedTagsError: null,
      },
      () => {
        const tag = this.state.tag
        putTagRelatedTags.length = 0
        _.each(this.state.tagsDropdownValues, relatedTagId => {
          putTagRelatedTags.push(
            CancelablePromise(
              new Promise((resolve, reject) => {
                tagApi
                  .putTagRelatedTag(tag.id, relatedTagId)
                  .then(() => {
                    resolve()
                  })
                  .catch(error => {
                    if (error.isCanceled) {
                      reject(error)
                      return
                    }

                    logger.error(`put tag related tag tagId #${tag.id} relatedTagId #${relatedTagId} error`, error)

                    resolve()
                  })
              }),
            ),
          )
        })

        Promise.all(_.map(putTagRelatedTags, putTagRelatedTag => putTagRelatedTag.promise))
          .then(() => {
            // 一覧の更新
            if (this.props.onSuccessDataChanged) {
              this.props.onSuccessDataChanged()
            }
            return this.retrieveRelatedTags()
          })
          .then(() => {
            // 登録に成功したら TagsDropdown の値はリセット
            this.setState({
              tagsDropdownValues: [],
              isAddModalOpen: false,
              isBusy: false,
              isHiddenTagsTable: false,
            })
          })
          .catch(error => {
            logger.debug(`put tag related tag tagId #${tag.id} error`, error)

            this.setState({
              isBusy: false,
              isTagsBusy: false,
              relatedTagsError: error,
            })
          })
      },
    )
  }

  /**
   * タグ削除用モーダルの削除ボタンを押したときのハンドラ
   */
  handleDeleteModalApproveButtonClick = () => {
    const tag = this.state.tag
    const deleteTag = this.state.deleteTag

    this.setState({
      isTagsBusy: true,
      relatedTagsError: null,
    })

    deleteTagRelatedTag = CancelablePromise(tagApi.deleteTagRelatedTag(tag.id, deleteTag.id))
    deleteTagRelatedTag.promise
      .then(() => {
        // 一覧の更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        // 削除に成功したら削除対象として保存していた deleteTag はリセット
        this.setState({ deleteTag: null })

        return this.retrieveRelatedTags()
      })
      .then(() => {
        // 削除時に関連タグが 0 個の場合、関連タグテーブルを非表示に設定
        this.setState({
          isDeleteModalOpen: false,
          isHiddenTagsTable: _.size(this.state.relatedTags) === 0,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`delete tag related tag tagId #${tag.id} deleteTagId #${deleteTag.id} error`, error)

        this.setState({
          isTagsBusy: false,
          relatedTagsError: error,
        })
      })
  }

  /**
   * タグ追加用モーダルに表示するタグドロップダウンからタグを変更したときのハンドラ
   */
  handleAddModalDropdownChange = (event, { value }) => {
    this.setState({ tagsDropdownValues: value })
  }

  /**
   * タグ追加用モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleAddModalCancelButtonClick = () => {
    this.setState({
      isAddModalOpen: false,
      tagsDropdownValues: [],
    })
  }

  /**
   * タグ削除用モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleDeleteModalCancelButtonClick = () => {
    this.setState({
      deleteTag: null,
      isDeleteModalOpen: false,
    })
  }

  /**
   * タグ名で絞り込むインプットの値を変更したときのハンドラ
   */
  handleTagNameInputChange = (event, data) => {
    const filtering = this.state.filtering
    filtering.name = data.value

    this.setState({ filtering })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = event => {
    event.preventDefault()

    this.retrieveRelatedTags()
  }

  /**
   * Handler to be called when the description is changed
   */
  handleDescriptionChange = e => {
    const description = e.target.value
    let isDescriptionModified = false

    if (!_.isEqual(this.initialFormValues.description, description)) {
      isDescriptionModified = true
    }

    this.setState({ description, isDescriptionModified })
  }

  /**
   * 関連タグ追加用モーダルを開く関数
   */
  openTagAddModal() {
    this.setState({ isAddModalOpen: true })
  }

  /**
   * 関連タグ削除用モーダルを開く関数
   * @param {Object} tag - 関連タグデータ
   */
  openDeleteModal(tag) {
    this.setState({
      isDeleteModalOpen: true,
      deleteTag: tag,
    })
  }

  /**
   * フォームの初期化
   * @param {Object} tag - タグデータ
   */
  initializeFormValues(tag) {
    const description = tag.description

    this.initialFormValues.name = tag.name
    this.initialFormValues.exclude = tag.exclude
    this.initialFormValues.slug = tag.slug
    this.initialFormValues.description = description
    this.initialFormValues['brandInfo.type.cosme'] = _.get(tag, 'brandInfo.type.cosme', false)
    this.initialFormValues['brandInfo.type.fashion'] = _.get(tag, 'brandInfo.type.fashion', false)
    this.initialFormValues['brandInfo.nameEn'] = _.get(tag, 'brandInfo.nameEn', '')
    this.initialFormValues['brandInfo.nameJa'] = _.get(tag, 'brandInfo.nameJa', '')
    this.initialFormValues['brandInfo.nameKana'] = _.get(tag, 'brandInfo.nameKana', '')

    this.setState({ description })

    logger.debug('initialize form values', this.initialFormValues)
  }

  /**
   * API にデータを送信
   */
  sendData = submitTagData => {
    if (_.isEmpty(this.props.tag)) {
      return tagApi.postTag(submitTagData)
    }

    const tagId = this.props.tag.id
    return tagApi.patchTag(tagId, { tagUpdateValues: submitTagData })
  }

  /**
   * 関連タグ一覧取得
   */
  retrieveRelatedTags = () =>
    new Promise((resolve, reject) => {
      const tag = this.state.tag
      if (_.isEmpty(tag) || !_.has(tag, 'id')) {
        // タグ id がない場合は何もしない
        resolve()

        return
      }

      const tagId = tag.id

      this.setState({
        isTagsBusy: true,
        relatedTagsError: null,
      })

      tagApi
        .getTagRelatedTags(tagId, this.getRequestQuery())
        .then(response => {
          const relatedTags = response.data
          const responseHeader = response.header
          const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
          const totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
          const paginationCurrentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
          const currentPage = paginationCurrentPage === 0 ? 1 : paginationCurrentPage
          const itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)

          this.setState({
            relatedTags,
            totalPages,
            totalItems,
            currentPage,
            itemsPerPage,
            isTagsBusy: false,
          })

          resolve()
        })
        .catch(error => {
          logger.debug('retrieve related tags error', { error })

          this.setState({
            relatedTags: [],
            totalPages: 0,
            totalItems: 0,
            currentPage: 1,
            itemsPerPage: 5,
            isTagsBusy: false,
            relatedTagsError: error,
          })

          reject(error)
        })
    })

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}

    initialData.name = initialFormValues.name
    initialData.exclude = initialFormValues.exclude
    initialData.slug = initialFormValues.slug
    initialData.description = initialFormValues.description
    _.set(initialData, 'brandInfo.type.cosme', _.get(initialFormValues, 'brandInfo.type.cosme', false))
    _.set(initialData, 'brandInfo.type.fashion', _.get(initialFormValues, 'brandInfo.type.fashion', false))
    _.set(initialData, 'brandInfo.nameEn', _.get(initialFormValues, 'brandInfo.nameEn', ''))
    _.set(initialData, 'brandInfo.nameJa', _.get(initialFormValues, 'brandInfo.nameJa', ''))
    _.set(initialData, 'brandInfo.nameKana', _.get(initialFormValues, 'brandInfo.nameKana', ''))

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    if (_.has(requestParameters, 'name')) {
      // タグ名の前後にスペースがあった場合は取り除く
      requestParameters.name = _.trim(requestParameters.name)
    }

    // slug に空文字に設定されていた場合はリセット
    if (_.isEqual(requestParameters.slug, '')) {
      requestParameters.slug = ' '
    }

    // description に空文字に設定されていた場合はリセット
    if (_.isEqual(requestParameters.description, '')) {
      requestParameters.description = ' '
    }

    logger.debug('get request parameters', {
      requestParameters,
      initialData,
      submitFormData,
    })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const totalPage = Math.ceil(this.state.totalItems / this.state.itemsPerPage)
    const currentPage = totalPage > 0 && this.state.currentPage > totalPage ? totalPage : this.state.currentPage
    const itemsPerPage = this.state.itemsPerPage

    // ソート順
    const sorting = _.map(this.state.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    // フィルタリング
    const filtering = []
    if (!_.isEmpty(this.state.filtering.name)) {
      let filteringName = this.state.filtering.name
      if (filteringName.match(/\,/)) { // eslint-disable-line
        filteringName = ''
      }

      filtering.push(`name LIKE "%${filteringName}%"`)
    }
    const query = {
      currentPage,
      itemsPerPage,
      sorting,
      filtering,
    }

    logger.debug('get request query', query)

    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const formErrorLabel = <Label color="red" pointing />

    return (
      <Modal
        className="TagEditModal"
        size="large"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.state.tag) ? 'タグの作成' : 'タグの編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* API エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            <Grid columns={2} doubling>
              <Grid.Column>
                {/* タグ名入力フィールド */}
                <Form.Input name="name" label="タグ名" placeholder="タグ名を入力してください" required />

                <Form.Field>
                  <label>非表示設定</label>
                  <Form.Checkbox name="exclude" label="サービス上に表示しない" />
                </Form.Field>

                {/* スラッグ入力フィールド */}
                <Popup
                  inverted
                  trigger={
                    <Form.Input
                      name="slug"
                      label="スラッグ"
                      placeholder="スラッグを入力してください"
                      validations={{ matchRegexp: /^[a-z0-9-]*$/i }}
                      validationErrors={{
                        matchRegexp: '英数字または - で入力してください。',
                      }}
                      errorLabel={formErrorLabel}
                    />
                  }
                  content="URLの最後に表示される文字を設定できます。"
                />

                {/* 概要入力フィールド */}
                <Form.Field
                  name="description"
                  label="概要"
                  placeholder="内容を入力してください"
                  minRows={10}
                  maxRows={1000}
                  rows={1000}
                  control={TextareaAutosize}
                  onChange={this.handleDescriptionChange}
                />
              </Grid.Column>

              <Grid.Column>
                {/* ブランド情報入力フィールド */}
                <Form.Field>
                  <label>ブランド情報</label>

                  <Form.Group>
                    <Form.Checkbox name="brandInfo.type.cosme" label="コスメタグ" />

                    <Form.Checkbox name="brandInfo.type.fashion" label="ファッションタグ" />
                  </Form.Group>
                </Form.Field>

                {/* ブランド名 (英語) 入力フィールド */}
                <Form.Input
                  name="brandInfo.nameEn"
                  label="ブランド名 (英語)"
                  placeholder="ブランド名を英語で入力してください"
                />

                {/* ブランド名 (日本語) 入力フィールド */}
                <Form.Input
                  name="brandInfo.nameJa"
                  label="ブランド名 (日本語)"
                  placeholder="ブランド名を日本語で入力してください"
                />

                {/* ブランド名 (カナ) 入力フィールド */}
                <Form.Input
                  name="brandInfo.nameKana"
                  label="ブランド名 (カナ)"
                  placeholder="ブランド名をカナで入力してください"
                />
              </Grid.Column>
            </Grid>
          </Form>

          <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
            <Divider hidden />
            {/* 関連タグ */}
            {!this.state.isHiddenTagsTable && (
              <Form.Field>
                <label>関連タグ</label>

                <Segment loading={this.state.isTagsBusy} padded>
                  {/* 検索エリア */}
                  <Input
                    name="tag-search"
                    type="text"
                    placeholder="タグ名で検索"
                    action
                    fluid
                    value={this.state.filtering.name}
                    onChange={this.handleTagNameInputChange}
                    validations={{ matchRegexp: /^((?!,).)*$/i }}
                    validationErrors={{
                      matchRegexp: 'キーワードに不正な記号があるため検索できません',
                    }}
                    errorLabel={formErrorLabel}
                  >
                    <input />

                    <Button
                      icon="search"
                      onClick={this.handleSearchButtonClick}
                      disabled={!this.state.isFormSearchValid}
                    />
                  </Input>

                  {/* API エラーメッセージ */}
                  <ApiErrorMessage error={this.state.relatedTagsError} />

                  {!_.isEmpty(this.state.relatedTags) && (
                    <div>
                      <Divider hidden />

                      {/* 関連タグ表示テーブル */}
                      <DataTable
                        items={this.state.relatedTags}
                        sort={this.state.sorting}
                        currentPage={this.state.currentPage}
                        totalPages={this.state.totalPages}
                        itemsPerPage={this.state.itemsPerPage}
                        onSelectionChange={this.handleDataTableSelectionChange}
                        onPageChange={this.handleDataTablePageChange}
                        rowKey="id"
                        columns={[
                          {
                            collapsing: false,
                            label: 'タグ名',
                            field: 'name',
                          },
                          {
                            label: '操作',
                            align: 'center',
                            render: tag => (
                              <Button
                                secondary
                                key={tag.id}
                                icon="trash alternate outline"
                                onClick={event => {
                                  event.preventDefault()
                                  this.openDeleteModal(tag)
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  )}
                </Segment>
              </Form.Field>
            )}
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.state.tag) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || (!this.state.isFormModified && !this.state.isDescriptionModified)}
          />

          {!_.isEmpty(this.state.tag) && (
            <Button
              primary
              content="関連タグの追加"
              onClick={event => {
                event.preventDefault()
                this.openTagAddModal()
              }}
            />
          )}
        </Modal.Actions>

        {/* タグ追加用のモーダル */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isAddModalOpen}
          onClose={this.handleAddModalCancelButtonClick}
          closeOnDimmerClick={false}
        >
          <Modal.Header>タグの追加</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.isTagsBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* API エラーメッセージ */}
            <ApiErrorMessage error={this.state.relatedTagsError} />

            <TagsDropdown value={this.state.tagsDropdownValues} onChange={this.handleAddModalDropdownChange} />
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleAddModalCancelButtonClick} />

            <Button positive content="追加" onClick={this.handleAddModalApproveButtonClick} />
          </Modal.Actions>
        </Modal>

        {/* タグ削除用のモーダル */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isDeleteModalOpen}
          onClose={this.handleDeleteModalCancelButtonClick}
          closeOnDimmerClick={false}
        >
          <Modal.Header>タグの削除</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.isTagsBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* API エラーメッセージ */}
            <ApiErrorMessage error={this.state.relatedTagsError} />
            {_.get(this.state, 'deleteTag.name')} を削除しますか？
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleDeleteModalCancelButtonClick} />

            <Button negative content="削除" onClick={this.handleDeleteModalApproveButtonClick} />
          </Modal.Actions>
        </Modal>
      </Modal>
    )
  }
}

TagEditModal.propTypes = propTypes
TagEditModal.defaultProps = defaultProps

export default TagEditModal
