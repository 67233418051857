import React, { Component } from 'react'
import {
  Dimmer,
  Loader,
  Header,
  Icon,
  Menu,
  Button,
  Image,
  Divider,
  Label,
  Popup,
  Modal,
  Statistic,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import Store from 'store'
import { FormattedDate, FormattedTime } from 'react-intl'
import { MatomeApi } from 'trill-api-admin-client'
import _ from 'lodash'
import moment from 'moment'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import MatomeEditModal from '../../components/MatomeEditModal'
import MatomeRemoveModal from '../../components/MatomeRemoveModal'
import PublishDateRangePicker from '../../components/PublishDatetimeRangePicker'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'
import GetPermission from '../../GetPermission'

const logger = LogLevel.getLogger('Matomes')
const matomeApi = new MatomeApi()
let getMatomes
let putMatome
let getMatomeDetails = []

/**
 * 記事の状態
 * @enum {string}
 */
const MatomeStatus = {
  /** 公開と予約 */
  ALL: 'all',
  /** ゴミ箱 */
  TRASH: 'trash',
}

class Matomes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      matomes: [],
      isMatomeEditModalOpen: false,
      isMatomeRemoveModalOpen: false,
      editMatome: null,
      removeMatome: null,
      status: MatomeStatus.ALL,
      isUndoModalOpen: false,
      undoMatome: null,
      apiError: null,
      undoApiError: null,
      permission: GetPermission('matome'),
      isFormSearchValid: false,
    }

    _.each(MatomeStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 50,
          sorting: { publishDatetime: 'desc' },
          filtering: {
            dateRangeStartAt: moment().startOf('month'),
            dateRangeEndAt: moment().endOf('month'),
            title: '',
            clearDateRange: true,
          },
        },
      })
    })
  }

  componentDidMount() {
    // 前回のページ表示状態を読み込む
    const pageState = Store.get('matomesPageState')

    if (pageState) {
      const matomes = pageState.matomes
      const isMatomeEditModalOpen = pageState.isMatomeEditModalOpen
      const editMatome = pageState.editMatome
      const status = _.get(pageState, 'status', MatomeStatus.ALL)
      const tableData = _.get(pageState, status, this.state[status])
      const dateRangeStartAt = _.get(tableData, 'filtering.dateRangeStartAt', moment().startOf('month'))
      const dateRangeEndAt = _.get(tableData, 'filtering.dateRangeEndAt', moment().endOf('month'))
      _.set(tableData, 'filtering.dateRangeStartAt', moment(dateRangeStartAt))
      _.set(tableData, 'filtering.dateRangeEndAt', moment(dateRangeEndAt))

      this.setState(
        {
          matomes,
          isMatomeEditModalOpen,
          editMatome,
          status,
          [status]: tableData,
        },
        () => {
          this.retrieveMatomes()
        },
      )
    } else {
      this.retrieveMatomes()
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getMatomes)) {
      getMatomes.cancel()
    }
    if (!_.isNil(putMatome)) {
      putMatome.cancel()
    }
    if (!_.isEmpty(getMatomeDetails)) {
      _.each(getMatomeDetails, getMatomeDetail => getMatomeDetail.cancel())
      getMatomeDetails.length = 0
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveMatomes()
    })
  }

  /**
   * 作成ボタンを押したときのハンドラ
   */
  handleCreateButtonClick = () => {
    this.setState({
      isMatomeEditModalOpen: true,
      editMatome: {},
    })
  }

  /**
   * 更新・作成モーダルを閉じたときのハンドラ
   */
  handleMatomeEditModalClose = () => {
    this.setState(
      {
        isMatomeEditModalOpen: false,
        editMatome: null,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * 削除モーダルを閉じたときのハンドラ
   */
  handleMatomeRemoveModalClose = () => {
    this.setState(
      {
        isMatomeRemoveModalOpen: false,
        removeMatome: null,
      },
      () => {
        this.savePageState()
      },
    )
  }

  /**
   * 公開日付範囲で絞り込む情報に関するイベントハンドラ
   */
  handleDateRangePickerEvent = (event, picker) => {
    if (event.type === 'apply' || event.type === 'cancel') {
      const status = this.state.status
      const tableData = this.state[status]
      let startDate
      let endDate
      let clearDateRange

      if (event.type === 'apply') {
        startDate = picker.startDate
        endDate = picker.endDate
        clearDateRange = false
      } else if (event.type === 'cancel') {
        startDate = moment().startOf('day')
        endDate = moment().endOf('day')
        endDate = moment().endOf('day')
        clearDateRange = true
      }

      tableData.filtering.dateRangeStartAt = startDate
      tableData.filtering.dateRangeEndAt = endDate
      tableData.filtering.clearDateRange = clearDateRange

      this.setState({ [status]: tableData }, () => {
        this.retrieveMatomes()
        this.savePageState()
      })
    }
  }

  /**
   * タイトル名で絞り込む値を変更したときのハンドラ
   */
  handleTitleInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.title = data.value

    this.setState({ [status]: tableData })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveMatomes()
    this.savePageState()
  }

  /**
   * 記事を編集するボタンを押したときのハンドラ
   */
  handleArticleEditButtonClick = article => {
    this.savePageState()
    this.props.router.push(`/article/${article.id}`)
  }

  /**
   * まとめのデータに変更があったときのハンドラ
   */
  handleMatomeDataChanged = () => {
    this.retrieveMatomes()
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveMatomes()
      this.savePageState()
    })
  }

  /**
   * データテーブルの並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveMatomes()
    })
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const matomeId = this.state.undoMatome.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putMatome = CancelablePromise(matomeApi.putMatome(matomeId))
    putMatome.promise
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoMatome: null,
        })

        this.retrieveMatomes()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`put matome matomeId #${matomeId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoMatome: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoMatome: null,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   */
  openUndoModal(matome) {
    this.setState({
      isUndoModalOpen: true,
      undoMatome: matome,
    })
  }

  /**
   * 作成・更新モーダルを表示する関数
   * @param {Object} matome - まとめデータ
   */
  openMatomeEditModal(matome) {
    this.setState({
      isMatomeEditModalOpen: true,
      editMatome: matome,
    })
  }

  /**
   * 削除モーダルを表示する関数
   * @param {Object} matome - まとめデータ
   */
  openMatomeRemoveModal(matome) {
    this.setState({
      isMatomeRemoveModalOpen: true,
      removeMatome: matome,
    })
  }

  /**
   * 特集一覧の取得
   */
  retrieveMatomes() {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getMatomes = CancelablePromise(matomeApi.getMatomes(this.getRequestQuery()))
    getMatomes.promise
      .then(summaryResponse => {
        const responseHeader = summaryResponse.header
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 50), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        getMatomeDetails.length = 0
        getMatomeDetails = _.map(summaryResponse.data, summary => {
          const id = summary.id
          return CancelablePromise(
            matomeApi.getMatome(id).then(detailResponse => {
              const detail = detailResponse.data
              return _.extend(summary, detail)
            }),
          )
        })
        return Promise.all(_.map(getMatomeDetails, matomeDetail => matomeDetail.promise))
      })
      .then(matomes => {
        logger.debug('retrieve matomes', { matomes })

        this.setState({
          isBusy: false,
          matomes,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve matomes error', error)

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totaPage = 0
        tableData.totalItems = 0

        this.setState({
          matomes: [],
          [status]: tableData,
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 表示中の特集のステータス
    const status = this.state.status
    const tableData = this.state[status]

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    // フィルタリング
    const filtering = []
    if (_.isEqual(status, MatomeStatus.TRASH)) {
      // ゴミ箱記事の場合
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    // 日付での絞り込み
    if (!tableData.filtering.clearDateRange) {
      const dateRangeStartAt = tableData.filtering.dateRangeStartAt
      const dateRangeEndAt = tableData.filtering.dateRangeEndAt
      filtering.push(`publishDatetime >= '${dateRangeStartAt.toISOString()}'`)
      filtering.push(`publishDatetime <= '${dateRangeEndAt.toISOString()}'`)
    }

    if (!_.isEmpty(tableData.filtering.title)) {
      let filteringTitle = tableData.filtering.title
      if (filteringTitle.match(/\,/)) { // eslint-disable-line
        filteringTitle = ''
      }

      // タイトルで検索する場合
      filtering.push(`title LIKE "%${filteringTitle}%"`)
    }

    // ソートを配列に変換
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  /**
   * 特集の表示状態を保存 (特集詳細で記事の編集画面に遷移したときに状態を戻すために使用)
   */
  savePageState() {
    const matomes = this.state.matomes
    const isMatomeEditModalOpen = this.state.isMatomeEditModalOpen
    const editMatome = this.state.editMatome
    const status = this.state.status
    const tableData = this.state[status]

    const pageState = {
      matomes,
      isMatomeEditModalOpen,
      editMatome,
      status,
      [status]: tableData,
    }

    logger.debug('save page state', { pageState })
    Store.set('matomesPageState', pageState)
  }

  /**
   * サムネイル画像とカバー画像のレンダリング(共通処理)
   * @param {Object} item Image を格納するオブジェクト
   */
  renderImage(item) {
    // eslint-disable-line
    const imageUrl = _.get(item, 'image.url', '')
    return <Image src={imageUrl} centered size="tiny" shape="rounded" />
  }

  render() {
    const { status, permission } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission
    const tableData = this.state[status]

    return (
      <div className="Matomes">
        <Header as="h1">
          <Icon name="book" />

          <Header.Content>特集</Header.Content>
        </Header>

        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={MatomeStatus.ALL}
            active={_.isEqual(status, MatomeStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={MatomeStatus.TRASH}
            active={_.isEqual(status, MatomeStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={this.handleCreateButtonClick}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索エリア */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="matomes-search"
                type="text"
                placeholder="タイトルで検索"
                action
                value={tableData.filtering.title}
                onChange={this.handleTitleInputChange}
                validations={{ matchRegexp: /^((?!,).)*$/i }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Button
                  icon="search"
                  onClick={this.handleSearchButtonClick}
                  color="blue"
                  disabled={!this.state.isFormSearchValid}
                />
              </Input>
            </Form.Field>
            <Form.Field width={4}>
              <PublishDateRangePicker
                startDate={tableData.filtering.dateRangeStartAt}
                endDate={tableData.filtering.dateRangeEndAt}
                onEvent={this.handleDateRangePickerEvent}
                clear={tableData.filtering.clearDateRange}
                disabled={!this.state.isFormSearchValid}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        {/* 件数表示メッセージ */}
        {_.isEmpty(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {/** まとめ一覧 */}
        {!_.isEmpty(this.state.matomes) && (
          <DataTable
            sort={tableData.sorting}
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSelectionChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            rowKey="id"
            items={this.state.matomes}
            cellStyle={{ position: 'relative' }} // ラベル表示のためセルにスタイルを指定
            columns={[
              {
                label: 'ID',
                align: 'center',
                field: 'id',
              },
              {
                label: 'サムネイル',
                align: 'center',
                render: item => {
                  // 公開中
                  const isPublish = moment(item.publishDatetime).isSameOrBefore(moment())
                  return (
                    <div>
                      {!isPublish && (
                        <Popup
                          trigger={<Label corner icon="checked calendar" />}
                          content="予約中のコンテンツです"
                          inverted
                        />
                      )}

                      {this.renderImage(item.thumbnail)}
                    </div>
                  )
                },
              },
              {
                label: 'カバー',
                align: 'center',
                render: item => this.renderImage(item.cover),
              },
              {
                label: 'タイトル',
                field: 'title',
                minWidth: '20em',
                collapsing: false,
              },
              {
                label: 'サマリー',
                field: 'description',
                collapsing: false,
                minWidth: '20em',
              },
              {
                label: '公開日時',
                field: 'publishDatetime',
                render: item =>
                  !_.isEmpty(item.publishDatetime) && (
                    <span>
                      <FormattedDate
                        value={item.publishDatetime}
                        day="numeric"
                        month="long"
                        year="numeric"
                        weekday="narrow"
                      />
                      <br />
                      <FormattedTime value={item.publishDatetime} hour="numeric" minute="numeric" second="numeric" />
                    </span>
                  ),
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.openMatomeEditModal(item)
                      }}
                    />

                    {!_.isEqual(status, MatomeStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openMatomeRemoveModal(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, MatomeStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* 特集作成・編集モーダル */}
        {(hasCreatePermission || hasUpdatePermission) && (
          <MatomeEditModal
            matome={this.state.editMatome}
            relatedArticles={this.state.relatedArticles}
            open={this.state.isMatomeEditModalOpen}
            onClose={this.handleMatomeEditModalClose}
            onArticleEdit={this.handleArticleEditButtonClick}
            onSuccessDataChanged={this.handleMatomeDataChanged}
          />
        )}

        {/* 特集削除モーダル */}
        {hasDeletePermission && (
          <MatomeRemoveModal
            matome={this.state.removeMatome}
            open={this.state.isMatomeRemoveModalOpen}
            onClose={this.handleMatomeRemoveModalClose}
            onSuccessDataChanged={this.handleMatomeDataChanged}
          />
        )}

        {hasRestorePermission && (
          <Modal
            size="tiny"
            closeIcon={true}
            open={this.state.isUndoModalOpen}
            onClose={this.handleUndoModalClose}
            closeOnDimmerClick={false}
          >
            <Modal.Header>まとめの公開</Modal.Header>

            {/* ローディング */}
            <Dimmer active={this.state.isBusy} inverted>
              <Loader />
            </Dimmer>

            <Modal.Content>
              {/* エラーメッセージ */}
              <ApiErrorMessage error={this.state.undoApiError} />
              {_.get(this.state, 'undoMatome.title')} を戻しますか？
            </Modal.Content>

            <Modal.Actions>
              <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

              <Button positive content="戻す" onClick={this.handleUndoModalPublishButtonClick} />
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

export default Matomes
