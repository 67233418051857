import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Modal, Button, Dimmer, Loader, Segment, Popup, Icon, Input, Divider } from 'semantic-ui-react'
import { ChromePicker } from 'react-color'
import { Form } from 'formsy-semantic-ui-react'
import { CategoryApi } from 'trill-api-admin-client'

import DataTable from './DataTable'
import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import TagsDropdown from './TagsDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'
import { flattenObject } from '../util'

const logger = LogLevel.getLogger('CategoryEditModal')
const categoryApi = new CategoryApi()
let sendCategory
let getCategoryTags
let deleteCategoryTag
const putCategoryTags = []

const propTypes = {
  /**
   * 編集対象のカテゴリ（作成の場合は空）
   */
  category: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新が成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  category: {},
  open: false,
}

class CategoryEditModal extends Component {
  state = {
    category: {},
    isFormValid: false,
    isFormModified: false,
    isBusy: false,
    apiError: null,
    relatedTagsError: null,
    // 関連タグ
    tags: [],
    totalItems: 0,
    totalPages: 0,
    itemsPerPage: 5,
    currentPage: 1,
    sorting: { name: 'asc' },
    filtering: { name: '' },
    isTagsBusy: false,
    isHiddenTagsTable: true,
    tagsDropdownValues: [],
    isAddModalOpen: false,
    isDeleteModalOpen: false,
    deleteTag: null,
    color: '',
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されてるかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.category, nextProps.category)) {
      this.setState(
        {
          category: nextProps.category,
          isFormModified: false,
          tags: [],
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 5,
          sorting: { name: 'asc' },
          filtering: { name: '' },
          // 初回のみ関連タグ表示まで isBusy を true に設定
          isBusy: true,
          color: _.get(nextProps, 'category.color', ''),
        },
        () => {
          this.retrieveTags()
            .then(() => {
              this.setState({
                isBusy: false,
                isHiddenTagsTable: _.size(this.state.tags) === 0,
              })
            })
            .catch(() => {
              this.setState({ isBusy: false })
            })
        },
      )

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.category) {
        this.initializeFormValues(this.props.category)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(sendCategory)) {
      sendCategory.cancel()
    }
    if (!_.isNil(deleteCategoryTag)) {
      deleteCategoryTag.cancel()
    }
    if (!_.isNil(getCategoryTags)) {
      getCategoryTags.cancel()
    }
    if (!_.isEmpty(putCategoryTags)) {
      _.each(putCategoryTags, putCategoryTag => putCategoryTag.cancel())
      putCategoryTags.length = 0
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }
    const flattenCurrentValues = flattenObject(currentValues)

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(flattenCurrentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const sendData = this.getRequestParameters(submitFormData)
    sendCategory = CancelablePromise(this.sendData(sendData))

    this.checkDuplicateFeatured(sendData.featured)
      .then(() => sendCategory.promise)
      .then(response => {
        const category = response.data

        // 更新 or 作成に成功したらカテゴリ一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        // フォームデータを更新して初期化
        this.initializeFormValues(category)

        resetForm(this.initialFormValues)

        this.setState({
          isFormModified: false,
          isBusy: false,
          category,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send category data error', { error })

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 関連タグテーブルのソート変更時のハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const sorting = sort

    this.setState({ sorting }, () => {
      this.retrieveTags()
    })
  }

  /**
   * 関連タグテーブルのページ情報変更時のハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    this.setState(
      {
        currentPage,
        itemsPerPage,
      },
      () => {
        this.retrieveTags()
      },
    )
  }

  /**
   * 関連タグ追加用モーダルの追加ボタンを押したときのハンドラ
   */
  handleAddModalApproveButtonClick = () => {
    this.setState(
      {
        isTagsBusy: true,
        // タグがなかった場合、テーブルにローディングを出せないため全体のローディングを出す
        isBusy: _.size(this.state.tags) === 0,
        relatedTagsError: null,
      },
      () => {
        const category = this.state.category
        putCategoryTags.length = 0
        _.each(this.state.tagsDropdownValues, tagId => {
          putCategoryTags.push(
            CancelablePromise(
              new Promise((resolve, reject) => {
                categoryApi
                  .putCategoryTag(category.id, tagId)
                  .then(() => {
                    resolve()
                  })
                  .catch(error => {
                    if (error.isCanceled) {
                      reject(error)
                      return
                    }

                    logger.error(`put category tag categoryId #${category.id} tagId #${tagId} error`, error)

                    // 同じタグを登録した場合などに発生するエラー (登録できるタグは登録させたいため resolve)
                    resolve()
                  })
              }),
            ),
          )
        })

        Promise.all(_.map(putCategoryTags, putCategoryTag => putCategoryTag.promise))
          .then(() => {
            // 一覧の更新
            if (this.props.onSuccessDataChanged) {
              this.props.onSuccessDataChanged()
            }
            return this.retrieveTags()
          })
          .then(() => {
            // 登録に成功したら TagsDropdown の値はリセット
            this.setState({
              tagsDropdownValues: [],
              isAddModalOpen: false,
              isBusy: false,
              isHiddenTagsTable: false,
            })
          })
          .catch(error => {
            if (error.isCanceled) {
              return
            }

            logger.error(`put category tag categoryId #${category.id} error`, error)

            this.setState({
              isBusy: false,
              isTagsBusy: false,
              relatedTagsError: error,
            })
          })
      },
    )
  }

  /**
   * タグ追加用モーダルに表示するタグドロップダウンからタグを変更したときのハンドラ
   */
  handleAddModalDropdownChange = (event, { value }) => {
    this.setState({ tagsDropdownValues: value })
  }

  /**
   * タグ追加用モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleAddModalCancelButtonClick = () => {
    this.setState({
      isAddModalOpen: false,
      tagsDropdownValues: [],
    })
  }

  /**
   * タグ削除用モーダルの削除ボタンを押したときのハンドラ
   */
  handleDeleteModalApproveButtonClick = () => {
    const category = this.state.category
    const tag = this.state.deleteTag

    this.setState({
      isTagsBusy: true,
      relatedTagsError: null,
    })

    deleteCategoryTag = CancelablePromise(categoryApi.deleteCategoryTag(category.id, tag.id))
    deleteCategoryTag.promise
      .then(() => {
        // 一覧の更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        // 削除に成功したら削除対象として保存していた deleteTag はリセット
        this.setState({ deleteTag: null })

        return this.retrieveTags()
      })
      .then(() => {
        // 削除時に関連タグが 0 個の場合、関連タグテーブルを非表示に設定
        this.setState({
          isDeleteModalOpen: false,
          isHiddenTagsTable: _.size(this.state.tags) === 0,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`delete category tag categoryId #${category.id} tagId #${tag.id} error`, error)

        this.setState({
          isTagsBusy: false,
          relatedTagsError: error,
        })
      })
  }

  /**
   * タグ削除用モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleDeleteModalCancelButtonClick = () => {
    this.setState({
      deleteTag: null,
      isDeleteModalOpen: false,
    })
  }

  /**
   * カラーピッカーの色を変更したときのハンドラ
   */
  handleChromePickerChange = color => {
    this.setState({ color: color.hex })
  }

  /**
   * カラーピッカー項目のキャンセルボタンを押したときのハンドラ
   */
  handleChromePickerCancelIconClick = () => {
    this.setState({ color: '' })
  }

  /**
   * タグ名で絞り込むインプットの値を変更したときのハンドラ
   */
  handleTagNameInputChange = (event, data) => {
    const filtering = this.state.filtering
    filtering.name = data.value

    this.setState({ filtering })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = event => {
    event.preventDefault()

    this.retrieveTags()
  }

  /**
   * タグ追加用モーダルを開く関数
   */
  openTagAddModal() {
    this.setState({ isAddModalOpen: true })
  }

  /**
   * タグ削除用モーダルを開く関数
   * @param {Object} tag - タグデータ
   */
  openDeleteModal(tag) {
    this.setState({
      isDeleteModalOpen: true,
      deleteTag: tag,
    })
  }

  /**
   * フォームの初期化
   * @param {Object} category - カテゴリデータ
   */
  initializeFormValues(category) {
    this.initialFormValues.name = category.name
    this.initialFormValues.slug = category.slug
    // フォームの変更判定を正しく行うために string に変換
    this.initialFormValues.featured = category.featured ? category.featured.toString() : ''
    this.initialFormValues.color = _.defaultTo(category.color, '')
    this.initialFormValues['adUnitId.ios'] = _.get(category, 'adUnitId.ios', '')
    this.initialFormValues['adUnitId.android'] = _.get(category, 'adUnitId.android', '')
    this.initialFormValues['adUnitId.webMobile'] = _.get(category, 'adUnitId.webMobile', '')
    this.initialFormValues['adUnitId.webDesktop'] = _.get(category, 'adUnitId.webDesktop', '')
  }

  /**
   * API にデータを送信
   */
  sendData = submitCategoryData => {
    if (_.isEmpty(this.state.category)) {
      return categoryApi.postCategory(submitCategoryData)
    }

    const categoryId = this.state.category.id
    return categoryApi.patchCategory(categoryId, {
      categoryUpdateValues: submitCategoryData,
    })
  }

  /**
   * 人気記事の表示順番が重複してるかをチェック
   * (表示順が重複していると 500 Internal Server Error が発生するため、具体的なエラー文言を表示させるために追加)
   * TODO: API 側で正しいエラーを送ってもらえるようになったら不要 (https://github.com/trill-corp/trill-admin/issues/207)
   */
  checkDuplicateFeatured = featured =>
    new Promise((resolve, reject) => {
      if (!featured) {
        resolve()

        return
      }

      const header = { filtering: [`featured = ${featured}`] }

      categoryApi
        .getCategories(header)
        .then(response => {
          const category = response.data

          if (_.isEmpty(category)) {
            resolve()
          } else {
            reject(new Error('すでに存在する 【人気記事一覧の表示順番】 が設定されています。'))
          }
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * 関連タグ一覧取得
   */
  retrieveTags = () =>
    new Promise((resolve, reject) => {
      const category = this.state.category
      if (_.isEmpty(category) || !_.has(category, 'id')) {
        // カテゴリ id がない場合は何もしない
        resolve()

        return
      }

      const categoryId = category.id

      this.setState({
        isTagsBusy: true,
        relatedTagsError: null,
      })

      getCategoryTags = CancelablePromise(categoryApi.getCategoryTags(categoryId, this.getRequestQuery()))
      getCategoryTags.promise
        .then(response => {
          const tags = response.data
          const responseHeader = response.header
          const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
          const totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
          const itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
          const paginationCurrentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
          const currentPage = paginationCurrentPage === 0 ? 1 : paginationCurrentPage

          logger.debug('get category tags', {
            tags,
            totalPages,
            totalItems,
            currentPage,
            itemsPerPage,
          })

          this.setState({
            tags,
            totalPages,
            totalItems,
            currentPage,
            itemsPerPage,
            isTagsBusy: false,
          })

          resolve()
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          logger.error('retrieve tags error', { error })

          this.setState({
            tags: [],
            totalPages: 0,
            totalItems: 0,
            currentPage: 1,
            itemsPerPage: 5,
            isTagsBusy: false,
            relatedTagsError: error,
          })

          reject(error)
        })
    })

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // featured の値を数値に変換
    submitFormData.featured = _.isEmpty(submitFormData.featured) ? 0 : parseInt(submitFormData.featured, 10)

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}
    initialData.name = initialFormValues.name
    initialData.slug = initialFormValues.slug
    initialData.featured = _.isEmpty(initialFormValues.featured) ? 0 : parseInt(initialFormValues.featured, 10)
    initialData.color = initialFormValues.color

    _.set(initialData, 'adUnitId.ios', _.get(initialFormValues, 'adUnitId.ios'))
    _.set(initialData, 'adUnitId.android', _.get(initialFormValues, 'adUnitId.android'))
    _.set(initialData, 'adUnitId.webMobile', _.get(initialFormValues, 'adUnitId.webMobile'))
    _.set(initialData, 'adUnitId.webDesktop', _.get(initialFormValues, 'adUnitId.webDesktop'))

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    if (_.has(requestParameters, 'featured')) {
      requestParameters.featured = parseInt(submitFormData.featured, 10)
    }

    // slug に空文字に設定されていた場合はリセット
    if (_.isEqual(requestParameters.slug, '')) {
      requestParameters.slug = ' '
    }

    // slug に空文字に設定されていた場合はリセット
    if (_.isEqual(requestParameters.color, '')) {
      requestParameters.color = ' '
    }

    if (_.isEqual(_.get(requestParameters, 'adUnitId.ios'), '')) {
      _.set(requestParameters, 'adUnitId.ios', ' ')
    }

    if (_.isEqual(_.get(requestParameters, 'adUnitId.android'), '')) {
      _.set(requestParameters, 'adUnitId.android', ' ')
    }

    if (_.isEqual(_.get(requestParameters, 'adUnitId.webMobile'), '')) {
      _.set(requestParameters, 'adUnitId.webMobile', ' ')
    }

    if (_.isEqual(_.get(requestParameters, 'adUnitId.webDesktop'), '')) {
      _.set(requestParameters, 'adUnitId.webDesktop', ' ')
    }

    logger.debug('get request parameters', { requestParameters })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(this.state.totalItems / this.state.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && this.state.currentPage > totalPage ? totalPage : this.state.currentPage
    const itemsPerPage = this.state.itemsPerPage

    // ソート順
    const sorting = _.map(this.state.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    // フィルタリング
    const filtering = []
    if (!_.isEmpty(this.state.filtering.name)) {
      filtering.push(`name LIKE "%${this.state.filtering.name}%"`)
    }
    const query = {
      currentPage,
      itemsPerPage,
      sorting,
      filtering,
    }

    logger.debug(
      'get request query',
      _.omitBy(query, o => !_.isNumber(o) && _.isEmpty(o)),
    )

    // 数値ではなく、空のプロパティの場合は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    return (
      <Modal
        className="CategoryEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.state.category) ? 'カテゴリの作成' : 'カテゴリの編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* API エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/* カテゴリ名入力フィールド */}
            <Form.Input
              name="name"
              label="カテゴリ名"
              placeholder="カテゴリ名を入力してください"
              required
              validations="maxLength:9"
              validationErrors={{ maxLength: '9 文字以内で入力してください。' }}
              errorLabel={<FormErrorLabel />}
            />

            <Form.Group widths="2">
              {/* スラッグ入力フィールド */}
              <Popup
                wide="very"
                inverted
                trigger={
                  <Form.Input
                    name="slug"
                    label="スラッグ"
                    placeholder="スラッグを入力してください"
                    validations={{ matchRegexp: /^[a-z0-9-]*$/i }}
                    validationErrors={{
                      matchRegexp: '英数字または - で入力してください。',
                    }}
                    errorLabel={<FormErrorLabel />}
                  />
                }
                content="URLの最後に表示される文字を設定できます。"
              />

              {/* 表示順番入力フィールド */}
              <Form.Input
                name="featured"
                label="人気記事一覧の表示順番"
                placeholder="人気記事一覧に表示する場合は、並べ順の番号を入力してください"
                validations={{
                  isPositiveNumber: (values, value) =>
                    (!_.isEmpty(value) && value > 0 && value % 1 === 0) || _.isEmpty(value),
                }}
                validationErrors={{
                  isPositiveNumber: '正数で入力してください。',
                }}
                errorLabel={<FormErrorLabel />}
                type="number"
              />
            </Form.Group>

            <Form.Group widths="equal">
              {/* iOS の 広告 ID 入力フィールド */}
              <Popup
                inverted
                wide="very"
                content="記事詳細で表示する広告の SpaceId を入れてください。"
                trigger={
                  <Form.Input
                    name="adUnitId.ios"
                    label="広告 ID (iOS)"
                    placeholder="iOS の広告 ID を入力してください"
                  />
                }
              />

              {/* Android の 広告 ID 入力フィールド */}
              <Popup
                inverted
                wide="very"
                content="記事詳細で表示する広告の SpaceId を入れてください。"
                trigger={
                  <Form.Input
                    name="adUnitId.android"
                    label="広告 ID (Android)"
                    placeholder="Android の広告 ID を入力してください"
                  />
                }
              />
            </Form.Group>

            <Form.Group widths="equal">
              {/* web mobile の 広告 ID 入力フィールド */}
              <Popup
                inverted
                wide="very"
                content="記事詳細で表示する広告の SpaceId を入れてください。"
                trigger={
                  <Form.Input
                    name="adUnitId.webMobile"
                    label="広告 ID (web mobile)"
                    placeholder="web (mobile) の広告 ID を入力してください"
                  />
                }
              />

              {/* web desktop の 広告 ID 入力フィールド */}
              <Popup
                inverted
                wide="very"
                content="記事詳細で表示する広告の SpaceId を入れてください。"
                trigger={
                  <Form.Input
                    name="adUnitId.webDesktop"
                    label="広告 ID (web desktop)"
                    placeholder="web (desktop) の広告 ID を入力してください"
                  />
                }
              />
            </Form.Group>

            {/* カテゴリのラベル色 */}
            <Popup
              style={{ padding: 0 }}
              trigger={
                <Form.Input
                  label="カテゴリのラベル色"
                  placeholder="カテゴリのラベルに設定する色を選んでください"
                  icon={<Icon name="remove" link onClick={this.handleChromePickerCancelIconClick} />}
                  readOnly
                  name="color"
                  value={this.state.color}
                />
              }
              content={<ChromePicker color={this.state.color} onChange={this.handleChromePickerChange} />}
              on="click"
            />

            {!this.state.isHiddenTagsTable && (
              <Form.Field>
                <label>関連タグ</label>

                <Segment loading={this.state.isTagsBusy} padded>
                  {/* 検索エリア */}
                  <Input
                    type="text"
                    placeholder="タグ名で検索"
                    action
                    fluid
                    value={this.state.filtering.name}
                    onChange={this.handleTagNameInputChange}
                  >
                    <input />

                    <Button icon="search" onClick={this.handleSearchButtonClick} />
                  </Input>

                  {/* API エラーメッセージ */}
                  <ApiErrorMessage error={this.state.relatedTagsError} />

                  {!_.isEmpty(this.state.tags) && (
                    <div>
                      <Divider hidden />

                      <DataTable
                        items={this.state.tags}
                        sort={this.state.sorting}
                        currentPage={this.state.currentPage}
                        totalPages={this.state.totalPages}
                        itemsPerPage={this.state.itemsPerPage}
                        onSelectionChange={this.handleDataTableSelectionChange}
                        onPageChange={this.handleDataTablePageChange}
                        rowKey="id"
                        columns={[
                          {
                            collapsing: false,
                            label: 'タグ名',
                            field: 'name',
                          },
                          {
                            label: '操作',
                            align: 'center',
                            render: tag => (
                              <Button
                                secondary
                                key={tag.id}
                                icon="trash alternate outline"
                                onClick={event => {
                                  event.preventDefault()
                                  this.openDeleteModal(tag)
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  )}
                </Segment>
              </Form.Field>
            )}
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.state.category) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || !this.state.isFormModified}
          />

          {!_.isEmpty(this.state.category) && (
            <Button
              primary
              content="関連タグの追加"
              onClick={event => {
                event.preventDefault()
                this.openTagAddModal()
              }}
            />
          )}
        </Modal.Actions>

        {/* タグ追加用のモーダル */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isAddModalOpen}
          onClose={this.handleAddModalCancelButtonClick}
          closeOnDimmerClick={false}
        >
          <Modal.Header>タグの追加</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.isTagsBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* API エラーメッセージ */}
            <ApiErrorMessage error={this.state.relatedTagsError} />

            <TagsDropdown value={this.state.tagsDropdownValues} onChange={this.handleAddModalDropdownChange} />
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleAddModalCancelButtonClick} />

            <Button positive content="追加" onClick={this.handleAddModalApproveButtonClick} />
          </Modal.Actions>
        </Modal>

        {/* タグ削除用のモーダル */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isDeleteModalOpen}
          onClose={this.handleDeleteModalCancelButtonClick}
          closeOnDimmerClick={false}
        >
          <Modal.Header>タグの削除</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.isTagsBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* API エラーメッセージ */}
            <ApiErrorMessage error={this.state.relatedTagsError} />
            {_.get(this.state, 'deleteTag.name')} を削除しますか？
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleDeleteModalCancelButtonClick} />

            <Button negative content="削除" onClick={this.handleDeleteModalApproveButtonClick} />
          </Modal.Actions>
        </Modal>
      </Modal>
    )
  }
}

CategoryEditModal.propTypes = propTypes
CategoryEditModal.defaultProps = defaultProps

export default CategoryEditModal
