import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TagApi } from 'trill-api-admin-client'
import _ from 'lodash'
import InfiniteDropdown from './InfiniteDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('TagsDropdown')
const tagApi = new TagApi()
let getTags
let getTag

const propTypes = {
  /**
   * 選択中のタグ ID 一覧
   */
  value: PropTypes.array,

  /**
   * 幅を最大にするかどうか
   */
  fluid: PropTypes.bool,

  /**
   * ゴミ箱にあるコンテンツを表示させたくない場合
   */
  ignoreTrashContents: PropTypes.bool,

  /**
   * タグ変更時時に呼び出す外部関数
   *
   * @param {SyntheticEvent} event - React の SyntheticEvent
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {Array.<string>} data.value - 選択中のタグ ID 一覧
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  value: [],
  fluid: true,
  ignoreTrashContents: true,
}

class TagsDropdown extends Component {
  state = {
    isBusy: false,
    tags: [],
    currentTags: [],
    currentPage: 0,
    totalPages: 0,
  }

  /**
   * ドロップダウンの値を変更したかどうか
   */
  isDropdownValueChange = false

  /**
   * タグ名検索クエリ
   */
  tagSearchQuery = ''

  componentDidMount() {
    const getTagPromises = []
    _.each(this.props.value, tagId => getTagPromises.push(this.retrieveTag(tagId)))

    Promise.all(getTagPromises).then(() => {
      this.setState({ currentTags: this.state.tags })
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.value, nextProps.value) && !this.isDropdownValueChange) {
      this.setState(
        {
          tags: [],
          currentPage: 0,
          totalPages: 0,
        },
        () => {
          // タグの取得
          const getTagPromises = []
          _.each(this.props.value, tagId => getTagPromises.push(this.retrieveTag(tagId)))

          Promise.all(getTagPromises).then(() => {
            this.setState({ currentTags: this.state.tags })
          })
        },
      )
    }

    this.isDropdownValueChange = false
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getTags)) {
      getTags.cancel()
    }
    if (!_.isNil(getTag)) {
      getTag.cancel()
    }
  }

  /**
   * ドロップダウンにタグ名を入れて検索を行ったときのハンドラ
   */
  handleInfiniteDropdownSearch = searchQuery => {
    this.tagSearchQuery = searchQuery

    this.setState(
      {
        currentPage: 0,
        totalPages: 0,
      },
      () => {
        this.retrieveTags()
      },
    )
  }

  /**
   * ドロップダウンの値を変更したときのハンドラ
   */
  handleInfiniteDropdownChange = (event, { value }) => {
    const oldValue = this.props.value
    const newValue = value

    if (!_.isEqual(_.sortBy(oldValue), _.sortBy(newValue))) {
      this.isDropdownValueChange = true

      const { tags, currentTags } = this.state

      if (newValue.length > oldValue.length) {
        const selectedTagId = _.difference(newValue, oldValue)[0]
        const selectedTag = _.filter(tags, { id: selectedTagId })
        const concatenatedTags = _.uniqBy(_.concat(currentTags, selectedTag), 'id')
        this.setState({ tags: concatenatedTags, currentTags: concatenatedTags })
      } else {
        const deletedTagId = _.difference(oldValue, newValue)[0]
        const deletedTag = _.filter(currentTags, { id: deletedTagId })
        _.pull(currentTags, deletedTag[0])
        this.setState({ tags: currentTags, currentTags })
      }

      _.invoke(this.props, 'onChange', event, { name: 'tagIds', value })
    }
  }

  /**
   * タグ詳細の取得
   */
  retrieveTag = tagId =>
    new Promise(resolve => {
      if (_.isNil(tagId)) {
        resolve()

        return
      }

      this.setState({ isBusy: true })

      getTag = CancelablePromise(tagApi.getTag(tagId))
      getTag.promise
        .then(response => {
          const tag = response.data
          const tags = this.state.tags
          tags.unshift(tag)

          logger.debug(`get tag tag id #${tagId}`, { tag, tags }, _.uniqBy(tags, 'id'))

          this.setState(
            {
              tags: _.uniqBy(tags, 'id'),
              isBusy: false,
            },
            () => resolve(),
          )
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          logger.error(`get tag tag id #${tagId} error`, error)

          this.setState({ isBusy: false })

          // タグ詳細が取得できなくても処理は続行させたいため resolve
          resolve()
        })
    })

  /**
   * タグ一覧の取得
   */
  retrieveTags = () => {
    if (!_.isEmpty(this.tagSearchQuery)) {
      this.setState({ isBusy: true })
      getTags = CancelablePromise(tagApi.getTags(this.getRequestQuery()))
      getTags.promise
        .then(response => {
          const tags = _.uniqBy(_.concat(this.state.tags, response.data), 'id')
          const responseHeader = response.header
          const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 1), 10)
          const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)

          this.setState({
            isBusy: false,
            tags,
            totalPages,
            currentPage,
          })
        })
        .catch(error => {
          if (error.isCanceled) {
            return
          }

          logger.error('retrieve tags error', error)

          this.setState({ isBusy: false })
        })
    } else {
      this.setState({ tags: this.state.currentTags })
    }
  }

  /**
   * 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const { currentTags, currentPage } = this.state

    // 絞り込み
    const filtering = []

    // タグ検索文字列があった場合は、タグ名で絞り込むため filtering に追加
    if (!_.isEmpty(this.tagSearchQuery)) {
      filtering.push(`name LIKE '%${this.tagSearchQuery}%'`)
    }

    if (this.props.ignoreTrashContents) {
      // 削除済みのタグは除外
      filtering.push('deleted_at IS NULL')
    }

    // 取得済みで選択済み Tag 一覧は除外して一覧を取得: 取得済みで選択済みのタグを再取得しないため
    _.each(currentTags, tag => filtering.push(`id <> '${tag.id}'`))

    const query = {
      itemsPerPage: 10,
      currentPage: currentPage + 1,
      filtering,
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    return (
      <InfiniteDropdown
        fluid={this.props.fluid}
        placeholder="タグを選択してください"
        noResultsMessage="該当するタグが見つかりません"
        loading={this.state.isBusy}
        multiple={true}
        value={this.props.value}
        options={_.concat(
          _.map(this.state.tags, tag => ({
            text: tag.name,
            value: tag.id,
            icon: 'tag',
          })),
        )}
        renderLabel={data => ({
          content: data.text,
          icon: 'tag',
        })}
        onLoadMore={this.retrieveTags}
        onSearch={this.handleInfiniteDropdownSearch}
        onChange={this.handleInfiniteDropdownChange}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
      />
    )
  }
}

TagsDropdown.propTypes = propTypes
TagsDropdown.defaultProps = defaultProps

export default TagsDropdown
