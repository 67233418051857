import React, { Component } from 'react'
import { Header, Button, Image, Divider, Dimmer, Loader } from 'semantic-ui-react'
import { FormattedDate, FormattedTime } from 'react-intl'
import W2aCreativeEditModal from '../../components/W2aCreativeEditModal'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import w2aCreativeTypes from '../../enums/w2aCreativeTypes'
import LogLevel from '../../LogLevel'
import DataTable from '../../components/DataTable'
import _ from 'lodash'
import W2aCreativeClearModal from '../../components/W2aCreativeClearModal'

import './W2a.css'

const logger = LogLevel.getLogger('W2A')

class W2a extends Component {
  constructor(props) {
    super(props)

    logger.debug('this.props', this.props)
  }

  render() {
    const { getW2aCreatives, w2aCategories, w2aCreatives, apiError, isBusy, permission } = this.props

    const createTestParam = { label: 'テストパラメータ', field: 'testParam' }

    const formatedTime = item => (
      <span>
        <FormattedDate value={item.updatedAt} day="numeric" month="long" year="numeric" weekday="narrow" />
        <br />
        <FormattedTime value={item.updatedAt} hour="numeric" minute="numeric" second="numeric" />
      </span>
    )

    const createUpdatedAt = {
      label: '更新日時',
      field: 'updatedAt',
      render: item => !_.isEmpty(item.updatedAt) && formatedTime(item),
    }

    const createButtonGroup = (item, w2aCreativeType) => (
      <Button.Group secondary>
        <W2aCreativeEditModal
          permission={permission}
          type={w2aCreativeType}
          w2aCreative={item}
          getW2aCreatives={getW2aCreatives}
        />
        <W2aCreativeClearModal permission={permission} w2aCreative={item} getW2aCreatives={getW2aCreatives} />
      </Button.Group>
    )

    const createEdit = w2aCreativeType => {
      return {
        label: '編集',
        render: item => createButtonGroup(item, w2aCreativeType),
      }
    }

    const imageColumns = [
      {
        label: '画像',
        field: 'image',
        render: item => {
          const src = item.image
          return <Image src={src} shape="rounded" size="small" centered />
        },
      },
      { label: 'alt属性', field: 'alt', collapsing: false },
    ]

    const imageAndButtonColumns = [
      {
        label: '画像',
        field: 'image',
        render: item => {
          const src = item.image
          return <Image src={src} shape="rounded" size="small" centered />
        },
      },
      { label: 'alt属性', field: 'alt', collapsing: false },
      { label: 'ボタンカラー（カラーコード）', field: 'color', collapsing: false },
    ]

    const textColumns = [
      {
        label: 'テキスト',
        collapsing: false,
        field: 'text',
        cellClassName: 'W2a__Text',
      },
    ]

    const getColumns = w2aCreativeType => {
      const columns = {
        [w2aCreativeTypes.IMAGE]: imageColumns,
        [w2aCreativeTypes.IMAGE_AND_BUTTON]: imageAndButtonColumns,
        [w2aCreativeTypes.TEXT]: textColumns,
      }
      return _.concat(_.get(columns, w2aCreativeType), [createTestParam, createUpdatedAt, createEdit(w2aCreativeType)])
    }

    return (
      <div>
        <ApiErrorMessage error={apiError} />
        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>
        <Header as="h1">
          <Header.Content>W2A入稿</Header.Content>
        </Header>

        {!_.isEmpty(w2aCategories) &&
          w2aCategories.map(w2aCategory => (
            <div key={w2aCategory.id}>
              <Divider />
              <Header as="h3">
                <Header.Content>
                  {w2aCategory.name} ({w2aCategory.positionName})
                </Header.Content>
              </Header>
              {!_.isEmpty(w2aCreatives) && _.has(w2aCreatives, w2aCategory.id) ? (
                <DataTable rowKey="id" items={w2aCreatives[w2aCategory.id]} columns={getColumns(w2aCategory.type)} />
              ) : null}
            </div>
          ))}
      </div>
    )
  }
}

export default W2a
