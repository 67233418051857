import _ from 'lodash'
import { Component } from 'react'
import PropTypes from 'prop-types'
import FormErrorLabel from './FormErrorLabel'
import { Button, Dropdown } from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import GameCategoriesDropdown from './GameCategoriesDropdown'
import { SEARCH_INPUT_PATTERN } from '../constants/pattern'
import LogLevel from '../LogLevel'
import { GameLocation, GameStatus } from '../enums/GameEnum'

const logger = LogLevel.getLogger('GameFilterContainer')

const propTypes = {
  /**
   * Initial data for filtering
   */
  filtering: PropTypes.object,

  /**
   * Set false to hide status filter
   */
  isShowStatusFilter: PropTypes.bool,

  /**
   * Called when the search button is clicked or other fields changed
   */
  onFilterChange: PropTypes.func,
}

const defaultPropTypes = {
  isShowStatusFilter: true,
}

const SEARCH_INPUT_LIMIT = 255

const SearchInputProperties = {
  id: {
    placeholder: 'IDで検索',
    validations: {
      isNumeric: true,
    },
    validationErrors: {
      isNumeric: '半角数字で入力してください。',
    },
  },
  title: {
    placeholder: 'タイトルで検索',
    validations: {
      maxLength: SEARCH_INPUT_LIMIT,
      matchRegexp: SEARCH_INPUT_PATTERN,
    },
    validationErrors: {
      maxLength: `${SEARCH_INPUT_LIMIT}文字以内で入力してください。`,
      matchRegexp: 'キーワードに不正な記号があるため検索できません',
    },
  },
}

class GameFilterContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filtering: {
        search: '',
        searchKey: 'title',
        status: null,
        location: null,
        gameCategory: null,
      },

      isFormSearchValid: true,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isNil(nextProps.filtering) && !_.isEqual(this.state.filtering, nextProps.filtering)) {
      this.setState({
        filtering: _.cloneDeep(nextProps.filtering),
      })
    }
  }

  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * Called when has changes on search and searchKey
   */
  handleSearchFieldsChange = (event, { name, value }) => {
    const { filtering } = this.state
    _.set(filtering, name, value)

    this.setState({ filtering })
  }

  emitFilterChange = () => {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(_.cloneDeep(this.state.filtering))
    }
  }

  /**
   * Called when click on search button
   * This method will call `onFilterChange` if the search value is valid
   */
  handleSearchButtonClick = () => {
    if (!this.state.isFormSearchValid) {
      return
    }

    this.emitFilterChange()
  }

  /**
   * Called when has changes on other fields
   * This method will always call `onFilterChange` callback when the value changed
   */
  handleFilteringValueChange = (event, { name, value }) => {
    const { filtering } = this.state
    const previousValue = _.get(filtering, name)

    if (_.isEqual(previousValue, value)) {
      return
    }

    _.set(filtering, name, value)

    this.setState({ filtering }, this.emitFilterChange)
  }

  render() {
    const { search, searchKey, status, location, gameCategory } = this.state.filtering
    return (
      <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
        <Form.Group>
          <Form.Field width={10}>
            <Input
              type="text"
              action
              name="search"
              value={search}
              onChange={this.handleSearchFieldsChange}
              errorLabel={<FormErrorLabel />}
              {..._.get(SearchInputProperties, searchKey, {})}
            >
              <input />

              <Dropdown
                name="searchKey"
                value={searchKey}
                onChange={this.handleSearchFieldsChange}
                selection
                options={[
                  {
                    key: 'title',
                    value: 'title',
                    text: 'タイトル',
                  },
                  {
                    key: 'id',
                    value: 'id',
                    text: 'ID',
                  },
                ]}
                style={{ minWidth: '9rem' }}
              />

              <Button
                icon="search"
                onClick={this.handleSearchButtonClick}
                color="blue"
                disabled={!this.state.isFormSearchValid}
              />
            </Input>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          {this.props.isShowStatusFilter && (
            <Form.Field width={3}>
              <Dropdown
                name="status"
                value={status}
                onChange={this.handleFilteringValueChange}
                placeholder="ステータスで絞り込み"
                selection
                options={[
                  {
                    text: 'すべて',
                    value: null,
                    icon: `circle${_.isNull(status) ? '' : ' outline'}`,
                  },
                  {
                    text: '公開',
                    value: GameStatus.publish,
                    icon: `circle${_.isEqual(status, GameStatus.publish) ? '' : ' outline'}`,
                  },
                  {
                    text: '非公開',
                    value: GameStatus.draft,
                    icon: `circle${_.isEqual(status, GameStatus.draft) ? '' : ' outline'}`,
                  },
                ]}
                disabled={!this.state.isFormSearchValid}
              />
            </Form.Field>
          )}

          <Form.Field width={this.props.isShowStatusFilter ? 3 : 5}>
            <Dropdown
              name="location"
              value={location}
              onChange={this.handleFilteringValueChange}
              placeholder="表示箇所で絞り込み"
              selection
              options={[
                {
                  text: 'すべて',
                  value: null,
                  icon: `circle${_.isNull(location) ? '' : ' outline'}`,
                },
                {
                  text: '無料の人気ゲーム',
                  value: GameLocation.popular,
                  icon: `circle${_.isEqual(location, GameLocation.popular) ? '' : ' outline'}`,
                },
                {
                  text: 'おすすめのゲーム',
                  value: GameLocation.recommended,
                  icon: `circle${_.isEqual(location, GameLocation.recommended) ? '' : ' outline'}`,
                },
              ]}
              disabled={!this.state.isFormSearchValid}
            />
          </Form.Field>
          <Form.Field width={this.props.isShowStatusFilter ? 4 : 5}>
            <GameCategoriesDropdown
              name="gameCategory"
              placeholder="ゲームカテゴリで絞り込み"
              ignoreTrashContents={true}
              selectedGameCategory={gameCategory}
              onChange={this.handleFilteringValueChange}
              disabled={!this.state.isFormSearchValid}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

GameFilterContainer.propTypes = propTypes
GameFilterContainer.defaultPropTypes = defaultPropTypes

export default GameFilterContainer
