import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import _ from 'lodash'

import DataTable from './DataTable'

const propTypes = {
  /**
   * メディアの設定一覧
   */
  mediumItems: PropTypes.array,

  /**
   * 並び替え
   */
  sort: PropTypes.object,

  /**
   * 現在表示中のページ
   */
  currentPage: PropTypes.number,

  /**
   * 1 ページあたりの表示件数
   */
  itemsPerPage: PropTypes.number,

  /**
   * 合計ページ数
   */
  totalPages: PropTypes.number,

  /**
   * 追加で表示するカラム
   */
  extColumns: PropTypes.array,

  /**
   * テーブルのページ情報を変更したときに呼び出す外部関数
   */
  onPageChange: PropTypes.func,
}

const defaultProps = {
  mediumItems: [],
  extColumns: [],
}

/**
 * メディアの設定一覧テーブルを表示するコンポーネント
 */
const MediumItemDataTable = props => {
  const columns = _.concat(
    [
      {
        label: 'ID',
        align: 'center',
        field: 'id',
      },
      {
        label: 'メディアの設定名',
        field: 'name',
        collapsing: false,
      },
      {
        label: 'Feed',
        align: 'center',
        collapsing: false,
        render: item => {
          const feedUrl = _.get(item, 'url')
          return feedUrl ? (
            <a href={feedUrl} target="_blank" rel="noopener noreferrer">
              <Icon name="feed" />
            </a>
          ) : (
            ''
          )
        },
      },
      {
        label: '記事保持期間(日)',
        align: 'center',
        render: item => <span>{_.get(item, 'retentionPeriod')}</span>,
      },
      {
        label: '重み付け',
        field: 'hotFactor',
        align: 'center',
        render: item => <span>{item.hotFactor}</span>,
      },
      {
        label: '公開設定',
        render: item => {
          const autoPublish = _.get(item, 'autoPublish')
          return autoPublish ? 'オート' : 'マニュアル'
        },
      },
      {
        label: 'Shannon',
        collapsing: false,
        render: item => {
          const shannon = _.get(item, 'shannonSubmissionAllowed')
          return shannon ? '連携' : '非連携'
        },
      },
    ],
    props.extColumns,
  )

  return (
    <DataTable
      items={props.mediumItems}
      sort={props.sort}
      cellStyle={{ position: 'relative' }}
      columns={columns}
      rowKey="id"
      currentPage={props.currentPage}
      itemsPerPage={props.itemsPerPage}
      totalPages={props.totalPages}
      onPageChange={props.onPageChange}
    />
  )
}

MediumItemDataTable.propTypes = propTypes
MediumItemDataTable.defaultProps = defaultProps

export default MediumItemDataTable
