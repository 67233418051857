import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Message, Dimmer, Loader } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { TagGroupApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('TagGroupRemoveModal')
const tagGroupApi = new TagGroupApi()
let deleteTagGroup

const propTypes = {
  /**
   * 削除対象のタグ・グループ
   */
  tagGroup: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * データの更新に成功したときに呼び出す外部関数
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultProps = {
  open: false,
}

class TagGroupRemoveModal extends Component {
  state = {
    isBusy: false,
    isFormValid: false,
    apiError: null,
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(deleteTagGroup)) {
      deleteTagGroup.cancel()
    }
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効なときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = () => {
    const tagGroupId = this.props.tagGroup.id

    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteTagGroup = CancelablePromise(tagGroupApi.deleteTagGroup(tagGroupId))
    deleteTagGroup.promise
      .then(() => {
        // 削除に成功したら一覧を更新
        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }
        this.setState({ isBusy: false }, () => {
          if (this.props.onClose) {
            this.props.onClose()
          }
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('delete tag group error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 削除ボタンを押したときのハンドラ
   */
  handleRemoveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <Modal
        className="TagGroupRemoveModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{`${_.get(this.props.tagGroup, 'name', 'タグ・グループ')} の削除`}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          {/* 警告メッセージ */}
          <Message negative content="確認のため、ゴミ箱へ移動するタグ・グループ名を入力してください。" />

          {/* 削除フォーム */}
          {this.props.tagGroup && (
            <Form
              ref="form"
              noValidate
              onChange={this.handleFormChange}
              onValid={this.handleFormValid}
              onInvalid={this.handleFormInvalid}
              onValidSubmit={this.handleFormValidSubmit}
            >
              {/* タグ・グループ名入力フィールド */}
              <Form.Input
                name="name"
                label="タグ・グループ名"
                placeholder="削除するタグ・グループ名を入力してください"
                validations={{
                  isSameTagGroupName: (values, value) => value === this.props.tagGroup.name,
                }}
                required
              />
            </Form>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            negative
            content="ゴミ箱へ移動"
            onClick={this.handleRemoveButtonClick}
            disabled={!this.state.isFormValid}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

TagGroupRemoveModal.propTypes = propTypes
TagGroupRemoveModal.defaultProps = defaultProps

export default TagGroupRemoveModal
