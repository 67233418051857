import React, { Component } from 'react'
import { Modal, Button, Image, Dimmer, Loader, Divider, Form } from 'semantic-ui-react'
import LogLevel from '../LogLevel'
import _ from 'lodash'

import { CouponBrandUpdateValues, CouponContact, CouponBrandImage } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import CouponCompaniesDropdown from './CouponCompaniesDropdown'

const logger = LogLevel.getLogger('CouponBrandsEditModal')

class CouponBrandsEditModal extends Component {
  static defaultProps = { permission: { hasUpdatePermission: false } }

  constructor(props) {
    super(props)
    this.state = {
      isBusy: false,
      open: false,
      apiError: null,
      couponBrandUpdateValues: this.newCouponBrandUpdateValues(),
      initialCouponBrandUpdateValues: null,
    }
  }

  openModal = () => {
    const couponBrandUpdateValues = this.newCouponBrandUpdateValues(_.cloneDeep(this.props.couponBrand))
    this.setState({
      open: true,
      couponBrandUpdateValues,
      initialCouponBrandUpdateValues: _.cloneDeep(couponBrandUpdateValues),
    })
  }
  closeModal = () => this.setState({ open: false, apiError: null })

  newCouponBrandUpdateValues = couponBrand => {
    const couponBrandUpdateValues = new CouponBrandUpdateValues()
    const couponContact = new CouponContact()
    const couponBrandImage = new CouponBrandImage()
    couponBrandUpdateValues.contact = couponContact
    couponBrandUpdateValues.image = couponBrandImage

    // input value 初期化
    couponBrandUpdateValues.name = ''
    couponBrandUpdateValues.displayName = ''
    couponBrandUpdateValues.contact.tel = ''
    couponBrandUpdateValues.contact.url = ''
    couponBrandUpdateValues.priority = ''

    if (couponBrand) {
      _.merge(couponBrandUpdateValues, couponBrand)
      couponBrandUpdateValues.couponCompanyId = _.get(couponBrandUpdateValues, 'couponCompany.id')
      // 要らない要素を削る
      _.unset(couponBrandUpdateValues, ['couponCompany'])
      _.unset(couponBrandUpdateValues, ['createdAt'])
      _.unset(couponBrandUpdateValues, ['deletedAt'])
      _.unset(couponBrandUpdateValues, ['updatedAt'])
    }

    return couponBrandUpdateValues
  }

  readFileAsDataURL = (targetName, files) => {
    const { couponBrandUpdateValues } = this.state
    const key = targetName
    const file = files[0]
    const reader = new FileReader()

    reader.onload = () => {
      if (_.has(couponBrandUpdateValues, key)) {
        _.set(couponBrandUpdateValues, key, reader.result)
      }
      this.setState({ couponBrandUpdateValues })
    }

    reader.readAsDataURL(file)
  }

  handleChange = event => {
    if (event.dataTransfer) {
      this.readFileAsDataURL(event.target.name, event.dataTransfer.files)
    } else if (event.target.files) {
      this.readFileAsDataURL(event.target.name, event.target.files)
    } else {
      const { couponBrandUpdateValues } = this.state

      const key = event.target.name
      let value = event.target.value
      const type = _.get(event.target, 'type', '')
      if (value.length > 0 && type === 'number') {
        value = _.parseInt(value)
      }

      if (_.has(couponBrandUpdateValues, key)) {
        _.set(couponBrandUpdateValues, key, value)
      }

      this.setState({ couponBrandUpdateValues }, () => {
        logger.debug('state', this.state.couponBrandUpdateValues)
      })
    }
  }

  getRequestParameters = (updateValues, defaultValues) => {
    // 差分を取得する関数
    logger.debug({ updateValues, defaultValues })
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(updateValues, defaultValues)

    // priority が設定されていない場合リセット
    if (_.has(requestParameters, 'priority') && _.isEqual(requestParameters.priority, '')) {
      requestParameters.priority = 0
    }

    return _.omitBy(
      _.pickBy(requestParameters, (v, p) => _.has(defaultValues, p)),
      v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v) && v !== '',
    )
  }

  handleSaveButtonClick = async () => {
    const { couponBrandId } = this.props
    const { couponBrandUpdateValues, initialCouponBrandUpdateValues } = this.state

    const requestParameters = this.getRequestParameters(couponBrandUpdateValues, initialCouponBrandUpdateValues)

    if (!_.isEmpty(requestParameters) && this.props.patchCouponBrand) {
      this.setState({ isBusy: true })
      await this.props.patchCouponBrand(couponBrandId, requestParameters)
      this.setState({ isBusy: false, apiError: this.props.apiError })
    }

    if (!this.props.apiError) {
      this.closeModal()
    }
  }

  handleCouponCompaniesDropdownChange = (event, { value }) => {
    const { couponBrandUpdateValues } = this.state
    couponBrandUpdateValues.couponCompanyId = value
    this.setState({ couponBrandUpdateValues })
  }

  render() {
    const { isBusy, open, apiError, couponBrandUpdateValues } = this.state
    const { couponCompanyId, name, displayName, image, contact, priority } = couponBrandUpdateValues
    const { logo } = image
    const { tel, url } = contact
    const isButtonDisabled = !name || !displayName || _.isNil(couponCompanyId)
    const { permission } = this.props
    const { hasUpdatePermission } = permission

    return (
      <Modal
        size="tiny"
        closeIcon
        open={open}
        onClose={this.closeModal}
        onOpen={this.openModal}
        trigger={<Button disabled={!hasUpdatePermission} icon="edit" />}
      >
        <Modal.Header>クーポン提供ブランドの編集</Modal.Header>
        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={apiError} />

          {/* ローディング */}
          <Dimmer active={isBusy} inverted>
            <Loader />
          </Dimmer>

          <Form onSubmit={this.handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input required label="ブランド名(正式名称)" name="name" value={name} onChange={this.handleChange} />
              <Form.Input
                required
                label="ブランド名(表示用)"
                name="displayName"
                value={displayName}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Field required>
              <label>ブランド保有企業</label>
              <CouponCompaniesDropdown
                ignoreTrashContents={false}
                couponCompanyId={couponCompanyId}
                fluid={true}
                onChange={this.handleCouponCompaniesDropdownChange}
              />
            </Form.Field>

            <Form.Input
              type="file"
              label="ロゴマーク"
              name="image.logo"
              accept={['image/*']}
              onChange={this.handleChange}
            />
            <Image src={logo} size="medium" />

            <Divider hidden />

            <Form.Group widths="equal">
              <Form.Input
                label="電話番号（ハイフンあり）"
                name="contact.tel"
                value={tel}
                onChange={this.handleChange}
              />
              <Form.Input label="問い合わせURL" name="contact.url" value={url} onChange={this.handleChange} />
            </Form.Group>

            <Form.Group>
              <Form.Input
                label="優先度（1,2,3,…の順でクーポンブランド一覧に優先表示）"
                name="priority"
                value={priority}
                type="number"
                placeholder="1以上の整数を入力"
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="キャンセル" onClick={this.closeModal} />

          <Button
            positive
            content="更新"
            type="button"
            onClick={this.handleSaveButtonClick}
            disabled={isButtonDisabled}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CouponBrandsEditModal
