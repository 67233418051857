import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import FormErrorLabel from './FormErrorLabel'

const propTypes = {
  /**
   * 編集対象の関連記事
   */
  article: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * 関連記事変更時時に呼び出す外部関数
   *
   * @param {SyntheticEvent} event - React の SyntheticEvent
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {Object} data.article - 関連記事データ
   */
  onChange: PropTypes.func,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,
}

const defaultProps = {
  article: {},
  open: false,
}

class MediumRelatedArticleEditModal extends Component {
  state = {
    articleId: '',
    isFormValid: false,
    isFormModified: false,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されてるかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.article, nextProps.article)) {
      if (!_.isEmpty(nextProps.article)) {
        this.setState({ articleId: nextProps.key })
      } else {
        this.setState({ articleId: '' })
      }

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.article) {
        this.initialFormValues.title = this.props.article.title
        this.initialFormValues.link = this.props.article.link

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }
      this.setState({
        isFormModified: false,
      })
      this.isFormResetted = true
    }
  }

  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = {
      isFormModified: false,
    }

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(currentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  handleFormValidSubmit = submitFormData => {
    const article = submitFormData
    article.key = this.props.article.key

    _.invoke(this.props, 'onChange', { ...this.props, article })
  }

  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <Modal
        className="MediumRelatedArticleEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{this.state.articleId ? 'メディア関連記事の編集' : 'メディア関連記事の作成'}</Modal.Header>

        <Modal.Content>
          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/* タイトル入力フィールド */}
            <Form.Input name="title" label="タイトル" placeholder="タイトルを入力してください" required />

            {/* リンク入力フィールド */}
            <Form.Input
              name="link"
              label="リンク"
              placeholder="リンク URL を入力してください"
              validations="isUrl"
              validationErrors={{ isUrl: '無効な URL です' }}
              errorLabel={<FormErrorLabel />}
              required
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={this.state.articleId ? '更新' : '保存'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || !this.state.isFormModified}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

MediumRelatedArticleEditModal.propTypes = propTypes
MediumRelatedArticleEditModal.defaultProps = defaultProps

export default MediumRelatedArticleEditModal
