import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Modal, Label, Dimmer, Loader, Button, Message, Dropdown } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { ArticleApi } from 'trill-api-admin-client'

import ApiErrorMessage from '../components/ApiErrorMessage'
import CategoriesDropdown from '../components/CategoriesDropdown'
import MediaDropdown from '../components/MediaDropdown'
import ArticleDataTable from './ArticleDataTable'
import PublishDateRangePicker from './PublishDatetimeRangePicker'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('MatomeEditModal')
const articleApi = new ArticleApi()
let getArticles

const propTypes = {
  /**
   * 記事一覧取得から除外する記事
   */
  ignoreArticles: PropTypes.array,

  /**
   * モーダルの開閉状態
   */
  open: PropTypes.bool,

  /**
   * ローディングの表示状態
   */
  loading: PropTypes.bool,

  /**
   * 追加ボタンを押したときに呼び出す外部関数
   */
  onAdd: PropTypes.func,

  /**
   * キャンセルボタンを押したときに呼び出す外部関数
   */
  onCancel: PropTypes.func,
}

const defaultPropTyes = {
  open: false,
  ignoreArticles: [],
  loading: false,
}

/**
 * まとめに紐づける記事を選択するコンポーネント
 */
class MatomeAddArticleModal extends Component {
  state = {
    isBusy: false,
    articles: [],
    selectedAddArticleIds: [],
    sorting: {},
    filtering: {
      textType: 'title',
      text: '',
      categoryId: null,
      mediumId: null,
      dateRangeStartAt: moment().startOf('month'), // 絞り込み日時の開始日( moment )
      dateRangeEndAt: moment().endOf('month'), // 絞り込み日時の終了日( moment )
      clearDateRange: true,
    },
    currentPage: 1,
    itemsPerPage: 5,
    totalPages: 0,
    totalItems: 0,
    apiError: null,
    isFormSearchValid: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.open, nextProps.open) && nextProps.open) {
      // モーダル画面を開いたときに初期化を行う
      this.setState(
        {
          articles: [],
          selectedAddArticleIds: [],
          currentPage: 1,
          itemsPerPage: 5,
          totalItems: 0,
          totalPages: 0,
          sorting: {},
          filtering: {
            textType: 'title',
            text: '',
            categoryId: null,
            mediumId: null,
            dateRangeStartAt: moment().startOf('month'), // 絞り込み日時の開始日( moment )
            dateRangeEndAt: moment().endOf('month'), // 絞り込み日時の終了日( moment )
            clearDateRange: true,
          },
        },
        () => {
          this.retrieveArticles()
        },
      )
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getArticles)) {
      getArticles.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * 記事一覧のデータテーブルにチェックを入れたときのハンドラ
   */
  handleDataTableSelectionChange = (event, { selectedItems, sort, isHeaderCellClick }) => {
    if (isHeaderCellClick) {
      this.setState({ sorting: sort }, () => {
        this.retrieveArticles()
      })
    } else {
      this.setState({ selectedAddArticleIds: _.keys(selectedItems) })
    }
  }

  /**
   * 記事一覧のデータテーブルのページ情報を更新したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    this.setState(
      {
        currentPage,
        itemsPerPage,
      },
      () => {
        this.retrieveArticles()
      },
    )
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  /**
   * 追加ボタンを押したときのハンドラ
   */
  handleApproveButtonClick = () => {
    if (this.props.onAdd) {
      this.props.onAdd(this.state.selectedAddArticleIds)
    }
  }

  /**
   *  公開日付範囲で絞り込む情報に関するイベントハンドラ
   */
  handleDateRangePickerEvent = (event, picker) => {
    if (event.type === 'apply' || event.type === 'cancel') {
      let startDate
      let endDate
      let clearDateRange

      if (event.type === 'apply') {
        startDate = picker.startDate
        endDate = picker.endDate
      } else if (event.type === 'cancel') {
        startDate = moment().startOf('day')
        endDate = moment().endOf('day')
        clearDateRange = true
      }

      // 日付で検索を行う
      const filtering = this.state.filtering
      filtering.dateRangeStartAt = startDate
      filtering.dateRangeEndAt = endDate
      filtering.clearDateRange = clearDateRange

      this.setState({ filtering }, () => {
        this.retrieveArticles()
      })
    }
  }

  /**
   * タイトル or 本文で絞り込む値を変更したときのハンドラ
   */
  handleTextInputChange = (event, data) => {
    const filtering = this.state.filtering
    filtering.text = data.value

    this.setState({ filtering })
  }

  /**
   * タイトル or 本文で絞り込むドロップダウンの値を変更したときのハンドラ
   */
  handleTextTypeDropdownChange = (event, data) => {
    const filtering = this.state.filtering
    filtering.textType = data.value

    this.setState({ filtering })
  }

  /**
   * カテゴリで絞り込む値を変更したときのハンドラ
   */
  handleCatgoriesDropdownChange = (event, { value }) => {
    const filtering = this.state.filtering
    filtering.categoryId = value

    this.setState({ filtering }, () => {
      this.retrieveArticles()
    })
  }

  /**
   * メディアで絞り込む値を変更したときのハンドラ
   */
  handleMediaDropdownChange = (event, { value }) => {
    const filtering = this.state.filtering
    filtering.mediumId = value

    this.setState({ filtering }, () => {
      this.retrieveArticles()
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveArticles()
  }

  /**
   * 記事一覧取得
   */
  retrieveArticles = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    getArticles = CancelablePromise(articleApi.getArticles(this.getRequestQuery()))
    getArticles.promise
      .then(response => {
        const responseHeader = response.header
        const articles = response.data
        const currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        const itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 5), 10)
        const totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        const totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
        this.setState({
          isBusy: false,
          articles,
          currentPage,
          itemsPerPage,
          totalPages,
          totalItems,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('get articles error', error)

        this.setState({
          isBusy: false,
          articles: [],
          currentPage: 1,
          itemsPerPage: 5,
          totalPages: 0,
          totalItems: 0,
          apiError: error,
        })
      })
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(this.state.totalItems / this.state.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && this.state.currentPage > totalPage ? totalPage : this.state.currentPage
    // 1 ページあたりに含めるデータの数
    const itemsPerPage = this.state.itemsPerPage

    // フィルタリング
    const filtering = []
    // 削除コンテンツは除外
    filtering.push('deletedAt IS NULL')

    // 記事一覧の場合は、すでに特集に紐づいている記事 ID を除外する
    _.each(this.props.ignoreArticles, article => filtering.push(`id != ${article.id}`))

    // 期間での絞り込み
    if (!this.state.filtering.clearDateRange) {
      const dateRangeStartAt = this.state.filtering.dateRangeStartAt
      const dateRangeEndAt = this.state.filtering.dateRangeEndAt
      filtering.push(`publishDatetime >= '${dateRangeStartAt.toISOString()}'`)
      filtering.push(`publishDatetime <= '${dateRangeEndAt.toISOString()}'`)
    }

    // 文字列検索
    if (!_.isEmpty(this.state.filtering.text) && !_.isEmpty(this.state.filtering.textType)) {
      let filteringText = this.state.filtering.text
      if (filteringText.match(/\,/)) { // eslint-disable-line
        filteringText = ''
      }

      const textType = this.state.filtering.textType
      const text = `${textType} LIKE '%${filteringText}%'`

      filtering.push(text)
    }

    const categoryId = this.state.filtering.categoryId
    if (_.isNumber(categoryId) || !_.isEmpty(categoryId)) {
      filtering.push(`categoryId = ${categoryId}`)
    }

    const mediumId = this.state.filtering.mediumId
    if (_.isNumber(mediumId) || !_.isEmpty(mediumId)) {
      filtering.push(`mediumId = ${mediumId}`)
    }

    // ソートを配列に変換
    const sorting = _.map(this.state.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  render() {
    const active = this.state.isBusy || this.props.loading

    const formErrorLabel = <Label color="red" pointing />

    return (
      <Modal
        className="MatomeAddArticleModal"
        size="large"
        closeIcon={true}
        open={this.props.open}
        onClose={this.handleCancelButtonClick}
        closeOnDimmerClick={false}
      >
        <Modal.Header>記事の追加</Modal.Header>

        <Dimmer active={active} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* 検索エリア */}
          <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
            <Form.Field>
              <Form.Input
                name="matome-add-article-search"
                type="text"
                placeholder={_.isEqual(this.state.filtering.textType, 'title') ? 'タイトルで検索' : '本文で検索'}
                action
                fluid
                value={this.state.filtering.text}
                onChange={this.handleTextInputChange}
                validations={{ matchRegexp: /^((?!,).)*$/i }}
                validationErrors={{
                  matchRegexp: 'キーワードに不正な記号があるため検索できません',
                }}
                errorLabel={formErrorLabel}
              >
                <input />

                <Dropdown
                  onChange={this.handleTextTypeDropdownChange}
                  value={this.state.filtering.textType}
                  selection
                  options={[
                    {
                      key: 'title',
                      value: 'title',
                      text: 'タイトル',
                    },
                    {
                      key: 'description',
                      value: 'description',
                      text: '本文',
                    },
                  ]}
                />

                <Button icon="search" onClick={this.handleSearchButtonClick} disabled={!this.state.isFormSearchValid} />
              </Form.Input>
            </Form.Field>

            <Form.Group widths="equal">
              <Form.Field>
                <PublishDateRangePicker
                  startDate={this.state.filtering.dateRangeStartAt}
                  endDate={this.state.filtering.dateRangeEndAt}
                  onEvent={this.handleDateRangePickerEvent}
                  clear={this.state.filtering.clearDateRange}
                  disabled={!this.state.isFormSearchValid}
                />
              </Form.Field>

              <Form.Field>
                <Form.Field>
                  <CategoriesDropdown
                    categoryId={this.state.filtering.categoryId}
                    fluid={false}
                    onChange={this.handleCatgoriesDropdownChange}
                    disabled={!this.state.isFormSearchValid}
                  />
                </Form.Field>
              </Form.Field>

              <Form.Field>
                <Form.Field>
                  <MediaDropdown
                    mediumId={this.state.filtering.mediumId}
                    fluid={false}
                    onChange={this.handleMediaDropdownChange}
                    disabled={!this.state.isFormSearchValid}
                  />
                </Form.Field>
              </Form.Field>
            </Form.Group>
          </Form>

          {/* 件数表示メッセージ */}
          {_.isEmpty(this.state.apiError) && (
            <Message info>
              {this.state.totalItems} 件が該当します
              {_.size(this.state.articles) > 0 && ' / おすすめ記事に設定したい記事を選択してください'}
            </Message>
          )}

          {/* API エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          {!_.isEmpty(this.state.articles) && (
            <ArticleDataTable
              articles={this.state.articles}
              sort={this.state.sorting}
              selectable={true}
              currentPage={this.state.currentPage}
              itemsPerPage={this.state.itemsPerPage}
              totalPages={this.state.totalPages}
              onSelectionChange={this.handleDataTableSelectionChange}
              onPageChange={this.handleDataTablePageChange}
              extColumns={[
                {
                  label: '参照',
                  align: 'center',
                  render: item => (
                    <Button
                      color="black"
                      as="a"
                      icon="world"
                      href={`${process.env.REACT_APP_TRILL_WEB_URL}/articles/${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                },
              ]}
            />
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button positive content="追加" onClick={this.handleApproveButtonClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

MatomeAddArticleModal.propTypes = propTypes
MatomeAddArticleModal.defaultPropTyes = defaultPropTyes
export default MatomeAddArticleModal
